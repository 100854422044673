/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    IToastsService,
    IToastsServiceTypeName
} from "sirius-platform-support-library/shared/toasts/toasts-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {ToastsService, ToastsServiceTypeName} from "./toasts-service";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    IThemeHandler,
    IThemeHandlerTypeName
} from "sirius-platform-support-library/shared/theming/management/handlers/theme-handler.interface";

export class ToastsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'toasts'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/libs/@uxd/clarity-theme/dist/',
                        resourceNameTemplate: 'uxd-clarity-dark-theme.css'
                    },
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        dependencyContainer
            .addUsingFactoryAsSingleton<IToastsService, ToastsService>(DependencyType.SHARED, IToastsServiceTypeName, ToastsServiceTypeName, (di) => {
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const themingHandlersManager = di.resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
                return ToastsService.build(
                    window,
                    document,
                    tenant,
                    localizationService,
                    eventBus,
                    styleSheetsBasedThemeHandler,
                    themingHandlersManager,
                    di
                );
            })
            .addInterfacesOnSingleton(ToastsServiceTypeName, IAfterPlatformReadyInitTypeName)
            .addUsingInstanceAsSingleton<IThemeHandler, StyleSheetsBasedThemeHandler>(DependencyType.PRIVATE, IThemeHandlerTypeName, styleSheetsBasedThemeHandler.getClassName(), styleSheetsBasedThemeHandler);
    }
}

export const toastsDependencyModule = new ToastsDependencyModule();

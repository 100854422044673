/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {Tenant} from "../tenant/tenant";
import {SystemJs} from "../dynamic-imports/system-js";
import {IPlatform} from "./platform.interface";
import {ITenantContextLoader} from "../tenant/context/tenant-context.loader.interface";
import {ITenantCustomBootstrapCodeLoader} from "../tenant/custom-code/tenant-custom-bootstrap-code.loader.interface";
import {ISiteStyler} from "../site/stylers/site-styler.interface";
import {
    ITenantCustomBootstrapCode
} from "sirius-shell-support-library/tenants/custom-code/tenant-custom-bootstrap-code.interface";
import {PlatformInitializationAwaiter} from "./platform-initialization-awaiter";
import {IApplication} from "../../micro-frontends/application.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    IDependencyContainer
} from "sirius-platform-support-library/dependency-injection/generic/dependency-container.interface";
import {SiteConfig, TenantContext} from "sirius-platform-support-library/tenants/tenant-context";
import {EnvironmentContext} from "../environment/environment-context";
import {ServicesInitializer} from "../initializer/services-initializer";
import {ISiteComponents} from "../site/components/site-components.interface";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {LoadingIndicatorService} from "../loading-indicators/loading-indicator.service";
import {
    IDeviceTraitsService
} from "sirius-platform-support-library/shared/device-traits/device-traits-service.interface";
import {LocalizationService} from "../localization/localization-service";
import {TenantContextLoaderError} from "../tenant/tenant-context-loader.error";
import {
    FullScreenDefaultPageNavigateToOptions
} from "sirius-platform-support-library/shared/site/default-pages/default-page-navigate-to.options";

export const PlatformTypeName = 'Platform';

export class Platform implements IPlatform {
    public static readonly GLOBAL_KEY = 'window.sirius.shell.platform';
    private readonly window: Window;
    private readonly environmentContext: EnvironmentContext;
    private readonly systemJs: SystemJs;
    private readonly platformInitializationAwaiter: PlatformInitializationAwaiter;
    private readonly tenant: Tenant;
    private readonly tenantContextLoader: ITenantContextLoader;
    private readonly tenantCustomBootstrapCodeLoader: ITenantCustomBootstrapCodeLoader;
    private readonly siteStyler: ISiteStyler;
    private readonly servicesInitializer: ServicesInitializer;
    private readonly application: IApplication;
    private readonly dependencyContainer: IDependencyContainer;
    private readonly siteComponents: ISiteComponents;
    private readonly defaultPagesService: IDefaultPagesService;
    private readonly loadingIndicatorService: LoadingIndicatorService;
    private readonly deviceTraitsService: IDeviceTraitsService;
    private readonly localizationService: LocalizationService;

    constructor(
        window: Window,
        environmentContext: EnvironmentContext,
        systemJs: SystemJs,
        platformInitializationAwaiter: PlatformInitializationAwaiter,
        tenant: Tenant,
        tenantContextLoader: ITenantContextLoader,
        tenantCustomBootstrapCodeLoader: ITenantCustomBootstrapCodeLoader,
        siteStyler: ISiteStyler,
        servicesInitializer: ServicesInitializer,
        application: IApplication,
        dependencyContainer: IDependencyContainer,
        siteComponents: ISiteComponents,
        defaultPagesService: IDefaultPagesService,
        loadingIndicatorService: LoadingIndicatorService,
        deviceTraitsService: IDeviceTraitsService,
        localizationService: LocalizationService
    ) {
        this.window = window;
        this.environmentContext = environmentContext;
        this.systemJs = systemJs;
        this.platformInitializationAwaiter = platformInitializationAwaiter;
        this.tenant = tenant;
        this.tenantContextLoader = tenantContextLoader;
        this.tenantCustomBootstrapCodeLoader = tenantCustomBootstrapCodeLoader;
        this.siteStyler = siteStyler;
        this.servicesInitializer = servicesInitializer;
        this.application = application;
        this.dependencyContainer = dependencyContainer;
        this.siteComponents = siteComponents;
        this.defaultPagesService = defaultPagesService;
        this.loadingIndicatorService = loadingIndicatorService;
        this.deviceTraitsService = deviceTraitsService;
        this.localizationService = localizationService;
    }

    public static getInstance(): Platform {
        const instance = ObjectUtility.getFromObjectPath<Platform>(Platform.GLOBAL_KEY);
        if (!instance) {
            throw new Error('Platform was not initialized')
        }
        return instance;
    }

    public async initialize(): Promise<IPlatform> {
        const envCode = this.environmentContext.getCode();
        console.debug(`Sirius environment ${envCode}`);
        console.debug('Sirius platform is initializing...');

        const isOnErrorPage = this.defaultPagesService.isOnFullScreenErrorPage();

        await this.loadingIndicatorService.show();

        let tenantContext = undefined;
        try {
            tenantContext = await this.tenantContextLoader.load(isOnErrorPage);
            if (!tenantContext) {
                throw Error('Failed to load tenant context. Sirius is unable to render any further.');
            }
            this.tenant.setContext(tenantContext);
        } catch (e) {
            console.error('Encountered an exception while loading tenant context.', e)
            if (!isOnErrorPage) {
                let options: FullScreenDefaultPageNavigateToOptions = {
                    footer: {
                        visible: false
                    }
                };
                if (e instanceof TenantContextLoaderError && e.defaultedDueToUnavailability) {
                    options = {
                        header: {
                            visible: false
                        },
                        ...options,
                    };
                }
                await this.defaultPagesService.navigateToFullScreenErrorPage(this, options);
                return;
            }
        }

        await this.localizationService.preInit();

        let tenantCustomBootstrapCode = undefined;
        if (!isOnErrorPage) {
            try {
                tenantCustomBootstrapCode = await this.tenantCustomBootstrapCodeLoader.load(tenantContext?.tenantCustomCodeLibrary);
            } catch (e) {
                await this.defaultPagesService.navigateToFullScreenErrorPage(this);
                return;
            }
        }

        await this.processTenantCustomBootstrapCode(tenantCustomBootstrapCode);

        await this.deviceTraitsService.refresh();

        await this.servicesInitializer.initializeServicesBeforePlatformReady();

        await this.onInitialized();

        this.siteComponents.bootstrapRequiredComponents(isOnErrorPage);

        await this.applySiteStyle(tenantContext?.site);

        await this.prepareApplication(tenantContext);

        ObjectUtility.assignOnObjectPath(Platform.GLOBAL_KEY, this);

        console.debug('Sirius platform initialization completed.');

        this.platformInitializationAwaiter.done(this);

        return this;
    }

    public async start(): Promise<IPlatform> {
        if (!this.defaultPagesService.isOnFullScreenErrorPage()) {
            await this.application.start();
            console.debug('Sirius platform is ready (page is interactive).');
        }

        await this.loadingIndicatorService.autoHideIfConfigured(true, 3000); // delay for 3 seconds the hiding of the loading indicator

        await this.onReady();

        return this;
    }

    private async processTenantCustomBootstrapCode(tenantCustomBootstrapCode: ITenantCustomBootstrapCode): Promise<void> {
        this.tenant.setBootstrapCode(tenantCustomBootstrapCode);

        if (!tenantCustomBootstrapCode) {
            return;
        }

        console.debug(`Registered '${this.tenant.getContext()?.tenantCode}' tenant context.`);

        const bootstrapCode = this.tenant.getBootstrapCode();
        if (bootstrapCode.imports) {
            try {
                console.debug('Invoking tenant bootstrap code - imports.');
                await bootstrapCode.imports.apply(this.window, []);
            } catch (e) {
                console.error('Encountered an exception while running tenant custom code for platform `imports`', e);
            }
        }

        try {
            console.debug('Invoking tenant bootstrap code - bootstrap.');
            await bootstrapCode.bootstrap(this.tenant.getContext(), this.dependencyContainer);
        } catch (e) {
            console.error('Encountered an exception while running tenant custom code for platform `bootstrap`', e);
            throw e;
        }
    }

    private async applySiteStyle(siteConfig?: SiteConfig): Promise<void> {
        await this.siteStyler.style(siteConfig);
        console.debug('Applied site styles according to tenant context.');
    }

    private async prepareApplication(tenantContext: TenantContext): Promise<void> {
        if (this.defaultPagesService.isOnFullScreenErrorPage()) {
            return;
        }

        await this.application.bootstrap(tenantContext);
        console.debug('Micro-frontend application was bootstrapped.');
    }

    private async onInitialized(): Promise<void> {
        const bootstrapCode = this.tenant.getBootstrapCode();
        if (bootstrapCode) {
            try {
                console.debug('Invoking tenant bootstrap code - initialized.');
                await bootstrapCode.initialized();
            } catch (e) {
                console.error('Encountered an exception while running tenant custom code for platform `initialized`', e);
            }
        }
    }

    private async onReady(): Promise<void> {
        await this.servicesInitializer.initializeServicesAfterPlatformReady();

        const bootstrapCode = this.tenant.getBootstrapCode();
        if (bootstrapCode) {
            console.debug('Invoking tenant bootstrap code - ready.');
            bootstrapCode.ready().catch(e => {
                console.error('Encountered an exception while running tenant custom code for platform `ready` state', e);
            });
        }
    }
}

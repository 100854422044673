/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ISplashScreenService
} from "sirius-platform-support-library/shared/splash-screen/splash-screen-service.interface";
import {ISplashScreenModule} from "sirius-platform-support-library/shared/splash-screen/splash-screen-module.interface";
import {SplashScreenDomHandler} from "./splash-screen.dom-handler";
import {SystemJs} from "../dynamic-imports/system-js";
import {RuntimeConfigurationLoader} from "../runtime-configuration/runtime-configuration.loader";
import {pathToRegexp} from "path-to-regexp";
import {
    SplashScreenModuleOptions
} from "sirius-platform-support-library/shared/splash-screen/splash-screen-module.options";
import {retry} from "sirius-platform-support-library/utilities/promises/retry-promise";

export const SplashScreenServiceTypeName = 'SplashScreenService';

export class SplashScreenService implements ISplashScreenService {
    public static readonly SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER = '{{TENANT_SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER}}';
    public static readonly DEFAULT_SPLASH_SCREEN_BASE_URL = '/splash-screen';
    public static readonly DEFAULT_SPLASH_SCREEN_MINIFIED_BUNDLE_URL = `${SplashScreenService.DEFAULT_SPLASH_SCREEN_BASE_URL}/main.min.js`;
    public static readonly DEFAULT_SPLASH_SCREEN_BUNDLE_URL = `${SplashScreenService.DEFAULT_SPLASH_SCREEN_BASE_URL}/main.js`;

    private readonly window: Window;
    private readonly systemJs: SystemJs;
    private readonly runtimeConfigurationLoader: RuntimeConfigurationLoader;
    private readonly splashScreenDomHandler: SplashScreenDomHandler;

    private failFast: boolean = false;
    private splashScreenModule?: ISplashScreenModule;

    public constructor(
        window: Window,
        systemJs: SystemJs,
        runtimeConfigurationLoader: RuntimeConfigurationLoader,
        splashScreenDomHandler: SplashScreenDomHandler
    ) {
        this.window = window;
        this.systemJs = systemJs;
        this.runtimeConfigurationLoader = runtimeConfigurationLoader;
        this.splashScreenDomHandler = splashScreenDomHandler;
        this.splashScreenDomHandler.register(this.getSplashScreenModule.bind(this));
    }

    public async getOptions(): Promise<SplashScreenModuleOptions | undefined> {
        try {
            const module = await this.getSplashScreenModule();
            return module?.getOptions ? await module?.getOptions() : undefined;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public async isAvailable(): Promise<boolean> {
        const module = await this.getSplashScreenModule();
        return !!module?.isRegistered();
    }

    public async shouldIgnoreForRoute(route?: string): Promise<boolean> {
        const module = await this.getSplashScreenModule();
        const routes = module?.getOptions ? module?.getOptions()?.ignoreOnRoutes ?? [] : [];
        return this.isRouteMatching(routes, route);
    }

    public async show(): Promise<void> {
        if (this.splashScreenDomHandler.isShown()) {
            return;
        }
        await this.splashScreenDomHandler.show();
    }

    public async hide(): Promise<void> {
        if (!this.splashScreenDomHandler.isShown()) {
            return;
        }
        await this.splashScreenDomHandler.hide();
    }

    public isShown(): boolean {
        return this.splashScreenDomHandler.isShown();
    }

    public setFailFast(failFast: boolean): void {
        this.failFast = failFast;
    }

    private async getSplashScreenModule(): Promise<ISplashScreenModule | undefined> {

        if (this.splashScreenModule) {
            return this.splashScreenModule;
        }
        try {
            let bundleUrlDefined = false;
            const options = this.runtimeConfigurationLoader?.getRuntimeOptionsConfigurator()?.getRuntimeOptions();
            const argsUrl = options?.splashScreenBundleUrl;
            let urls: string[] = [];
            if (argsUrl && argsUrl !== SplashScreenService.SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER) {
                bundleUrlDefined = true;
                urls.push(argsUrl);
            }
            // @ts-ignore
            if (!bundleUrlDefined && this.window?.sirius?.config?.tenantSplashScreenEnabled?.toString() != 'true') {
                return undefined;
            }
            urls = [
                ...urls,
                SplashScreenService.DEFAULT_SPLASH_SCREEN_MINIFIED_BUNDLE_URL,
                SplashScreenService.DEFAULT_SPLASH_SCREEN_BUNDLE_URL
            ]
            for (let index = 0; index < urls.length; index++) {
                const url = urls[index];
                if (!url) {
                    continue;
                }
                const module = await this.getSplashScreenModuleByUrl(url);
                if (module) {
                    this.splashScreenModule = module;
                    if (this.splashScreenModule?.register) {
                        this.splashScreenModule?.register();
                    }
                    break;
                }
            }
        } catch (e) {
            console.debug(e);
            this.splashScreenModule = undefined;
        }
        return this.splashScreenModule;
    }

    private async getSplashScreenModuleByUrl(url: string): Promise<ISplashScreenModule | undefined> {
        try {
            if (url.startsWith('https://') || url.startsWith('http://')) {
                return this.failFast ? await this.systemJs.import<ISplashScreenModule>(url) : await retry(() => this.systemJs.import<ISplashScreenModule>(url), {
                    retries: 3,
                    backoff: "EXPONENTIAL",
                    maxBackOff: 5 * 1000
                }) as ISplashScreenModule;
            } else {
                return await this.systemJs.import<ISplashScreenModule>(url) as ISplashScreenModule;
            }
        } catch (e) {
            console.debug(e);
            return undefined;
        }
    }

    private isRouteMatching(routes: string[], route?: string): boolean {
        if (!route) {
            return false;
        }
        const matches = routes.some((r: string) => {
            const routeTemplate = r.replaceAll('*', '(.*)');
            const expression = pathToRegexp(routeTemplate, undefined, {end: true});
            return expression.test(route);
        });
        return !!matches;
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {EnvironmentContext} from "./environment-context";
import {
    IEnvironmentCodeProvider
} from "sirius-platform-support-library/shared/environment/environment-code-provider.interface";
import {EnvironmentDefinition, EnvironmentType} from "sirius-platform-support-library/tenants/tenant-context";

export const EnvironmentCodeProviderTypeName = 'EnvironmentCodeProvider';

export class EnvironmentCodeProvider implements IEnvironmentCodeProvider {
    private readonly environmentContext: EnvironmentContext;

    constructor(environmentContext: EnvironmentContext) {
        this.environmentContext = environmentContext;
    }

    public getCodeWithGivenEnvironments(environments: Record<string, EnvironmentDefinition>, environmentType?: EnvironmentType | string): string | undefined {
        return this.environmentContext.getCodeWithGivenEnvironments(environments, environmentType);
    }

    public getCode(environmentType?: EnvironmentType | string): string | undefined {
        return this.environmentContext.getCode(environmentType);
    }

    public isProductionEnvironment(): boolean {
        return this.environmentContext.isProductionEnvironment();
    }
}

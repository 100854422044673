/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {KeyValuePair} from "sirius-platform-support-library/models/common";

export const ManagedSessionCookieClaimsMapperTypeName = 'ManagedSessionCookieClaimsMapper';

export class ManagedSessionCookieClaimsMapper {
    public map(dellClaims: KeyValuePair[]): Record<string, string> {
        const claims: Record<string, string> = {};
        dellClaims.forEach(keyValuePair => {
            claims[keyValuePair.key] = keyValuePair.value;
        });
        return claims;
    }
}

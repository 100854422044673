/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {HistoryEntry} from "../history-entry";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';
import {NavigationHistoryEntryShim} from "./navigation-history-entry.shim";
import {sanitizeUrl} from "@braintree/sanitize-url";

// Used for Firefox where window.navigation is not supported
export class NavigationShim {
    private static readonly ENTRIES_KEY = 'sirius.navigation-shim.entries';

    private readonly window: Window;

    private readonly history: NavigationHistoryEntryShim[];

    private constructor(window: Window) {
        this.history = [];

        this.window = window;
        this.window.addEventListener('load', (event: Event) => {
            const url = sanitizeUrl(this.window.location.href);
            this.addEntry(url);
        });
        this.window.addEventListener('popstate', (event: PopStateEvent) => {
            const url = sanitizeUrl(this.window.location.href);
            this.addEntry(url, true);
        });
        this.window.addEventListener('hashchange', (event: HashChangeEvent) => {
            const url = sanitizeUrl(this.window.location.href);
            this.addEntry(url);
        });
    }

    public static apply(window: Window): void {
        // @ts-ignore
        if (!window.navigation) {
            // @ts-ignore
            window.navigation = new NavigationShim(window);
        }
    }

    public get currentEntry(): NavigationHistoryEntryShim {
        const url = sanitizeUrl(this.window.location.href);
        return _.findLast(this.history, (item: NavigationHistoryEntryShim) => item.url === url) ?? {
            id: uuidv4(),
            key: uuidv4(),
            index: 0,
            url: url,
            sameDocument: true
        };
    }

    public entries(): HistoryEntry[] {
        return this.history;
    }

    private addEntry(url: string, exclusive: boolean = false) {
        const entries = this.entries();
        const lastEntry = _.last(entries);
        if (exclusive && entries.some(e => e.url === url) || lastEntry?.url === url) {
            return;
        }
        const index = entries.length;
        this.history.push({
            id: uuidv4(),
            key: uuidv4(),
            index: index,
            url: url,
            sameDocument: true
        });
    }
}

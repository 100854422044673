/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {DynamicMenuService, DynamicMenuServiceTypeName} from "./dynamic-menu.service";
import {
    IDynamicMenuService,
    IDynamicMenuServiceTypeName
} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {MenuItemMapper, MenuItemMapperTypeName} from "./mappers/menu-item.mapper";
import {
    PlatformDynamicMenuCustomizer,
    PlatformDynamicMenuCustomizerTypeName
} from "./customizer/platform-dynamic-menu.customizer";
import {DynamicMenuItemBuilder, DynamicMenuItemBuilderTypeName} from "./builder/dynamic-menu-item.builder";
import {
    IDynamicMenuItemBuilder,
    IDynamicMenuItemBuilderTypeName
} from "sirius-platform-support-library/shared/dynamic-menu/builder/dynamic-menu-item-builder.interface";
import {
    IDynamicMenuCustomizer,
    IDynamicMenuCustomizerTypeName
} from "sirius-platform-support-library/shared/dynamic-menu/customizer/dynamic-menu-customizer.interface";
import {
    IAuthorizationService,
    IAuthorizationServiceTypeName
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import {
    IDynamicMenuOperations,
    IDynamicMenuOperationsTypeName
} from "sirius-platform-support-library/shared/dynamic-menu/operations/dynamic-menu-operations.interface";
import {DynamicMenuOperations, DynamicMenuOperationsTypeName} from "./operations/dynamic-menu-operations";

export class DynamicMenuDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IDynamicMenuItemBuilder, DynamicMenuItemBuilder>(DependencyType.SHARED, IDynamicMenuItemBuilderTypeName, DynamicMenuItemBuilderTypeName, (di) => {
                const menuItemMapper = di.resolve<MenuItemMapper>(MenuItemMapperTypeName);
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);

                return new DynamicMenuItemBuilder(
                    menuItemMapper,
                    localizationService,
                    di
                );
            })
            .addUsingFactoryAsSingleton<IDynamicMenuOperations, DynamicMenuOperations>(DependencyType.SHARED, IDynamicMenuOperationsTypeName, DynamicMenuOperationsTypeName, (di) => {
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const menuItemMapper = di.resolve<MenuItemMapper>(MenuItemMapperTypeName);
                const dynamicMenuItemBuilder = di.resolve<IDynamicMenuItemBuilder>(IDynamicMenuItemBuilderTypeName);

                return new DynamicMenuOperations(
                    localizationService,
                    menuItemMapper,
                    dynamicMenuItemBuilder,
                    di
                );
            })
            .addUsingFactoryAsSingleton<PlatformDynamicMenuCustomizer>(DependencyType.PRIVATE, PlatformDynamicMenuCustomizerTypeName, (di) => {
                const dynamicMenuCustomizer = di.resolve<IDynamicMenuCustomizer>(IDynamicMenuCustomizerTypeName);

                return new PlatformDynamicMenuCustomizer(
                    dynamicMenuCustomizer
                );
            })
            .addUsingInstanceAsSingleton<MenuItemMapper>(DependencyType.PRIVATE, MenuItemMapperTypeName, new MenuItemMapper())
            .addUsingFactoryAsSingleton<IDynamicMenuService, DynamicMenuService>(DependencyType.SHARED, IDynamicMenuServiceTypeName, DynamicMenuServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const localizationService = di.resolve<ILocalizationService>(ILocalizationServiceTypeName);
                const dynamicMenuOperations = di.resolve<IDynamicMenuOperations>(IDynamicMenuOperationsTypeName);
                const dynamicMenuCustomizer = di.resolve<PlatformDynamicMenuCustomizer>(PlatformDynamicMenuCustomizerTypeName);
                const authorizationService = di.resolve<IAuthorizationService>(IAuthorizationServiceTypeName);

                return DynamicMenuService.build(
                    window,
                    tenant,
                    eventBus,
                    localizationService,
                    dynamicMenuOperations,
                    dynamicMenuCustomizer,
                    authorizationService,
                    di
                );
            })
            .addInterfacesOnSingleton(DynamicMenuServiceTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const dynamicMenuDependencyModule = new DynamicMenuDependencyModule();

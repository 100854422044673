/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IEnvironmentContext} from "sirius-platform-support-library/shared/environment/environment-context.interface";
import {ServerTimingsHeaderEnvironmentCodeProvider} from "./server-timings-header-environment-code-provider";
import {TenantContextEnvironmentCodeProvider} from "./tenant-context-environment-code-provider";
import {EnvironmentsDefinitionsCodeProcessor} from "./environments-definitions-code-processor";
import {EnvironmentDefinition, EnvironmentType} from "sirius-platform-support-library/tenants/tenant-context";

export const EnvironmentContextTypeName = 'EnvironmentContext';

export class EnvironmentContext implements IEnvironmentContext {
    public static readonly GLOBAL_KEY = 'window.sirius.shared.environment';

    public static build(
        environmentsDefinitionsCodeProcessor: EnvironmentsDefinitionsCodeProcessor,
        tenantContextEnvironmentCodeProvider: TenantContextEnvironmentCodeProvider,
        serverTimingsHeaderEnvironmentCodeProvider: ServerTimingsHeaderEnvironmentCodeProvider
    ): EnvironmentContext {
        let instance = ObjectUtility.getFromObjectPath<EnvironmentContext>(EnvironmentContext.GLOBAL_KEY);
        if (!instance) {
            instance = new EnvironmentContext(
                environmentsDefinitionsCodeProcessor,
                tenantContextEnvironmentCodeProvider,
                serverTimingsHeaderEnvironmentCodeProvider
            );
            ObjectUtility.assignOnObjectPath(EnvironmentContext.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): EnvironmentContext {
        return ObjectUtility.getFromObjectPath(EnvironmentContext.GLOBAL_KEY);
    }

    private readonly environmentsDefinitionsCodeProcessor: EnvironmentsDefinitionsCodeProcessor;
    private readonly tenantContextEnvironmentCodeProvider: TenantContextEnvironmentCodeProvider;
    private readonly serverTimingsHeaderEnvironmentCodeProvider: ServerTimingsHeaderEnvironmentCodeProvider;

    private constructor(
        environmentsDefinitionsCodeProcessor: EnvironmentsDefinitionsCodeProcessor,
        tenantContextEnvironmentCodeProvider: TenantContextEnvironmentCodeProvider,
        serverTimingsHeaderEnvironmentCodeProvider: ServerTimingsHeaderEnvironmentCodeProvider
    ) {
        this.environmentsDefinitionsCodeProcessor = environmentsDefinitionsCodeProcessor;
        this.tenantContextEnvironmentCodeProvider = tenantContextEnvironmentCodeProvider;
        this.serverTimingsHeaderEnvironmentCodeProvider = serverTimingsHeaderEnvironmentCodeProvider;
    }

    public getCodeWithGivenEnvironments(environments: Record<string, EnvironmentDefinition>, environmentType?: EnvironmentType | string): string | undefined {
        let code = this.environmentsDefinitionsCodeProcessor.getCodeWithGivenEnvironments(environments, environmentType);
        if (!code) {
            code = this.getCode(environmentType);
        }
        return code;
    }

    public getCode(environmentType?: EnvironmentType | string): string | undefined {
        let code = this.tenantContextEnvironmentCodeProvider.getCode(environmentType);
        if (!code) {
            code = this.serverTimingsHeaderEnvironmentCodeProvider.getCode();
        }
        return code;
    }

    public isProductionEnvironment(): boolean {
        let productionEnvironment = this.tenantContextEnvironmentCodeProvider.isProductionEnvironment();
        if (productionEnvironment === undefined) {
            productionEnvironment = this.serverTimingsHeaderEnvironmentCodeProvider.isProductionEnvironment();
        }
        return productionEnvironment;
    }
}

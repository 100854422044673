/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IMicroFrontendLoadingSkeleton
} from "sirius-platform-support-library/shared/site/loaders/micro-frontend-loading-skeleton.interface";
import {SkeletonLoaderConstants} from "sirius-platform-support-library/shared/site/loaders/skeleton-loader.constants";
import {SiriusFooterSkeleton} from "./web-elements/footer-skeleton.web-element";

export const FooterLoaderSkeletonTypeName = 'FooterLoaderSkeleton';

export class FooterLoaderSkeleton implements IMicroFrontendLoadingSkeleton {
    public for(): string {
        return SkeletonLoaderConstants.FOOTER_LOADER;
    }

    public getMarkup(): string {
        return `<${SiriusFooterSkeleton.CUSTOM_ELEMENT_NAME}></${SiriusFooterSkeleton.CUSTOM_ELEMENT_NAME}>`
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {defineCustomElements as initSkeleton} from 'skeleton-webcomponent-loader/loader/index';
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";

export class SiriusContentSkeleton extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-content-skeleton';

    public static styles = css`
      :host {
        width: 100%;
      }

      .container {
        height: 535px;
        max-height: 535px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 12px;
        padding-bottom: 14px;
      }
    `;

    public render() {
        return html`
            <script src="/libs/skeleton-webcomponent-loader/dist/esm/skeleton-webcomponent.js"></script>

            <div class="container">
            </div>
        `;
    }

    public async connectedCallback() {
        super.connectedCallback();
        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'content-skeleton',
                suffix: 'skeleton'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.renderRoot, DomInjectionType.PREPEND);

        const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
        if (themingHandlersManager) {
            themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
            await themingHandlersManager.attach(ThemedComponent.SHELL_CONTENT_SKELETON, this.renderRoot, DomInjectionType.PREPEND);
        }

        await initSkeleton();
    }
}

if (!customElements.get(SiriusContentSkeleton.CUSTOM_ELEMENT_NAME)) {
    customElements.define(SiriusContentSkeleton.CUSTOM_ELEMENT_NAME, SiriusContentSkeleton);
}


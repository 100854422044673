/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    Action,
    ActionTargetEnum,
    ActionTypeEnum,
    ButtonStyle,
    ButtonType,
    RequiredAuthorization
} from "sirius-platform-support-library/models/common";
import {DialogAction} from "sirius-platform-support-library/shared/dialogs/dialog-action";
import {ILocalizable} from "sirius-platform-support-library/shared/localization/localizable.interface";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {DialogActionTemplate} from "./templates/dialog-action.template";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IActionHandler} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {IButtonStyleProcessor} from "../common-ui/buttons/button-style.processor.interface";

export class RendarableDialogAction implements DialogAction, ILocalizable {
    public static readonly ACTION_CODE_PLACEHOLDER = '{{actionCode}}';
    public static readonly CLASSES_PLACEHOLDER = '{{classes}}';
    public static readonly LOCALIZED_TEXT_PLACEHOLDER = '{{localizedText}}';
    public type: ActionTypeEnum;
    public code: string;
    public localizationCode: string;
    public action: any;
    public target?: ActionTargetEnum;
    public data?: any;
    public closeAfterAction: boolean;
    public buttonType: ButtonType;
    public buttonStyle: ButtonStyle;
    public localizedText: string;
    public authorization?: RequiredAuthorization;
    private readonly translationService: ITranslationService;
    private readonly actionHandler: IActionHandler;
    private readonly buttonStyleProcessor: IButtonStyleProcessor;

    public constructor(
        action: DialogAction,
        translationService: ITranslationService,
        actionHandler: IActionHandler,
        buttonStyleProcessor: IButtonStyleProcessor
    ) {
        this.actionHandler = actionHandler;
        this.translationService = translationService;
        this.buttonStyleProcessor = buttonStyleProcessor;

        this.type = action.type;
        this.code = action.code;
        this.action = action.action;
        this.target = action.target;
        this.data = action.data;

        this.closeAfterAction = ObjectUtility.isDefined(action.closeAfterAction) ? action.closeAfterAction : false;

        this.buttonType = action.buttonType ?? ButtonType.OUTLINE;
        this.buttonStyle = action.buttonStyle ?? ButtonStyle.UNSET;

        this.localizationCode = action.localizationCode;
        this.localizedText = this.localizationCode;
        this.authorization = action.authorization;
    }

    public async localize(): Promise<void> {
        if (!this.localizationCode) {
            return;
        }
        try {
            this.localizedText = await this.translationService.translate(this.localizationCode!!);
        } catch (e) {
            console.warn('Could not localize banner alert action', this, e);
        }
    }

    public toHtml(): string {
        return DialogActionTemplate
            .replaceAll(RendarableDialogAction.ACTION_CODE_PLACEHOLDER, this.code)
            .replaceAll(RendarableDialogAction.CLASSES_PLACEHOLDER, this.getSuitableClasses().join(' '))
            .replaceAll(RendarableDialogAction.LOCALIZED_TEXT_PLACEHOLDER, this.localizedText);
    }

    public toDialogAction(): DialogAction {
        return {
            type: this.type,
            code: this.code,
            localizationCode: this.localizationCode,
            action: this.action,
            target: this.target,
            data: this.data,
            closeAfterAction: this.closeAfterAction,
            buttonType: this.buttonType,
            buttonStyle: this.buttonStyle,
            authorization: this.authorization
        }
    }

    private getSuitableClasses(): string[] {
        const classes = ['btn', 'btn-md'];
        const extendedClasses = this.buttonStyleProcessor.getClasses(this);
        if (extendedClasses?.length) {
            classes.push(...extendedClasses);
        }

        const constructedAction = {
            type: this.type,
            code: this.code,
            localizationCode: this.localizationCode,
            authorization: this.authorization
        } as Action;

        const isActionHidden = !this.actionHandler.isActionAuthorized(constructedAction) && this.actionHandler.getButtonStyle(constructedAction) === 'hidden';
        const isActionDisabled = !this.actionHandler.isActionAuthorized(constructedAction) && this.actionHandler.getButtonStyle(constructedAction) === 'disabled';

        const hiddenAction = isActionHidden ? 'action-hidden' : '';
        const disabledAction = isActionDisabled ? 'action-disabled' : '';

        if (hiddenAction) {
            classes.push(hiddenAction);
        }

        if (disabledAction) {
            classes.push(disabledAction);
        }

        return classes;
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {v4 as uuidv4} from "uuid";
import {
    INotificationsService,
    NotificationsCountChangedCallback
} from "sirius-platform-support-library/shared/notifications/notifications-service.interface";
import {
    INotificationsProvider,
    INotificationsProviderTypeName
} from "sirius-platform-support-library/shared/notifications/providers/notifications-provider.interface";
import {NotificationsConstants} from "sirius-platform-support-library/shared/notifications/notifications.constants";
import {
    NotificationsCountChangedEvent,
    NotificationsEvents
} from "sirius-platform-support-library/shared/notifications/events/notifications.events";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";

export const NotificationsServiceTypeName = 'NotificationsService';

export class NotificationsService implements INotificationsService, IAfterPlatformReadyInit {

    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();

    private readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;
    private readonly notificationsCount: Record<string, number> = {};
    private readonly providers: Record<string, INotificationsProvider> = {};

    public constructor(
        eventBus: IEventBus,
        serviceCollection: IServiceCollection
    ) {
        this.eventBus = eventBus;
        this.serviceCollection = serviceCollection;
    }

    public static build(
        eventBus: IEventBus,
        serviceCollection: ITypedServiceCollection
    ): NotificationsService {
        let instance = ObjectUtility.getFromObjectPath<NotificationsService>(NotificationsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new NotificationsService(
                eventBus,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(NotificationsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): INotificationsService {
        return ObjectUtility.getFromObjectPath<INotificationsService>(NotificationsConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        await this.bindProviders();
        await this.load();
    }

    public async load(): Promise<void> {
        const namespaces = Object.values(this.providers).map(p => p.getNamespace());
        for (let index = 0; index < namespaces.length; index++) {
            const namespace = namespaces[index];
            if (!namespace) {
                continue;
            }
            await this.loadNamespaceNotificationsCount(namespace, false);
        }
        namespaces.map(_ => this.notifyNotificationsCountChanged());
    }


    public getNotificationsCount(namespace?: string): number {
        if (namespace) {
            return this.notificationsCount[namespace] || 0;
        } else {
            return Object.values(this.notificationsCount).reduce((acc, count) => acc + count, 0);
        }
    }

    public onNotificationsCountChanged(context: any, subscriberName: string, callback: NotificationsCountChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<NotificationsCountChangedCallback>(this, NotificationsServiceTypeName, NotificationsEvents.NOTIFICATIONS_CHANGED, async (event) => {
            try {
                await callback?.call(context, event.data);
            } catch (e) {
                console.error(e);
            }
        });
    }

    private async bindProviders(): Promise<void> {
        const onProviderRefreshRequestedBinded = this.onProviderRefreshRequested.bind(this);
        const providers = this.serviceCollection.resolveAll<INotificationsProvider>(INotificationsProviderTypeName);
        for (let index = 0; index < providers.length; index++) {
            const provider = providers[index];
            if (!provider) {
                continue;
            }
            try {
                if (provider.onRefreshRequested) {
                    provider.onRefreshRequested(onProviderRefreshRequestedBinded);
                }
                this.providers[provider.getNamespace()] = provider;
            } catch (e) {
                console.error(e);
            }
        }
    }

    private async onProviderRefreshRequested(namespace: string): Promise<void> {
        await this.loadNamespaceNotificationsCount(namespace);
    }

    private async loadNamespaceNotificationsCount(namespace: string, notify: boolean = true): Promise<void> {
        try {
            const provider = this.providers[namespace];
            if (!provider) {
                return;
            }

            const notificationsCounts = await provider.getNotificationsCount();
            this.notificationsCount[namespace] = notificationsCounts;
            if (notify) {
                this.notifyNotificationsCountChanged();
            }
        } catch (e) {
            console.error(e);
        }
    }

    private notifyNotificationsCountChanged(): void {
        const count = Object.entries(this.notificationsCount).reduce((acc, [_, value]) => acc + value, 0);
        const event: NotificationsCountChangedEvent = {
            broadcasterId: NotificationsService.BROADCASTER_ID,
            count: count
        };
        this.eventBus.dispatchBroadcast(NotificationsServiceTypeName, NotificationsEvents.NOTIFICATIONS_CHANGED, event, undefined, true);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    IActionHandler,
    IActionHandlerTypeName
} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IPlaceholdersReplacerService,
    IPlaceholdersReplacerServiceTypeName
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholders-replacer-service.interface";
import {ActionHandler, ActionHandlerTypeName} from "./action.handler";
import {
    PlatformBrowserNavigationService,
    PlatformBrowserNavigationServiceTypeName
} from "../browser-events/platform-browser-navigation.service";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    PlatformActionInteractionService,
    PlatformActionInteractionServiceTypeName
} from "../browser-events/platform-action-interaction.service";
import {
    IAuthorizationService,
    IAuthorizationServiceTypeName
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";

export class ActionHandlerDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IActionHandler, ActionHandler>(DependencyType.SHARED, IActionHandlerTypeName, ActionHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const placeholdersReplacerService = di.resolve<IPlaceholdersReplacerService>(IPlaceholdersReplacerServiceTypeName);
                const platformBrowserNavigationService = di.resolve<PlatformBrowserNavigationService>(PlatformBrowserNavigationServiceTypeName);
                const platformActionInteractionService = di.resolve<PlatformActionInteractionService>(PlatformActionInteractionServiceTypeName);
                const authorizationService = di.resolve<IAuthorizationService>(IAuthorizationServiceTypeName);

                return new ActionHandler(
                    window,
                    tenant,
                    eventBus,
                    placeholdersReplacerService,
                    platformBrowserNavigationService,
                    platformActionInteractionService,
                    authorizationService,
                    di
                );
            });
    }
}

export const actionHandlerDependencyModule = new ActionHandlerDependencyModule();

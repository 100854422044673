/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    LocaleCodeNormalizer
} from "sirius-platform-support-library/shared/localization/normalizers/locale-code-normalizer";
import {ILocaleProvider} from "sirius-platform-support-library/shared/localization/providers/locale-provider.interface";
import {ILocaleStore} from "sirius-platform-support-library/shared/localization/providers/locale-store.interface";

export const LocalStorageLocaleProviderTypeName = 'LocalStorageLocaleProvider';

export class LocalStorageLocaleProvider implements ILocaleProvider, ILocaleStore {
    public static readonly CODE = 'LOCAL_STORAGE';

    private static readonly LOCAL_STORAGE_KEY_TEMPLATE = `sirius.localization.locale-code`;

    private readonly window: Window;
    private readonly localeCodeNormalizer: LocaleCodeNormalizer;

    constructor(window: Window) {
        this.window = window;
        this.localeCodeNormalizer = new LocaleCodeNormalizer();
    }

    public getProviderCode(): string {
        return LocalStorageLocaleProvider.CODE;
    }

    public getLocaleCode(): string {
        try {
            const localeCode = this.window.localStorage.getItem(LocalStorageLocaleProvider.LOCAL_STORAGE_KEY_TEMPLATE);
            return this.localeCodeNormalizer.normalize(localeCode);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public store(localeCode: string): void {
        if (!localeCode) {
            return;
        }

        const currentLocale = this.getLocaleCode();
        if (currentLocale === localeCode) {
            return;
        }

        this.window.localStorage.setItem(LocalStorageLocaleProvider.LOCAL_STORAGE_KEY_TEMPLATE, localeCode);
    }
}

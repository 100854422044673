import {css, html, LitElement} from "lit";
import {defineCustomElements as initSkeleton} from 'skeleton-webcomponent-loader/loader/index';
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {Footer} from "sirius-platform-support-library/tenants/tenant-context";
import {
    StandardFeatures
} from "sirius-platform-support-library/shared/micro-frontends/features/standard-features.constants";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";

export class SiriusFooterSkeleton extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-footer-skeleton';
    public static styles = css`
      :host {
        width: 100%;
      }

      .container {
        background-color: var(--color-slate-100,#EBF1F6);
        background: var(--color-slate-100, #EBF1F6);

        min-height: 230px;
        padding: 24px;
        display: flex;
        justify-content: space-between;
      }

      .content-section {
        padding: 20px;
        flex-grow: 1;
      }

      .description {
        display: flex;
        flex-direction: column;
        --skeleton-margin-bottom: 4px;
        margin: 12px 0;
      }

      .feature-links {
        padding: 20px;
        display: flex;
        flex-direction: column;
      }

      .text-skeleton {
        --skeleton-background: var(--color-slate-900, #d2d7dc);
        margin: 2px;
      }

      .text-skeleton.title {
        margin-bottom: 20px;
      }

      .bottom-features {
        margin-top: 12px;
      }

      .footer-items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        column-gap: 3em;
        row-gap: 0.8em;
      }

      .footer-column {
        flex-direction: column;
        display: flex;
      }

      .footer-heading {
        --skeleton-margin-bottom: 4px;
      }

      .footer-link {
        --skeleton-margin-bottom: 8px;
      }
    `;
    private tenant?: ITenant;
    private footerOptions?: Footer;
    private renderTitle: boolean;
    private renderDescription: boolean;
    private renderLanguageSelector: boolean;
    private renderContactUs: boolean;
    private renderAbout: boolean;
    private renderPrivacy: boolean;
    private renderCopyright: boolean;

    public render() {
        return html`
            <script src="/libs/skeleton-webcomponent-loader/dist/esm/skeleton-webcomponent.js"></script>

            <div class="container">
                <div class="content-section">
                    ${this.renderTitle
                            ? html`
                                <nb-skeleton class="text-skeleton title" height="40px" width="180px" variant="rect">
                                </nb-skeleton>`
                            : ''}
                    ${this.renderDescription
                            ? html`
                                <div class="description">
                                    <nb-skeleton class="text-skeleton" height="20px" width="600px" variant="rect">
                                    </nb-skeleton>
                                    <nb-skeleton class="text-skeleton" height="20px" width="480px" variant="rect">
                                    </nb-skeleton>
                                </div>`
                            : ''}
                    ${this.footerOptions.items.length
                            ? html`
                                <div class="footer-items">
                                    ${this.footerOptions.items.map((column) => html`
                                        <div class="footer-column">
                                            <nb-skeleton
                                                    class="footer-heading text-skeleton"
                                                    height="20px" width="140px"
                                                    variant="rect"
                                            ></nb-skeleton>
                                            ${column.items.map((link, i) => html`
                                                <nb-skeleton
                                                        class="footer-link text-skeleton"
                                                        height="20px" width="${i % 2 ? '120px' : '100px'}"
                                                        variant="rect"
                                                ></nb-skeleton>
                                            `)
                                            }
                                        </div>`)}
                                </div>`
                            : ''}
                    <div class="bottom-features">
                        ${this.renderLanguageSelector
                                ? html`
                                    <nb-skeleton class="text-skeleton language-selector" height="24px" width="140px"
                                                 variant="rect">
                                    </nb-skeleton>`
                                : ''}
                        ${this.renderLanguageSelector
                                ? html`
                                    <nb-skeleton class="text-skeleton title" height="24px" width="140px" variant="rect">
                                    </nb-skeleton>`
                                : ''}
                    </div>
                </div>
                <div class="feature-links">
                    ${this.renderAbout
                            ? html`
                                <nb-skeleton class="text-skeleton" height="20px" width="80px" variant="rect">
                                </nb-skeleton>`
                            : ''}
                    ${this.renderPrivacy
                            ? html`
                                <nb-skeleton class="text-skeleton" height="20px" width="110px" variant="rect">
                                </nb-skeleton>`
                            : ''}
                    ${this.renderCopyright
                            ? html`
                                <nb-skeleton class="text-skeleton" height="20px" width="100px" variant="rect">
                                </nb-skeleton>`
                            : ''}
                </div>
            </div>
        `;
    }

    public async connectedCallback() {
        super.connectedCallback();

        this.tenant = SharedDependencyContainer
            .getInstance()
            .getServiceCollection()
            .resolve<ITenant>(ITenantTypeName);

        this.footerOptions = this.tenant?.getContext()?.site?.footer;

        this.renderDescription = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.FOOTER_DESCRIPTION && f.enabled);
        this.renderTitle = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.FOOTER_TITLE && f.enabled);
        this.renderCopyright = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.FOOTER_COPYRIGHT_LINK && f.enabled);
        this.renderPrivacy = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.FOOTER_PRIVACY_LINK && f.enabled);
        this.renderLanguageSelector = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.LANGUAGE_SELECTOR && f.enabled);
        this.renderContactUs = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.FOOTER_CONTACT_US_LINK && f.enabled);
        this.renderAbout = !!(this.footerOptions?.features || []).find(f => f.code === StandardFeatures.FOOTER_ABOUT_DELL_LINK && f.enabled);

        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'footer-skeleton',
                suffix: 'skeleton'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.renderRoot, DomInjectionType.PREPEND);

        const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
        if (themingHandlersManager) {
            themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
            await themingHandlersManager.attach(ThemedComponent.SHELL_FOOTER_SKELETON, this.renderRoot, DomInjectionType.PREPEND);
        }

        await initSkeleton();
    }
}

if (!customElements.get(SiriusFooterSkeleton.CUSTOM_ELEMENT_NAME)) {
    customElements.define(SiriusFooterSkeleton.CUSTOM_ELEMENT_NAME, SiriusFooterSkeleton);
}


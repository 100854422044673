/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {MenuItem} from "sirius-platform-support-library/models/common";
import {
    IDynamicMenuItemBuilder
} from "sirius-platform-support-library/shared/dynamic-menu/builder/dynamic-menu-item-builder.interface";
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {
    LocalizableResource
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {
    LocalizableResourceTranslationService
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource-translation.service";
import {MenuItemMapper} from "../mappers/menu-item.mapper";

export const DynamicMenuItemBuilderTypeName = 'DynamicMenuItemBuilder';

export class DynamicMenuItemBuilder implements IDynamicMenuItemBuilder {
    private readonly menuItemMapper: MenuItemMapper;
    private readonly localizationService: ILocalizationService;
    private readonly serviceCollection: IServiceCollection;

    public constructor(
        menuItemMapper: MenuItemMapper,
        localizationService: ILocalizationService,
        serviceCollection: IServiceCollection
    ) {
        this.menuItemMapper = menuItemMapper;
        this.localizationService = localizationService;
        this.serviceCollection = serviceCollection;
    }

    public async build(item: MenuItem, context: any, localizableResources?: LocalizableResource, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        const translationsCache = new TranslationsCache(context, localizableResources ?? {}, false);
        const translationService = new LocalizableResourceTranslationService(this.localizationService, translationsCache, this.serviceCollection);
        return await this.menuItemMapper.mapSingle(item, translationService, parent);
    }
}

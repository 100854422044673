/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {DefaultPagesShellConstants} from "./default-pages-shell.constants";
import {InternalNavigateToOptions} from "./options/internal-navigate-to.options";
import {NavigateToOptionsFactory} from "./options/factory/navigate-to-options.factory";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";

export class DefaultPageHelper {
    private static readonly navigateToOptionsFactory = new NavigateToOptionsFactory();

    public static getOptionsCode(searchString?: string): string | undefined {
        const queryParameters = RoutingUtilities.getQueryParameters(searchString);
        return queryParameters[DefaultPagesShellConstants.OPTIONS_CODE_QUERY_PARAMETER];
    }

    public static parseOptions(pageCode: string, optionsValue?: string): InternalNavigateToOptions | undefined {
        try {
            const json = decodeURIComponent(optionsValue);
            if (!json) {
                return this.navigateToOptionsFactory.get(pageCode);
            }
            const obj = ObjectUtility.deserialize<InternalNavigateToOptions>(json);
            if (!Object.keys(obj).length) {
                return this.navigateToOptionsFactory.get(pageCode);
            }
            return obj;
        } catch (e) {
            return this.navigateToOptionsFactory.get(pageCode);
        }
    }
}

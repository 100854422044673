/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IManagedSessionCookieUserInfoService
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.user-info.service.interface";
import {
    IManagedSessionCookieSessionService
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.session.service.interface";
import {
    ManagedSessionCookieAuthenticationConfigurator
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/managed-session-cookie.authentication.configurator";
import {
    ManagedSessionCookieUserInfoResponse
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.user-info.response";
import {
    IRequiredClaimsMappingDelegatedProvider
} from "sirius-platform-support-library/shared/authentication/claims/required-claims-mapping.delegated-provider.interface";
import {
    IOptionalClaimsMappingDelegatedProvider
} from "sirius-platform-support-library/shared/authentication/claims/optional-claims-mapping.delegated-provider.interface";
import {Session} from "sirius-platform-support-library/shared/authentication/session/session";
import {RequiredClaims} from "sirius-platform-support-library/shared/authentication/claims/required-claims.enum";
import {OptionalClaims} from "sirius-platform-support-library/shared/authentication/claims/optional-claims.enum";

export const ManagedSessionCookieUserInfoWithSessionServiceTypeName = 'ManagedSessionCookieUserInfoWithSessionService';

export class ManagedSessionCookieUserInfoWithSessionService implements IManagedSessionCookieUserInfoService,
    IManagedSessionCookieSessionService,
    IRequiredClaimsMappingDelegatedProvider,
    IOptionalClaimsMappingDelegatedProvider {
    private readonly window: Window;

    public constructor(window: Window) {
        this.window = window;
    }

    public getRequiredClaimsMappings(): Record<string, string> {
        const mappings: Record<string, string> = {};
        mappings[RequiredClaims.USER_IDENTIFIER] = 'http://www.dell.com/identity/claims/profile/id';
        mappings[RequiredClaims.PRINCIPAL_NAME] = 'http://www.dell.com/identity/claims/profile/adusername';
        mappings[RequiredClaims.DISPLAY_NAME] = 'http://www.dell.com/identity/claims/profile/displayName';
        return mappings;
    }

    public getOptionalClaimsMappings(): Record<string, string> {
        const mappings: Record<string, string> = {};
        mappings[OptionalClaims.FIRST_NAME] = 'http://www.dell.com/identity/claims/profile/firstname';
        mappings[OptionalClaims.LAST_NAME] = 'http://www.dell.com/identity/claims/profile/lastname';
        return mappings;
    }

    public async getUserInfo(configurator: ManagedSessionCookieAuthenticationConfigurator): Promise<ManagedSessionCookieUserInfoResponse> {
        try {
            if (!configurator) {
                throw new Error('No configurator provided');
            }
            const response = await this.window.fetch(configurator.getUserInfoUrl(), {
                method: "GET",
                headers: {
                    accept: "application/json",
                },
                cache: 'no-cache',
                mode: 'cors',
                credentials: 'include',
                referrerPolicy: "no-referrer"
            });
            if (response.status !== 200) {
                throw new Error(`Fetch failed with ${response.status} status code. Details: ${response.statusText}`);
            }
            return await response.json() as ManagedSessionCookieUserInfoResponse;
        } catch (e) {
            console.warn('An exception has been encountered while trying to fetch user info', e)
            throw e;
        }
    }

    public async renewSession(configurator: ManagedSessionCookieAuthenticationConfigurator): Promise<Session> {
        try {
            if (!configurator) {
                throw new Error('No configurator provided');
            }
            const response = await this.window.fetch(configurator.getSessionRenewalUrl(), {
                method: "GET",
                headers: {
                    accept: "application/json",
                },
                cache: 'no-cache',
                mode: 'cors',
                credentials: 'include',
                referrerPolicy: "no-referrer"
            });
            if (response.status !== 200) {
                throw new Error(`Fetch failed with ${response.status} status code`);
            }
            return await response.json() as Session;
        } catch (e) {
            console.warn('An exception has been encountered while trying to fetch user info', e)
            throw e;
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ISplashScreenModule} from "sirius-platform-support-library/shared/splash-screen/splash-screen-module.interface";

export type SplashScreenModuleGetterCallback = () => Promise<ISplashScreenModule | undefined>;

export const SplashScreenDomHandlerTypeName = 'SplashScreenDomHandler';

export class SplashScreenDomHandler {
    private readonly document: Document;
    private container: HTMLElement;

    private moduleGetter?: SplashScreenModuleGetterCallback;

    public constructor(document: Document) {
        this.document = document;
    }

    public register(moduleGetter: SplashScreenModuleGetterCallback) {
        this.moduleGetter = moduleGetter;
    }

    public isShown(): boolean {
        return this.container?.children?.length > 0;
    }

    public async show(): Promise<void> {
        try {
            const splashScreenModule = await this.moduleGetter();
            this.container = this.document.getElementById('splash-screen-full-screen-overlay') as HTMLElement;
            if (!splashScreenModule?.isRegistered || !splashScreenModule.isRegistered() || !splashScreenModule?.getCustomElementName || !this.container) {
                return;
            }
            this.document.body.classList.add('no-scroll');
            const splashScreenElement = this.document.createElement(splashScreenModule.getCustomElementName());
            this.container.appendChild(splashScreenElement);
            this.container.classList.add('active');
        } catch (e) {
            console.error(e);
        }
    }

    public async hide(): Promise<void> {
        try {
            const splashScreenModule = await this.moduleGetter();
            if (!splashScreenModule?.isRegistered || !splashScreenModule.isRegistered() || !this.container) {
                return;
            }
            this.document.body.classList.remove('no-scroll');
            this.container.classList.remove('active');
            this.container.innerHTML = '';
        } catch (e) {
            console.error(e);
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DialogAction} from "sirius-platform-support-library/shared/dialogs/dialog-action";
import {ActionValidator} from "sirius-platform-support-library/shared/actions/validators/action.validator";
import {ValidationError} from "sirius-platform-support-library/validators/validation-error";

export const DialogActionValidatorTypeName = 'DialogActionValidator';

export class DialogActionValidator {
    private readonly actionValidator: ActionValidator;

    public constructor() {
        this.actionValidator = new ActionValidator()
    }

    public validate(action: DialogAction): void {
        if (!action) {
            return;
        }
        const errors: Error[] = [];
        try {
            this.actionValidator.validate(action);
        } catch (e) {
            errors.push(...e.errors);
        }
        if (errors.length > 0) {
            throw new ValidationError('Validation errors detected for the given dialog action', errors);
        }
    }
}

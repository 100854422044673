/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {v4 as uuidv4} from 'uuid';
import {RequestedToast, ShownToast} from "sirius-platform-support-library/shared/toasts/toast";
import {ToastType} from "sirius-platform-support-library/shared/toasts/toast-type";
import {
    ITranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations-cache.interface";
import {
    TranslationsRequestedCallback
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {ILocalizable} from "sirius-platform-support-library/shared/localization/localizable.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    LocalizableResourceTranslationService
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource-translation.service";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {DismissButtonTemplate, ToastTemplate} from "./templates";
import {toastTypeIconTemplate} from "./toasts-constants";

export class RenderableToast implements ShownToast, ILocalizable {
    public static readonly LOCALIZED_TEXT_PLACEHOLDER = '{{localizedText}}';
    public static readonly ICON_PLACEHOLDER = '{{iconPlaceholder}}';
    public static readonly DISMISS_BUTTON_PLACEHOLDER = '{{dismissButtonPlaceholder}}';

    public readonly context: any;

    public id: string;
    public timestamp: number;
    public type: ToastType;
    public dismissable: boolean;
    public pauseOnHover: boolean;
    public autoDismissIn?: number;
    public dismissTimer?: NodeJS.Timeout;
    public dismissHandler: (string) => void;

    public localizationCode: string;
    public localizedText: string;

    public localizedResources?: Record<string, any>;
    public onTranslationsRequested?: TranslationsRequestedCallback;

    private remainingTime: number;
    private readonly translationsCache: ITranslationsCache;
    private readonly translationService: ITranslationService;

    public constructor(
        toast: RequestedToast,
        context: any,
        localizationService: ILocalizationService,
        serviceCollection: IServiceCollection,
        dismissHandler,
        defaultAutoDismissDuration: number,
    ) {
        this.context = context;

        this.translationsCache = new TranslationsCache(context, this);
        this.translationService = new LocalizableResourceTranslationService(
            localizationService,
            this.translationsCache,
            serviceCollection
        );

        this.id = uuidv4().toLowerCase();
        this.timestamp = 0;
        this.type = toast.type;
        this.dismissable = toast.dismissable;
        this.pauseOnHover = toast.pauseOnHover;
        this.autoDismissIn = (toast.autoDismissIn && toast.autoDismissIn > 0)
            ? toast.autoDismissIn
            : defaultAutoDismissDuration;
        this.localizationCode = toast.localizationCode;
        this.localizedText = this.localizationCode;
        this.dismissHandler = dismissHandler;

        this.localizedResources = toast.localizedResources || {};
        this.onTranslationsRequested = toast.onTranslationsRequested;
    }

    public async localize(): Promise<void> {
        if (!this.localizationCode) {
            return;
        }
        try {
            this.localizedText = await this.translationService.translate(this.localizationCode);
        } catch (e) {
            console.warn('Could not localize toast', this, e);
        }
    }

    public activateTimer(): void {
        this.timestamp = Math.round(Date.now() / 1000);
        this.setTimer(this.autoDismissIn);
    }

    public pauseTimer(): void {
        const currentTime = Math.round(Date.now() / 1000);
        this.remainingTime = currentTime - this.timestamp;
        this.clearTimer();
    }

    public resumeTimer(): void {
        this.setTimer(this.remainingTime && this.remainingTime > 2 ? this.remainingTime + 1 : 3);
    }

    public clearTimer(): void {
        clearTimeout(this.dismissTimer);
    }

    public toHtml(): string {
        return ToastTemplate
            .replaceAll(RenderableToast.LOCALIZED_TEXT_PLACEHOLDER, this.localizedText)
            .replaceAll(RenderableToast.DISMISS_BUTTON_PLACEHOLDER, this.dismissable ? DismissButtonTemplate : '')
            .replaceAll(RenderableToast.ICON_PLACEHOLDER, toastTypeIconTemplate[this.type])
    }

    public toShownToast(): ShownToast {
        return {
            id: this.id,
            timestamp: this.timestamp,
            type: this.type,
            localizationCode: this.localizationCode,
            autoDismissIn: this.autoDismissIn,
            dismissable: this.dismissable,
            pauseOnHover: this.pauseOnHover,
        }
    }

    private setTimer(seconds): void {
        this.dismissTimer = setTimeout(() => {
            this.dismissTimer = undefined;
            this.dismissHandler(this.id);
        }, seconds * 1000);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IconProperties} from "../icon-properties";

export const UxdIconParserTypeName = 'UxdIconParser';

export class UxdIconParser {
    public static readonly OUTLINE_ICON_PROPERTY = 'outline';
    public static readonly SOLID_ICON_PROPERTY = 'solid';
    public static readonly PRIMARY_ICON_PROPERTY = UxdIconParser.OUTLINE_ICON_PROPERTY;

    public async parse(iconCode: string, text: string): Promise<IconProperties | undefined> {
        try {
            // @ts-ignore
            text = text.replaceAll('export', '').replaceAll('const ', 'this.');
            const context = await this.evalIsolated(text, {
                icon: undefined
            });
            if (context.icon) {
                Object.keys(context.icon).forEach(property => {
                    context.icon[property] = this.buildCompleteSvg(context.icon[property]);
                });
            }
            return {
                code: iconCode,
                properties: context.icon || {}
            }
        } catch (e) {
            console.warn(`Failed to pare icon ('${iconCode}') due to an encountered exception.`, e);
            return undefined;
        }
    }

    public getPrimaryResource(iconProperties: IconProperties, primaryIconProperty: string = UxdIconParser.PRIMARY_ICON_PROPERTY): string | undefined {
        // eslint-disable-next-line no-prototype-builtins
        if (iconProperties.properties.hasOwnProperty(primaryIconProperty)) {
            const primary = iconProperties.properties[primaryIconProperty];
            return `<svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">${primary}</svg>`;
        }
        return undefined;
    }

    private buildCompleteSvg(resource?: string): string | undefined {
        if (!resource) {
            return resource;
        }
        return `<svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">${resource}</svg>`;
    }

    private async evalIsolated(text: string, context?: any): Promise<any> {
        return new Promise((resolve) => {
            context = context || { icon: '' };
            context.icon = undefined;
            (function () {
                try {
                    eval(text);
                } catch (e) {
                    // Suppress error, we do not want it not even in the console.
                }
            }).call(context);
            resolve(context);
        });
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {NotificationsService, NotificationsServiceTypeName} from "./notifications.service";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    INotificationsService,
    INotificationsServiceTypeName
} from "sirius-platform-support-library/shared/notifications/notifications-service.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";

export class NotificationsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<INotificationsService, NotificationsService>(DependencyType.SHARED, INotificationsServiceTypeName, NotificationsServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                return NotificationsService.build(
                    eventBus,
                    di
                );
            })
            .addInterfacesOnSingleton(NotificationsServiceTypeName, IAfterPlatformReadyInitTypeName);
    }
}

export const notificationDependencyModule = new NotificationsDependencyModule();


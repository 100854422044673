/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ISiteStyler} from "./site-styler.interface";
import {DEFAULT_SITE_FAV_ICON} from "../constants";
import {Footer, SiteConfig} from "sirius-platform-support-library/tenants/tenant-context";
import {ISiteService} from "sirius-platform-support-library/shared/site/site.interface";

export const SiteStylerTypeName = 'SiteStyler';

export class SiteStyler implements ISiteStyler {
    private static readonly SITE_CONTENT_SELECTOR = '.site-wrapper .site-content';
    private static readonly CONTENT_MAIN_SECTION_SELECTOR = `${SiteStyler.SITE_CONTENT_SELECTOR} > .main-section`;
    private static readonly CONTENT_WRAPPER_SECTION_SELECTOR = `${SiteStyler.CONTENT_MAIN_SECTION_SELECTOR} > .content-wrapper-section`;
    private static readonly FOOTER_SECTION_SELECTOR = '.site-wrapper .footer-section';

    private readonly window: Window;
    private readonly document: Document;
    private readonly siteService: ISiteService;

    constructor(
        window: Window,
        document: Document,
        siteService: ISiteService
    ) {
        this.window = window;
        this.document = document;
        this.siteService = siteService;
    }

    public async style(siteConfig?: SiteConfig): Promise<void> {
        await this.applyTitle(siteConfig?.title);
        this.applyFavIcon(siteConfig?.favIconUrl);
        this.applyContentSectionProperties(siteConfig);
        this.applyFooterSectionProperties(siteConfig?.footer);
        this.applyFontSize(siteConfig?.fontSize)
    }

    private async applyTitle(title?: string): Promise<void> {
        await this.siteService.setTitle(this, title)
    }

    private applyFavIcon(favIconUrl?: string): void {
        let favIconUrlValue = DEFAULT_SITE_FAV_ICON;
        if (favIconUrl) {
            favIconUrlValue = favIconUrl;
        }
        let link = this.document.querySelector("link[rel~='icon']");
        if (!link) {
            link = this.document.createElement('link');
            link.setAttribute('rel', 'icon');
            this.document.getElementsByTagName('head')[0].appendChild(link);
        }
        link.setAttribute('href', favIconUrlValue);
    }

    private applyContentSectionProperties(siteConfig?: SiteConfig): void {
        if (!siteConfig) {
            return;
        }

        const siteContentElement = this.document.querySelector(SiteStyler.SITE_CONTENT_SELECTOR);
        if (!siteContentElement) {
            return;
        }

        const observer = new MutationObserver((mutationList: MutationRecord[], observer: MutationObserver) => {
            const contentSectionElement = this.document.querySelector<Element>(SiteStyler.CONTENT_MAIN_SECTION_SELECTOR);
            const contentWrapperSectionElement = this.document.querySelector<Element>(SiteStyler.CONTENT_WRAPPER_SECTION_SELECTOR);
            if (contentSectionElement) {
                if (siteConfig?.backgroundColor) {
                    // @ts-ignore
                    contentSectionElement.style.backgroundColor = siteConfig.backgroundColor;
                }
                observer.disconnect();
            }
            if (contentWrapperSectionElement) {
                if (siteConfig.backgroundColor) {
                    // @ts-ignore
                    contentWrapperSectionElement.style.backgroundColor = siteConfig.backgroundColor;
                }
                observer.disconnect();
            }
        });

        observer.observe(siteContentElement, {attributes: true, childList: true, subtree: true});
    }

    private applyFooterSectionProperties(footerConfig?: Footer): void {
        if (!footerConfig) {
            return;
        }

        const siteContentElement = this.document.querySelector(SiteStyler.SITE_CONTENT_SELECTOR);
        if (!siteContentElement) {
            return;
        }

        const observer = new MutationObserver((mutationList: MutationRecord[], observer: MutationObserver) => {
            const footerSectionElement = this.document.querySelector<Element>(SiteStyler.FOOTER_SECTION_SELECTOR);
            if (footerSectionElement) {
                if (footerConfig?.backgroundColor && (footerSectionElement as any)?.style) {
                    // @ts-ignore
                    footerSectionElement.style.backgroundColor = footerConfig.backgroundColor;
                }
                observer.disconnect();
            }
        });

        observer.observe(siteContentElement, {attributes: true, childList: true, subtree: true});
    }

    private applyFontSize(fontSize?: string): void {
        if (!fontSize) {
            return;
        }
        const styleElement = document.createElement("style");
        const cssRule = `html { font-size: ${fontSize}; }`;
        styleElement.appendChild(document.createTextNode(cssRule));
        document.head.appendChild(styleElement);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    ISplashScreenService,
    ISplashScreenServiceTypeName
} from "sirius-platform-support-library/shared/splash-screen/splash-screen-service.interface";
import {SplashScreenService, SplashScreenServiceTypeName} from "./splash-screen.service";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {SplashScreenDomHandler, SplashScreenDomHandlerTypeName} from "./splash-screen.dom-handler";
import {SystemJs, SystemJsTypeName} from "../dynamic-imports/system-js";
import {
    RuntimeConfigurationLoader,
    RuntimeConfigurationLoaderTypeName
} from "../runtime-configuration/runtime-configuration.loader";

export class SplashScreenDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<SplashScreenDomHandler>(DependencyType.PRIVATE, SplashScreenDomHandlerTypeName, new SplashScreenDomHandler(document))
            .addUsingFactoryAsSingleton<ISplashScreenService, SplashScreenService>(DependencyType.PRIVATE, ISplashScreenServiceTypeName, SplashScreenServiceTypeName, (di) => {
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                const runtimeConfigurationLoader = di.resolve<RuntimeConfigurationLoader>(RuntimeConfigurationLoaderTypeName);
                const splashScreenDomHandler = di.resolve<SplashScreenDomHandler>(SplashScreenDomHandlerTypeName);
                return new SplashScreenService(
                    window,
                    systemJs,
                    runtimeConfigurationLoader,
                    splashScreenDomHandler
                );
            });
    }
}

export const splashScreenDependencyModule = new SplashScreenDependencyModule();

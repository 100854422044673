/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";
import {
    IDynamicMenuOperations
} from "sirius-platform-support-library/shared/dynamic-menu/operations/dynamic-menu-operations.interface";
import {
    LocalizableResource
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {InternalMenuItem} from "../internal-menu-item";
import {ActionTypeEnum, MenuItem} from "sirius-platform-support-library/models/common";
import {
    IDynamicMenuItemBuilder
} from "sirius-platform-support-library/shared/dynamic-menu/builder/dynamic-menu-item-builder.interface";
import {DynamicMenuAdd} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-add.enum";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {
    LocalizableResourceTranslationService
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource-translation.service";
import {MenuItemMapper} from "../mappers/menu-item.mapper";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";


export const DynamicMenuOperationsTypeName = 'DynamicMenuOperations';

export class DynamicMenuOperations implements IDynamicMenuOperations {
    private readonly localizationService: ILocalizationService;
    private readonly menuItemMapper: MenuItemMapper;
    private readonly dynamicMenuItemBuilder: IDynamicMenuItemBuilder;
    private readonly serviceCollection: IServiceCollection;

    public constructor(
        localizationService: ILocalizationService,
        menuItemMapper: MenuItemMapper,
        dynamicMenuItemBuilder: IDynamicMenuItemBuilder,
        serviceCollection: IServiceCollection
    ) {
        this.dynamicMenuItemBuilder = dynamicMenuItemBuilder;
        this.menuItemMapper = menuItemMapper;
        this.localizationService = localizationService;
        this.serviceCollection = serviceCollection;
    }

    public async getMenuItemById(rootItems: DynamicMenuItem[], itemId: string): Promise<DynamicMenuItem | undefined> {
        try {
            return await this.findNodeById(rootItems, itemId);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public async getMenuItemByPath(rootItems: DynamicMenuItem[], itemPath: string): Promise<DynamicMenuItem | undefined> {
        try {
            return await this.findNodeByPath(rootItems, itemPath);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public async getMenuItemByCode(rootItems: DynamicMenuItem[], itemCode: string, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        try {
            let containerItems;
            if (parent) {
                const parentReference = await this.findNodeByReference(rootItems, parent);
                containerItems = parentReference?.items ?? [];
            } else {
                containerItems = rootItems ?? [];
            }
            return containerItems.find(i => i.code === itemCode);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public async getMenuItemByCondition(rootItems: DynamicMenuItem[], condition: (menuItem: DynamicMenuItem) => boolean, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        try {
            let containerItems;
            if (parent) {
                const parentReference = await this.findNodeByReference(rootItems, parent);
                containerItems = parentReference?.items ?? [];
            } else {
                containerItems = rootItems ?? [];
            }
            return await this.findNodeByCondition(containerItems, condition);
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public async addMenuItem(rootItems: DynamicMenuItem[], context: any, item: DynamicMenuItem, localizableResources?: LocalizableResource, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        await this.recursivelyValidateMenuItem(item);
        let containerItems;
        let parentReference;
        if (parent) {
            parentReference = await this.findNodeByReference(rootItems, parent);
            if (!parentReference) {
                throw new Error(`The given parent menu item is not a reference of an exiting menu item.`);
            }
            if (!parentReference.items) {
                parentReference.items = [];
            }
            containerItems = parentReference.items;
        } else {
            containerItems = rootItems;
        }
        if (!containerItems) {
            throw new Error(`Could not get a suitable container for given menu item.`);
        }
        const internalItem = await this.dynamicMenuItemBuilder.build(item, context, localizableResources, parentReference);
        containerItems.push(internalItem);
        return internalItem;
    }

    public async addMenuItemBefore(rootItems: DynamicMenuItem[], context: any, item: DynamicMenuItem, referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource): Promise<DynamicMenuItem | undefined> {
        const results = await this.addMenuItemsBasedOnReferenceNode(rootItems, context, [item], referenceNode, DynamicMenuAdd.BEFORE, localizableResources);
        return results.find((item, index) => index === 0);
    }

    public async addMenuItemAfter(rootItems: DynamicMenuItem[], context: any, item: DynamicMenuItem, referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource): Promise<DynamicMenuItem | undefined> {
        const results = await this.addMenuItemsBasedOnReferenceNode(rootItems, context, [item], referenceNode, DynamicMenuAdd.AFTER, localizableResources);
        return results.find((item, index) => index === 0);
    }

    public async addMenuItems(rootItems: DynamicMenuItem[], context: any, items: DynamicMenuItem[], localizableResources?: LocalizableResource, parent?: DynamicMenuItem): Promise<DynamicMenuItem[]> {
        for (let index = 0; index < rootItems.length; index++) {
            const item = rootItems[index];
            await this.recursivelyValidateMenuItem(item);
        }
        const result: DynamicMenuItem[] = [];
        let containerItems;
        let parentReference;
        if (parent) {
            parentReference = await this.findNodeByReference(rootItems, parent);
            if (!parentReference) {
                throw new Error(`The given parent menu item is not a reference of an exiting menu item.`);
            }
            if (!parentReference.items) {
                parentReference.items = [];
            }
            containerItems = parentReference.items;
        } else {
            containerItems = rootItems;
        }
        if (!containerItems) {
            throw new Error(`Could not get a suitable container for given menu item.`);
        }
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const internalItem = await this.dynamicMenuItemBuilder.build(item, context, localizableResources, parentReference);
            containerItems.push(internalItem);
            result.push(internalItem);
        }
        return result;
    }

    public async addMenuItemsBefore(rootItems: DynamicMenuItem[], context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        return await this.addMenuItemsBasedOnReferenceNode(rootItems, context, items, referenceNode, DynamicMenuAdd.BEFORE, localizableResources);
    }

    public async addMenuItemsAfter(rootItems: DynamicMenuItem[], context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        return await this.addMenuItemsBasedOnReferenceNode(rootItems, context, items, referenceNode, DynamicMenuAdd.AFTER, localizableResources);
    }

    public async updateMenuItem(rootItems: DynamicMenuItem[], context: any, item: DynamicMenuItem, localizableResources?: LocalizableResource): Promise<DynamicMenuItem | undefined> {
        await this.recursivelyValidateMenuItem(item);
        const nodeReference = await this.findNodeByReference(rootItems, item);
        if (!nodeReference) {
            throw new Error(`The given menu item is not a reference of an exiting menu item.`);
        }
        nodeReference.code = item.code;
        nodeReference.localizationCode = item.localizationCode;
        nodeReference.iconCode = item.iconCode;
        nodeReference.index = item.index;
        nodeReference.action = item.action;
        nodeReference.data = item.data;
        if (localizableResources) {
            const translationsCache = new TranslationsCache(context, localizableResources ?? {}, false);
            (nodeReference as InternalMenuItem).translationService = new LocalizableResourceTranslationService(this.localizationService, translationsCache, this.serviceCollection);
        }
        await (nodeReference as InternalMenuItem)?.localize();
        nodeReference.items = await this.menuItemMapper.mapMany(item.items ?? [], (nodeReference as InternalMenuItem)?.translationService, nodeReference);
        return nodeReference;
    }

    public async removeMenuItem(rootItems: DynamicMenuItem[], item: DynamicMenuItem): Promise<void> {
        const nodeReference = await this.findNodeByReference(rootItems, item);
        if (!nodeReference) {
            throw new Error(`The given menu item is not a reference of an exiting menu item.`);
        }
        await this.removeMenuItemInternal(rootItems, nodeReference);
    }

    public async removeMenuItems(rootItems: DynamicMenuItem[], items: DynamicMenuItem[]): Promise<void> {
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            await this.removeMenuItem(rootItems, item);
        }
    }

    public async removeMenuItemById(rootItems: DynamicMenuItem[], itemId: string): Promise<void> {
        const nodeReference = await this.findNodeById(rootItems, itemId);
        if (!nodeReference) {
            throw new Error(`Could not find menu item for the given id (${itemId}).`);
        }
        await this.removeMenuItemInternal(rootItems, nodeReference);
    }

    public async removeMenuItemByPath(rootItems: DynamicMenuItem[], itemPath: string): Promise<void> {
        const nodeReference = await this.findNodeByPath(rootItems, itemPath);
        if (!nodeReference) {
            throw new Error(`Could not find menu item for the given path (${itemPath}).`);
        }
        await this.removeMenuItemInternal(rootItems, nodeReference);
    }

    public async removeMenuItemByCode(rootItems: DynamicMenuItem[], itemCode: string, parent?: DynamicMenuItem): Promise<void> {
        let containerItems;
        let parentReference;
        if (parent) {
            parentReference = await this.findNodeByReference(rootItems, parent);
            if (!parentReference) {
                throw new Error(`The given parent menu item node is not a reference of an exiting menu item.`);
            }
            if (!parentReference.items) {
                parentReference.items = [];
            }
            containerItems = parentReference.items;
        } else {
            containerItems = rootItems;
        }
        if (!containerItems) {
            throw new Error(`Could not get a suitable container for given menu item.`);
        }
        const nodeReference = containerItems.find(m => m.code === itemCode);
        if (!nodeReference) {
            throw new Error(`Could not find menu item for the given code (${itemCode}).`);
        }
        await this.removeMenuItemInternal(rootItems, nodeReference);
    }

    public async traverseTree(rootNode: DynamicMenuItem, action: (node: DynamicMenuItem) => Promise<boolean>): Promise<void> {
        const children = rootNode.items ?? [];
        for (let index = 0; index < children.length; index++) {
            const currentNode = children[index];
            const result = await action(currentNode);
            if (result) {
                return;
            }
            await this.traverseTree(currentNode, action);
        }
    }

    public async mapSingle(item: MenuItem, translationService: ITranslationService, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        return await this.menuItemMapper.mapSingle(item, translationService, parent);
    }

    public async mapMany(items: MenuItem[], translationService: ITranslationService, parent?: DynamicMenuItem): Promise<DynamicMenuItem[]> {
        return await this.menuItemMapper.mapMany(items, translationService, parent);
    }

    private async recursivelyValidateMenuItem(item: DynamicMenuItem): Promise<void> {
        this.validateMenuItem(item);
        await this.traverseTree(item, async (node: DynamicMenuItem) => {
            this.validateMenuItem(node);
            return false;
        });
    }

    private validateMenuItem(item: DynamicMenuItem): void {
        if (!item) {
            throw new Error('Invalid menu item provided.');
        }
        // @ts-ignore
        const serialized = JSON.stringify(item.asSerializable ? item.asSerializable() : item);
        if (!item.localizationCode) {
            throw new Error(`Missing localizationCode on: ${serialized}`);
        }
        if (item.action && item.action.type === ActionTypeEnum.CALLBACK) {
            throw new Error(`Unsupported action type on: ${serialized}`);
        }
    }

    private async findNodeByReference(nodes: DynamicMenuItem[], reference: MenuItem): Promise<DynamicMenuItem | undefined> {
        let found = undefined;
        for (let index = 0; index < nodes.length; index++) {
            if (found) {
                break;
            }
            const currentNode = nodes[index];
            if (currentNode == reference) {
                return currentNode;
            }
            await this.traverseTree(currentNode, async (node: DynamicMenuItem) => {
                if (node == reference) {
                    found = node;
                    return true;
                }
                return false;
            });
        }
        return found;
    }

    private async addMenuItemsBasedOnReferenceNode(rootItems: DynamicMenuItem[], context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, position: DynamicMenuAdd, localizableResources?: LocalizableResource): Promise<DynamicMenuItem[] | undefined> {
        for (let index = 0; index < rootItems.length; index++) {
            const item = rootItems[index];
            await this.recursivelyValidateMenuItem(item);
        }
        let containerItems;
        const siblingReference = await this.findNodeByReference(rootItems, referenceNode);
        if (!siblingReference) {
            throw new Error(`The given sibling menu item is not a reference of an exiting menu item.`);
        }
        if (siblingReference.parent) {
            if (!siblingReference.parent.items) {
                siblingReference.parent.items = [];
            }
            containerItems = siblingReference.parent.items;
        } else {
            containerItems = rootItems ?? [];
        }
        if (!containerItems) {
            throw new Error(`Could not get a suitable container for given menu item.`);
        }
        const internalItems = [];
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const internalItem = await this.dynamicMenuItemBuilder.build(item, context, localizableResources, siblingReference?.parent);
            internalItems.push(internalItem);
        }
        const index = containerItems.findIndex(item => item == siblingReference);
        if (index > -1 && position === DynamicMenuAdd.AFTER) {
            containerItems.splice(index + 1, 0, ...internalItems);
        }
        if (index > -1 && position === DynamicMenuAdd.BEFORE) {
            containerItems.splice(index, 0, ...internalItems);
        }
        return internalItems;
    }

    private async findNodeById(nodes: DynamicMenuItem[], itemId: string): Promise<DynamicMenuItem | undefined> {
        let found = undefined;
        for (let index = 0; index < nodes.length; index++) {
            if (found) {
                break;
            }
            const currentNode = nodes[index];
            if (currentNode.id === itemId) {
                return currentNode;
            }
            await this.traverseTree(currentNode, async (node: DynamicMenuItem) => {
                if (node.id === itemId) {
                    found = node;
                    return true;
                }
                return false;
            });
        }
        return found;
    }

    private async findNodeByCondition(nodes: DynamicMenuItem[], condition: (node: DynamicMenuItem) => boolean): Promise<DynamicMenuItem | undefined> {
        const localCondition = (node: DynamicMenuItem) => {
            try {
                return condition(node);
            } catch (e) {
                console.error(e);
                return false;
            }
        }
        let found = undefined;
        for (let index = 0; index < nodes.length; index++) {
            if (found) {
                break;
            }
            const currentNode = nodes[index];
            if (localCondition(currentNode)) {
                return currentNode;
            }
            await this.traverseTree(currentNode, async (node: DynamicMenuItem) => {
                if (localCondition(node)) {
                    found = node;
                    return true;
                }
                return false;
            });
        }
        return found;
    }

    private async findNodeByPath(nodes: DynamicMenuItem[], path: string, level?: number): Promise<DynamicMenuItem | undefined> {
        if (!path) {
            return undefined;
        }
        const pathSegments = path.split('.');
        if (!pathSegments.length) {
            return undefined;
        }
        if (!ObjectUtility.isDefined(level)) {
            level = 0;
        }
        const code = pathSegments[level];
        const node = nodes.find(n => n.code === code);
        if (!node) {
            return undefined;
        }
        if (level === pathSegments.length - 1) {
            return node;
        }
        level++;
        return this.findNodeByPath(node.items, path, level);
    }

    private async removeMenuItemInternal(rootItems: DynamicMenuItem[], item: DynamicMenuItem): Promise<void> {
        if (!item.parent) {
            const index = rootItems.findIndex(m => m == item);
            if (index > -1) {
                rootItems.splice(index, 1);
            }
        } else {
            const index = item.parent?.items?.findIndex(m => m == item) ?? -1;
            if (index > -1) {
                item.parent.items.splice(index, 1);
            }
        }
    }
}

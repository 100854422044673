/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

export const IPlatformTypeName = 'IPlatform';

export interface IPlatform {
    initialize(): Promise<IPlatform>;

    start(): Promise<IPlatform>;
}

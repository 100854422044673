/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IDeviceTraitsProviderModule
} from "sirius-shell-support-library/device-traits/device-traits-provider-module.interface";
import {SystemJs} from "../dynamic-imports/system-js";
import {
    IDeviceTraitsProvider
} from "sirius-platform-support-library/shared/device-traits/device-traits-provider.interface";
import {RuntimeConfigurationLoader} from "../runtime-configuration/runtime-configuration.loader";

export const DeviceTraitsProviderLoaderTypeName = 'DeviceTraitsProviderLoader';

export class DeviceTraitsProviderLoader {
    public static readonly DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER = '{{TENANT_DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER}}';
    public static readonly DEFAULT_BUNDLE_URL_BASE_URL = '/libs/device-traits/'
    public static readonly DEFAULT_BUNDLE_PRIMARY_ASSET = 'main.min.js';
    public static readonly DEFAULT_BUNDLE_SECONDARY_ASSET = 'main.js';

    private readonly window: Window;
    private readonly systemJs: SystemJs;
    private readonly runtimeConfigurationLoader: RuntimeConfigurationLoader;

    private module?: IDeviceTraitsProviderModule;

    public constructor(
        window: Window,
        systemJs: SystemJs,
        runtimeConfigurationLoader: RuntimeConfigurationLoader
    ) {
        this.window = window;
        this.systemJs = systemJs;
        this.runtimeConfigurationLoader = runtimeConfigurationLoader;
    }

    public async load(): Promise<void> {
        if (this.module) {
            return;
        }
        this.module = await this.getModule();
    }

    public getDeviceTraitsProvider(): IDeviceTraitsProvider | undefined {
        try {
            return this.module?.getDeviceTraitsProvider ? this.module?.getDeviceTraitsProvider() : undefined;
        } catch (e) {
            console.debug(e);
            return undefined;
        }
    }

    private async getModule(): Promise<IDeviceTraitsProviderModule | undefined> {
        let bundleUrlDefined = false;

        const urls: string[] = [];

        // @ts-ignore
        const argsUrl = this.window?.sirius?.config?.tenantDeviceTraitsUrl;
        if (argsUrl && argsUrl !== DeviceTraitsProviderLoader.DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER) {
            bundleUrlDefined = true;
            urls.push(argsUrl);
        }

        const runtimeOptionsConfigurator = this.runtimeConfigurationLoader?.getRuntimeOptionsConfigurator();
        const runtimeOptions = typeof runtimeOptionsConfigurator?.getRuntimeOptions === 'function' ? runtimeOptionsConfigurator?.getRuntimeOptions() : undefined;
        const runtimeUrl = runtimeOptions?.deviceTraitsProviderBundleUrl;
        if (runtimeUrl) {
            bundleUrlDefined = true;
            urls.push(runtimeUrl);
        }

        // @ts-ignore
        if (!bundleUrlDefined && this.window?.sirius?.config?.tenantDeviceTraitsEnabled?.toString() != 'true') {
            return undefined;
        }

        // @ts-ignore
        urls.push(`${DeviceTraitsProviderLoader.DEFAULT_BUNDLE_URL_BASE_URL}${DeviceTraitsProviderLoader.DEFAULT_BUNDLE_PRIMARY_ASSET}`);
        urls.push(`${DeviceTraitsProviderLoader.DEFAULT_BUNDLE_URL_BASE_URL}${DeviceTraitsProviderLoader.DEFAULT_BUNDLE_SECONDARY_ASSET}`);

        for (let index = 0; index < urls.length; index++) {
            const url = urls[index];
            if (!url) {
                continue;
            }
            const module = await this.getModuleFromUrl(url);
            if (module) {
                return module;
            }
        }
    }

    private async getModuleFromUrl(url: string): Promise<IDeviceTraitsProviderModule | undefined> {
        try {
            const module = await this.systemJs.import<IDeviceTraitsProviderModule>(url);
            // @ts-ignore
            return (module?.default ?? module) as IDeviceTraitsProviderModule;
        } catch (e) {
            console.debug(e);
            return undefined;
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";
import {BaseSectionPageCustomizationHandler} from "./base-section-page-customization.handler";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export const QuickNavSectionPageCustomizationHandlerTypeName = 'QuickNavSectionPageCustomizationHandler';

export class QuickNavSectionPageCustomizationHandler extends BaseSectionPageCustomizationHandler {
    private readonly tenant: ITenant;

    public constructor(
        window: Window,
        tenant: ITenant
    ) {
        super(window);
        this.tenant = tenant;
    }

    public getSectionMarkerCode(): string {
        return SectionMarkerCodes.QUICKNAV_MARKER;
    }

    public getDefaultBackgroundColor(): string {
        return  this.tenant?.getContext()?.site?.top?.quicknav?.backgroundColor ?? 'var(--uxd-neutral-white, #FFFFFF)';
    }
}

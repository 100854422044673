/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ISiteComponents} from "./site-components.interface";
import {SiriusWidgetModule} from "sirius-platform-support-library/shared/micro-frontends/widgets/sirius-widget-module";
import {SkipToContentComponent} from "./skip-to-content/skip-to-content.web-component";
import {
    ProgressIndicatorBlockingComponent
} from "./progress-indicator/blocking/progress-indicator.blocking.web-component";
import {
    ProgressIndicatorNonBlockingComponent
} from "./progress-indicator/non-blocking/progress-indicator.non-blocking.web-component";

export const SiteComponentsTypeName = 'SiteComponents';

export class SiteComponents implements ISiteComponents {
    private static readonly SKIP_TO_CONTENT_WEB_COMPONENT_NAME = 'sirius-skip-to-content'

    public constructor() {
    }

    public bootstrapRequiredComponents(isOnErrorPage: boolean = false): void {
        if (!isOnErrorPage) {
            const skipToContentComponent = new SiriusWidgetModule(SiteComponents.SKIP_TO_CONTENT_WEB_COMPONENT_NAME, SkipToContentComponent, null);
            skipToContentComponent.bootstrap();
        }

        const progressIndicatorNonBlockingComponent = new SiriusWidgetModule(ProgressIndicatorNonBlockingComponent.CUSTOM_ELEMENT_NAME, ProgressIndicatorNonBlockingComponent, null);
        progressIndicatorNonBlockingComponent.bootstrap();

        const progressIndicatorBlockingComponent = new SiriusWidgetModule(ProgressIndicatorBlockingComponent.CUSTOM_ELEMENT_NAME, ProgressIndicatorBlockingComponent, null);
        progressIndicatorBlockingComponent.bootstrap();
    }
}

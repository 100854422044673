import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {
    FeatureTogglesChangedCallback,
    IFeatureTogglesService
} from "sirius-platform-support-library/shared/feature-toggles/feature-toggles-service.interface";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    FeatureTogglesConstants
} from "sirius-platform-support-library/shared/feature-toggles/feature-toggles.constants";
import {
    IFeatureTogglesProvider,
    IFeatureTogglesProviderTypeName
} from "sirius-platform-support-library/shared/feature-toggles/providers/feature-toggles-provider.interface";
import {v4 as uuidv4} from "uuid";
import {
    FeatureTogglesChangedEvent,
    FeatureTogglesEvents
} from "sirius-platform-support-library/shared/feature-toggles/events/feature-toggles.events";
import {
    RuntimeFeatureToggle
} from "sirius-platform-support-library/shared/feature-toggles/models/runtime-feature-toggle.model";
import {NamespaceCodePair} from "./models/namespace-code-pair.model";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export const FeatureTogglesServiceTypeName = 'FeatureTogglesService';

export class FeatureTogglesService implements IFeatureTogglesService, IAfterPlatformReadyInit {
    public static readonly BROADCASTER_ID = uuidv4().toLowerCase();
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;
    private readonly featureToggles: Record<string, RuntimeFeatureToggle[]> = {};
    private readonly providers: Record<string, IFeatureTogglesProvider> = {};
    private defaultNamespace: string | undefined;
    private defaultIfNotFound: boolean | undefined;

    public constructor(
        tenant: ITenant,
        eventBus: IEventBus,
        serviceCollection: IServiceCollection
    ) {
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.serviceCollection = serviceCollection;
    }

    public static build(
        tenant: ITenant,
        eventBus: IEventBus,
        serviceCollection: ITypedServiceCollection
    ): FeatureTogglesService {
        let instance = ObjectUtility.getFromObjectPath<FeatureTogglesService>(FeatureTogglesConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new FeatureTogglesService(
                tenant,
                eventBus,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(FeatureTogglesConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public async init(): Promise<void> {
        const config = this.tenant?.getContext()?.behaviour?.featureToggles;
        this.defaultNamespace = config?.defaultNamespace;
        this.defaultIfNotFound = ObjectUtility.isDefined(config?.defaultIfNotFound) ? config?.defaultIfNotFound : true;

        await this.bindProviders();
        await this.load();
    }

    public getDefaultNamespace(): string | undefined {
        return this.defaultNamespace;
    }

    public setDefaultNamespace(namespace: string | undefined): void {
        this.defaultNamespace = namespace;
    }

    public getDefaultIfNotFound(): boolean | undefined {
        return this.defaultIfNotFound;
    }

    public setDefaultIfNotFound(defaultIfNotFound: boolean | undefined): void {
        this.defaultIfNotFound = defaultIfNotFound;
    }

    public async load(): Promise<void> {
        const namespaces = Object.values(this.providers).map(p => p.getNamespace());
        for (let index = 0; index < namespaces.length; index++) {
            const namespace = namespaces[index];
            if (!namespace) {
                continue;
            }
            await this.loadNamespaceFeatureToggles(namespace, false);
        }
        this.notifyFeatureTogglesChanged(...namespaces);
    }

    public getFeatureToggles(namespace?: string): RuntimeFeatureToggle[] {
        const namespaces = [...(namespace ? [namespace] : Object.keys(this.featureToggles))];
        return namespaces.flatMap(ns => this.featureToggles[ns]);
    }

    public getFeatureToggle(codeWithNamespace: string): RuntimeFeatureToggle | undefined {
        const pair = NamespaceCodePair.fromKey(codeWithNamespace, this.defaultNamespace);
        if (!pair) {
            return undefined;
        }
        return this.featureToggles[pair.namespace]?.find(ft => ft.code === pair.code);
    }

    public async setFeatureToggle(codeWithNamespace: string, enabled: boolean): Promise<RuntimeFeatureToggle> {
        const featureToggle = this.getFeatureToggle(codeWithNamespace);
        if (!featureToggle) {
            throw new Error(`No feature toggle found for '${codeWithNamespace}'`);
        }
        const provider = this.providers[featureToggle.namespace];
        if (!provider) {
            throw new Error(`Feature toggles provider not found for namespace '${featureToggle.namespace}'`);
        }
        if (typeof (provider as any).setFeatureToggle != 'function') {
            throw new Error(`Feature toggles provider '${featureToggle.namespace}' does not support setting feature toggles`);
        }
        await (provider as any).setFeatureToggle(featureToggle.code, enabled);
        featureToggle.enabled = enabled;
        return featureToggle;
    }

    public isEnabled(codeWithNamespace: string, defaultIfNotFound?: boolean): boolean {
        if (!ObjectUtility.isDefined(defaultIfNotFound)) {
            defaultIfNotFound = this.defaultIfNotFound ?? false;
        }
        const featureToggle = this.getFeatureToggle(codeWithNamespace);
        if (!featureToggle) {
            return defaultIfNotFound;
        }
        return featureToggle.enabled;
    }

    public onFeatureTogglesChanged(context: any, subscriberName: string, callback: FeatureTogglesChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<FeatureTogglesChangedEvent>(this, FeatureTogglesServiceTypeName, FeatureTogglesEvents.FEATURE_TOGGLES_CHANGED, async (event) => {
            try {
                await callback?.call(context, event.data);
            } catch (e) {
                console.error(e);
            }
        });
    }

    private async bindProviders(): Promise<void> {
        const onProviderRefreshRequestedBinded = this.onProviderRefreshRequested.bind(this);
        const providers = this.serviceCollection.resolveAll<IFeatureTogglesProvider>(IFeatureTogglesProviderTypeName);
        for (let index = 0; index < providers.length; index++) {
            const provider = providers[index];
            if (!provider) {
                continue;
            }
            try {
                if (provider.onRefreshRequested) {
                    provider.onRefreshRequested(onProviderRefreshRequestedBinded);
                }
                const namespace = provider.getNamespace();
                if (!namespace) {
                    continue;
                }
                this.providers[namespace] = provider;
            } catch (e) {
                console.error(e);
            }
        }
    }

    private async loadNamespaceFeatureToggles(namespace: string, notify: boolean = true): Promise<void> {
        try {
            const provider = this.providers[namespace];
            if (!provider) {
                return;
            }
            const featureToggles = await provider.getFeatureToggles() ?? [];
            this.featureToggles[namespace] = featureToggles.map(ft => new RuntimeFeatureToggle(ft));
            if (notify) {
                this.notifyFeatureTogglesChanged(...namespace);
            }
        } catch (e) {
            console.error(e);
        }
    }

    private async onProviderRefreshRequested(namespace: string, notify: boolean = true): Promise<void> {
        await this.loadNamespaceFeatureToggles(namespace, notify);
    }

    private notifyFeatureTogglesChanged(...namespaces: string[]): void {
        const event: FeatureTogglesChangedEvent = {
            broadcasterId: FeatureTogglesService.BROADCASTER_ID,
            namespaces: namespaces
        };
        this.eventBus.dispatchBroadcast(FeatureTogglesServiceTypeName, FeatureTogglesEvents.FEATURE_TOGGLES_CHANGED, event, undefined, true);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    PageCustomizationsOptions
} from "sirius-platform-support-library/models/page-customizations/page-customization.models";
import {
    IPageCustomizationsProvider,
    IPageCustomizationsProviderTypeName,
    PageCustomizationsProviderRefreshRequestedCallback
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-provider.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {TenantContextPageCustomizationsProvider} from "./tenant-context-page-customizations.provider";

export const AggregatedPageCustomizationsProviderTypeName = 'AggregatedPageCustomizationsProvider';

export class AggregatedPageCustomizationsProvider {
    private readonly tenantContextPageCustomizationsProvider: TenantContextPageCustomizationsProvider;
    private readonly serviceCollection: IServiceCollection;

    private pageCustomizationsProvider?: IPageCustomizationsProvider;
    private refreshRequestedCallback?: PageCustomizationsProviderRefreshRequestedCallback;

    public constructor(
        tenantContextPageCustomizationsProvider: TenantContextPageCustomizationsProvider,
        serviceCollection: IServiceCollection
    ) {
        this.tenantContextPageCustomizationsProvider = tenantContextPageCustomizationsProvider;
        this.serviceCollection = serviceCollection;
    }

    public async getCustomization(route: string): Promise<PageCustomizationsOptions> {
        try {
            const tenantContextCustomizations = await this.tenantContextPageCustomizationsProvider.getCustomization(route);
            const provider = this.getTenantPageCustomizationsProvider();
            const customCustomizations = await provider?.getCustomization(route, ObjectUtility.deepClone<PageCustomizationsOptions | undefined>(tenantContextCustomizations));
            return customCustomizations ? customCustomizations : tenantContextCustomizations;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    public onRefreshRequested?(callback: PageCustomizationsProviderRefreshRequestedCallback): void {
        this.refreshRequestedCallback = callback;
    }

    private getTenantPageCustomizationsProvider(): IPageCustomizationsProvider {
        if (!this.pageCustomizationsProvider) {
            this.pageCustomizationsProvider = this.serviceCollection.resolve<IPageCustomizationsProvider>(IPageCustomizationsProviderTypeName)
            if (this.pageCustomizationsProvider?.onRefreshRequested && this.refreshRequestedCallback) {
                this.pageCustomizationsProvider?.onRefreshRequested(this.refreshRequestedCallback);
            }
        }
        return this.pageCustomizationsProvider;
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {OpenedDialog, RequestedDialog} from "sirius-platform-support-library/shared/dialogs/dialog";
import {
    ITranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations-cache.interface";
import {
    TranslationsRequestedCallback
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {RendarableDialogAction} from "./rendarable-dialog-action";
import {ILocalizable} from "sirius-platform-support-library/shared/localization/localizable.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    LocalizableResourceTranslationService
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource-translation.service";
import {
    ITranslationService
} from "sirius-platform-support-library/shared/localization/translations/translation-service.interface";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {DialogTemplate} from "./templates/dialog.template";
import {DialogContentTemplate} from "./templates/dialog-content.template";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IDialogsDismissalInteractionHandler} from "./handlers/dialogs-dismissal-interaction-handler.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {DialogDismissButtonTemplate} from "./templates/dialog-dismiss-button.template";
import {DialogSize} from "sirius-platform-support-library/shared/dialogs/dialog-size";

import {v4 as uuidv4} from 'uuid';
import {IActionHandler} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {IButtonStyleProcessor} from "../common-ui/buttons/button-style.processor.interface";

export class RendarableDialog implements OpenedDialog, ILocalizable {
    public static readonly DIALOG_CONTENT_PLACEHOLDER = '{{dialogContent}}';
    public static readonly LOCALIZED_TITLE_PLACEHOLDER = '{{localizedTitle}}';
    public static readonly LOCALIZED_BODY_PLACEHOLDER = '{{localizedBody}}';

    public static readonly ACTIONS_PLACEHOLDER = '{{actions}}';
    public static readonly DISMISS_BUTTON_PLACEHOLDER = '{{dismissButton}}';
    public readonly context: any;
    public id: string;
    public timestamp: number;
    public owner: string;
    public reference: string;
    public size: DialogSize;
    public closeButtonVisible: boolean;
    public closeOnClickOutside: boolean;
    public showBackdrop: boolean;
    public modal: boolean;
    public title: { localizationCode: string, localizedText: string };
    public bodyHtml?: { localizationCode: string, localizedText: string };
    public bodyControl?: { name?: string, attributes?: Record<string, string> };
    public actions: RendarableDialogAction[];
    public localizationCode: string;
    public localizedResources?: Record<string, any>;
    public onTranslationsRequested?: TranslationsRequestedCallback;
    private readonly interactionHandler: IDialogsDismissalInteractionHandler;
    private readonly translationsCache: ITranslationsCache;
    private readonly translationService: ITranslationService;
    private readonly actionHandler: IActionHandler;
    private readonly buttonStyleProcessor: IButtonStyleProcessor;

    public constructor(
        context: any,
        dialog: RequestedDialog,
        interactionHandler: IDialogsDismissalInteractionHandler,
        localizationService: ILocalizationService,
        actionHandler: IActionHandler,
        buttonStyleProcessor: IButtonStyleProcessor,
        serviceCollection: IServiceCollection
    ) {
        this.context = context;

        this.interactionHandler = interactionHandler;
        this.buttonStyleProcessor = buttonStyleProcessor;
        this.translationsCache = new TranslationsCache(context, this);
        this.translationService = new LocalizableResourceTranslationService(
            localizationService,
            this.translationsCache,
            serviceCollection
        );
        this.actionHandler = actionHandler;

        this.id = uuidv4().toLowerCase();
        this.timestamp = Math.round(Date.now() / 1000);
        this.owner = dialog.owner || "";
        this.reference = dialog.reference || "";
        this.size = dialog.size || DialogSize.MEDIUM;
        this.modal = ObjectUtility.isDefined(dialog.modal) ? dialog.modal : false;
        this.closeButtonVisible = ObjectUtility.isDefined(dialog.closeButtonVisible) ? dialog.closeButtonVisible : true;
        this.closeOnClickOutside = ObjectUtility.isDefined(dialog.closeOnClickOutside) ? dialog.closeOnClickOutside : true;
        this.showBackdrop = ObjectUtility.isDefined(dialog.showBackdrop) ? dialog.showBackdrop : true;
        this.title = Object.assign({});
        this.title.localizationCode = dialog.title.localizationCode;
        this.title.localizedText = this.title.localizationCode;
        this.bodyHtml = Object.assign({localizationCode: undefined, localizedText: undefined}, dialog.bodyHtml);
        this.bodyHtml.localizationCode = dialog.bodyHtml?.localizationCode;
        this.bodyHtml.localizedText = this.bodyHtml?.localizationCode;
        this.bodyControl = Object.assign({}, dialog.bodyControl);
        this.bodyControl.name = dialog.bodyControl?.name;
        this.bodyControl.attributes = Object.assign({}, dialog.bodyControl?.attributes);
        this.actions = (dialog.actions || [])
            .filter(action => action)
            .map(action => new RendarableDialogAction(action, this.translationService, this.actionHandler, this.buttonStyleProcessor));
        this.localizedResources = dialog.localizedResources || {};
        this.onTranslationsRequested = dialog.onTranslationsRequested;
    }

    public async localize(): Promise<void> {
        try {
            await Promise.allSettled(this.actions.map(action => action.localize()));
            this.title.localizedText = await this.translationService.translate(this.title.localizationCode);
            if (this.bodyHtml?.localizationCode) {
                this.bodyHtml.localizedText = await this.translationService.translate(this.bodyHtml?.localizationCode);
            }
        } catch (e) {
            console.warn('Could not localize dialog', this, e);
        }
    }

    public getActionByCode(actionCode: string): RendarableDialogAction {
        return this.actions.find(action => action.code === actionCode);
    }

    public toHtml(): string {
        const dialogContentTemplate = this.applyDialogContent();
        return DialogTemplate
            .replaceAll(RendarableDialog.DIALOG_CONTENT_PLACEHOLDER, dialogContentTemplate);
    }

    public applyDialogContent(): string {
        const actionsHtml = this.actions.map(a => a.toHtml());
        const dismissButtonHtml = this.closeButtonVisible ? DialogDismissButtonTemplate : '';

        let bodyContent = this.bodyHtml?.localizedText;
        if (this.bodyControl?.name) {
            const sanitizedBodyControl = this.bodyControl.name.replace(/</g, '&lt;').replace(/>/g, '&gt;').trim();
            const attributesString = Object.entries(this.bodyControl.attributes || {}).map(([key, value]) => `${key}="${value.replace(/"/g, '&quot;')}"`).join(' ').trim();
            bodyContent = `<${sanitizedBodyControl} dialog-id="${this.id}" ${attributesString}></${sanitizedBodyControl}>`;
        }

        return DialogContentTemplate
            .replaceAll(RendarableDialog.LOCALIZED_TITLE_PLACEHOLDER, this.title.localizedText)
            .replaceAll(RendarableDialog.LOCALIZED_BODY_PLACEHOLDER, bodyContent)
            .replaceAll(RendarableDialog.DISMISS_BUTTON_PLACEHOLDER, dismissButtonHtml)
            .replaceAll(RendarableDialog.ACTIONS_PLACEHOLDER, actionsHtml.join('\n'));
    }

    public updateActions(actions) {
        this.actions = (actions || [])
            .filter(action => action)
            .map(action => new RendarableDialogAction(action, this.translationService, this.actionHandler, this.buttonStyleProcessor));
    }

    public toOpenedDialog(): OpenedDialog {
        return {
            id: this.id,
            timestamp: this.timestamp,
            owner: this.owner,
            size: this.size,
            closeButtonVisible: this.closeButtonVisible,
            closeOnClickOutside: this.closeOnClickOutside,
            showBackdrop: this.showBackdrop,
            modal: this.modal,
            title: this.title,
            bodyHtml: this.bodyHtml,
            bodyControl: this.bodyControl,
            reference: this.reference,
            actions: this.actions.map(action => action.toDialogAction())
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ToastsConfig} from "sirius-platform-support-library/tenants/tenant-context";
import {RenderableToast} from "./renderable-toast";
import {toastTypeClass} from "./toasts-constants";
import {createNodeElement} from "sirius-platform-support-library/utilities/dom-helper";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {
    IThemingHandlersManager
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";

export class ToastsDomHandler {
    public static readonly TOASTS_CONTAINER_SELECTOR = '.toasts-container';
    public static readonly TOASTS_SELECTOR = '.toast';
    public static readonly DISMISS_SELECTOR = '.toast__dismiss';
    public static readonly TOAST_ID_ATTRIBUTE = 'toast-id';

    private readonly window: Window;
    private readonly document: Document;
    private readonly styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler;
    private readonly themingHandlersManager: IThemingHandlersManager;
    private readonly onDismiss: (toast: RenderableToast) => Promise<void>;
    private readonly onOpen: (toast: RenderableToast) => Promise<void>;
    private readonly maximumToasts: number = 5;
    private readonly config: ToastsConfig;

    private containerElement: HTMLElement;
    private wrapperElement: HTMLElement;

    private queuedToasts: RenderableToast[] = [];
    private renderedToasts: RenderableToast[] = [];

    public constructor(
        window: Window,
        document: Document,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        config: ToastsConfig,
        renderCallback,
        dismissCallback,
    ) {
        this.maximumToasts = config?.maximumDisplayedToasts;
        this.window = window;
        this.config = config;
        this.document = document;
        this.styleSheetsBasedThemeHandler = styleSheetsBasedThemeHandler;
        this.themingHandlersManager = themingHandlersManager;
        this.onOpen = renderCallback;
        this.onDismiss = dismissCallback;
    }

    public async init(): Promise<void> {
        await this.build();
    }

    public async show(toast: RenderableToast) {

        if (this.renderedToasts.length < this.maximumToasts) {
            await this.render(toast);
        } else {
            this.queuedToasts.push(toast);
        }
    }

    public async render(toast: RenderableToast): Promise<void> {
        if (!toast) {
            return;
        }

        const pendingToastElement = this.document.createElement('div')
        pendingToastElement.setAttribute(ToastsDomHandler.TOAST_ID_ATTRIBUTE, toast.id);
        pendingToastElement.classList.add('toast');
        pendingToastElement.innerHTML = toast.toHtml();
        pendingToastElement.classList.add(toastTypeClass[toast.type]);
        this.containerElement.appendChild(pendingToastElement);
        this.renderedToasts.push(toast);
        toast.activateTimer();
        if (toast.pauseOnHover) {
            pendingToastElement.addEventListener('mouseenter', this.pause(toast));
            pendingToastElement.addEventListener('mouseleave', this.resume(toast))
        }
        return await this.onOpen(toast);
    }

    public async updateAll(): Promise<void> {
        await Promise.allSettled(this.renderedToasts.map(async toast => await this.update(toast)));
    }

    public async update(toast: RenderableToast): Promise<void> {
        if (!toast) {
            return;
        }
        const toastElement = this.getToastElementById(toast.id);

        if (!toastElement) {
            return;
        }

        await toast.localize();
        toastElement.innerHTML = toast.toHtml();
    }

    public async dismissById(toastId: string): Promise<void> {
        const toastElement = this.getToastElementById(toastId);
        if (!toastElement) {
            return;
        }
        const toast = this.renderedToasts.find(toast => toast.id === toastId);
        await this.onDismiss(toast);
        this.renderedToasts.splice(this.renderedToasts.findIndex(toast => toast.id === toastId), 1);

        if (toast.pauseOnHover) {
            toastElement.removeEventListener('mouseenter', this.pause(toast));
            toastElement.removeEventListener('mouseleave', this.resume(toast));
        }
        toastElement.remove();
        this.renderQueuedToast();
    }

    private async build(): Promise<void> {
        this.wrapperElement = this.document.querySelector(ToastsDomHandler.TOASTS_CONTAINER_SELECTOR);
        if (!this.wrapperElement) {
            throw new Error(`Could not get toasts container by selector "${ToastsDomHandler.TOASTS_CONTAINER_SELECTOR}"`);
        }
        if (this.config?.location) {
            this.wrapperElement.classList.add(this.config.location === 'left' ? 'left' : 'right');
        }
        const shadow = this.wrapperElement.attachShadow({mode: 'open'});

        this.wrapperElement.shadowRoot.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/libs/@clr/ui/clr-ui.min.css'
        }));

        const toastsStyleSheetElement = this.wrapperElement.shadowRoot.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/styles/core/toasts/toasts.css'
        }));

        await this.styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(toastsStyleSheetElement, DomInjectionType.BEFORE);

        await this.themingHandlersManager.attach(ThemedComponent.SHELL_TOASTS, toastsStyleSheetElement, DomInjectionType.BEFORE);

        this.containerElement = this.document.createElement("div");
        this.containerElement.classList.add('inner-toasts-container');
        this.containerElement.addEventListener('click', this.onDismissClick.bind(this));
        shadow.appendChild(this.containerElement);
    }

    private async onDismissClick(event: PointerEvent): Promise<void> {
        const dismissButton = (event.target as HTMLElement).closest(ToastsDomHandler.DISMISS_SELECTOR);
        if (!dismissButton) {
            return;
        }

        const toastElement = dismissButton.closest(ToastsDomHandler.TOASTS_SELECTOR);
        if (!toastElement) {
            return;
        }

        const toastId = toastElement.getAttribute(ToastsDomHandler.TOAST_ID_ATTRIBUTE);
        if (!toastId) {
            return;
        }

        const toast = this.renderedToasts.find(t => t.id === toastId);
        toast.clearTimer();
        return toast.dismissHandler(toast.id);
    }

    private renderQueuedToast(): void {
        if (this.renderedToasts.length >= this.maximumToasts || this.queuedToasts.length === 0) {
            return;
        }
        const newToast = this.queuedToasts.shift();

        this.render(newToast);
    }

    private getToastElementById(id: string): HTMLElement | null {
        return this.containerElement.querySelector(`${ToastsDomHandler.TOASTS_SELECTOR}[${ToastsDomHandler.TOAST_ID_ATTRIBUTE}="${id}"]`);
    }

    private pause(toast: RenderableToast): () => void {
        return () => {
            toast.pauseTimer();
        }
    }

    private resume(toast: RenderableToast): () => void {
        return () => {
            toast.resumeTimer();
        }
    }
}

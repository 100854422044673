/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    IAnalyticsEngine,
    IAnalyticsEngineTypeName
} from "sirius-platform-support-library/shared/analytics/analytics-engine.interface";
import {GlassboxEngine, GlassboxEngineTypeName} from "./engines/glassbox-engine";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {PendoEngine, PendoEngineTypeName} from "./engines/pendo-engine";
import {AnalyticsService, AnalyticsServiceTypeName} from "./analytics-service";
import {
    IAnalyticsService,
    IAnalyticsServiceTypeName
} from "sirius-platform-support-library/shared/analytics/analytics-service.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";

export class AnalyticsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IAnalyticsEngine, GlassboxEngine>(DependencyType.SHARED, IAnalyticsEngineTypeName, GlassboxEngineTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new GlassboxEngine(window, document, tenant);
            })
            .addUsingFactoryAsSingleton<IAnalyticsEngine, PendoEngine>(DependencyType.SHARED, IAnalyticsEngineTypeName, PendoEngineTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new PendoEngine(window, document, tenant);
            })
            .addUsingFactoryAsSingleton<IAnalyticsService, AnalyticsService>(DependencyType.SHARED, IAnalyticsServiceTypeName, AnalyticsServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return AnalyticsService.build(
                    window,
                    tenant,
                    di
                );
            })
            .addInterfacesOnSingleton(AnalyticsServiceTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const analyticsDependencyModule = new AnalyticsDependencyModule();

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IBannerAlertsPriorityAssigner} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts-priority-assigner.interface";
import {BannerAlert, PushedBannerAlert} from "sirius-platform-support-library/shared/banner-alerts/banner-alert";
import {BannerAlertType} from "sirius-platform-support-library/shared/banner-alerts/banner-alert-type";

export const DefaultBannerAlertsPriorityAssignerTypeName = 'DefaultBannerAlertsPriorityAssigner';

export class DefaultBannerAlertsPriorityAssigner implements IBannerAlertsPriorityAssigner {
    private static readonly groupBy = (items, key) => items.reduce((result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }), {},
    );

    public prioritize(alerts: BannerAlert[]): BannerAlert[] {
        const grouped = DefaultBannerAlertsPriorityAssigner.groupBy(alerts, 'type');
        const e = this.prioritizeAndSort(grouped[BannerAlertType.ERROR]);
        const w = this.prioritizeAndSort(grouped[BannerAlertType.WARNING]);
        const i = this.prioritizeAndSort(grouped[BannerAlertType.INFO]);
        const s = this.prioritizeAndSort(grouped[BannerAlertType.SUCCESS]);
        const prioritized = [].concat(...e, ...w, ...i, ...s).reverse();
        alerts.splice(0, alerts.length);
        alerts.push(...prioritized);
        return alerts;
    }

    private prioritizeAndSort(items?: PushedBannerAlert[]) {
        if (!items) {
            return [];
        } else {
            return items.sort((a, b) => Number(b.highPriority) - Number(a.highPriority) || b.timestamp - a.timestamp);
        }
    }
}

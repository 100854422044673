/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IBannerAlertsInteractionHandler} from "../handlers/banner-alerts-interaction-handler.interface";
import {RenderableBannerAlert} from "../renderable-banner-alert";
import {BannerAlertsContainerTemplate} from "../templates/banner-alerts-container.template";
import {BannerAlertStyleType} from "./banner-alert-style-type";
import {BannerAlertsEvents} from "sirius-platform-support-library/shared/banner-alerts/events/banner-alerts-events";
import {
    BannerAlertsContainerVisibilityChangedEvent
} from "sirius-platform-support-library/shared/banner-alerts/events/banner-alerts-container-visibility-changed.event";
import {createNodeElement} from "sirius-platform-support-library/utilities/dom-helper";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    IThemingHandlersManager
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";

export class BannerAlertsDomHandler {
    public static readonly BANNER_ALERTS_WRAPPER_ID = 'sirius-banner-alerts';

    public static readonly PREVIOUS_ELEMENT_SELECTOR = '.alerts-navigation>.previous-button';
    public static readonly CURRENT_PAGE_SELECTOR = '.alerts-navigation>.alerts-pagination>.current-page';
    public static readonly TOTAL_PAGES_SELECTOR = '.alerts-navigation>.alerts-pagination>.total-pages';
    public static readonly NEXT_ELEMENT_SELECTOR = '.alerts-navigation>.next-button';
    public static readonly ALERTS_SELECTOR = '.alert-items';
    public static readonly DISMISS_SELECTOR = '.dismiss-button';
    public static readonly ACTIVE_ALERT_SELECTOR = '.alert-item';
    public static readonly ACTION_SELECTOR = 'button';
    public static readonly NAVIGATION_ELEMENT_SELECTOR = '.alerts-navigation';

    public static readonly ALERT_ID_ATTRIBUTE = 'alert-id';

    private readonly window: Window;
    private readonly document: Document;
    private readonly interactionHandler: IBannerAlertsInteractionHandler;
    private readonly styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler;
    private readonly themingHandlersManager: IThemingHandlersManager;
    private readonly serviceCollection: IServiceCollection;

    private wrapperElement: HTMLElement;
    private containerElement: HTMLElement;

    private navigationElement: HTMLElement;
    private previousElement: HTMLElement;
    private nextElement: HTMLElement;
    private currentPageElement: HTMLElement;
    private totalPagesElement: HTMLElement;
    private dismissElement: HTMLElement;
    private alertsElement: HTMLElement;
    private hideNavigationIfNotRequired: boolean;

    public constructor(
        window: Window,
        document: Document,
        interactionHandler: IBannerAlertsInteractionHandler,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.document = document;
        this.interactionHandler = interactionHandler;
        this.styleSheetsBasedThemeHandler = styleSheetsBasedThemeHandler;
        this.themingHandlersManager = themingHandlersManager;
        this.serviceCollection = serviceCollection;

        const tenant = this.serviceCollection.resolve<ITenant>(ITenantTypeName);
        const context = tenant.getContext();
        this.hideNavigationIfNotRequired = ObjectUtility.isDefined(context?.behaviour?.bannerAlerts?.hideNavigationIfNotRequired)
            ? context.behaviour.bannerAlerts.hideNavigationIfNotRequired : false;
    }

    public async init(): Promise<void> {
        await this.build();
    }

    public render(bannerAlert: RenderableBannerAlert): void {
        if (!bannerAlert) {
            this.alertsElement.innerHTML = "";
            return;
        }
        const pendingAlertElement = this.document.createElement('div')
        pendingAlertElement.setAttribute(BannerAlertsDomHandler.ALERT_ID_ATTRIBUTE, bannerAlert.id);
        pendingAlertElement.classList.add('alert-item');
        pendingAlertElement.innerHTML = bannerAlert.toHtml();
        this.alertsElement.replaceChildren(pendingAlertElement);
        this.applyClickEventListenersOnActions(pendingAlertElement);
        this.applyContainerStyle(bannerAlert);
        this.setDismissButtonVisibility(bannerAlert);
    }

    public update(bannerAlert: RenderableBannerAlert): void {
        const activeAlertElement = this.alertsElement.querySelector(BannerAlertsDomHandler.ACTIVE_ALERT_SELECTOR);
        if (!activeAlertElement) {
            return;
        }
        const bannerAlertId = activeAlertElement.getAttribute(BannerAlertsDomHandler.ALERT_ID_ATTRIBUTE);
        if (bannerAlertId !== bannerAlert.id) {
            return;
        }
        activeAlertElement.innerHTML = bannerAlert.toHtml();
        this.applyClickEventListenersOnActions(activeAlertElement);
        this.applyContainerStyle(bannerAlert);
    }

    public clear(bannerAlert: RenderableBannerAlert): void {
        const activeAlertElement = this.alertsElement.querySelector(BannerAlertsDomHandler.ACTIVE_ALERT_SELECTOR);
        if (!activeAlertElement) {
            return;
        }
        const bannerAlertId = activeAlertElement.getAttribute(BannerAlertsDomHandler.ALERT_ID_ATTRIBUTE);
        if (bannerAlertId !== bannerAlert.id) {
            return;
        }
        this.alertsElement.replaceChildren();
    }

    public updateCount(currentIndex: number, totalCount: number): void {
        this.currentPageElement.innerText = (currentIndex + 1).toString();
        this.totalPagesElement.innerText = totalCount.toString();
        this.currentPageElement.setAttribute('aria-label', `Page ${(currentIndex + 1)} of ${totalCount}`);
        this.totalPagesElement.setAttribute('aria-label', `Total number ${totalCount} of pages`);
        if (totalCount === 0) {
            this.hide();
        } else {
            this.show();
        }
        this.showOrHideCarousel(totalCount);
    }

    public addHighPriorityNotification() {
        this.navigationElement.classList.add('flash');
        setTimeout(() => this.removeHighPriorityNotification(), 7500);
    }

    public removeHighPriorityNotification() {
        this.navigationElement.classList.remove('flash')
    }

    public addEffectForContainer() {
        this.containerElement.classList.add('container-flash');
        setTimeout(() => this.removeEffectForContainer(), 7500);
    }

    public removeEffectForContainer() {
        this.containerElement.classList.remove('container-flash')
    }

    private show(): void {
        this.wrapperElement.classList.add('alerts-section-visible');
        this.wrapperElement.classList.remove('alerts-section-hidden');
    }

    private hide(): void {
        this.wrapperElement.classList.remove('alerts-section-visible');
        this.wrapperElement.classList.add('alerts-section-hidden');
        setTimeout(() => {
            this.triggerVisibilityChangedEvent(false);
        }, 100);
    }

    private async build(): Promise<void> {
        this.wrapperElement = this.document.getElementById(BannerAlertsDomHandler.BANNER_ALERTS_WRAPPER_ID);
        if (!this.wrapperElement) {
            throw new Error(`Could not get banner alerts wrapper element with ID ${BannerAlertsDomHandler.BANNER_ALERTS_WRAPPER_ID}`);
        }

        this.wrapperElement.addEventListener("animationend", (ev: AnimationEvent) => {
            let visible = true;
            if (ev.animationName === 'easeOut') {
                visible = false;
            } else if (ev.animationName === 'easeIn') {
                visible = true;
            }
            this.triggerVisibilityChangedEvent(visible);
        });

        const shadow = this.wrapperElement.attachShadow({mode: 'open'});

        shadow.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/libs/@clr/ui/clr-ui.min.css'
        }));

        const bannerAlertsStyleSheetElement = shadow.appendChild(createNodeElement('link', {
            rel: 'stylesheet',
            href: '/styles/core/banner-alerts/banner-alerts.css'
        }));

        await this.styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(bannerAlertsStyleSheetElement, DomInjectionType.BEFORE);

        await this.themingHandlersManager.attach(ThemedComponent.SHELL_BANNER_ALERTS, bannerAlertsStyleSheetElement, DomInjectionType.BEFORE);

        this.containerElement = this.document.createElement("div");
        this.containerElement.classList.add('banner-alert-container');
        this.containerElement.classList.add('alert');
        this.containerElement.innerHTML = BannerAlertsContainerTemplate;

        shadow.appendChild(this.containerElement);

        this.navigationElement = this.containerElement.querySelector(BannerAlertsDomHandler.NAVIGATION_ELEMENT_SELECTOR);
        this.previousElement = this.containerElement.querySelector(BannerAlertsDomHandler.PREVIOUS_ELEMENT_SELECTOR);
        if (this.previousElement) {
            this.previousElement.addEventListener('click', this.onPreviousClick.bind(this));
        }
        this.currentPageElement = this.containerElement.querySelector(BannerAlertsDomHandler.CURRENT_PAGE_SELECTOR);
        this.totalPagesElement = this.containerElement.querySelector(BannerAlertsDomHandler.TOTAL_PAGES_SELECTOR);
        this.nextElement = this.containerElement.querySelector(BannerAlertsDomHandler.NEXT_ELEMENT_SELECTOR);
        if (this.nextElement) {
            this.nextElement.addEventListener('click', this.onNextClick.bind(this));
        }
        this.alertsElement = this.containerElement.querySelector(BannerAlertsDomHandler.ALERTS_SELECTOR);
        this.dismissElement = this.containerElement.querySelector(BannerAlertsDomHandler.DISMISS_SELECTOR);
        if (this.dismissElement) {
            this.dismissElement.addEventListener('click', this.onDismissClick.bind(this));
        }
    }

    private async onPreviousClick(): Promise<void> {
        await this.interactionHandler.handlePrevious();
    }

    private async onNextClick(): Promise<void> {
        await this.interactionHandler.handleNext();
    }

    private async onDismissClick(): Promise<void> {
        const activeAlertElement = this.alertsElement.querySelector(BannerAlertsDomHandler.ACTIVE_ALERT_SELECTOR);
        if (!activeAlertElement) {
            return;
        }
        const bannerAlertId = activeAlertElement.getAttribute('alert-id');
        if (bannerAlertId) {
            await this.interactionHandler.handleDismiss(bannerAlertId);
        }
    }

    private async onActionClick(event: Event): Promise<void> {
        const actionElement = event.target as Element;
        if (!actionElement) {
            return;
        }
        const bannerAlertId = actionElement.parentElement?.parentElement?.getAttribute('alert-id');
        const actionCode = actionElement.getAttribute('action-code');
        if (!bannerAlertId || !actionCode) {
            return;
        }
        await this.interactionHandler.handleAction(bannerAlertId, actionCode);
    }

    private applyContainerStyle(bannerAlert: RenderableBannerAlert): void {
        this.containerElement.classList.remove(...Object.values(BannerAlertStyleType).map(value => value.toString()));
        this.containerElement.classList.add(`${BannerAlertStyleType[bannerAlert.type]}`);
    }

    private applyClickEventListenersOnActions(activeAlertElement: Element): void {
        const actionElements = activeAlertElement.querySelectorAll(BannerAlertsDomHandler.ACTION_SELECTOR);
        actionElements.forEach(actionElement => {
            actionElement.addEventListener('click', this.onActionClick.bind(this));
        });
    }

    private setDismissButtonVisibility(bannerAlert: RenderableBannerAlert): void {
        const dismissButtonVisible = !ObjectUtility.isDefined(bannerAlert?.dismissButtonVisible) ? true : bannerAlert.dismissButtonVisible;
        if (dismissButtonVisible) {
            this.showDismissButton();
        } else {
            this.hideDismissButton();
        }
    }

    private triggerVisibilityChangedEvent(bannerAlertsContainerVisible: boolean): void {
        const event = new CustomEvent<BannerAlertsContainerVisibilityChangedEvent>(BannerAlertsEvents.NATIVE_BANNER_ALERTS_CONTAINER_VISIBILITY_CHANGED, {
            detail: {
                bannerAlertsContainerVisible: bannerAlertsContainerVisible
            }
        });
        this.window.dispatchEvent(event);
    }

    private showOrHideCarousel(count: number) {
        if (count === 1 && this.hideNavigationIfNotRequired) {
            this.navigationElement.classList.add('hidden');
        } else {
            this.navigationElement.classList.remove('hidden');
        }
    }

    private showDismissButton(): void {
        this.dismissElement.style.visibility = "visible";
    }

    private hideDismissButton(): void {
        this.dismissElement.style.visibility = "hidden";
    }
}

import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    ManagedSessionCookieUserInfoWithSessionService,
    ManagedSessionCookieUserInfoWithSessionServiceTypeName
} from "./services/managed-session-cookie.user-info-with-session.service";
import {
    ManagedSessionCookieAuthenticationHandler,
    ManagedSessionCookieAuthenticationHandlerTypeName
} from "./managed-session-cookie.authentication-handler";
import {
    IManagedSessionCookieUserInfoServiceTypeName
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.user-info.service.interface";
import {
    IManagedSessionCookieSessionServiceTypeName
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.session.service.interface";
import {
    IAuthenticationHandler,
    IAuthenticationHandlerTypeName
} from "sirius-platform-support-library/shared/authentication/handlers/authentication-handler.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    ITenantStoreService,
    ITenantStoreServiceTypeName
} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IBrowserNavigationService,
    IBrowserNavigationServiceTypeName
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-service.interface";
import {
    IUserContextUpdater,
    IUserContextUpdaterTypeName
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {
    ISessionLifecycleManagerTypeName
} from "sirius-platform-support-library/shared/authentication/session/session-lifecycle-manager.interface";
import {
    IRequiredClaimsMappingDelegatedProviderTypeName
} from "sirius-platform-support-library/shared/authentication/claims/required-claims-mapping.delegated-provider.interface";
import {
    IOptionalClaimsMappingDelegatedProviderTypeName
} from "sirius-platform-support-library/shared/authentication/claims/optional-claims-mapping.delegated-provider.interface";

export class ManagedSessionCookieDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<ManagedSessionCookieUserInfoWithSessionService>(DependencyType.PRIVATE, ManagedSessionCookieUserInfoWithSessionServiceTypeName, new ManagedSessionCookieUserInfoWithSessionService(window))
            .addInterfacesOnSingleton(ManagedSessionCookieUserInfoWithSessionServiceTypeName, IManagedSessionCookieUserInfoServiceTypeName)
            .addInterfacesOnSingleton(ManagedSessionCookieUserInfoWithSessionServiceTypeName, IManagedSessionCookieSessionServiceTypeName)
            .addInterfacesOnSingleton(ManagedSessionCookieUserInfoWithSessionServiceTypeName, IRequiredClaimsMappingDelegatedProviderTypeName)
            .addInterfacesOnSingleton(ManagedSessionCookieUserInfoWithSessionServiceTypeName, IOptionalClaimsMappingDelegatedProviderTypeName)

            .addUsingFactoryAsSingleton<IAuthenticationHandler, ManagedSessionCookieAuthenticationHandler>(DependencyType.PRIVATE, IAuthenticationHandlerTypeName, ManagedSessionCookieAuthenticationHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const tenantStore = di.resolve<ITenantStoreService>(ITenantStoreServiceTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const browserNavigationService = di.resolve<IBrowserNavigationService>(IBrowserNavigationServiceTypeName);
                const userContextUpdater = di.resolve<IUserContextUpdater>(IUserContextUpdaterTypeName);
                return new ManagedSessionCookieAuthenticationHandler(
                    window,
                    tenant,
                    tenantStore,
                    eventBus,
                    browserNavigationService,
                    userContextUpdater,
                    di
                );
            })
            .addInterfacesOnSingleton(ManagedSessionCookieAuthenticationHandlerTypeName, ISessionLifecycleManagerTypeName)
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {TenantContext} from "sirius-platform-support-library/tenants/tenant-context";
import {
    ITenantCustomBootstrapCode
} from "sirius-shell-support-library/tenants/custom-code/tenant-custom-bootstrap-code.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";

export const TenantTypeName = 'Tenant';

export class Tenant implements ITenant {
    private tenantContext: TenantContext;
    private bootstrapCode: ITenantCustomBootstrapCode;

    public getContext(): TenantContext | undefined {
        return ObjectUtility.deepClone<TenantContext>(this.tenantContext)
    }

    public setContext(tenantContext?: TenantContext) {
        this.tenantContext = tenantContext;
    }

    public getBootstrapCode(): ITenantCustomBootstrapCode | undefined {
        return this.bootstrapCode;
    }

    public setBootstrapCode(bootstrapCode?: ITenantCustomBootstrapCode) {
        this.bootstrapCode = bootstrapCode;
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IAuthorizationService
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import {RoutingGuardOptions} from "sirius-platform-support-library/shared/authorization/models/routing-guard.options";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IPlatformBrowserNavigationGateHandler
} from "../../browser-events/platform/platform-browser-navigation-gate-handler.interface";
import {pathToRegexp} from "path-to-regexp";
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {DefaultPagesEnum} from "sirius-platform-support-library/shared/site/default-pages/default-pages.enum";

export const AuthorizationBrowserNavigationGateHandlerTypeName = 'AuthorizationBrowserNavigationGateHandler';

export class AuthorizationBrowserNavigationGateHandler implements IPlatformBrowserNavigationGateHandler {
    private readonly tenant: ITenant;
    private readonly authorizationService: IAuthorizationService;
    private readonly defaultPagesService: IDefaultPagesService;

    private readonly guards: Record<string, RoutingGuardOptions>;
    private readonly behaviourUnauthorizedPage: DefaultPagesEnum | string;

    public constructor(
        tenant: ITenant,
        authorizationService: IAuthorizationService,
        defaultPagesService: IDefaultPagesService
    ) {
        this.tenant = tenant;
        this.authorizationService = authorizationService;
        this.defaultPagesService = defaultPagesService;

        const context = this.tenant.getContext();
        this.guards = Object.assign({}, context?.site?.routing?.guards ?? {});
        this.behaviourUnauthorizedPage = context?.behaviour?.authorization?.unauthorizedPage ?? DefaultPagesEnum.DEFAULT_PAGE_403;
    }

    public getUniqueId(): string {
        return AuthorizationBrowserNavigationGateHandlerTypeName;
    }

    public isNavigationAllowed(url?: string, hardNavigation?: boolean): boolean {
        url = RoutingUtilities.getPathName(url);
        const guard = this.getMatchingGuard(url)
        if (!guard?.permissions) {
            return true;
        }
        const authorized = this.authorizationService.isAuthorized(guard.permissions);
        if (!authorized) {
            const unauthorizedPage = guard?.unauthorizedPage ?? this.behaviourUnauthorizedPage;
            setTimeout(async () => {
                await this.defaultPagesService.navigateTo(this, unauthorizedPage, {
                    originRoute: url
                });
            }, 100);
        }
        return authorized;
    }

    private getMatchingGuard(url?: string): RoutingGuardOptions | undefined {
        if (!url) {
            return undefined;
        }
        try {
            let matching: RoutingGuardOptions = undefined
            for (const route in this.guards) {
                try {
                    const routeTemplate = route.replaceAll('*', '(.*)');
                    const expression = pathToRegexp(routeTemplate, undefined, {end: true});
                    if (expression.test(url)) {
                        matching = this.guards[route];
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            return matching;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IEventSubscriptionHandler} from "./event-subscription-handler.interface";
import {SubscriberIdGenerator} from "./subscriber-id.generator";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {EventType} from "sirius-platform-support-library/shared/event-bus/event-type";
import {EventCallback} from "sirius-platform-support-library/shared/event-bus/event-callback";

export class EventSubscription<T> implements IEventSubscription {
    public readonly subscriberContext: any;
    public readonly subscriberName: string;
    public readonly id: string;
    public readonly timestamp: Date;
    public readonly eventType: EventType;
    public readonly event: string;
    public readonly callback: EventCallback<T>;
    public readonly channel?: string;

    private readonly handler: IEventSubscriptionHandler;

    constructor(subscriberContext: any, subscriberName: string, eventType: EventType, event: string, callback: EventCallback<T>, handler: IEventSubscriptionHandler, channel?: string) {
        this.subscriberContext = subscriberContext;
        this.subscriberName = subscriberName;
        this.id = SubscriberIdGenerator.getNextId();
        this.timestamp = new Date();
        this.eventType = eventType;
        this.event = event;
        this.callback = callback;
        this.handler = handler;
        this.channel = channel;
    }

    unregister(): void {
        this.handler.unsubscribe(this);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    AggregatedBrowserNavigationGateHandler,
    AggregatedBrowserNavigationGateHandlerTypeName
} from "./aggregated-browser-navigation-gate.handler";
import {AggregatedPageUnloadHandler, AggregatedPageUnloadHandlerTypeName} from "./aggregated-page-unload.service";
import {
    PlatformBrowserNavigationService,
    PlatformBrowserNavigationServiceTypeName
} from "./platform-browser-navigation.service";
import {BrowserEventsInterceptor, BrowserEventsInterceptorTypeName} from "./browser-events.interceptor";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {
    IBrowserNavigationService,
    IBrowserNavigationServiceTypeName
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-service.interface";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {
    AggregatedActionInteractionGateHandler,
    AggregatedActionInteractionGateHandlerTypeName
} from "./aggregated-action-interaction-gate.handler";
import {
    PlatformActionInteractionService,
    PlatformActionInteractionServiceTypeName
} from "./platform-action-interaction.service";
import {
    IActionInteractionService,
    IActionInteractionServiceTypeName
} from "sirius-platform-support-library/shared/browser-events/action-interaction-service.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {
    IPossibleGateChangedEventsReceiverTypeName
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events-receiver";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    NavigationAndInteractionGateEventsReceiver,
    NavigationAndInteractionGateEventsReceiverTypeName
} from "./navigation-and-interaction-gate.events-receiver";

export class BrowserEventDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<AggregatedBrowserNavigationGateHandler>(DependencyType.PRIVATE, AggregatedBrowserNavigationGateHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new AggregatedBrowserNavigationGateHandler(
                    tenant,
                    di
                );
            })
            .addInterfacesOnSingleton(AggregatedBrowserNavigationGateHandlerTypeName, IBeforePlatformReadyInitTypeName)
            .addUsingFactoryAsSingleton<AggregatedPageUnloadHandler>(DependencyType.SHARED, AggregatedPageUnloadHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new AggregatedPageUnloadHandler(
                    tenant,
                    di
                );
            })
            .addUsingFactoryAsSingleton<IBrowserNavigationService, PlatformBrowserNavigationService>(
                DependencyType.SHARED,
                IBrowserNavigationServiceTypeName,
                PlatformBrowserNavigationServiceTypeName,
                (di) => {
                    const tenant = di.resolve<ITenant>(ITenantTypeName);
                    const eventBus = di.resolve<IEventBus>(IEventBusTypeName)
                    const aggregatedBrowserNavigationGateHandler = di.resolve<AggregatedBrowserNavigationGateHandler>(AggregatedBrowserNavigationGateHandlerTypeName);
                    const aggregatedPageUnloadHandler = di.resolve<AggregatedPageUnloadHandler>(AggregatedPageUnloadHandlerTypeName);
                    const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);
                    return new PlatformBrowserNavigationService(
                        window,
                        tenant,
                        eventBus,
                        aggregatedBrowserNavigationGateHandler,
                        aggregatedPageUnloadHandler,
                        defaultPagesService
                    );
                })
            .addInterfacesOnSingleton(PlatformBrowserNavigationServiceTypeName, IPossibleGateChangedEventsReceiverTypeName)
            .addUsingFactoryAsSingleton<AggregatedActionInteractionGateHandler>(DependencyType.PRIVATE, AggregatedActionInteractionGateHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new AggregatedActionInteractionGateHandler(
                    tenant,
                    di
                );
            })
            .addInterfacesOnSingleton(AggregatedActionInteractionGateHandlerTypeName, IBeforePlatformReadyInitTypeName)
            .addUsingFactoryAsSingleton<IActionInteractionService, PlatformActionInteractionService>(
                DependencyType.SHARED,
                IActionInteractionServiceTypeName,
                PlatformActionInteractionServiceTypeName,
                (di) => {
                    const tenant = di.resolve<ITenant>(ITenantTypeName);
                    const eventBus = di.resolve<IEventBus>(IEventBusTypeName)
                    const aggregatedActionInteractionGateHandler = di.resolve<AggregatedActionInteractionGateHandler>(AggregatedActionInteractionGateHandlerTypeName);
                    return new PlatformActionInteractionService(
                        window,
                        tenant,
                        eventBus,
                        aggregatedActionInteractionGateHandler
                    );
                })
            .addInterfacesOnSingleton(PlatformActionInteractionServiceTypeName, IPossibleGateChangedEventsReceiverTypeName)
            .addUsingFactoryAsSingleton<BrowserEventsInterceptor>(
                DependencyType.PRIVATE,
                BrowserEventsInterceptorTypeName,
                (di) => {
                    const platformBrowserNavigationService = di.resolve<PlatformBrowserNavigationService>(PlatformBrowserNavigationServiceTypeName);
                    return new BrowserEventsInterceptor(
                        window,
                        platformBrowserNavigationService
                    );
                })
            .addInterfacesOnSingleton(BrowserEventsInterceptorTypeName, IAfterPlatformReadyInitTypeName)
            .addUsingFactoryAsSingleton<NavigationAndInteractionGateEventsReceiver>(DependencyType.PRIVATE, NavigationAndInteractionGateEventsReceiverTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName)
                return new NavigationAndInteractionGateEventsReceiver(eventBus);
            });
    }
}

export const browserEventsDependencyModule = new BrowserEventDependencyModule();

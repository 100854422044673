import {
    IThemeVariantSelector
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selector.interface";
import {
    IThemeVariantSelectorsPriorityAssigner,
    THEME_VARIANT_SELECTOR_MAX_PRIORITY,
    THEME_VARIANT_SELECTORS_PRIORITIES
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selectors-priority-assigner.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";

export const DefaultThemeVariantSelectorsPriorityAssignerTypeName = 'DefaultThemeVariantSelectorsPriorityAssigner';

export class DefaultThemeVariantSelectorsPriorityAssigner implements IThemeVariantSelectorsPriorityAssigner {
    private priorityMap: Record<string, number> = THEME_VARIANT_SELECTORS_PRIORITIES;

    public getPriorities(): Record<string, number> {
        return this.priorityMap;
    }

    public configure(priorityMap: Record<string, number>): void {
        if (priorityMap) {
            this.priorityMap = priorityMap;
        }
    }

    public prioritize(selectors: IThemeVariantSelector[]): IThemeVariantSelector[] {
        const mutatedPriorityMap: Record<string, number> = {};
        let minPriority = THEME_VARIANT_SELECTOR_MAX_PRIORITY;
        for (const selectorKey of selectors.map(s => s.getUniqueCode())) {
            let selectorPriority = this.priorityMap[selectorKey];
            if (!ObjectUtility.isDefined(selectorPriority)) {
                minPriority = Math.min(minPriority, ...Object.values(mutatedPriorityMap));
                selectorPriority = minPriority - 1000;
            }
            mutatedPriorityMap[selectorKey] = selectorPriority;
        }

        return selectors.sort((a, b) => {
            const aPriority = mutatedPriorityMap[a.getUniqueCode()] ?? 0;
            const bPriority = mutatedPriorityMap[b.getUniqueCode()] ?? 0;
            if (aPriority < bPriority) {
                return -1;
            }
            if (aPriority > bPriority) {
                return 1;
            }
            return 0;
        });
    }

    public reset(): void {
        this.priorityMap = THEME_VARIANT_SELECTORS_PRIORITIES;
    }
}

import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {ManagedSessionCookieDependencyModule} from "./managed-session-cookie/managed-session-cookie.dependency-module";

export class DellIdentityDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        new ManagedSessionCookieDependencyModule().register(dependencyContainer);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ItemAddedCallback,
    ItemEvictedCallback,
    ItemRemovedCallback,
    ItemUpdatedCallback,
    ITenantStoreService
} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {StoreItemMetadata} from "./store-item.metadata";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {StoreItemOptions} from "sirius-platform-support-library/shared/tenant-store/store-item-options";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {TenantStoreConstants} from "sirius-platform-support-library/shared/tenant-store/tenant-store-constants";
import {TenantStoreEvent} from "sirius-platform-support-library/shared/tenant-store/events/tenant-store-event";
import {TenantStoreEvents} from "sirius-platform-support-library/shared/tenant-store/events/tenant-store-events";

export const TenantStoreServiceTypeName = 'TenantStoreService';

export class TenantStoreService implements ITenantStoreService, IBeforePlatformReadyInit {
    public static readonly STORE_INDEXES_KEY = 'sirius.store.indexes';

    private readonly window: Window;
    private readonly eventBus: IEventBus

    private storeIndexes: Record<string, StoreItemMetadata>;

    public static build(window: Window, eventBus: IEventBus): TenantStoreService {
        let instance = ObjectUtility.getFromObjectPath<TenantStoreService>(TenantStoreConstants.GLOBAL_KEY);
        if (!instance) {
            instance = new TenantStoreService(window, eventBus);
            ObjectUtility.assignOnObjectPath(TenantStoreConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): TenantStoreService {
        return ObjectUtility.getFromObjectPath<TenantStoreService>(TenantStoreConstants.GLOBAL_KEY);
    }

    private constructor(window: Window, eventBus: IEventBus) {
        this.window = window;
        this.eventBus = eventBus;
        this.loadStoreIndexes();
    }

    public async init(): Promise<void> {
        this.runEvictionSequence();
    }

    public set(key: string, value: any, options?: StoreItemOptions): void {
        this.loadStoreIndexes();
        const exists = this.internalContains(key);
        const epochNow = this.getEpochNow();
        this.storeIndexes[key] = {
            itemKey: key,
            evictionTime: options?.evictIn ? epochNow + options.evictIn : undefined
        };
        const json = ObjectUtility.serialize(value);
        this.window.localStorage.setItem(key, json);
        this.triggerEvent(!exists ? TenantStoreEvents.ITEM_ADDED_EVENT : TenantStoreEvents.ITEM_UPDATED_EVENT, key, value);
        this.saveStoreIndexes();
    }

    public get<TType>(key: string): TType | undefined {
        this.loadStoreIndexes();
        const metadata = this.storeIndexes[key];
        if (!metadata) {
            this.window.localStorage.removeItem(key);
            return undefined;
        }
        if (this.isStoreItemEvictable(metadata, this.getEpochNow())) {
            this.evict(metadata);
            return undefined;
        }
        const json = this.window.localStorage.getItem(key);
        return ObjectUtility.deserialize<TType>(json);
    }

    public remove(key: string): void {
        this.loadStoreIndexes();
        const metadata = this.storeIndexes[key];
        if (!metadata) {
            this.window.localStorage.removeItem(key);
            return;
        }
        const json = this.window.localStorage.getItem(key);
        const value = ObjectUtility.deserialize<any>(json);
        delete this.storeIndexes[key];
        this.window.localStorage.removeItem(key);
        if (ObjectUtility.isDefined(value)) {
            this.triggerEvent(TenantStoreEvents.ITEM_REMOVED_EVENT, key, value);
        }
        this.saveStoreIndexes();
    }

    public getAllKeys(): string[] {
        this.loadStoreIndexes();
        return Object.keys(this.storeIndexes);
    }

    public contains(key: string): boolean {
        this.loadStoreIndexes();
        return this.internalContains(key);
    }

    public onItemAdded(context: any, subscriberName: string, callback: ItemAddedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<TenantStoreEvent>(this, subscriberName, TenantStoreEvents.ITEM_ADDED_EVENT, (event) => {
            callback?.call(context, event.data.itemKey, event.data.itemValue);
        });
    }

    public onItemUpdated(context: any, subscriberName: string, callback: ItemUpdatedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<TenantStoreEvent>(this, subscriberName, TenantStoreEvents.ITEM_UPDATED_EVENT, (event) => {
            callback?.call(context, event.data.itemKey, event.data.itemValue);
        });
    }

    public onItemRemoved(context: any, subscriberName: string, callback: ItemRemovedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<TenantStoreEvent>(this, subscriberName, TenantStoreEvents.ITEM_REMOVED_EVENT, (event) => {
            callback?.call(context, event.data.itemKey, event.data.itemValue);
        });
    }

    public onItemEvicted(context: any, subscriberName: string, callback: ItemEvictedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<TenantStoreEvent>(this, subscriberName, TenantStoreEvents.ITEM_EVICTED_EVENT, (event) => {
            callback?.call(context, event.data.itemKey, event.data.itemValue);
        });
    }

    private internalContains(key: string): boolean {
        const metadata = this.storeIndexes[key];
        return !!metadata;
    }

    private runEvictionSequence() {
        this.window.setInterval(() => {
            if (!this.storeIndexes) {
                return;
            }
            const evictable = Object.keys(this.storeIndexes).map(key => this.storeIndexes[key]).filter(m => this.isStoreItemEvictable(m, this.getEpochNow()));
            evictable.forEach(storeItemMetadata => {
                this.evict(storeItemMetadata);
            });
        }, 100);
    }

    private evict(storeItemMetadata: StoreItemMetadata) {
        const json = this.window.localStorage.getItem(storeItemMetadata.itemKey);
        const value = ObjectUtility.deserialize<any>(json);
        this.window.localStorage.removeItem(storeItemMetadata.itemKey);
        delete this.storeIndexes[storeItemMetadata.itemKey];
        if (ObjectUtility.isDefined(value)) {
            this.triggerEvent(TenantStoreEvents.ITEM_EVICTED_EVENT, storeItemMetadata.itemKey, value);
        }
        this.saveStoreIndexes();
    }

    private triggerEvent(eventType: string, storeItemKey: string, storeItemValue: any): void {
        const event: TenantStoreEvent = {
            itemKey: storeItemKey,
            itemValue: storeItemValue
        }
        return this.eventBus.dispatchBroadcast<any>(TenantStoreServiceTypeName, eventType, event, undefined, true);
    }

    private getEpochNow(): number {
        return Math.round(Date.now() / 1000);
    }

    private isStoreItemEvictable(storeItemMetadata: StoreItemMetadata, epochNow: number): boolean {
        return storeItemMetadata.evictionTime && storeItemMetadata.evictionTime <= epochNow;
    }

    private loadStoreIndexes(): void {
        try {
            const json = this.window.localStorage.getItem(TenantStoreService.STORE_INDEXES_KEY);
            this.storeIndexes = (JSON.parse(json) ?? {}) as Record<string, StoreItemMetadata>;
        } catch (e) {
            this.storeIndexes = {};
        }
    }

    private saveStoreIndexes(): void {
        const json = JSON.stringify(this.storeIndexes);
        this.window.localStorage.setItem(TenantStoreService.STORE_INDEXES_KEY, json);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {EventBus, EventBusTypeName} from "./bus/event-bus";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {CrossTabEventBusBridge, CrossTabEventBusBridgeTypeName} from "./bus/cross-tabs/cross-tab-event-bus-bridge";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";

export class EventBusDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<CrossTabEventBusBridge>(DependencyType.PRIVATE, CrossTabEventBusBridgeTypeName, new CrossTabEventBusBridge(window))
            .addUsingFactoryAsSingleton<IEventBus, EventBus>(DependencyType.SHARED, IEventBusTypeName, EventBusTypeName, (di) => {
                const crossTabEventBusBridge = di.resolve<CrossTabEventBusBridge>(CrossTabEventBusBridgeTypeName);
                return EventBus.build(crossTabEventBusBridge)
            })
            .addInterfacesOnSingleton(EventBusTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const eventBusDependencyModule = new EventBusDependencyModule();

import {CACHE_BREAKER_KEY_PLACEHOLDER} from "../sirius.config.constants";

export function applyCacheBreakerScripts() {
    const CACHE_BREAKER_KEY = 'timestamp';

    const config = (window as any).sirius?.config;
    const useCacheBreaker = config?.['useCacheBreaker'] === 'true';
    const cacheBreakerKey = config?.['cacheBreakerKey'] === CACHE_BREAKER_KEY_PLACEHOLDER || !config?.['cacheBreakerKey'] ? Date.now().toString() : config?.['cacheBreakerKey'] ?? Date.now().toString();

    const _externalResourcesCapableTags = {
        'script': 'src',
        'img': 'src',
        'link': 'href',
        'iframe': 'src',
        'audio': 'src',
        'video': 'src',
        'source': 'src',
        'track': 'src',
        'embed': 'src',
        'object': 'data',
        'picture': 'srcset',
    };

    const _originalCreateElement = document.createElement;

    function setIntervalWithTimeout(callback: any, interval: number, timeout: number) {
        let timerId: NodeJS.Timeout;
        let elapsedTime = 0;

        function run() {
            // Execute the callback function
            const breakInterval = callback();
            if (breakInterval) {
                clearTimeout(timerId);
                return;
            }

            // Check if the elapsed time exceeds the timeout
            if (elapsedTime >= timeout) {
                clearTimeout(timerId);
            } else {
                // Schedule the next iteration
                const remainingTime = timeout - elapsedTime;
                timerId = setTimeout(run, Math.min(interval, remainingTime));
                elapsedTime += interval;
            }
        }

        // Start the interval
        timerId = setTimeout(run, interval);
    }

    (window as any).sirius = (window as any).sirius || {};
    (window as any).sirius.utilities = (window as any).sirius.utilities || {};
    (window as any).sirius.utilities.isExternalUrl = (urlString: string) => {
        try {
            const url = new URL(urlString, urlString.startsWith('/') ? document.baseURI : undefined);
            if (url.origin !== new URL(document.URL, document.baseURI).origin) {
                return true;
            }
        } catch (e) {
            console.debug(e);
            return true;
        }
        return false;
    }

    (window as any).sirius.utilities.applyCacheBreaker = (url: URL, cacheBreakerKey: string) => {
        if (!(window as any).sirius.utilities.isExternalUrl(url.toString())) {
            const parsedUrl = new URL(url.toString(), document.baseURI);
            parsedUrl.searchParams.set(CACHE_BREAKER_KEY, cacheBreakerKey);
            return parsedUrl.toString();
        }
        return url;
    };

    if (useCacheBreaker) {
        // Patch createElement
        document.createElement = function (tagName: string) {
            const element = _originalCreateElement.call(document, tagName);
            const attribute = _externalResourcesCapableTags[tagName.toLowerCase()];
            if (attribute) {
                if (element.setAttribute) {
                    const originalSetAttribute = element.setAttribute;
                    element.setAttribute = function (name: string, value: string) {
                        if (name.toLowerCase() === attribute) {
                            value = (window as any).sirius.utilities.applyCacheBreaker(value, cacheBreakerKey);
                        }
                        originalSetAttribute.call(this, name, value);
                    };
                }
            }
            return element;
        };

        // Patch fetch
        const originalFetch = window.fetch;
        window.fetch = function (url, options) {
            url = (window as any).sirius.utilities.applyCacheBreaker(url, cacheBreakerKey);
            return originalFetch.call(window, url, options);
        };

        // Patch SystemJS
        let systemJsImportPatched = false;
        const onSystemJsPropertyChanged = (system: any) => {
            if (systemJsImportPatched) {
                return;
            }
            systemJsImportPatched = true;
            setIntervalWithTimeout(() => {
                if (System?.import) {
                    const originalSystemImport = System.import;
                    System.import = (moduleId, parentUrl) => {
                        moduleId = (window as any).sirius.utilities.applyCacheBreaker(moduleId, cacheBreakerKey);
                        return originalSystemImport.call(System, moduleId, parentUrl);
                    };
                    return true;
                }
            }, 100, 10000);
        }

        Object.defineProperties(window, {
            System: {
                get: () => {
                    return this._system;
                },
                set: (val) => {
                    this._system = val;
                    onSystemJsPropertyChanged(this._system);
                }
            }
        });
    }

    (window as any).loadResourceDynamically = (location: any, type: string, attributes: Record<string, string>) => {
        if (!location || !type) {
            return;
        }
        try {
            const element = document.createElement(type);
            if (!element) {
                return;
            }
            if (attributes) {
                for (const key in attributes) {
                    if (attributes.hasOwnProperty(key)) {
                        element.setAttribute(key, attributes[key]);
                    }
                }
            }
            let target: any;
            if (location instanceof HTMLElement) {
                target = location;
            } else {
                switch (location.toLowerCase()) {
                    case 'head':
                        target = document.head;
                        break;
                    case 'body':
                        target = document.body;
                        break;
                    default:
                        target = document.head;
                }
            }
            if (!target) {
                return;
            }
            target.appendChild(element);
        } catch (e) {
            console.error(e);
        }
    };
}
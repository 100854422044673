/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export class EngineUtilities {
    public static async waitUntilResultDefined(func: () => Promise<any | undefined>, timeout?: number): Promise<any | undefined> {
        if (!timeout) {
            timeout = 5 * 60;
        }
        const wrappedFunc = async () => {
            try {
                return await func();
            } catch (e) {
                console.error(e);
                return undefined;
            }
        }
        const result = await wrappedFunc();
        if (result) {
            return result;
        }
        return await new Promise<any | undefined>((resolve) => {
            let timer = 0;
            let handler = setInterval(async () => {
                if (!handler) {
                    return;
                }
                if (timeout && timer >= timeout) {
                    clearInterval(handler);
                    handler = undefined;
                    resolve(undefined);
                    return;
                }
                const result = await wrappedFunc();
                if (result) {
                    clearInterval(handler);
                    handler = undefined;
                    resolve(result)
                    return;
                }
                timer++;
            }, 1000);
        });
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {EnvironmentDefinition, EnvironmentType} from "sirius-platform-support-library/tenants/tenant-context";
import {EnvironmentsDefinitionsCodeProcessor} from "./environments-definitions-code-processor";

export const TenantContextEnvironmentCodeProviderTypeName = 'TenantContextEnvironmentCodeProvider';

export class TenantContextEnvironmentCodeProvider {
    private readonly tenant: ITenant;
    private readonly environmentsDefinitionsCodeProcessor: EnvironmentsDefinitionsCodeProcessor;

    constructor(tenant: ITenant, environmentsDefinitionsCodeProcessor: EnvironmentsDefinitionsCodeProcessor) {
        this.tenant = tenant;
        this.environmentsDefinitionsCodeProcessor = environmentsDefinitionsCodeProcessor;
    }

    public getCodeWithGivenEnvironments(environments: Record<string, EnvironmentDefinition>, environmentType?: EnvironmentType | string): string | undefined {
        return this.environmentsDefinitionsCodeProcessor.getCodeWithGivenEnvironments(environments, environmentType);
    }

    public getCode(environmentType?: EnvironmentType | string): string | undefined {
        const tenantContext = this.tenant.getContext();
        return this.getCodeWithGivenEnvironments(tenantContext?.environments, environmentType);
    }

    public isProductionEnvironment(): boolean | undefined {
        const tenantContext = this.tenant.getContext();
        if (!tenantContext) {
            return undefined;
        }
        const code = this.getCode();
        if (!code) {
            return undefined;
        }
        return tenantContext.environments[code]?.production === true
    }
}

import {ThemingConfig} from "sirius-platform-support-library/models/theming/theming-config.model";
import {IThemesProvider} from "sirius-platform-support-library/shared/theming/providers/themes-provider.interface";
import {DEFAULT_THEMING_CONFIG} from "./default-themes-config.constants";

export const DefaultThemesProviderTypeName = 'DefaultThemesProvider';

export class DefaultThemesProvider implements IThemesProvider {
    public async getThemingConfig(): Promise<ThemingConfig | undefined> {
        return DEFAULT_THEMING_CONFIG;
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {
    ISearchService,
    SearchBarVisibilityChangedCallback,
    SearchCallback,
    SearchTextChangedCallback,
    SearchTextClearedCallback
} from "sirius-platform-support-library/shared/search/search-service.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {SearchConstants} from "sirius-platform-support-library/shared/search/search-constants";
import {ISearchDomHandler} from "sirius-platform-support-library/shared/search/handlers/search-dom-handler.interface";
import {SearchOptions} from "sirius-platform-support-library/shared/search/search.options";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {
    SearchBarVisibilityChangedEvent
} from "sirius-platform-support-library/shared/search/events/search-bar-visibility-changed.event";
import {SearchEvents} from "sirius-platform-support-library/shared/search/events/search-events";
import {SearchTextChangedEvent} from "sirius-platform-support-library/shared/search/events/search-text-changed.event";
import {SearchTextClearedEvent} from "sirius-platform-support-library/shared/search/events/search-text-cleared.event";
import {SearchEvent} from "sirius-platform-support-library/shared/search/events/search-event";
import {
    IPlatformBrowserNavigationEventsReceiver
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";

import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";

export const SearchServiceTypeName = 'SearchService';

export class SearchService implements ISearchService, IAfterPlatformReadyInit, IPlatformBrowserNavigationEventsReceiver {
    public static build(
        window: Window,
        eventBus: IEventBus,
        serviceCollection: IServiceCollection,
    ): SearchService {
        let instance = ObjectUtility.getFromObjectPath<SearchService>(SearchConstants.GLOBAL_KEY);

        if (instance == undefined) {
            instance = new SearchService(
                window,
                eventBus,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(SearchConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): ISearchService {
        return ObjectUtility.getFromObjectPath<ISearchService>(SearchConstants.GLOBAL_KEY);
    }

    private readonly window: Window;
    private readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;

    private domHandlers: Record<string, ISearchDomHandler> = {};
    private currentUrl?: string;

    private constructor(
        window: Window,
        eventBus: IEventBus,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.eventBus = eventBus;
        this.serviceCollection = serviceCollection;
    }

    public async init(): Promise<void> {
    }

    public register(domHandler: ISearchDomHandler): void {
        if (!domHandler) {
            return;
        }
        this.bind(domHandler);
        this.domHandlers[domHandler.getUniqueCode()] = domHandler;
    }

    public unregister(domHandler: ISearchDomHandler): void {
        if (!domHandler) {
            return;
        }
        delete this.domHandlers[domHandler.getUniqueCode()];
    }

    public configure(options: SearchOptions, target?: string): void {
        this.getFilteredDomHandlersByUniqueCode(target).forEach(domHandler => {
            try {
                const previousConfig = domHandler.getConfiguration();
                const shouldTriggerVisibilityChangedEvent = previousConfig.visible !== options.visible;
                domHandler.updateConfiguration(options);
                if (shouldTriggerVisibilityChangedEvent) {
                    this.triggerEvent<SearchBarVisibilityChangedEvent>(SearchEvents.SEARCH_VISIBILITY_CHANGED_EVENT, {
                        target: domHandler.getUniqueCode(),
                        visible: options.visible
                    });
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    public getText(target?: string): string {
        const domHandler = this.getFilteredDomHandlersByUniqueCode(target).find((domHandler, index) => index === 0);
        return domHandler?.getText();
    }

    public setText(text: string, target?: string): ISearchService {
        this.getFilteredDomHandlersByUniqueCode(target).forEach(domHandler => {
            try {
                domHandler.setText(text);
            } catch (e) {
                console.error(e);
            }
        });
        return this;
    }

    public clearText(focus: boolean, target?: string): ISearchService {
        this.getFilteredDomHandlersByUniqueCode(target).forEach(domHandler => {
            try {
                domHandler.clearText(focus);
            } catch (e) {
                console.error(e);
            }
        });
        return this;
    }

    public focusText(select: boolean, target?: string): ISearchService {
        this.getFilteredDomHandlersByUniqueCode(target).forEach(domHandler => {
            try {
                domHandler.focusText(select);
            } catch (e) {
                console.error(e);
            }
        });
        return this;
    }

    public selectText(target?: string): ISearchService {
        this.getFilteredDomHandlersByUniqueCode(target).forEach(domHandler => {
            try {
                domHandler.selectText();
            } catch (e) {
                console.error(e);
            }
        });
        return this;
    }

    public onBeforeNavigate(): void | Promise<void> {
        this.currentUrl = this.window.location.pathname;
    }

    public onAfterNavigate(url: string): Promise<void> | void {
        if (!RoutingUtilities.routeEqual(this.currentUrl, url, true)) {
            this.clearText(false)
        }
    }

    public hasFocusText(target?: string): boolean {
        const domHandler = this.getFilteredDomHandlersByUniqueCode(target).find((domHandler, index) => index === 0);
        return domHandler?.hasFocusText();
    }

    public onSearchBarVisibilityChanged(context: any, subscriberName: string, callback: SearchBarVisibilityChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SearchBarVisibilityChangedEvent>(this, subscriberName, SearchEvents.SEARCH_VISIBILITY_CHANGED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    public onTextChanged(context: any, subscriberName: string, callback: SearchTextChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SearchTextChangedEvent>(this, subscriberName, SearchEvents.SEARCH_TEXT_CHANGED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    public onTextCleared(context: any, subscriberName: string, callback: SearchTextClearedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SearchTextClearedEvent>(this, subscriberName, SearchEvents.SEARCH_TEXT_CLEARED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    public onSearch(context: any, subscriberName: string, callback: SearchCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<SearchEvent>(this, subscriberName, SearchEvents.SEARCH_PERFORMED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    private handleTextChanged(target: string, text: string): void {
        this.triggerEvent<SearchTextChangedEvent>(SearchEvents.SEARCH_TEXT_CHANGED_EVENT, {
            target: target,
            text: text
        });
    }

    private handleTextCleared(target: string): void {
        this.triggerEvent<SearchTextClearedEvent>(SearchEvents.SEARCH_TEXT_CLEARED_EVENT, {
            target: target
        });
    }

    private handleSearch(target: string, text: string): void {
        this.triggerEvent<SearchEvent>(SearchEvents.SEARCH_PERFORMED_EVENT, {
            target: target,
            text: text
        });
    }

    private getFilteredDomHandlersByUniqueCode(target?: string): ISearchDomHandler[] {
        if (target) {
            target = target.toLowerCase();
        }
        return Object.keys(this.domHandlers).filter(key => key.toLowerCase() === target || !target).map(key => this.domHandlers[key]);
    }

    private triggerEvent<TType>(eventType: string, event?: TType): void {
        return this.eventBus.dispatchBroadcast<TType>(SearchServiceTypeName, eventType, event);
    }

    private bind(domHandler: ISearchDomHandler): void {
        domHandler.onTextChanged((text) => {
            this.handleTextChanged(domHandler.getUniqueCode(), text);
        });
        domHandler.onTextCleared(() => {
            this.handleTextCleared(domHandler.getUniqueCode());
        });
        domHandler.onSearch((text) => {
            this.handleSearch(domHandler.getUniqueCode(), text);
        });
    }
}

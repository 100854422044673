/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IUserContextUpdater,
    UserContextUpdatedCallback
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {UserInfo} from "sirius-platform-support-library/models/user/user-info";

export const UserContextUpdaterTypeName = 'UserContextUpdater';

export class UserContextUpdater implements IUserContextUpdater {
    private context: any;
    private callback: UserContextUpdatedCallback;

    public async updateUserContext(userInfo: UserInfo): Promise<void> {
        if (this.callback) {
            await this.callback.call(this.context, userInfo);
        }
    }

    public onUserContextUpdated(context: any, callback: UserContextUpdatedCallback): void {
        this.context = context;
        this.callback = callback;
    }
}
/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IPlaceholdersReplacerService} from "sirius-platform-support-library/shared/placeholder-replacers/placeholders-replacer-service.interface";
import {ITypedServiceCollection} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {
    IPlaceholderReplacer,
    IPlaceholderReplacerTypeName
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer.interface";
import {
    IPlaceholderReplacerPriorityAssigner,
    IPlaceholderReplacerPriorityAssignerTypeName
} from "sirius-platform-support-library/shared/placeholder-replacers/placeholder-replacer-priority-assigner.interface";

export const PlaceholdersReplacerServiceTypeName = 'PlaceholdersReplacerService';

export class PlaceholdersReplacerService implements IPlaceholdersReplacerService {

    private readonly serviceCollection: ITypedServiceCollection;
    private placeholderReplacers: IPlaceholderReplacer[];

    constructor(serviceCollection) {
        this.serviceCollection = serviceCollection;
        this.assignAndSortReplacers();
    }

    replace(input: string): string {
        let result = input;
        this.placeholderReplacers.forEach((placeholderReplacers: IPlaceholderReplacer) => {
            result = placeholderReplacers.replace(result);
        });
        return result;
    }

    private assignAndSortReplacers(): void {
        const unsortedPlaceholderReplacers = this.serviceCollection.resolveAll<IPlaceholderReplacer>(IPlaceholderReplacerTypeName);
        const placeholderReplacerPriorityAssigner = this.serviceCollection.resolve<IPlaceholderReplacerPriorityAssigner>(IPlaceholderReplacerPriorityAssignerTypeName);
        if (placeholderReplacerPriorityAssigner) {
            try {
                this.placeholderReplacers = placeholderReplacerPriorityAssigner.prioritize(unsortedPlaceholderReplacers);
            } catch (e) {
                console.error('Failed to assign placeholder provider priorities due to an encountered exception.', e);
                this.placeholderReplacers = unsortedPlaceholderReplacers;
            }
        } else {
            this.placeholderReplacers = unsortedPlaceholderReplacers;
        }
    }

}

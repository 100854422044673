/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IBrowserNavigationEventsReceiver
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-events-receiver.interface";

export const IPlatformBrowserNavigationEventsReceiverTypeName = 'IPlatformBrowserNavigationEventsReceiver';

export interface IPlatformBrowserNavigationEventsReceiver extends IBrowserNavigationEventsReceiver {
}
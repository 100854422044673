/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ContextualRequestedBannerAlert,
    PushedBannerAlert,
    RequestedBannerAlert
} from "sirius-platform-support-library/shared/banner-alerts/banner-alert";
import {
    BannerAlertDismissedCallback,
    BannerAlertsChangedCallback,
    CurrentBannerAlertChangedCallback,
    IBannerAlertsService
} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts-service.interface";
import {BannerAlertsConstants} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts.constants";
import {RenderableBannerAlert} from "./renderable-banner-alert";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {BannerAlertsDomHandler} from "./dom/banner-alerts-dom.handler";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {IBannerAlertsInteractionHandler} from "./handlers/banner-alerts-interaction-handler.interface";
import {BannerAlertsInteractionHandler} from "./handlers/banner-alerts-interaction.handler";
import {
    IBannerAlertsPriorityAssigner
} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts-priority-assigner.interface";
import {IActionHandler} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {BannerAlertValidator} from "./validators/banner-alert.validator";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    BannerAlertDismissedEvent
} from "sirius-platform-support-library/shared/banner-alerts/events/banner-alert-dismissed.event";
import {
    BannerAlertsChangedEvent,
    BannerAlertsNamespacesChangedEvent
} from "sirius-platform-support-library/shared/banner-alerts/events/banner-alerts-changed.event";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {BannerAlertsEvents} from "sirius-platform-support-library/shared/banner-alerts/events/banner-alerts-events";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    IPlatformBrowserNavigationEventsReceiver
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import _ from "lodash";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {ActionPerformedEvent} from "sirius-platform-support-library/shared/actions/events/action-performed.event";
import {RenderableBannerAlertAction} from "./renderable-banner-alert-action";
import {PlatformActionInteractionService} from "../browser-events/platform-action-interaction.service";
import {VirtualActions} from "sirius-platform-support-library/shared/actions/virtual-actions";
import {ActionTypeEnum} from "sirius-platform-support-library/models/common";
import {
    CurrentBannerAlertChangedEvent
} from "sirius-platform-support-library/shared/banner-alerts/events/current-banner-alert-changed.event";
import {
    IThemingHandlersManager
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {IButtonStyleProcessor} from "../common-ui/buttons/button-style.processor.interface";
import {
    IBannerAlertsProvider,
    IBannerAlertsProviderTypeName
} from "sirius-platform-support-library/shared/banner-alerts/providers/banner-alerts-provider.interface";
import {v4 as uuidv4} from "uuid";

export const BannerAlertsServiceTypeName = 'BannerAlertsService';

export class BannerAlertsService implements IBannerAlertsService, IAfterPlatformReadyInit, IPlatformBrowserNavigationEventsReceiver {
    public static readonly EVENT_SOURCE = 'banner-alert';
    public static readonly DEDICATED_BUTTON_DISMISSAL_SOURCE = 'dedicated-button';
    public static readonly TIMEOUT_DISMISSAL_SOURCE = 'timeout';
    public static readonly ACTION_DISMISSAL_SOURCE = 'action';
    public static readonly API_DISMISSAL_SOURCE = 'api';

    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();

    private readonly tenant: ITenant;
    private readonly localizationService: ILocalizationService;
    private readonly priorityAssigner: IBannerAlertsPriorityAssigner;
    private readonly actionHandler: IActionHandler;
    private readonly bannerAlertValidator: BannerAlertValidator;
    private readonly eventBus: IEventBus;
    private readonly actionInteractionService: PlatformActionInteractionService;
    private readonly serviceCollection: IServiceCollection;
    private readonly window: Window;
    private readonly interactionHandler: IBannerAlertsInteractionHandler;
    private readonly domHandler: BannerAlertsDomHandler;
    private readonly buttonStyleProcessor: IButtonStyleProcessor;
    private bannerAlerts: RenderableBannerAlert[];
    private currentBannerAlert: RenderableBannerAlert;

    private readonly updateBannerAlertsThrottled: (namespace: string) => void;

    private readonly providers: Record<string, IBannerAlertsProvider> = {};
    private readonly namespacedBannerAlerts: Record<string, RenderableBannerAlert[]> = {};

    private constructor(
        window: Window,
        document: Document,
        tenant: ITenant,
        localizationService: ILocalizationService,
        priorityAssigner: IBannerAlertsPriorityAssigner,
        actionHandler: IActionHandler,
        bannerAlertValidator: BannerAlertValidator,
        eventBus: IEventBus,
        actionInteractionService: PlatformActionInteractionService,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        buttonStyleProcessor: IButtonStyleProcessor,
        serviceCollection: IServiceCollection
    ) {
        this.bannerAlerts = [];

        this.tenant = tenant;
        this.localizationService = localizationService;
        this.priorityAssigner = priorityAssigner;
        this.actionHandler = actionHandler;
        this.bannerAlertValidator = bannerAlertValidator;
        this.eventBus = eventBus;
        this.actionInteractionService = actionInteractionService;
        this.buttonStyleProcessor = buttonStyleProcessor;
        this.serviceCollection = serviceCollection;

        this.interactionHandler = new BannerAlertsInteractionHandler(this);
        this.interactionHandler.onPreviousCallback = this.onPreviousCallback;
        this.interactionHandler.onNextCallback = this.onNextCallback;
        this.interactionHandler.onDismissCallback = this.onDismissCallback;
        this.interactionHandler.onActionCallback = this.onActionCallback;
        this.interactionHandler.onRefreshCallback = this.onRefreshCallback;
        this.window = window;

        this.domHandler = new BannerAlertsDomHandler(
            this.window,
            document,
            this.interactionHandler,
            styleSheetsBasedThemeHandler,
            themingHandlersManager,
            this.serviceCollection
        );
        this.updateBannerAlertsThrottled = _.throttle(this.updateBannerAlerts, 250);
    }

    public static build(
        window: Window,
        document: Document,
        tenant: ITenant,
        localizationService: ILocalizationService,
        priorityAssigner: IBannerAlertsPriorityAssigner,
        actionHandler: IActionHandler,
        bannerAlertValidator: BannerAlertValidator,
        eventBus: IEventBus,
        actionInteractionService: PlatformActionInteractionService,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        buttonStyleProcessor: IButtonStyleProcessor,
        serviceCollection: IServiceCollection
    ): BannerAlertsService {
        let instance = ObjectUtility.getFromObjectPath<BannerAlertsService>(BannerAlertsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new BannerAlertsService(
                window,
                document,
                tenant,
                localizationService,
                priorityAssigner,
                actionHandler,
                bannerAlertValidator,
                eventBus,
                actionInteractionService,
                styleSheetsBasedThemeHandler,
                themingHandlersManager,
                buttonStyleProcessor,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(BannerAlertsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IBannerAlertsService {
        return ObjectUtility.getFromObjectPath<IBannerAlertsService>(BannerAlertsConstants.GLOBAL_KEY);
    }

    public onBeforeNavigate(url: string): void | Promise<void> {
    }

    public async onAfterNavigate(url: string): Promise<void> {
        if (!url) {
            return;
        }

        const highPriorityBannerAlert = _.findLast(this.bannerAlerts, banner => banner.isHighPriority());
        if (highPriorityBannerAlert) {
            await this.focus(highPriorityBannerAlert);
        }
    }

    public async init(): Promise<void> {
        await this.domHandler.init();
        this.bind();
        await this.bindProviders();
        await this.loadProviders();
    }

    public getAllBannerAlerts(): PushedBannerAlert[] {
        return this.bannerAlerts.map(bannerAlert => bannerAlert.toPushedBannerAlert());
    }

    public getCurrentBannerAlert(): PushedBannerAlert | undefined {
        return this.currentBannerAlert?.toPushedBannerAlert();
    }

    public async load(context: any, bannerAlerts: RequestedBannerAlert[]): Promise<PushedBannerAlert[]> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }

        this.bannerAlertValidator.validateMany(bannerAlerts);

        this.bannerAlerts.splice(0, this.bannerAlerts.length);

        const renderingBannerAlerts = bannerAlerts.map(bannerAlert => new RenderableBannerAlert(
            this.window,
            bannerAlert,
            context,
            this.interactionHandler,
            this.localizationService,
            this.actionHandler,
            this.buttonStyleProcessor,
            this.serviceCollection
        ));
        await Promise.allSettled(renderingBannerAlerts.map(bannerAlert => bannerAlert.localize()));

        this.bannerAlerts.push(...renderingBannerAlerts);

        return await this.internalLoad(this.bannerAlerts);
    }

    public async internalLoad(renderingBannerAlerts: RenderableBannerAlert[]): Promise<PushedBannerAlert[]> {
        this.priorityAssigner.prioritize(this.bannerAlerts);

        this.currentBannerAlert = renderingBannerAlerts[0];
        if (!this.currentBannerAlert) {
            return [];
        }

        this.renderBannerAlert(this.currentBannerAlert, this.getIndexOfCurrentBannerAlert());
        this.triggerBannerAlertsChange();

        return renderingBannerAlerts.map(bannerAlert => bannerAlert.toPushedBannerAlert());
    }

    public async push(context: any, bannerAlert: RequestedBannerAlert): Promise<PushedBannerAlert> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }

        this.bannerAlertValidator.validate(bannerAlert);

        const renderingBannerAlert = new RenderableBannerAlert(
            this.window,
            bannerAlert,
            context,
            this.interactionHandler,
            this.localizationService,
            this.actionHandler,
            this.buttonStyleProcessor,
            this.serviceCollection
        );

        await renderingBannerAlert.localize();

        const previousCount = this.bannerAlerts.length;

        this.bannerAlerts.push(renderingBannerAlert);
        this.priorityAssigner.prioritize(this.bannerAlerts);

        if (previousCount === 0 || !!renderingBannerAlert.isHighPriority()) {
            this.currentBannerAlert = renderingBannerAlert;
            this.domHandler.render(this.currentBannerAlert);
            this.triggerCurrentBannerAlertChanged(BannerAlertsEvents.BANNER_ALERTS_CURRENT_BANNER_ALERT_CHANGED, this.currentBannerAlert);
        }

        if (previousCount > 0 && !!renderingBannerAlert.isHighPriority()) {
            this.domHandler.addHighPriorityNotification();
        }

        this.domHandler.updateCount(this.getIndexOfCurrentBannerAlert(), this.bannerAlerts.length);
        this.triggerBannerAlertsChange();
        return renderingBannerAlert.toPushedBannerAlert();
    }

    public async update(context: any, bannerAlert: PushedBannerAlert): Promise<PushedBannerAlert> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }

        if (!bannerAlert) {
            throw new Error('Please provide a valid banner alert');
        }

        const currentBannerAlert = this.getCurrentBannerAlert();
        const existingBannerAlert = this.getBannerAlertById(bannerAlert.id);

        existingBannerAlert.update(bannerAlert);

        if (currentBannerAlert.id === bannerAlert.id) {
            this.updateBannerAlert(existingBannerAlert);
        }

        return existingBannerAlert.toPushedBannerAlert();
    }

    public async dismiss(bannerAlert: PushedBannerAlert): Promise<void> {
        await this.dismissById(bannerAlert?.id);
    }

    public async dismissById(bannerAlertId: string): Promise<void> {
        await this.internalDismissById(bannerAlertId, false, false, BannerAlertsService.API_DISMISSAL_SOURCE);
    }

    public async focus(bannerAlert: PushedBannerAlert, showEffect?: boolean): Promise<void> {
        await this.focusById(bannerAlert?.id, showEffect);
    }

    public async focusById(bannerAlertId: string, showEffect?: boolean): Promise<void> {
        if (!bannerAlertId) {
            return;
        }
        const existingBannerAlert = this.getBannerAlertById(bannerAlertId);
        if (!existingBannerAlert) {
            return;
        }
        this.currentBannerAlert = existingBannerAlert;
        await this.domHandler.render(this.currentBannerAlert);
        this.domHandler.updateCount(this.getIndexOfCurrentBannerAlert(), this.bannerAlerts.length);
        if (showEffect) {
            this.domHandler.addEffectForContainer();
        } else {
            this.domHandler.addHighPriorityNotification();

        }
        this.triggerCurrentBannerAlertChanged(BannerAlertsEvents.BANNER_ALERTS_CURRENT_BANNER_ALERT_CHANGED, this.currentBannerAlert);
    }

    public onBannerAlertDismissed(context: any, subscriberName: string, bannerAlertDismissedCallback: BannerAlertDismissedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<BannerAlertDismissedEvent>(this, subscriberName, BannerAlertsEvents.BANNER_ALERT_DISMISSED, (busEvent) => {
            bannerAlertDismissedCallback?.call(context, busEvent.data);
        });
    }

    public onBannerAlertsChanged(context: any, subscriberName: string, bannerAlertsChangedCallback: BannerAlertsChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<BannerAlertsChangedEvent>(this, subscriberName, BannerAlertsEvents.BANNER_ALERTS_CHANGED, (busEvent) => {
            bannerAlertsChangedCallback?.call(context, busEvent.data);
        });
    }

    public onPreviousBannerAlert(context: any, subscriberName: string, currentBannerAlertChangedCallback: CurrentBannerAlertChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<CurrentBannerAlertChangedEvent>(this, subscriberName, BannerAlertsEvents.BANNER_ALERT_PREVIOUS, (busEvent) => {
            currentBannerAlertChangedCallback?.call(context, busEvent.data);
        });
    }

    public onNextBannerAlert(context: any, subscriberName: string, currentBannerAlertChangedCallback: CurrentBannerAlertChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<CurrentBannerAlertChangedEvent>(this, subscriberName, BannerAlertsEvents.BANNER_ALERT_NEXT, (busEvent) => {
            currentBannerAlertChangedCallback?.call(context, busEvent.data);
        });
    }

    public onCurrentBannerAlertChanged(context: any, subscriberName: string, currentBannerAlertChangedCallback: CurrentBannerAlertChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<CurrentBannerAlertChangedEvent>(this, subscriberName, BannerAlertsEvents.BANNER_ALERTS_CURRENT_BANNER_ALERT_CHANGED, (busEvent) => {
            currentBannerAlertChangedCallback?.call(context, busEvent.data);
        });
    }

    private bind(): void {
        this.localizationService.onLocaleChanged(this, 'BannerAlertsService', this.onLocaleChanged);
        this.actionHandler.onActionPerformed(this, BannerAlertsService.EVENT_SOURCE, this.onActionPerformed.bind(this));
    }

    private async onProviderRefreshRequested(namespace: string, notify: boolean = true): Promise<void> {
        await this.loadNamespaceBannerAlerts(namespace, notify);
    }

    private async loadProviders(): Promise<void> {
        const namespaces = Object.values(this.providers).map(p => p.getNamespace());
        for (let index = 0; index < namespaces.length; index++) {
            const namespace = namespaces[index];
            if (!namespace) {
                continue;
            }
            await this.loadNamespaceBannerAlerts(namespace, false);
        }
        this.notifyBannerAlertsProviderChanged(...namespaces);
    }

    private async loadNamespaceBannerAlerts(namespace: string, notify: boolean = true): Promise<void> {
        try {
            const provider = this.providers[namespace];
            if (!provider) {
                return;
            }
            const bannerAlertsProvider = await provider.getBannerAlerts() ?? [];

            await this.processNamespaceBannerAlerts(namespace, bannerAlertsProvider, provider);
            if (notify) {
                this.notifyBannerAlertsProviderChanged(...namespace);
            }
        } catch (e) {
            console.error(e);
        }
    }


    public async processNamespaceBannerAlerts(namespace: string, bannerAlertsProvider: ContextualRequestedBannerAlert[], provider: IBannerAlertsProvider): Promise<void> {
        const renderingBannerAlerts = bannerAlertsProvider.map(bannerAlert => new RenderableBannerAlert(
            this.window,
            bannerAlert as RequestedBannerAlert,
            provider,
            this.interactionHandler,
            this.localizationService,
            this.actionHandler,
            this.buttonStyleProcessor,
            this.serviceCollection
        ));
        await Promise.allSettled(renderingBannerAlerts.map(bannerAlert => bannerAlert.localize()));
        this.namespacedBannerAlerts[namespace] = renderingBannerAlerts;
        this.updateBannerAlertsThrottled(namespace);

    }

    private async updateBannerAlerts(namespace: string): Promise<void> {
        const collection = [];
        const namespacedBannerAlerts = this.namespacedBannerAlerts[namespace];
        for (const bannerAlert of this.bannerAlerts) {
            if (!bannerAlert.namespace || bannerAlert.namespace !== namespace) {
                collection.push(bannerAlert);
                continue;
            }
            const existingInNamespace = namespacedBannerAlerts.some(nsBannerAlert => nsBannerAlert.namespace === bannerAlert.namespace && nsBannerAlert.id === bannerAlert.id);
            if (!existingInNamespace) {
                continue;
            }
            collection.push(bannerAlert);
        }
        for (const nsBannerAlert of namespacedBannerAlerts) {
            const existingIndex = this.bannerAlerts.findIndex(bannerAlert => bannerAlert.id === nsBannerAlert.id);
            if (existingIndex > -1) {
                collection[existingIndex] = nsBannerAlert;
            } else {
                collection.push(nsBannerAlert);
            }
        }
        this.bannerAlerts = collection;
        await this.internalLoad(this.bannerAlerts);
    }

    private notifyBannerAlertsProviderChanged(...namespaces: string[]): void {
        const event: BannerAlertsNamespacesChangedEvent = {
            broadcasterId: BannerAlertsService.BROADCASTER_ID,
            namespaces: namespaces
        };
        this.eventBus.dispatchBroadcast(BannerAlertsServiceTypeName, BannerAlertsEvents.BANNER_ALERTS_NAMESPACES_CHANGED, event, undefined, true);
    }

    private async bindProviders(): Promise<void> {
        const onProviderRefreshRequestedBinded = this.onProviderRefreshRequested.bind(this);
        const providers = this.serviceCollection.resolveAll<IBannerAlertsProvider>(IBannerAlertsProviderTypeName);
        for (let index = 0; index < providers.length; index++) {
            const provider = providers[index];
            if (!provider) {
                continue;
            }
            try {
                if (provider.onRefreshRequested) {
                    provider.onRefreshRequested(onProviderRefreshRequestedBinded);
                }
                const namespace = provider.getNamespace();
                if (!namespace) {
                    continue;
                }
                this.providers[namespace] = provider;
            } catch (e) {
                console.error(e);
            }
        }
    }

    private async onLocaleChanged(): Promise<void> {
        await Promise.allSettled(this.bannerAlerts.map(bannerAlert => bannerAlert.localize()));
        this.updateBannerAlert(this.currentBannerAlert);
    }

    private async onPreviousCallback(skipInteractionCheck: boolean = false): Promise<void> {
        if (!skipInteractionCheck && !this.isInteractionAllowed(VirtualActions.BANNER_ALERTS_PREVIOUS, this.onPreviousCallback.bind(this, true))) {
            return;
        }
        let currentIndex = this.getIndexOfCurrentBannerAlert();
        if (currentIndex <= 0) {
            return;
        }
        currentIndex--;
        this.currentBannerAlert = this.bannerAlerts[currentIndex];
        this.triggerCurrentBannerAlertChanged(BannerAlertsEvents.BANNER_ALERT_PREVIOUS, this.currentBannerAlert);
        await this.renderBannerAlert(this.currentBannerAlert, currentIndex);
    }

    private async onNextCallback(skipInteractionCheck: boolean = false): Promise<void> {
        if (!skipInteractionCheck && !this.isInteractionAllowed(VirtualActions.BANNER_ALERTS_NEXT, this.onNextCallback.bind(this, true))) {
            return;
        }
        let currentIndex = this.getIndexOfCurrentBannerAlert();
        if (currentIndex === this.bannerAlerts.length - 1) {
            return;
        }
        currentIndex++;
        this.currentBannerAlert = this.bannerAlerts[currentIndex];
        this.triggerCurrentBannerAlertChanged(BannerAlertsEvents.BANNER_ALERT_NEXT, this.currentBannerAlert);
        this.renderBannerAlert(this.currentBannerAlert, currentIndex);
    }

    private async onDismissCallback(bannerAlertId: string, timeoutDismissal?: boolean): Promise<void> {
        if (!bannerAlertId) {
            return;
        }

        const dismissedByDedicatedButton = !timeoutDismissal;
        const source = timeoutDismissal ? BannerAlertsService.TIMEOUT_DISMISSAL_SOURCE : BannerAlertsService.DEDICATED_BUTTON_DISMISSAL_SOURCE;

        await this.internalDismissById(bannerAlertId, false, dismissedByDedicatedButton, source);
    }

    private async onActionCallback(bannerAlertId: string, actionCode: string) {
        if (!bannerAlertId) {
            return;
        }
        const bannerAlert = this.getBannerAlertById(bannerAlertId);
        if (!bannerAlert) {
            return;
        }
        const action = bannerAlert.getActionByCode(actionCode);
        if (!action) {
            return;
        }

        if (action?.isActionTranslatable) {
            action.action = action.localizedAction;
        }

        const success = await this.actionHandler.handle(bannerAlert.context, 'BannerAlertsService', action, {
            source: BannerAlertsService.EVENT_SOURCE,
            sourceId: bannerAlertId
        }, false);
        if (!success) {
            return;
        }
        if (action.dismissAfterAction) {
            bannerAlert?.stopCountDownTimer();
            this.removeBannerAlertById(bannerAlert?.id);
            this.triggerDismissalEvent(bannerAlert, false, BannerAlertsService.ACTION_DISMISSAL_SOURCE);
        }
    }

    private onRefreshCallback(bannerAlertId: string): Promise<void> {
        const currentBannerAlert = this.getCurrentBannerAlert();
        if (currentBannerAlert?.id !== bannerAlertId) {
            return;
        }

        const currentIndex = this.getIndexOfCurrentBannerAlert();

        this.renderBannerAlert(this.currentBannerAlert, currentIndex);
    }

    private removeBannerAlertById(bannerAlertId: string): void {
        const currentIndex = this.getIndexOfBannerAlertById(bannerAlertId);
        if (currentIndex < 0) {
            return;
        }
        this.bannerAlerts.splice(currentIndex, 1);

        const lastIndex = this.bannerAlerts.length - 1;
        if (lastIndex < 0) {
            this.resetCounts();
            this.triggerBannerAlertsChange();
            return;
        }
        if ((currentIndex > 0) && (currentIndex > lastIndex)) {
            this.currentBannerAlert = this.bannerAlerts[currentIndex - 1];
        } else {
            this.currentBannerAlert = this.bannerAlerts[currentIndex];
        }
        this.renderBannerAlert(this.currentBannerAlert, this.getIndexOfCurrentBannerAlert());
        this.triggerBannerAlertsChange();
    }

    private internalDismissById(bannerAlertId: string, skipInteractionCheck: boolean = false, dismissedByDedicatedButton: boolean = false, dismissalSource: string = undefined): Promise<void> {
        if (!bannerAlertId) {
            return;
        }
        if (!skipInteractionCheck && !this.isInteractionAllowed(VirtualActions.BANNER_ALERTS_DISMISS, this.internalDismissById.bind(this, bannerAlertId, true, dismissedByDedicatedButton))) {
            return;
        }
        const existingBannerAlert = this.getBannerAlertById(bannerAlertId);
        existingBannerAlert?.stopCountDownTimer();
        this.removeBannerAlertById(existingBannerAlert?.id);
        this.triggerDismissalEvent(existingBannerAlert, dismissedByDedicatedButton, dismissalSource);
    }

    private resetCounts() {
        this.domHandler.updateCount(0, 0);
    }

    private renderBannerAlert(bannerAlert: RenderableBannerAlert, currentIndex: number): void {
        this.domHandler.render(bannerAlert);
        this.domHandler.updateCount(currentIndex, this.bannerAlerts.length);
        this.triggerCurrentBannerAlertChanged(BannerAlertsEvents.BANNER_ALERTS_CURRENT_BANNER_ALERT_CHANGED, bannerAlert);
    }

    private updateBannerAlert(bannerAlert: RenderableBannerAlert): void {
        if (!bannerAlert) {
            return;
        }

        this.domHandler.update(bannerAlert);
    }

    private getIndexOfCurrentBannerAlert(): number {
        return this.getIndexOfBannerAlertById(this.currentBannerAlert.id);
    }

    private getIndexOfBannerAlertById(id: string): number {
        if (!id) {
            return -1;
        }
        return this.bannerAlerts.findIndex(bannerAlert => bannerAlert.id === id);
    }

    private getBannerAlertById(id: string): RenderableBannerAlert | null {
        return this.bannerAlerts.find(bannerAlert => bannerAlert.id === id);
    }

    private triggerDismissalEvent(bannerAlert: RenderableBannerAlert, dismissedByDedicatedButton: boolean = false, dismissalSource: string = undefined): void {
        if (!bannerAlert) {
            return;
        }
        if (dismissedByDedicatedButton && !dismissalSource) {
            dismissalSource = BannerAlertsService.DEDICATED_BUTTON_DISMISSAL_SOURCE;
        }
        this.eventBus.dispatchBroadcast<BannerAlertDismissedEvent>('BannerAlertsService', BannerAlertsEvents.BANNER_ALERT_DISMISSED, {
            bannerAlert: bannerAlert.toPushedBannerAlert(),
            dismissedByDedicatedButton: dismissedByDedicatedButton,
            dismissalSource: dismissalSource
        });
    }

    private triggerBannerAlertsChange() {
        this.eventBus.dispatchBroadcast<BannerAlertsChangedEvent>('BannerAlertsService', BannerAlertsEvents.BANNER_ALERTS_CHANGED, {
            bannerAlertCount: this.bannerAlerts.length
        });
    }

    private triggerCurrentBannerAlertChanged(event: string, bannerAlert: RenderableBannerAlert) {
        this.eventBus.dispatchBroadcast<CurrentBannerAlertChangedEvent>(BannerAlertsServiceTypeName, event, {
            bannerAlert: bannerAlert.toPushedBannerAlert(),
        });
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private isInteractionAllowed(virtualActionCode: string, func: Function): boolean {
        if (!this.tenant.getContext()?.behaviour?.bannerAlerts?.disableInteractionIfNavigationIsDisabled) {
            return true;
        }
        return this.actionInteractionService.isInteractionAllowed({
            context: this,
            code: virtualActionCode,
            localizationCode: virtualActionCode,
            type: ActionTypeEnum.CALLBACK,
            action: func
        });
    }

    private async onActionPerformed(event: ActionPerformedEvent): Promise<void> {
        if (!event.sourceId) {
            return;
        }
        const action: RenderableBannerAlertAction = event?.action as RenderableBannerAlertAction;
        if (event.success && action?.dismissAfterAction) {
            await this.dismissById(event.sourceId);
        }
    }
}

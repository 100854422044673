/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {IButtonStyleProcessor, IButtonStyleProcessorTypeName} from "./buttons/button-style.processor.interface";
import {
    ClarityButtonStyleProcessor,
    ClarityButtonStyleProcessorTypeName
} from "./buttons/clarity/clarity.button-style.processor";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";

export class CommonUiDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IButtonStyleProcessor, ClarityButtonStyleProcessor>(DependencyType.PRIVATE, IButtonStyleProcessorTypeName, ClarityButtonStyleProcessorTypeName, new ClarityButtonStyleProcessor());
    }
}

export const commonUiDependencyModule = new CommonUiDependencyModule();

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {applyCommonScripts} from "./common/common.scripts";
import {PlatformInitializationAwaiter} from "./core/platform/platform-initialization-awaiter";
import replaceAllInserter from 'string.prototype.replaceall';
import {applyRuntimeConfig} from "sirius-platform-support-library/shared/environment/apply-runtime-config";
import {IPlatform, IPlatformTypeName} from "./core/platform/platform.interface";
import {RootDependencyContainer} from "./core/dependency-injection/root-dependency-container";
import {localizationDependencyModule} from "./core/localization/localization.dependency-module";
import {environmentDependencyModule} from "./core/environment/environment-dependency-module";
import {applicationDependencyModule} from "./micro-frontends/application.depenency-module";
import {platformDependencyModule} from "./core/platform/platform.dependency-module";
import {eventBusDependencyModule} from "./core/events/event-bus.depenency-module";
import {dynamicImportsDependencyModule} from "./core/dynamic-imports/dynamic-imports-dependency-module";
import {tenantDependencyModule} from "./core/tenant/tenant.dependency-module";
import {browserEventsDependencyModule} from "./core/browser-events/browser-events.depenency-module";
import {bannerAlertsDependencyModule} from "./core/banner-alerts/banner-alerts.dependency-module";
import {
    placeholderReplacersDependencyModule
} from "./core/placeholder-replacers/placeholder-replacers.dependency-module";
import {tenantStoreDependencyModule} from "./core/tenant-store/tenant-store.depenency-module";
import {progressIndicatorsDependencyModule} from "./core/progress-indicators/progress-indicators.dependency-module";
import {servicesInitializerDependencyModule} from "./core/initializer/services-initializer-dependency-module";
import {authenticationDependencyModule} from "./core/authentication/authentication.depenency-module";
import {dialogsDependencyModule} from "./core/dialogs/dialogs.depenency-module";
import {actionHandlerDependencyModule} from "./core/actions/action-handler.dependency-module";
import {mobileViewDependencyModule} from "./core/mobile-view/mobile-view.dependency-module";
import {siteDependencyModule} from "./core/site/site.dependency-module";
import {userProfileDependencyModule} from "./core/user-profile/user-profile.dependency-module";
import {environment} from "./environments/environment";
import {runtimeEnvironments} from "./environments/runtime-environments";
import {defaultPagesDependencyModule} from "./core/site/default-pages/default-pages.dependency-module";
import {toastsDependencyModule} from "./core/toasts/toasts.dependency-module";
import {EnvironmentType} from "sirius-platform-support-library/tenants/tenant-context";
import {searchDependencyModule} from "./core/search/search.dependency-module";
import {
    microFrontendsSkeletonsDependencyModule
} from "./core/site/components/skeletons/micro-frontends-skeletons.dependency-module";
import {iconsRegistryDependencyModule} from "./core/icons/icons-registry.dependency-module";
import {pageCustomizationsDependencyModule} from "./core/page-customizations/page-customizations.dependency-module";
import {SiriusIcon} from "sirius-platform-support-library/shared/icons/sirius-icon";
import {analyticsDependencyModule} from "./core/analytics/analytics-dependency-module";
import {dynamicMenuDependencyModule} from "./core/dynamic-menu/dynamic-menu.dependency-module";
import {quickNavMenuDependencyModule} from "./core/quicknav-menu/quicknav-menu.dependency-module";
import {authorizationDependencyModule} from "./core/authorization/authorization.depenency-module";
import {
    runtimeConfigurationDependencyModule
} from "./core/runtime-configuration/runtime-configuration.depenency-module";
import {
    RuntimeConfigurationLoader,
    RuntimeConfigurationLoaderTypeName
} from "./core/runtime-configuration/runtime-configuration.loader";
import {loadingIndicatorDependencyModule} from "./core/loading-indicators/loading-indicator.dependency-module";
import {splashScreenDependencyModule} from "./core/splash-screen/splash-screen.dependency-module";
import {deviceTraitsDependencyModule} from "./core/device-traits/device-traits.dependency-module";
import {
    DeviceTraitsProviderLoader,
    DeviceTraitsProviderLoaderTypeName
} from "./core/device-traits/device-traits-provider.loader";
import {DeviceTraitsService, DeviceTraitsServiceTypeName} from "./core/device-traits/device-traits.service";
import {
    ILoadingIndicatorService,
    ILoadingIndicatorServiceTypeName
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator-service.interface";
import {
    userActionDynamicLabelsDependencyModule
} from "./core/dynamic-labels/user-action-dynamic-labels.dependency-module";
import {LocalizationService, LocalizationServiceTypeName} from "./core/localization/localization-service";
import {DefaultPagesOptionsStore} from "./core/site/default-pages/options/options-store/default-pages-options.store";
import {featureTogglesDependencyModule} from "./core/feature-toggles/feature-toggles.dependency-module";
import {themingDependencyModule} from "./core/theming/theming.dependency-module";
import {ThemingService, ThemingServiceTypeName} from './core/theming/theming.service';
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from 'sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface';
import {
    ThemingHandlersManager,
    ThemingHandlersManagerTypeName
} from "./core/theming/management/managers/theming-handlers.manager";
import {commonUiDependencyModule} from "./core/common-ui/common-ui.depenency-module";
import {notificationDependencyModule} from "./core/notifications/notifications.depenency-module";
import {registerSiriusWorkboxServiceWorker} from "./sirius-workbox-registration";
import {SystemJs, SystemJsTypeName} from "./core/dynamic-imports/system-js";
import {TenantContextLoader} from "./core/tenant/context/tenant-context.loader";
import {TenantCustomBootstrapCodeLoader} from "./core/tenant/custom-code/tenant-custom-bootstrap-code.loader";

// Begin Region: Clarity Warnings
(window as any)['jasmine'] = true;
// End Region

System.addImportMap({
    'imports': {
        'single-spa': '/libs/single-spa/lib/system/single-spa.min.js',
    }
});

await applyCommonScripts();

replaceAllInserter.shim();

SiriusIcon.register();

(async function () {
    await registerSiriusWorkboxServiceWorker();

    PlatformInitializationAwaiter.getInstance();

    DefaultPagesOptionsStore.initialize(window);

    const dependencyContainer = RootDependencyContainer.getInstance();
    dependencyContainer
        .registerModule(tenantStoreDependencyModule)
        .registerModule(eventBusDependencyModule)
        .registerModule(browserEventsDependencyModule)
        .registerModule(environmentDependencyModule)
        .registerModule(applicationDependencyModule)
        .registerModule(dynamicImportsDependencyModule)
        .registerModule(runtimeConfigurationDependencyModule)
        .registerModule(deviceTraitsDependencyModule)
        .registerModule(splashScreenDependencyModule)
        .registerModule(loadingIndicatorDependencyModule)
        .registerModule(tenantDependencyModule)
        .registerModule(localizationDependencyModule)
        .registerModule(bannerAlertsDependencyModule)
        .registerModule(placeholderReplacersDependencyModule)
        .registerModule(actionHandlerDependencyModule)
        .registerModule(progressIndicatorsDependencyModule)
        .registerModule(servicesInitializerDependencyModule)
        .registerModule(platformDependencyModule)
        .registerModule(authenticationDependencyModule)
        .registerModule(mobileViewDependencyModule)
        .registerModule(siteDependencyModule)
        .registerModule(toastsDependencyModule)
        .registerModule(dialogsDependencyModule)
        .registerModule(defaultPagesDependencyModule)
        .registerModule(userProfileDependencyModule)
        .registerModule(searchDependencyModule)
        .registerModule(microFrontendsSkeletonsDependencyModule)
        .registerModule(iconsRegistryDependencyModule)
        .registerModule(analyticsDependencyModule)
        .registerModule(pageCustomizationsDependencyModule)
        .registerModule(dynamicMenuDependencyModule)
        .registerModule(quickNavMenuDependencyModule)
        .registerModule(authorizationDependencyModule)
        .registerModule(userActionDynamicLabelsDependencyModule)
        .registerModule(featureTogglesDependencyModule)
        .registerModule(themingDependencyModule)
        .registerModule(commonUiDependencyModule)
        .registerModule(notificationDependencyModule);

    const serviceCollection = dependencyContainer.getServiceCollection();

    const systemJs = serviceCollection.resolve<SystemJs>(SystemJsTypeName);

    Promise.allSettled([
        RuntimeConfigurationLoader.preload(systemJs, true),
        TenantContextLoader.preload(systemJs, undefined, true),
        TenantCustomBootstrapCodeLoader.preload(systemJs, true)
    ]).catch((e) => {
        // Suppress the error - we want to continue the initialization process
    });

    const localizationService = serviceCollection.resolve<LocalizationService>(LocalizationServiceTypeName);
    await localizationService?.preInit();

    const deviceTraitsProviderLoader = serviceCollection.resolve<DeviceTraitsProviderLoader>(DeviceTraitsProviderLoaderTypeName);
    const deviceTraitsService = serviceCollection.resolve<DeviceTraitsService>(DeviceTraitsServiceTypeName);

    const themingService = serviceCollection.resolve<ThemingService>(ThemingServiceTypeName);
    const themingHandlersManager = serviceCollection.resolve<ThemingHandlersManager>(ThemingHandlersManagerTypeName);

    const defaultPagesService = serviceCollection.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);

    const failFast = defaultPagesService.isOnFullScreenErrorPage();

    // This call is needed to ensure that the device traits are loaded via build argument provided url
    await deviceTraitsProviderLoader?.load();
    await deviceTraitsService?.load();

    const runtimeConfigurationLoader = serviceCollection.resolve<RuntimeConfigurationLoader>(RuntimeConfigurationLoaderTypeName);
    await runtimeConfigurationLoader?.load(failFast);

    TenantContextLoader.preload(systemJs, runtimeConfigurationLoader, failFast).catch((e) => {
    });

    // This call is needed to ensure that the device traits are loaded via runtime configuration
    await deviceTraitsProviderLoader?.load();
    await deviceTraitsService?.load();

    // This call is needed to ensure that the theming is loaded via runtime configuration before any UI component is rendered
    await themingService?.preInit();
    await themingHandlersManager?.preInit();

    const loadingIndicatorService = serviceCollection.resolve<ILoadingIndicatorService>(ILoadingIndicatorServiceTypeName);
    await loadingIndicatorService?.show();

    const platform = serviceCollection.resolve<IPlatform>(IPlatformTypeName);
    if (platform) {
        await platform.initialize();
        applyRuntimeConfig(environment, runtimeEnvironments, EnvironmentType.SIRIUS);
    }
})();


export function assignGlobalWindowHandlers() {
    const watchSiteOverlaySection = function (siteOverlayElement: any) {
        if (!siteOverlayElement) {
            return;
        }

        const updateBodyScroll = (overlayVisible: any) => {
            if (overlayVisible) {
                document.body.classList.add('no-scroll');
            } else {
                document.body.classList.remove('no-scroll');
            }
        };

        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === "attributes") {
                    const overlayVisible = siteOverlayElement.classList.contains('site-overlay-visible')
                    updateBodyScroll(overlayVisible);
                }
            });
        });

        observer.observe(siteOverlayElement, {
            attributes: true
        });
    };

    const handlers = {
        '.site-overlay': watchSiteOverlaySection
    };

    (window as any).handle = (selector: any) => {
        const handler = handlers[selector];
        if (!handler) {
            return;
        }
        const element = document.querySelector(selector);
        if (!element) {
            return;
        }
        handler(element);
    };
}

export function applySpaNavigationEnforcement() {
    const config = (window as any).sirius?.config;
    const enforceSpaNavigation = config?.['enforceSpaNavigation'] === 'true';

    if (enforceSpaNavigation) {
        const isIndexHtmlRoute = (path: string) => {
            const segments = (path || '').split('/').filter(segment => !!segment);
            const lastSegment = segments[segments.length - 1] || '';
            return lastSegment.toLowerCase().indexOf('index.html') !== -1;
        };

        if (isIndexHtmlRoute(window.location.pathname)) {
            const urlParams = new URLSearchParams(window.location.search);
            const targetParam = urlParams.get('target');
            let route = '/';
            if (targetParam) {
                route = decodeURIComponent(targetParam);
            }
            history.replaceState(null, null, route);
        }
    }
}
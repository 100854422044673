/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IMicroFrontendLoadingSkeleton,
    IMicroFrontendLoadingSkeletonTypeName
} from "sirius-platform-support-library/shared/site/loaders/micro-frontend-loading-skeleton.interface";

export const MicroFrontendLoadersFactoryTypeName = 'MicroFrontendLoadersFactory';

export class MicroFrontendLoadersFactory {
    private readonly tenant: ITenant;
    private readonly serviceCollection: IServiceCollection;
    private readonly loaders: IMicroFrontendLoadingSkeleton[];

    public constructor(
        tenant: ITenant,
        serviceCollection: IServiceCollection
    ) {
        this.tenant = tenant;
        this.serviceCollection = serviceCollection;
        this.loaders = this.getMicroFrontendLoadingSkeletons();
    }

    public getLoaderFor(loaderName: string): string | undefined {
        if (!this.isFeatureEnabled()) {
            return undefined;
        }
        const loader = this.loaders
            .filter(l => l.for() === loaderName)
            .at(-1);
        return loader?.getMarkup();
    }

    private getMicroFrontendLoadingSkeletons(): IMicroFrontendLoadingSkeleton[] {
        return this.serviceCollection.resolveAll<IMicroFrontendLoadingSkeleton>(IMicroFrontendLoadingSkeletonTypeName);
    }

    private isFeatureEnabled(): boolean {
        return this.tenant.getContext()?.behaviour?.microFrontends?.loadingPlaceholdersEnabled || false;
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

export const SystemJsTypeName = 'SystemJs';

export class SystemJs {
    private readonly system: any;

    constructor(system: any) {
        this.system = system;
    }

    public async import<T>(module: string): Promise<T> {
        const imported = await this.system.import(module);
        return imported as T;
    }

    public delete(module: string): void {
        this.system.delete(module);
    }
}

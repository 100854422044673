/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IAuthorizationService,
    PermissionsChangedCallback
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import {Permission} from "sirius-platform-support-library/shared/authorization/models/permission";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {AuthorizationConstants} from "sirius-platform-support-library/shared/authorization/authorization.constants";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {RuntimePermission} from "sirius-platform-support-library/shared/authorization/models/runtime-permission";
import {
    IAuthenticationService
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {UserContext} from "sirius-platform-support-library/shared/authentication/user-context/user-context";
import {
    IPermissionsProvider,
    IPermissionsProviderTypeName
} from "sirius-platform-support-library/shared/authorization/providers/permissions-provider.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {
    AuthorizationEvents,
    PermissionsChangedEvent
} from "sirius-platform-support-library/shared/authorization/events/authorization.events";
import {
    RequiredPermissionsEvaluator
} from "sirius-platform-support-library/shared/authorization/evaluators/required-permissions.evaluator";
import {RequiredPermissions} from "sirius-platform-support-library/shared/authorization/models/required-permissions";
import {v4 as uuidv4} from "uuid";
import {
    RequiredPermissionsStructure
} from "sirius-platform-support-library/shared/authorization/models/required-permissions-structure";
import {
    RequiredPermissionsStructureOperator
} from "sirius-platform-support-library/shared/authorization/models/required-permissions-structure-operator";

export const AuthorizationServiceTypeName = 'AuthorizationService';

export class AuthorizationService implements IAuthorizationService, IAfterPlatformReadyInit {

    private static readonly BROADCASTER_ID = uuidv4().toLowerCase();
    private readonly window: Window;
    private readonly eventBus: IEventBus;
    private readonly authenticationService: IAuthenticationService;
    private readonly permissionsEvaluator: RequiredPermissionsEvaluator;
    private readonly serviceCollection: IServiceCollection;
    private currentUserContext: UserContext;
    private readonly permissions: Record<string, RuntimePermission[]> = {};
    private readonly providers: Record<string, IPermissionsProvider> = {};

    public constructor(
        window: Window,
        eventBus: IEventBus,
        authenticationService: IAuthenticationService,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.eventBus = eventBus;
        this.authenticationService = authenticationService;
        this.serviceCollection = serviceCollection;

        const bindedIsPermissionGranted = this.isPermissionGranted.bind(this);
        this.permissionsEvaluator = new RequiredPermissionsEvaluator(bindedIsPermissionGranted);

        this.currentUserContext = this.authenticationService.getUserContext();
    }

    public static build(
        window: Window,
        eventBus: IEventBus,
        authenticationService: IAuthenticationService,
        serviceCollection: ITypedServiceCollection
    ): AuthorizationService {
        let instance = ObjectUtility.getFromObjectPath<AuthorizationService>(AuthorizationConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new AuthorizationService(
                window,
                eventBus,
                authenticationService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(AuthorizationConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public async init(): Promise<void> {
        this.currentUserContext = this.authenticationService.getUserContext();

        await this.bindProviders();
        await this.load();

        this.authenticationService.onUserContextChanged(this, AuthorizationServiceTypeName, this.onUserContextChanged.bind(this));
    }

    public async load(): Promise<void> {
        const namespaces = Object.values(this.providers).map(p => p.getNamespace());
        for (let index = 0; index < namespaces.length; index++) {
            const namespace = namespaces[index];
            if (!namespace) {
                continue;
            }
            await this.loadNamespacePermissions(namespace, false);
        }
        this.notifyPermissionsChanged(...namespaces);
    }

    public isAuthorized(permissions?: RequiredPermissions): boolean {
        if (!permissions) {
            return true;
        }

        let expression: RequiredPermissionsStructure = undefined;
        if (Array.isArray(permissions)) {
            if (!permissions.length) {
                return true;
            }
            expression = {
                operator: RequiredPermissionsStructureOperator.AND,
                permissions: permissions
            };
        } else {
            expression = permissions as RequiredPermissionsStructure;
        }

        return this.permissionsEvaluator.evaluate(expression);
    }

    public getPermissions(namespace?: string): Permission[] {
        const namespaces = [...(namespace ? [namespace] : Object.keys(this.permissions))];
        return namespaces.flatMap(ns => this.permissions[ns]);
    }

    public onPermissionsChanged(context: any, subscriberName: string, callback: PermissionsChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<PermissionsChangedEvent>(this, AuthorizationServiceTypeName, AuthorizationEvents.PERMISSIONS_CHANGED, async (event) => {
            try {
                await callback?.call(context, event.data);
            } catch (e) {
                console.error(e);
            }
        });
    }

    private async bindProviders(): Promise<void> {
        const onProviderRefreshRequestedBinded = this.onProviderRefreshRequested.bind(this);
        const providers = this.serviceCollection.resolveAll<IPermissionsProvider>(IPermissionsProviderTypeName);
        for (let index = 0; index < providers.length; index++) {
            const provider = providers[index];
            if (!provider) {
                continue;
            }
            try {
                if (provider.onRefreshRequested) {
                    provider.onRefreshRequested(onProviderRefreshRequestedBinded);
                }
                this.providers[provider.getNamespace()] = provider;
            } catch (e) {
                console.error(e);
            }
        }
    }

    private async onUserContextChanged(userContext: UserContext): Promise<void> {
        this.currentUserContext = userContext;
        await this.load();
    }

    private async onProviderRefreshRequested(namespace: string): Promise<void> {
        await this.loadNamespacePermissions(namespace);
    }

    private async loadNamespacePermissions(namespace: string, notify: boolean = true): Promise<void> {
        try {
            const provider = this.providers[namespace];
            if (!provider) {
                return;
            }

            const permissions = await provider.getPermissions(this.currentUserContext);
            this.permissions[namespace] = permissions.map(p => new RuntimePermission(p.namespace, p.code, p.description));
            if (notify) {
                this.notifyPermissionsChanged(...namespace);
            }
        } catch (e) {
            console.error(e);
        }
    }

    private getNamespace(permission: string): string | undefined {
        const separatorIndex = permission.indexOf(AuthorizationConstants.DEFAULT_NAMESPACE_SEPARATOR);
        if (separatorIndex < 0) {
            return undefined;
        }
        return permission.substring(0, separatorIndex);
    }

    private notifyPermissionsChanged(...namespaces: string[]): void {
        const event: PermissionsChangedEvent = {
            broadcasterId: AuthorizationService.BROADCASTER_ID,
            namespaces: namespaces
        };
        this.eventBus.dispatchBroadcast(AuthorizationServiceTypeName, AuthorizationEvents.PERMISSIONS_CHANGED, event, undefined, true);
    }

    private isPermissionGranted(permission?: string): boolean {
        if (!permission) {
            return true;
        }
        const namespace = this.getNamespace(permission);
        if (!namespace) {
            return false;
        }
        const namespacePermissions = this.permissions[namespace];
        if (!namespacePermissions) {
            return false;
        }
        return namespacePermissions.some(nsp => nsp.getKey() === permission);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {UserContext} from "sirius-platform-support-library/shared/authentication/user-context/user-context";
import {
    AuthenticationStateEnum
} from "sirius-platform-support-library/shared/authentication/user-context/authentication-state.enum";
import {RequiredClaims} from "sirius-platform-support-library/shared/authentication/claims/required-claims.enum";
import {
    AuthenticationResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/authentication-response";
import {OptionalClaims} from "sirius-platform-support-library/shared/authentication/claims/optional-claims.enum";
import {
    AuthenticationClaims
} from "sirius-platform-support-library/shared/authentication/handlers/authentication-claims";

export class CurrentUserContext implements UserContext, AuthenticationClaims {
    private _treatRegisteringStateAsAuthenticatedState?: boolean;

    public get treatRegisteringStateAsAuthenticatedState(): boolean | undefined {
        return this._treatRegisteringStateAsAuthenticatedState;
    }

    public set treatRegisteringStateAsAuthenticatedState(value: boolean | undefined) {
        this._treatRegisteringStateAsAuthenticatedState = value;
    }

    private _displayName?: string;

    public get displayName(): string | undefined {
        return this._displayName;
    }

    public set displayName(value: string | undefined) {
        this._displayName = value;
    }

    private _principalName?: string;

    public get principalName(): string | undefined {
        return this._principalName;
    }

    public set principalName(value: string | undefined) {
        this._principalName = value;
    }

    private _userIdentifier?: string;

    public get userIdentifier(): string | undefined {
        return this._userIdentifier;
    }

    public set userIdentifier(value: string | undefined) {
        this._userIdentifier = value;
    }

    private _firstName?: string;

    public get firstName(): string | undefined {
        return this._firstName;
    }

    public set firstName(value: string | undefined) {
        this._firstName = value;
    }

    private _lastName?: string;

    public get lastName(): string | undefined {
        return this._lastName;
    }

    public set lastName(value: string | undefined) {
        this._lastName = value;
    }

    private _claims: Record<string, string> = {};

    public get claims(): Record<string, string> {
        return this._claims;
    }

    public set claims(value: Record<string, string>) {
        this._claims = value;
    }

    public _grantedAuthorities: string[] = [];

    public get grantedAuthorities(): string[] {
        return this._grantedAuthorities;
    }

    public set grantedAuthorities(value: string[]) {
        this._grantedAuthorities = value;
    }

    public _authenticationState: AuthenticationStateEnum = AuthenticationStateEnum.UNAUTHENTICATED;

    public get authenticationState(): AuthenticationStateEnum {
        return this._authenticationState;
    }

    public set authenticationState(value: AuthenticationStateEnum) {
        this._authenticationState = value;
    }

    public static getUnauthenticatedContext(): CurrentUserContext {
        return new CurrentUserContext();
    }

    public static buildFromClaimsAndAuthenticationResponse(claims: Record<string, string>, authenticationResponse: AuthenticationResponse, treatRegisteringStateAsAuthenticatedState: boolean = false, proposedState?: AuthenticationStateEnum,): CurrentUserContext {
        const result = new CurrentUserContext();

        const clonedClaims = Object.assign({}, claims);

        result._treatRegisteringStateAsAuthenticatedState = treatRegisteringStateAsAuthenticatedState;

        result._principalName = clonedClaims[RequiredClaims.PRINCIPAL_NAME];
        result._userIdentifier = clonedClaims[RequiredClaims.USER_IDENTIFIER];
        result._displayName = clonedClaims[RequiredClaims.DISPLAY_NAME];
        result._firstName = clonedClaims[OptionalClaims.FIRST_NAME];
        result._lastName = clonedClaims[OptionalClaims.LAST_NAME];

        delete clonedClaims[RequiredClaims.PRINCIPAL_NAME];
        delete clonedClaims[RequiredClaims.USER_IDENTIFIER];
        delete clonedClaims[RequiredClaims.DISPLAY_NAME];
        delete clonedClaims[OptionalClaims.FIRST_NAME];
        delete clonedClaims[OptionalClaims.LAST_NAME];

        result._claims = clonedClaims;
        result._grantedAuthorities = authenticationResponse.grantedAuthorities ?? [];

        if (proposedState) {
            if (proposedState === AuthenticationStateEnum.AUTHENTICATED) {
                if (result._userIdentifier) {
                    result._authenticationState = proposedState;
                } else {
                    result._authenticationState = AuthenticationStateEnum.UNAUTHENTICATED;
                }
            } else {
                result._authenticationState = proposedState;
            }
        } else {
            result._authenticationState = result._userIdentifier ? AuthenticationStateEnum.AUTHENTICATED : AuthenticationStateEnum.UNAUTHENTICATED;
        }

        return result;
    }

    public static buildFromClaims(claims: Record<string, string>, treatRegisteringStateAsAuthenticatedState: boolean = false): CurrentUserContext {
        const result = new CurrentUserContext();

        const clonedClaims = Object.assign({}, claims);

        result._treatRegisteringStateAsAuthenticatedState = treatRegisteringStateAsAuthenticatedState;

        result._principalName = clonedClaims[RequiredClaims.PRINCIPAL_NAME];
        result._userIdentifier = clonedClaims[RequiredClaims.USER_IDENTIFIER];
        result._displayName = clonedClaims[RequiredClaims.DISPLAY_NAME];
        result._firstName = clonedClaims[OptionalClaims.FIRST_NAME];
        result._lastName = clonedClaims[OptionalClaims.LAST_NAME];

        delete clonedClaims[RequiredClaims.PRINCIPAL_NAME];
        delete clonedClaims[RequiredClaims.USER_IDENTIFIER];
        delete clonedClaims[RequiredClaims.DISPLAY_NAME];
        delete clonedClaims[OptionalClaims.FIRST_NAME];
        delete clonedClaims[OptionalClaims.LAST_NAME];

        result._claims = clonedClaims;

        return result;
    }

    public static buildFromUserContext(userContext: UserContext, treatRegisteringStateAsAuthenticatedState: boolean = false): CurrentUserContext {
        const result = new CurrentUserContext();

        result._treatRegisteringStateAsAuthenticatedState = treatRegisteringStateAsAuthenticatedState

        result._principalName = userContext.principalName;
        result._userIdentifier = userContext.userIdentifier;
        result._displayName = userContext.displayName;
        result._firstName = userContext.firstName;
        result._lastName = userContext.lastName;
        result._claims = userContext.claims;
        result._grantedAuthorities = userContext.grantedAuthorities;
        result._authenticationState = userContext.authenticationState
        return result;
    }

    public reset(): void {
        this._treatRegisteringStateAsAuthenticatedState = undefined;
        this._displayName = undefined;
        this._principalName = undefined;
        this._userIdentifier = undefined;
        this._firstName = undefined;
        this._lastName = undefined;
        this._claims = {};
        this._grantedAuthorities = [];
        this._authenticationState = AuthenticationStateEnum.UNAUTHENTICATED;
    }

    public update(userContext: UserContext, partial: boolean = false, treatRegisteringStateAsAuthenticatedState: boolean = false): CurrentUserContext {
        if (userContext) {
            this._treatRegisteringStateAsAuthenticatedState = treatRegisteringStateAsAuthenticatedState;
            this._userIdentifier = userContext.userIdentifier;
            this._principalName = userContext.principalName;
            this._displayName = userContext.displayName;
            this._firstName = userContext.firstName;
            this._lastName = userContext.lastName;
            this._claims = userContext.claims;
            this._grantedAuthorities = userContext.grantedAuthorities;
            if (!partial) {
                this._authenticationState = userContext.authenticationState;
            }
        }
        return this;
    }

    public isUnauthenticated(): boolean {
        return !(this.authenticationState === AuthenticationStateEnum.AUTHENTICATED);
    }

    public isAuthenticated(): boolean {
        return (this._treatRegisteringStateAsAuthenticatedState && this.authenticationState === AuthenticationStateEnum.REGISTERING) || this.authenticationState === AuthenticationStateEnum.AUTHENTICATED;
    }

    public isRegistering(): boolean {
        return this.authenticationState === AuthenticationStateEnum.REGISTERING;
    }

    public equals(userContext: UserContext) {
        return this._userIdentifier?.toLowerCase() === userContext.userIdentifier?.toLowerCase() && this._authenticationState === userContext.authenticationState;
    }

    public clone(): CurrentUserContext {
        return CurrentUserContext.buildFromUserContext(this.toUserContext(), this._treatRegisteringStateAsAuthenticatedState);
    }

    public toUserContext(): UserContext {
        return {
            authenticationState: this._treatRegisteringStateAsAuthenticatedState && this.authenticationState === AuthenticationStateEnum.REGISTERING ? AuthenticationStateEnum.AUTHENTICATED : this._authenticationState,
            userIdentifier: this._userIdentifier,
            principalName: this._principalName,
            displayName: this._displayName,
            firstName: this._firstName,
            lastName: this._lastName,
            claims: this._claims,
            grantedAuthorities: this._grantedAuthorities
        };
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SectionMarkerCodes} from "sirius-platform-support-library/shared/page-customizations/section-marker-codes";
import {BaseSectionPageCustomizationHandler} from "./base-section-page-customization.handler";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export const NavbarSectionPageCustomizationHandlerTypeName = 'NavbarSectionPageCustomizationHandler';

export class NavbarSectionPageCustomizationHandler extends BaseSectionPageCustomizationHandler {
    private readonly document: Document;
    private readonly tenant: ITenant;

    public constructor(
        window: Window,
        document: Document,
        tenant: ITenant
    ) {
        super(window);
        this.document = document;
        this.tenant = tenant;
    }

    public getSectionMarkerCode(): string {
        return SectionMarkerCodes.NAVBAR_MARKER;
    }

    public getDefaultBackgroundColor(): string {
     return  this.tenant?.getContext()?.site?.top?.navbar?.backgroundColor ?? 'var(--uxd-neutral-white, #FFFFFF)';
    }
}

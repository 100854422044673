/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {FeatureTogglesService, FeatureTogglesServiceTypeName} from "./feature-toggles.service";
import {
    IFeatureTogglesService,
    IFeatureTogglesServiceTypeName
} from "sirius-platform-support-library/shared/feature-toggles/feature-toggles-service.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export class FeatureTogglesDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IFeatureTogglesService, FeatureTogglesService>(DependencyType.SHARED, IFeatureTogglesServiceTypeName, FeatureTogglesServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                return FeatureTogglesService.build(
                    tenant,
                    eventBus,
                    di
                );
            })
            .addInterfacesOnSingleton(FeatureTogglesServiceTypeName, IAfterPlatformReadyInitTypeName)
    }
}

export const featureTogglesDependencyModule = new FeatureTogglesDependencyModule();

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {PlatformInitializationAwaiter, PlatformInitializationAwaiterTypeName} from "./platform-initialization-awaiter";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {SystemJs, SystemJsTypeName} from "../dynamic-imports/system-js";
import {Tenant, TenantTypeName} from "../tenant/tenant";
import {ITenantContextLoader, ITenantContextLoaderTypeName} from "../tenant/context/tenant-context.loader.interface";
import {
    ITenantCustomBootstrapCodeLoader,
    ITenantCustomBootstrapCodeLoaderTypeName
} from "../tenant/custom-code/tenant-custom-bootstrap-code.loader.interface";
import {ISiteStyler, ISiteStylerTypeName} from "../site/stylers/site-styler.interface";
import {SiteStyler, SiteStylerTypeName} from "../site/stylers/site-styler";
import {IPlatform, IPlatformTypeName} from "./platform.interface";
import {Platform, PlatformTypeName} from "./platform";
import {EnvironmentContext, EnvironmentContextTypeName} from "../environment/environment-context";
import {IApplication, IApplicationTypeName} from "../../micro-frontends/application.interface";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {ServicesInitializer, ServicesInitializerTypeName} from "../initializer/services-initializer";
import {ISiteComponents, ISiteComponentsTypeName} from "../site/components/site-components.interface";
import {SiteComponents, SiteComponentsTypeName} from "../site/components/site-components";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {ISiteService, ISiteServiceTypeName} from "sirius-platform-support-library/shared/site/site.interface";
import {
    LoadingIndicatorService,
    LoadingIndicatorServiceTypeName
} from "../loading-indicators/loading-indicator.service";
import {
    IDeviceTraitsService,
    IDeviceTraitsServiceTypeName
} from "sirius-platform-support-library/shared/device-traits/device-traits-service.interface";
import {LocalizationService, LocalizationServiceTypeName} from "../localization/localization-service";

export class PlatformDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer.addUsingInstanceAsSingleton<PlatformInitializationAwaiter>(DependencyType.PRIVATE, PlatformInitializationAwaiterTypeName, PlatformInitializationAwaiter.getInstance())
            .addUsingFactoryAsSingleton<ISiteStyler, SiteStyler>(DependencyType.PRIVATE, ISiteStylerTypeName, SiteStylerTypeName, (di) => {
                const siteService = di.resolve<ISiteService>(ISiteServiceTypeName);
                return new SiteStyler(
                    window,
                    document,
                    siteService
                );
            })
            .addUsingInstanceAsSingleton<ISiteComponents, SiteComponents>(DependencyType.PRIVATE, ISiteComponentsTypeName, SiteComponentsTypeName, new SiteComponents())
            .addUsingFactoryAsSingleton<IPlatform, Platform>(DependencyType.PRIVATE, IPlatformTypeName, PlatformTypeName, (di) => {
                const environmentContext = di.resolve<EnvironmentContext>(EnvironmentContextTypeName);
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                const platformInitializationAwaiter = di.resolve<PlatformInitializationAwaiter>(PlatformInitializationAwaiterTypeName);
                const tenant = di.resolve<Tenant>(TenantTypeName);
                const tenantContextLoader = di.resolve<ITenantContextLoader>(ITenantContextLoaderTypeName);
                const tenantCustomBootstrapCodeLoader = di.resolve<ITenantCustomBootstrapCodeLoader>(ITenantCustomBootstrapCodeLoaderTypeName);
                const siteStyler = di.resolve<ISiteStyler>(ISiteStylerTypeName);
                const servicesInitializer = di.resolve<ServicesInitializer>(ServicesInitializerTypeName);
                const application = di.resolve<IApplication>(IApplicationTypeName);
                const sharedDependencyContainer = SharedDependencyContainer.getInstance();
                const siteComponents = di.resolve<ISiteComponents>(ISiteComponentsTypeName);
                const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);
                const loadingIndicatorService = di.resolve<LoadingIndicatorService>(LoadingIndicatorServiceTypeName);
                const deviceTraitsService = di.resolve<IDeviceTraitsService>(IDeviceTraitsServiceTypeName);
                const localizationService = di.resolve<LocalizationService>(LocalizationServiceTypeName);

                return new Platform(
                    window,
                    environmentContext,
                    systemJs,
                    platformInitializationAwaiter,
                    tenant,
                    tenantContextLoader,
                    tenantCustomBootstrapCodeLoader,
                    siteStyler,
                    servicesInitializer,
                    application,
                    sharedDependencyContainer,
                    siteComponents,
                    defaultPagesService,
                    loadingIndicatorService,
                    deviceTraitsService,
                    localizationService
                );
            });
    }
}

export const platformDependencyModule = new PlatformDependencyModule();

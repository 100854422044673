/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {RuntimeConfigurationLoader, RuntimeConfigurationLoaderTypeName} from "./runtime-configuration.loader";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {SystemJs, SystemJsTypeName} from "../dynamic-imports/system-js";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";

export class RuntimeConfigurationDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<RuntimeConfigurationLoader>(DependencyType.PRIVATE, RuntimeConfigurationLoaderTypeName, (di) => {
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);
                const sharedDependencyContainer = SharedDependencyContainer.getInstance();
                return new RuntimeConfigurationLoader(
                    systemJs,
                    defaultPagesService,
                    sharedDependencyContainer
                );
            });
    }
}

export const runtimeConfigurationDependencyModule = new RuntimeConfigurationDependencyModule();

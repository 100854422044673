/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {HeaderUserActionDynamicLabels} from "sirius-platform-support-library/models/common";
import {
    HeaderUserActionDynamicLabelCallback,
    IUserActionsDynamicLabelsService
} from "sirius-platform-support-library/shared/dynamic-labels/user-actions-dynamic-labels-service.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    IUserActionsDynamicLabelsProvider,
    IUserActionsDynamicLabelsProviderTypeName
} from "sirius-platform-support-library/shared/dynamic-labels/user-action-dynamic-labels-provider.interface";
import {
    IAuthenticationService
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    UserActionsDynamicLabelsChangedEvent
} from "sirius-platform-support-library/shared/dynamic-labels/user-actions-dynamic-labels-changed-event";
import {
    UserActionDynamicLabelsEvents
} from "sirius-platform-support-library/shared/dynamic-labels/user-action-dynamic-labels-events";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {AuthorizationServiceTypeName} from "../authorization/authorization.service";
import {UserContext} from "sirius-platform-support-library/shared/authentication/user-context/user-context";

export const UserActionDynamicLabelsServiceTypeName = 'UserActionDynamicLabelsService';

export class UserActionDynamicLabelsService implements IUserActionsDynamicLabelsService, IBeforePlatformReadyInit {
    private readonly authenticationService: IAuthenticationService;
    protected readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;

    private currentUserContextClaims: Record<string, string>;
    private dynamicLabels: Record<string, HeaderUserActionDynamicLabels> = {};

    public constructor(
        authenticationService: IAuthenticationService,
        eventBus: IEventBus,
        serviceCollection: IServiceCollection
    ) {
        this.authenticationService = authenticationService;
        this.eventBus = eventBus;
        this.serviceCollection = serviceCollection;

        this.currentUserContextClaims = this.authenticationService.getUserContext().claims;

    }

    public async init(): Promise<void> {
        this.authenticationService.onUserContextChanged(this, AuthorizationServiceTypeName, this.onUserContextChanged.bind(this));
    }

    public async getDynamicLabels(dynamicLabels?: HeaderUserActionDynamicLabels[]): Promise<HeaderUserActionDynamicLabels[]> {
        (dynamicLabels || []).forEach(dynamicLabel => this.dynamicLabels[dynamicLabel?.code] = dynamicLabel);
        const providerDynamicLabels = await this.getProviderDynamicLabels();
        (providerDynamicLabels || []).forEach(providerDynamicLabel => {
            this.dynamicLabels[providerDynamicLabel.code] = providerDynamicLabel;
        });
        return this.mapDynamicLabels();
    }

    public onDynamicLabeledChanged(context: any, subscriberName: string, callback: HeaderUserActionDynamicLabelCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<UserActionsDynamicLabelsChangedEvent>(this, subscriberName, UserActionDynamicLabelsEvents.USER_ACTION_DYNAMIC_LABELS_CHANGED_EVENT, (event) => {
            callback?.call(context, event?.data?.dynamicLabels);
        });
    }

    private async getProviderDynamicLabels(): Promise<HeaderUserActionDynamicLabels[]> {
        for (const provider of this.getUserActionsDynamicLabelsProviders()) {
            try {
                return await provider?.getDynamicLabels();
            } catch (e) {
                console.error(e);
            }
        }
    }

    private getUserActionsDynamicLabelsProviders(): IUserActionsDynamicLabelsProvider[] {
        return this.serviceCollection
            .resolveAll<IUserActionsDynamicLabelsProvider>(IUserActionsDynamicLabelsProviderTypeName)
            .filter(p => p);
    }

    private onUserContextChanged(userContext: UserContext): void {
        this.currentUserContextClaims = userContext.claims;
        this.eventBus.dispatchBroadcast<UserActionsDynamicLabelsChangedEvent>('UserActionDynamicLabelsService', UserActionDynamicLabelsEvents.USER_ACTION_DYNAMIC_LABELS_CHANGED_EVENT, {
            dynamicLabels: this.mapDynamicLabels()
        });
    }

    private mapDynamicLabels(): HeaderUserActionDynamicLabels[] {
        const providedDynamicAssets: HeaderUserActionDynamicLabels[] = [];

        Object.values(this.dynamicLabels || []).forEach(dynamicLabel => {
            const providedLabel = {
                ...dynamicLabel,
                value: this.mapClaim(dynamicLabel?.value)
            };
            providedDynamicAssets.push(providedLabel)
        });
        return providedDynamicAssets;
    }

    private mapClaim(dynamicLabelClaim: string): string {
        const regex = /{{\s*([^}]+)\s*}}|\b(\w+)\b/g;
        const placeholders = [...dynamicLabelClaim.matchAll(regex)];

        let replacedStr = dynamicLabelClaim;

        placeholders.forEach((match) => {
            const [fullMatch, placeholder, word] = match;
            if (placeholder) {
                const trimmedKey = placeholder.trim();
                const value = this.currentUserContextClaims[trimmedKey] ?? '';
                replacedStr = replacedStr.replace(fullMatch, value);
            } else if (this.currentUserContextClaims.hasOwnProperty(word)) {
                replacedStr = replacedStr.replace(fullMatch, this.currentUserContextClaims[word].toString());
            }
        });
        return replacedStr;
    }
}

import {Workbox} from "workbox-window";
import {USE_WORKBOX_CACHING_PLACEHOLDER} from "./sirius.config.constants";

export const registerSiriusWorkboxServiceWorker = async (): Promise<void> => {
    const workboxServiceWorkerResourceName = 'sirius-workbox-sw.js';
    const useWorkboxCachingRawValue = (window as any)?.sirius?.config?.useWorkboxCaching;
    const useWorkboxCaching = useWorkboxCachingRawValue === 'true';
    if ('serviceWorker' in navigator) {
        if (useWorkboxCaching) {
            try {
                const wb = new Workbox(`/libs/sirius/${workboxServiceWorkerResourceName}`, {
                    type: 'module',
                    scope: '/'
                });
                await wb.register({immediate: true});
                console.debug('Sirius Workbox Service Worker registration successful');
            } catch (error) {
                console.debug('Sirius Workbox Service Worker registration failed:', error);
            }
        } else {
            const siriusWorkboxServiceWorkers = (await navigator.serviceWorker.getRegistrations())
                .filter(registration => registration.active?.scriptURL.endsWith(workboxServiceWorkerResourceName));
            for (const registration of siriusWorkboxServiceWorkers) {
                try {
                    await registration.unregister();
                } catch (error) {
                    console.warn('Failed to unregister Sirius Workbox Service Worker', registration, error);
                }
            }
        }
    } else if (useWorkboxCaching) {
        console.warn('Service workers are not supported in this browser');
    }
};

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IMicroFrontendLoadingSkeleton
} from "sirius-platform-support-library/shared/site/loaders/micro-frontend-loading-skeleton.interface";
import {SkeletonLoaderConstants} from "sirius-platform-support-library/shared/site/loaders/skeleton-loader.constants";
import {SiriusContentSkeleton} from "./web-elements/content-skeleton.web-element";

export const ContentLoaderSkeletonTypeName = 'ContentLoaderSkeleton';

export class ContentLoaderSkeleton implements IMicroFrontendLoadingSkeleton {
    public for(): string {
        return SkeletonLoaderConstants.CONTENT_LOADER;
    }

    public getMarkup(): string {
        return `<${SiriusContentSkeleton.CUSTOM_ELEMENT_NAME}></${SiriusContentSkeleton.CUSTOM_ELEMENT_NAME}>`
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    UserActionDynamicLabelsService,
    UserActionDynamicLabelsServiceTypeName
} from "./user-action-dynamic-labels.service";
import {
    IAuthenticationService,
    IAuthenticationServiceTypeName
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {
    IUserActionsDynamicLabelsService,
    IUserActionsDynamicLabelsServiceTypeName
} from "sirius-platform-support-library/shared/dynamic-labels/user-actions-dynamic-labels-service.interface";

import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";

export class UserActionDynamicLabelsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IUserActionsDynamicLabelsService, UserActionDynamicLabelsService>(DependencyType.SHARED, IUserActionsDynamicLabelsServiceTypeName, UserActionDynamicLabelsServiceTypeName, (di) => {
                const authenticationService = di.resolve<IAuthenticationService>(IAuthenticationServiceTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                return new UserActionDynamicLabelsService(
                    authenticationService,
                    eventBus,
                    di
                );
            });
    }
}

export const userActionDynamicLabelsDependencyModule = new UserActionDynamicLabelsDependencyModule();

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {AuthenticationServiceBase} from "./authentication-service-base";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {ITenantStoreService} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {
    IRequiredClaimsValidator
} from "sirius-platform-support-library/shared/authentication/claims/validators/required-claims-validator.interface";
import {IClaimsMapper} from "sirius-platform-support-library/shared/authentication/claims/claims-mapper.interface";
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {AuthenticationConstants} from "sirius-platform-support-library/shared/authentication/authentication-constants";
import {
    IVirtualRoutesProvider
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.interface";
import {VirtualRoute} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-route";
import {
    IUserContextUpdater
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {
    IPlatformBrowserNavigationGateHandler
} from "../browser-events/platform/platform-browser-navigation-gate-handler.interface";
import {
    IPlatformBrowserNavigationEventsReceiver
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {
    IProgressIndicatorsService
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicators-service.interface";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";


export const AuthenticationServiceTypeName = 'AuthenticationService';

export class AuthenticationService extends AuthenticationServiceBase implements IAfterPlatformReadyInit,
    IVirtualRoutesProvider,
    IPlatformBrowserNavigationGateHandler,
    IPlatformBrowserNavigationEventsReceiver {
    public static readonly AUTHENTICATION_VIRTUAL_ROUTE_CODE = 'authentication';
    public static readonly AUTHENTICATION_VIRTUAL_ROUTE_PATH = '/authentication';

    private constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        tenantStore: ITenantStoreService,
        requiredClaimsValidator: IRequiredClaimsValidator,
        claimsMapper: IClaimsMapper,
        userContextHolder: IUserContextUpdater,
        progressIndicatorsService: IProgressIndicatorsService,
        defaultPagesService: IDefaultPagesService,
        serviceCollection: ITypedServiceCollection,
    ) {
        super(
            window,
            tenant,
            eventBus,
            tenantStore,
            requiredClaimsValidator,
            claimsMapper,
            userContextHolder,
            progressIndicatorsService,
            defaultPagesService,
            serviceCollection
        );
    }

    public static build(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        tenantStore: ITenantStoreService,
        requiredClaimsValidator: IRequiredClaimsValidator,
        claimsMapper: IClaimsMapper,
        userContextHolder: IUserContextUpdater,
        progressIndicatorsService: IProgressIndicatorsService,
        defaultPagesService: IDefaultPagesService,
        serviceCollection: ITypedServiceCollection
    ): AuthenticationService {
        let instance = ObjectUtility.getFromObjectPath<AuthenticationService>(AuthenticationConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new AuthenticationService(
                window,
                tenant,
                eventBus,
                tenantStore,
                requiredClaimsValidator,
                claimsMapper,
                userContextHolder,
                progressIndicatorsService,
                defaultPagesService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(AuthenticationConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public async init(): Promise<void> {
        await this.bootstrap();
    }

    public getVirtualRoutes(): VirtualRoute[] {
        if (this.settings.enabled && this.settings.virtualRoute?.enabled) {
            return [{
                code: AuthenticationService.AUTHENTICATION_VIRTUAL_ROUTE_CODE,
                route: this.settings.virtualRoute?.route || AuthenticationService.AUTHENTICATION_VIRTUAL_ROUTE_PATH
            }];
        }
        return [];
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {InternalIcon} from "sirius-platform-support-library/shared/icons/registry/internal.icon";
import {IIconLoader} from "sirius-platform-support-library/shared/icons/loaders/icon-loader.interface";
import {
    ISiriusIconsRegistry
} from "sirius-platform-support-library/shared/icons/registry/sirius-icons-registry.interface";
import {sanitizeUrl} from "@braintree/sanitize-url";

export const ClrIconsLoaderTypeName = 'ClrIconsLoader';

export class ClrIconsLoader implements IIconLoader {
    public static readonly ALL_SHAPES_RESOURCES_URL = '/libs/@clr/icons/shapes/all-shapes.min.js'

    public static readonly UNIQUE_CODE = 'CLR';
    public static readonly SET_CODE = 'clr';

    public getUniqueCode(): string {
        return ClrIconsLoader.UNIQUE_CODE;
    }

    public async bind(iconRegistry: ISiriusIconsRegistry): Promise<void> {
        await this.registerLazyIcons(iconRegistry);
    }

    public async load(icon: InternalIcon): Promise<void> {
        try {
            const sanitizedUrl = sanitizeUrl(ClrIconsLoader.ALL_SHAPES_RESOURCES_URL);
            const result = await fetch(sanitizedUrl);
            const text = await result.text();
            const context = await this.evalIsolated(text);
            const allShapes = context?.AllShapes || {};
            const resource = allShapes[icon.code];
            if (!resource) {
                return;
            }
            icon.load(resource);
            if (context.AllShapes) {
                delete context.AllShapes;
            }
        } catch (e) {
            console.warn(`Could not load requested icon (${icon.code}) due to an encountered exception.`, e);
        }
    }

    private async registerLazyIcons(iconRegistry: ISiriusIconsRegistry): Promise<void> {
        try {
            const sanitizedUrl = sanitizeUrl(ClrIconsLoader.ALL_SHAPES_RESOURCES_URL);
            const result = await fetch(sanitizedUrl);
            const text = await result.text();
            const context = await this.evalIsolated(text);
            const allShapes = context?.AllShapes || {};
            Object.keys(allShapes).forEach((shapeCode) => {
                iconRegistry.addIcon({
                    code: shapeCode,
                    resource: undefined,
                    set: ClrIconsLoader.SET_CODE,
                    loaderCode: this.getUniqueCode()
                });
            });
            if (context.AllShapes) {
                delete context.AllShapes;
            }
        } catch (e) {
            console.warn(`Could not register ${ClrIconsLoader.SET_CODE} icons due to an encountered exception.`, e);
        }
    }

    private async evalIsolated(text: string): Promise<any> {
        try {
            const context = {};
            const evalFunction = new Function('context', text);
            evalFunction.call(context);
            return context;
        } catch (error) {
            throw error;
        }
    }
}

import {RuntimeConfigurationLoader} from "../../../runtime-configuration/runtime-configuration.loader";
import {ThemingConfig} from "sirius-platform-support-library/models/theming/theming-config.model";
import {IThemesProvider} from "sirius-platform-support-library/shared/theming/providers/themes-provider.interface";

export const RuntimeOptionsThemesProviderTypeName = 'RuntimeOptionsThemesProvider';

export class RuntimeOptionsThemesProvider implements IThemesProvider {
    private readonly runtimeConfigurationLoader: RuntimeConfigurationLoader

    public constructor(
        runtimeConfigurationLoader: RuntimeConfigurationLoader
    ) {
        this.runtimeConfigurationLoader = runtimeConfigurationLoader;
    }

    public async getThemingConfig(): Promise<ThemingConfig | undefined> {
        const runtimeOptionsConfigurator = this.runtimeConfigurationLoader?.getRuntimeOptionsConfigurator();
        const runtimeOptions = typeof runtimeOptionsConfigurator?.getRuntimeOptions === 'function' ? runtimeOptionsConfigurator?.getRuntimeOptions() : undefined;
        return runtimeOptions?.theming;
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const BannerAlertsContainerTemplate = `
<div class="alerts-navigation">
    <button type="button" class="previous-button alert-icon-wrapper" role="button" aria-label="Previous">
        <sirius-icon shape="angle left" set="uxd" size="20" aria-hidden="true"></sirius-icon>
    </button>
    <div class="alerts-pagination">
        <span class="current-page" tabindex="0" role="navigation" aria-label="0">0</span>/<span class="total-pages"  tabindex="0" role="heading" aria-label="0">0</span>
    </div>
    <button type="button" class="next-button alert-icon-wrapper" role="button"  aria-label="Next">
        <sirius-icon shape="angle right" set="uxd" size="20" aria-hidden="true"></sirius-icon>
    </button>
</div>
<div class="alert-items"></div>
<button type="button" class="dismiss-button close" role="button" aria-label="Close">
    <sirius-icon shape="window-close" set="uxd" size="16" aria-hidden="true"></sirius-icon>
</button>
`;

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    IPageUnloadEventReceiver,
    IPageUnloadEventReceiverTypeName
} from "sirius-platform-support-library/shared/browser-events/page-unload-event-receiver.interface";

import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IPlatformPageUnloadEventReceiver,
    IPlatformPageUnloadEventReceiverTypeName
} from "./platform/platform-page-unload-event-receiver.interface";

export const AggregatedPageUnloadHandlerTypeName = 'AggregatedPageUnloadHandler';

export class AggregatedPageUnloadHandler {
    private readonly tenant: ITenant;
    private readonly serviceCollection: IServiceCollection;

    public constructor(
        tenant: ITenant,
        serviceCollection: IServiceCollection
    ) {
        this.tenant = tenant;
        this.serviceCollection = serviceCollection;
    }

    public async init(): Promise<void> {
    }

    public onPageUnload(url?: string): void {
        this.getPlatformPageUnloadEventReceivers().forEach(handler => {
            try {
                handler.onPageUnload(url);
            } catch (e) {
                console.error(e);
            }
        });
        this.getTenantPageUnloadEventReceivers().forEach(handler => {
            try {
                handler.onPageUnload(url);
            } catch (e) {
                console.error(e);
            }
        });
    }

    private getPlatformPageUnloadEventReceivers(): IPlatformPageUnloadEventReceiver[] {
        return this.serviceCollection.resolveAll<IPlatformPageUnloadEventReceiver>(IPlatformPageUnloadEventReceiverTypeName);
    }

    private getTenantPageUnloadEventReceivers(): IPageUnloadEventReceiver[] {
        return this.serviceCollection.resolveAll<IPageUnloadEventReceiver>(IPageUnloadEventReceiverTypeName);
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";
import {EventType} from "sirius-platform-support-library/shared/event-bus/event-type";
import {EventIdGenerator} from "./event-id.generator";

export class Event<T> implements IEvent<T> {
    public static readonly DEFAULT_TTL: number = 5000;

    public readonly dispatcherName: string = undefined;
    public readonly type: EventType;
    public readonly id: string;
    public readonly timestamp: Date;
    public readonly event: string;
    public readonly data?: T;
    public readonly subscriberName?: string = undefined;
    public readonly channel?: string = undefined;
    public readonly ttl?: number = undefined;
    public readonly dequeueFunction?: (event: Event<any>, reason?: string) => void;

    public crossTabPropagation?: boolean = undefined;
    public loggingDisable?: boolean = undefined;

    private ttlHandler: any = undefined;

    constructor(
        dequeueFunction: (event: Event<any>, reason?: string) => void,
        dispatcherName: string,
        type: EventType,
        event: string,
        data?: T,
        subscriberName?: string,
        channel?: string,
        crossTabPropagation?: boolean,
        loggingDisable?: boolean,
        ttl?: number
    ) {
        if (type === EventType.DIRECT && !subscriberName) {
            throw new Error('Please provide a subscriber name if eventType is set to DIRECT');
        }

        this.dequeueFunction = dequeueFunction;

        this.dispatcherName = dispatcherName;
        this.type = type;
        this.id = EventIdGenerator.getNextId();
        this.timestamp = new Date();
        this.event = event;
        this.data = data;
        this.subscriberName = subscriberName
        this.channel = channel;
        this.ttl = !ttl || ttl < Event.DEFAULT_TTL ? Event.DEFAULT_TTL : ttl;
        this.crossTabPropagation = crossTabPropagation;
        this.loggingDisable = loggingDisable;
    }

    public startTtlTimer(): void {
        this.ttlHandler = setTimeout(() => {
            clearTimeout(this.ttlHandler);
            if (this.dequeueFunction) {
                this.dequeueFunction(this, 'TTL');
            }
        }, this.ttl);
    }

    public stopTtlTimer(): void {
        clearTimeout(this.ttlHandler);
    }

    public toSerializableEvent(): IEvent<T> {
        return {
            dispatcherName: this.dispatcherName,
            type: this.type,
            id: this.id,
            timestamp: this.timestamp,
            event: this.event,
            data: this.data,
            subscriberName: this.subscriberName,
            channel: this.channel,
            crossTabPropagation: this.crossTabPropagation,
            loggingDisable: this.loggingDisable,
            ttl: this.ttl
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    IClaimsMapper,
    IClaimsMapperTypeName
} from "sirius-platform-support-library/shared/authentication/claims/claims-mapper.interface";
import {ClaimsMapper, ClaimsMapperTypeName} from "./claims/claims-mapper";
import {
    IRequiredClaimsValidator,
    IRequiredClaimsValidatorTypeName
} from "sirius-platform-support-library/shared/authentication/claims/validators/required-claims-validator.interface";
import {RequiredClaimsValidator, RequiredClaimsValidatorTypeName} from "./claims/validators/required-claims-validator";
import {AuthenticationService, AuthenticationServiceTypeName} from "./authentication-service";
import {
    IAuthenticationService,
    IAuthenticationServiceTypeName
} from "sirius-platform-support-library/shared/authentication/authentication-service.interface";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    ITenantStoreService,
    ITenantStoreServiceTypeName
} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {IAfterPlatformReadyInitTypeName} from "../initializer/after-platform-ready-init.interface";
import {
    IVirtualRoutesProviderTypeName
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.interface";
import {
    IUserContextUpdater,
    IUserContextUpdaterTypeName
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {UserContextUpdater, UserContextUpdaterTypeName} from "./user-context/user-context.updater";
import {
    IPlatformBrowserNavigationGateHandlerTypeName
} from "../browser-events/platform/platform-browser-navigation-gate-handler.interface";
import {
    IPlatformBrowserNavigationEventsReceiverTypeName
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {
    IProgressIndicatorsService,
    IProgressIndicatorsServiceTypeName
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicators-service.interface";
import {DellIdentityDependencyModule} from "./handler/dell-identity/dell-identity.dependency-module";
import {
    IDefaultPagesService,
    IDefaultPagesServiceTypeName
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";

export class AuthenticationDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IClaimsMapper, ClaimsMapper>(DependencyType.PRIVATE, IClaimsMapperTypeName, ClaimsMapperTypeName, new ClaimsMapper())
            .addUsingInstanceAsSingleton<IRequiredClaimsValidator, RequiredClaimsValidator>(DependencyType.PRIVATE, IRequiredClaimsValidatorTypeName, RequiredClaimsValidatorTypeName, new RequiredClaimsValidator())
            .addUsingInstanceAsSingleton<IUserContextUpdater, UserContextUpdater>(DependencyType.SHARED, IUserContextUpdaterTypeName, UserContextUpdaterTypeName, new UserContextUpdater())
            .addUsingFactoryAsSingleton<IAuthenticationService, AuthenticationService>(DependencyType.SHARED, IAuthenticationServiceTypeName, AuthenticationServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const tenantStore = di.resolve<ITenantStoreService>(ITenantStoreServiceTypeName);
                const requiredClaimsValidator = di.resolve<IRequiredClaimsValidator>(IRequiredClaimsValidatorTypeName);
                const claimsMapper = di.resolve<IClaimsMapper>(IClaimsMapperTypeName);
                const userContextUpdater = di.resolve<IUserContextUpdater>(IUserContextUpdaterTypeName);
                const progressIndicatorsService = di.resolve<IProgressIndicatorsService>(IProgressIndicatorsServiceTypeName);
                const defaultPagesService = di.resolve<IDefaultPagesService>(IDefaultPagesServiceTypeName);
                return AuthenticationService.build(
                    window,
                    tenant,
                    eventBus,
                    tenantStore,
                    requiredClaimsValidator,
                    claimsMapper,
                    userContextUpdater,
                    progressIndicatorsService,
                    defaultPagesService,
                    di
                );
            })
            .addInterfacesOnSingleton(AuthenticationServiceTypeName, IAfterPlatformReadyInitTypeName)
            .addInterfacesOnSingleton(AuthenticationServiceTypeName, IVirtualRoutesProviderTypeName)
            .addInterfacesOnSingleton(AuthenticationServiceTypeName, IPlatformBrowserNavigationGateHandlerTypeName)
            .addInterfacesOnSingleton(AuthenticationServiceTypeName, IPlatformBrowserNavigationEventsReceiverTypeName);

        new DellIdentityDependencyModule().register(dependencyContainer);
    }
}

export const authenticationDependencyModule = new AuthenticationDependencyModule();

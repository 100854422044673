/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const DialogContentTemplate = `
        <div class="modal-content">
            <div class="modal-header">
            {{dismissButton}}
            <h3 class="modal-title"  role="heading" tabindex="0" aria-label="{{localizedTitle}}">{{localizedTitle}}</h3>
            </div>
            <div class="modal-body" role="document" tabindex="0">
            {{localizedBody}}
            </div>
            <div class="modal-footer sirius-dialog-actions">
               {{actions}}
            </div>
        </div>`

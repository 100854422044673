/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const allShapesFiles = [
    'accessibility-1.js',
    'accessibility-2.js',
    'add-text.js',
    'administrator.js',
    'alarm-clock.js',
    'alarm-off.js',
    'align-left-text.js',
    'align-right-text.js',
    'angle.js',
    'animation.js',
    'application.js',
    'applications.js',
    'archive.js',
    'arrow.js',
    'assign-user.js',
    'asterisk.js',
    'atom.js',
    'backup.js',
    'backup-restore.js',
    'balance.js',
    'ban.js',
    'bank.js',
    'bar-code.js',
    'bars.js',
    'battery.js',
    'bell.js',
    'beta.js',
    'bitcoin.js',
    'block.js',
    'blocks-group.js',
    'bluetooth.js',
    'bluetooth-off.js',
    'bold.js',
    'bolt.js',
    'book.js',
    'bookmark.js',
    'briefcase.js',
    'bubble-exclamation.js',
    'bug.js',
    'building.js',
    'bullet-list.js',
    'bullseye.js',
    'bundle.js',
    'calculator.js',
    'calendar.js',
    'camera.js',
    'capacitor.js',
    'cd-dvd.js',
    'center-text.js',
    'certificate.js',
    'chat-bubble.js',
    'check.js',
    'check-circle.js',
    'checkbox.js',
    'chevron-double.js',
    'child-arrow.js',
    'ci-cd.js',
    'circle.js',
    'circle-arrow.js',
    'clipboard.js',
    'clock.js',
    'clone.js',
    'cloud.js',
    'cloud-network.js',
    'cloud-scale.js',
    'cloud-traffic.js',
    'cluster.js',
    'code.js',
    'cog.js',
    'coin-bag.js',
    'collapse-card.js',
    'color-palette.js',
    'color-picker.js',
    'computer.js',
    'connect.js',
    'container.js',
    'container-volume.js',
    'control-lun.js',
    'copy.js',
    'copy-to-clipboard.js',
    'cpu.js',
    'credit-card.js',
    'crosshairs.js',
    'crown.js',
    'cursor-arrow.js',
    'cursor-hand.js',
    'cursor-hand-click.js',
    'cursor-hand-grab.js',
    'cursor-hand-open.js',
    'cursor-move.js',
    'dashboard.js',
    'data-cluster.js',
    'deploy.js',
    'details.js',
    'devices.js',
    'disconnect.js',
    'display.js',
    'dollar.js',
    'dollar-bill.js',
    'dot-circle.js',
    'download.js',
    'download-cloud.js',
    'drag-handle.js',
    'drag-handle-corner.js',
    'e-check.js',
    'ellipsis-horizontal.js',
    'ellipsis-vertical.js',
    'employee.js',
    'employee-group.js',
    'envelope.js',
    'eraser.js',
    'error-standard.js',
    'euro.js',
    'event.js',
    'exclamation-circle.js',
    'exclamation-triangle.js',
    'expand-card.js',
    'export.js',
    'eye.js',
    'eye-hide.js',
    'factory.js',
    'fast-forward.js',
    'file.js',
    'file-group.js',
    'file-settings.js',
    'file-share.js',
    'file-share-2.js',
    'film-strip.js',
    'filter.js',
    'filter-2.js',
    'filter-grid.js',
    'filter-grid-circle.js',
    'filter-off.js',
    'firewall.js',
    'first-aid.js',
    'fish.js',
    'flag.js',
    'flame.js',
    'flask.js',
    'floppy.js',
    'folder.js',
    'folder-arrow-right.js',
    'folder-open.js',
    'form.js',
    'fuel.js',
    'grid-view.js',
    'half-star.js',
    'happy-face.js',
    'hard-disk.js',
    'hard-drive.js',
    'hard-drive-disks.js',
    'hashtag.js',
    'headphones.js',
    'heart.js',
    'heart-broken.js',
    'helix.js',
    'help.js',
    'help-info.js',
    'history.js',
    'home.js',
    'host.js',
    'hourglass.js',
    'id-badge.js',
    'image.js',
    'image-gallery.js',
    'import.js',
    'inbox.js',
    'indent.js',
    'inductor.js',
    'info-circle.js',
    'info-standard.js',
    'install.js',
    'italic.js',
    'justify-text.js',
    'key.js',
    'keyboard.js',
    'landscape.js',
    'layers.js',
    'library.js',
    'lightbulb.js',
    'link.js',
    'list.js',
    'lock.js',
    'login.js',
    'logout.js',
    'loop.js',
    'media-changer.js',
    'memory.js',
    'minus.js',
    'minus-circle.js',
    'mobile.js',
    'moon.js',
    'mouse.js',
    'music-note.js',
    'network-globe.js',
    'network-settings.js',
    'network-switch.js',
    'neutral-face.js',
    'new.js',
    'no-access.js',
    'no-wifi.js',
    'node.js',
    'nodes.js',
    'note.js',
    'number-list.js',
    'nvme.js',
    'objects.js',
    'organization.js',
    'outdent.js',
    'paperclip.js',
    'paste.js',
    'pause.js',
    'pencil.js',
    'peso.js',
    'phone-handset.js',
    'piggy-bank.js',
    'pin.js',
    'pinboard.js',
    'play.js',
    'plugin.js',
    'plus.js',
    'plus-circle.js',
    'pop-out.js',
    'portrait.js',
    'pound.js',
    'power.js',
    'power-supply.js',
    'printer.js',
    'process-on-vm.js',
    'qr-code.js',
    'rack-server.js',
    'rack-server-search.js',
    'radar.js',
    'recycle.js',
    'redo.js',
    'refresh.js',
    'repeat.js',
    'replay-all.js',
    'replay-one.js',
    'resistor.js',
    'resize.js',
    'resource-pool.js',
    'rewind.js',
    'router.js',
    'ruble.js',
    'ruler-pencil.js',
    'rupee.js',
    'sad-face.js',
    'scissors.js',
    'scroll.js',
    'search.js',
    'share.js',
    'shield.js',
    'shield-check.js',
    'shield-x.js',
    'shopping-bag.js',
    'shopping-cart.js',
    'shrink.js',
    'shuffle.js',
    'slider.js',
    'snowflake.js',
    'sort-by.js',
    'squid.js',
    'ssd.js',
    'star.js',
    'step-forward.js',
    'step-forward-2.js',
    'stop.js',
    'storage.js',
    'storage-adapter.js',
    'store.js',
    'success-standard.js',
    'sun.js',
    'switch.js',
    'sync.js',
    'table.js',
    'tablet.js',
    'tag.js',
    'tags.js',
    'talk-bubbles.js',
    'tape-drive.js',
    'target.js',
    'tasks.js',
    'terminal.js',
    'thermometer.js',
    'thumbs-down.js',
    'thumbs-up.js',
    'times.js',
    'times-circle.js',
    'tools.js',
    'trash.js',
    'tree.js',
    'tree-view.js',
    'truck.js',
    'two-way-arrows.js',
    'unarchive.js',
    'undo.js',
    'uninstall.js',
    'unknown-status.js',
    'unlink.js',
    'unlock.js',
    'upload.js',
    'upload-cloud.js',
    'usb.js',
    'user.js',
    'user-circle.js',
    'users.js',
    'video-camera.js',
    'video-gallery.js',
    'view-cards.js',
    'view-columns.js',
    'view-list.js',
    'vm.js',
    'vmw-app.js',
    'volume.js',
    'volume-down.js',
    'volume-mute.js',
    'volume-up.js',
    'wallet.js',
    'wand.js',
    'warning-standard.js',
    'wifi.js',
    'window-close.js',
    'window-max.js',
    'window-min.js',
    'window-restore.js',
    'won.js',
    'world.js',
    'wrench.js',
    'wrench-circle.js',
    'yen.js',
    'zoom-in.js',
    'zoom-out.js'
];

import {IBeforePlatformReadyInit} from "../../initializer/before-platform-ready-init.interface";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {
    IVirtualRoutesProvider
} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-routes-provider.interface";
import {VirtualRoute} from "sirius-platform-support-library/shared/micro-frontends/virtual-routes/virtual-route";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {DefaultPagesConstants} from "sirius-platform-support-library/shared/site/default-pages/default-pages.constants";
import {
    DefaultPageNavigateToOptions,
    FullScreenDefaultPageNavigateToOptions,
} from "sirius-platform-support-library/shared/site/default-pages/default-page-navigate-to.options";
import {DefaultPagesEnum} from "sirius-platform-support-library/shared/site/default-pages/default-pages.enum";
import {IDefaultPagesOptionsStore} from "./options/options-store/default-pages-options-store.interface";
import {DefaultPagesShellConstants} from "./default-pages-shell.constants";
import {InternalNavigateToOptions} from "./options/internal-navigate-to.options";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {DefaultPages} from "sirius-platform-support-library/tenants/tenant-context";
import {NavigateToOptionsFactory} from "./options/factory/navigate-to-options.factory";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import _ from "lodash";
import {
    StandardFeatures
} from "sirius-platform-support-library/shared/micro-frontends/features/standard-features.constants";
import {StandardActions} from "sirius-platform-support-library/shared/micro-frontends/actions/standard-actions";
import {PlatformTranslationService} from "../../localization/translations/platform-translation.service";
import {sanitizeUrl} from "@braintree/sanitize-url";
import {Action, ActionTargetEnum, ActionTypeEnum} from "sirius-platform-support-library/models/common";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {
    ILoadingErrorPageConfigurator,
    ILoadingErrorPageConfiguratorTypeName
} from "sirius-platform-support-library/shared/site/default-pages/full-screen/loading-error-page-configurator.interface";

export const DefaultPagesServiceTypeName = 'DefaultPagesService';

export class DefaultPagesService implements IDefaultPagesService, IVirtualRoutesProvider, IBeforePlatformReadyInit {
    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly navigateToOptionsFactory: NavigateToOptionsFactory;
    private readonly defaultPagesOptionsStore: IDefaultPagesOptionsStore;
    private readonly localizationService: ILocalizationService;
    private readonly platformTranslateService: PlatformTranslationService;
    private readonly serviceCollection: IServiceCollection;

    private readonly settings: DefaultPages;
    private readonly routes: Record<string, string> = {};

    private constructor(
        window: Window,
        tenant: ITenant,
        navigateToOptionsFactory: NavigateToOptionsFactory,
        defaultPagesOptionsStore: IDefaultPagesOptionsStore,
        localizationService: ILocalizationService,
        platformTranslateService: PlatformTranslationService,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.tenant = tenant;
        this.navigateToOptionsFactory = navigateToOptionsFactory;
        this.defaultPagesOptionsStore = defaultPagesOptionsStore;
        this.localizationService = localizationService;
        this.platformTranslateService = platformTranslateService;
        this.serviceCollection = serviceCollection;

        this.settings = Object.assign(DefaultPagesConstants.DEFAULT_PAGES_CONFIG, this.tenant.getContext()?.site?.defaultPages || {});
        this.routes = this.settings?.enabled ? (this.settings?.routes || {}) : {};
    }

    public static build(
        window: Window,
        tenant: ITenant,
        navigateToOptionsFactory: NavigateToOptionsFactory,
        defaultPagesOptionsStore: IDefaultPagesOptionsStore,
        localizationService: ILocalizationService,
        platformTranslateService: PlatformTranslationService,
        serviceCollection: IServiceCollection
    ): DefaultPagesService {
        let instance = ObjectUtility.getFromObjectPath<DefaultPagesService>(DefaultPagesConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new DefaultPagesService(
                window,
                tenant,
                navigateToOptionsFactory,
                defaultPagesOptionsStore,
                localizationService,
                platformTranslateService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(DefaultPagesConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IDefaultPagesService {
        return ObjectUtility.getFromObjectPath<IDefaultPagesService>(DefaultPagesConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
    }

    public async navigateTo(context: any, page: DefaultPagesEnum | string, options?: DefaultPageNavigateToOptions): Promise<void> {
        if (!this.settings.enabled) {
            return;
        }
        const pageCode = page?.toString();
        if (!pageCode) {
            return;
        }
        let defaultPageUrl = this.routes[pageCode];
        if (!defaultPageUrl) {
            return;
        }
        const optionsCode = await this.buildOptions(context, pageCode, options, false);
        defaultPageUrl += `?${DefaultPagesShellConstants.PAGE_CODE_QUERY_PARAMETER}=${encodeURIComponent(pageCode)}&${DefaultPagesShellConstants.OPTIONS_CODE_QUERY_PARAMETER}=${encodeURIComponent(optionsCode)}`;
        this.window.history.replaceState(null, null, defaultPageUrl);
    }

    public async navigateToFullScreenErrorPage(context: any, options?: FullScreenDefaultPageNavigateToOptions): Promise<void> {
        if (this.isOnFullScreenErrorPage()) {
            return;
        }
        const pageCode = DefaultPagesEnum.DEFAULT_PAGE_ERROR.toString()
        if (options) {
            options.localizedResources = _.merge(this.tenant.getContext()?.behaviour?.fullScreenErrorPage?.localizedResources || {}, options?.localizedResources || {});
        }
        const optionsCode = await this.buildOptions(context, pageCode, options, true);
        const pageUrl = `${DefaultPagesConstants.FULL_PAGE_ERROR_PAGE_ROUTE}?${DefaultPagesShellConstants.OPTIONS_CODE_QUERY_PARAMETER}=${encodeURIComponent(optionsCode)}`;
        this.window.location.href = sanitizeUrl(pageUrl);
    }

    public getDefaultPagesRoutes(): string[] {
        return Object.keys(this.routes).map(routeKey => {
            return RoutingUtilities.getRelativeUrlIfSameOrigin(this.window, this.routes[routeKey]);
        }).filter(route => route);
    }

    public getVirtualRoutes(): VirtualRoute[] {
        if (!this.settings?.enabled) {
            return [];
        }
        const virtualRoute = Object.keys(this.routes).map(key => {
            const route = this.routes[key];
            return {
                code: `default-page-${key}`,
                route: route.startsWith('https://') || route.startsWith('http://') ? '' : route,
                loadingFunction: () => {
                    return import('../default-pages/pages/default-page.spa')
                }
            }
        }).filter(route => route.route);
        return virtualRoute;
    }

    public isLoadingErrorFullScreenPageEnabled(): boolean {
        const context = this.tenant?.getContext();
        const runtimeEnabled = this.isLoadingErrorFullScreenPageEnabledAtRuntime();
        return ObjectUtility.isDefined(runtimeEnabled) ?
            runtimeEnabled :
            (!ObjectUtility.isDefined(context) || !!(context?.behaviour?.microFrontends?.loadingErrorPageEnabled));
    }

    public isOnFullScreenErrorPage(): boolean {
        return this.window.location.pathname.toLowerCase() === DefaultPagesConstants.FULL_PAGE_ERROR_PAGE_ROUTE.toLowerCase();
    }

    private async buildOptions(context: any, pageCode: string, options?: DefaultPageNavigateToOptions | FullScreenDefaultPageNavigateToOptions, fullScreenPage: boolean = false): Promise<string> {
        const headerOptions = this.tenant?.getContext()?.site?.top?.header;
        const footerOptions = this.tenant?.getContext()?.site?.footer;
        const opts = (options || {}) as any;
        const fullScreenErrorPageOptions = this.tenant?.getContext()?.behaviour?.fullScreenErrorPage;
        const shellResources = await this.platformTranslateService.getTranslationsBySupportedLocales();
        opts.localizedResources = _.merge(
            {},
            shellResources,
            headerOptions?.localizedResources || {},
            footerOptions?.localizedResources || {},
            fullScreenErrorPageOptions?.localizedResources || {},
            opts?.localizedResources || {}
        );
        const translationsCache = new TranslationsCache(context, opts, false);
        const defaultNavigateToOptions = (this.navigateToOptionsFactory.get(pageCode) || {} as InternalNavigateToOptions);
        const siteOptions = this.tenant?.getContext()?.site;
        const tenantHeaderOptions = fullScreenErrorPageOptions?.header;
        const tenantFooterOptions = fullScreenErrorPageOptions?.footer;
        const siteHeaderLogoSrcFeature = (siteOptions?.top?.header?.features || []).find(feature => feature.code === StandardFeatures.HEADER_LOGO && feature?.enabled)?.options;
        const siteHeaderTitleFeature = (siteOptions?.top?.header?.features || []).find(feature => feature.code === StandardFeatures.HEADER_TITLE && feature?.enabled);
        const siteFooterTitleFeature = (siteOptions?.footer?.features || []).find(feature => feature.code === StandardFeatures.FOOTER_TITLE && feature?.enabled);
        const siteFooterDescriptionFeature = (siteOptions?.footer?.features || []).find(feature => feature.code === StandardFeatures.FOOTER_DESCRIPTION && feature?.enabled);
        const siteFooterCopyrightFeature = (siteOptions?.footer?.features || []).find(feature => feature.code === StandardFeatures.FOOTER_COPYRIGHT_LINK && feature?.enabled);
        const siteFooterCopyrightAction = this.patchAction((siteFooterCopyrightFeature?.actions || []).find(action => action.code === StandardActions.COPYRIGHT_LINK), fullScreenPage);
        const siteFooterCopyrightActionLink = siteFooterCopyrightAction?.action;
        const siteFooterCopyrightActionLinkTarget = siteFooterCopyrightAction?.target;
        const siteFooterPrivacyFeature = (siteOptions?.footer?.features || []).find(feature => feature.code === StandardFeatures.FOOTER_PRIVACY_LINK && feature?.enabled);
        const siteFooterPrivacyAction = this.patchAction((siteFooterPrivacyFeature?.actions || []).find(action => action.code === StandardActions.PRIVACY_LINK), fullScreenPage);
        const siteFooterPrivacyActionLink = siteFooterPrivacyAction?.action;
        const siteFooterPrivacyActionLinkTarget = siteFooterPrivacyAction?.target;
        const siteFooterAboutFeature = (siteOptions?.footer?.features || []).find(feature => feature.code === StandardFeatures.FOOTER_ABOUT_DELL_LINK && feature?.enabled);
        const siteFooterAboutAction = this.patchAction((siteFooterAboutFeature?.actions || []).find(action => action.code === StandardActions.ABOUT_DELL_LINK), fullScreenPage);
        const siteFooterAboutActionLink = siteFooterAboutAction?.action;
        const siteFooterAboutActionLinkTarget = siteFooterAboutAction?.target;
        const siteFooterContactFeature = (siteOptions?.footer?.features || []).find(feature => feature.code === StandardFeatures.FOOTER_CONTACT_US_LINK && feature?.enabled);
        const siteFooterContactAction = this.patchAction((siteFooterContactFeature?.actions || []).find(action => action.code === StandardActions.CONTACT_US_LINK), fullScreenPage);
        const siteFooterContactActionLink = siteFooterContactAction?.action;
        const siteFooterContactActionLinkTarget = siteFooterContactAction?.target;
        const restrictMaxPageWidth = ObjectUtility.isDefined(this.tenant?.getContext()?.site?.restrictMaxPageWidth) ? this.tenant?.getContext()?.site?.restrictMaxPageWidth : false;

        let navigateToOptions: InternalNavigateToOptions = {
            pageCode: pageCode,
            backgroundColor: opts?.backgroundColor ?? fullScreenErrorPageOptions?.backgroundColor ?? siteOptions?.backgroundColor,
            useDefaultLocale: !!fullScreenErrorPageOptions?.useDefaultLocale,
            originUrl: sanitizeUrl(opts?.originRoute ?? this.window.location.pathname),
            homeUrl: this.tenant.getContext()?.site?.routing?.default || '/',
            localizedResources: opts.localizedResources,
            pageTitle: opts?.pageTitle || fullScreenErrorPageOptions?.pageTitle || defaultNavigateToOptions?.pageTitle || this.window.document.title,
            title: opts?.title || fullScreenErrorPageOptions?.title || defaultNavigateToOptions?.title || '',
            description: opts?.description || fullScreenErrorPageOptions?.description || defaultNavigateToOptions?.description || '',
            homeButtonVisible: !!(opts?.homeButtonVisible ?? defaultNavigateToOptions?.homeButtonVisible),
            homeButtonText: opts?.homeButtonText || defaultNavigateToOptions?.homeButtonText || '',
            backButtonVisible: !!(opts?.backButtonVisible ?? defaultNavigateToOptions?.backButtonVisible),
            backButtonText: opts?.backButtonText || defaultNavigateToOptions?.backButtonText || '',
            headerVisible: !!(opts?.header?.visible ?? tenantHeaderOptions?.visible ?? defaultNavigateToOptions?.headerVisible),
            headerLogoSrc: opts?.header?.logoSrc || tenantHeaderOptions?.logoSrc || siteHeaderLogoSrcFeature?.imageSrc || defaultNavigateToOptions?.headerLogoSrc || '',
            headerTitle: opts?.header?.title || tenantHeaderOptions?.title || siteHeaderTitleFeature?.localizationCode || siteOptions?.title || defaultNavigateToOptions?.headerTitle || '',
            footerVisible: !!(opts?.footer?.visible ?? tenantFooterOptions?.visible ?? defaultNavigateToOptions?.footerVisible),
            footerTitleVisible: !!(opts?.footer?.titleVisible ?? tenantFooterOptions?.titleVisible ?? defaultNavigateToOptions?.footerTitleVisible),
            footerTitle: opts?.footer?.title || tenantFooterOptions?.title || siteFooterTitleFeature?.localizationCode || siteOptions?.title || defaultNavigateToOptions?.footerTitle || '',
            footerDescriptionVisible: !!(opts?.footer?.descriptionVisible ?? tenantFooterOptions?.descriptionVisible ?? defaultNavigateToOptions?.footerDescriptionVisible),
            footerDescription: opts?.footer?.description || tenantFooterOptions?.description || siteFooterDescriptionFeature?.localizationCode || defaultNavigateToOptions?.footerDescription || '',
            footerCopyrightVisible: !!(opts?.footer?.copyrightVisible ?? tenantFooterOptions?.copyrightVisible ?? defaultNavigateToOptions?.footerCopyrightVisible),
            footerCopyrightLinkText: opts?.footer?.copyrightLinkText || tenantFooterOptions?.copyrightLinkText || siteFooterCopyrightFeature?.localizationCode || defaultNavigateToOptions?.footerCopyrightLinkText || '',
            footerCopyrightLinkUrl: opts?.footer?.copyrightLinkUrl || tenantFooterOptions?.copyrightLinkUrl || siteFooterCopyrightActionLink || defaultNavigateToOptions?.footerCopyrightLinkUrl || '',
            footerCopyrightLinkTarget: opts?.footer?.copyrightLinkTarget || tenantFooterOptions?.copyrightLinkTarget || siteFooterCopyrightActionLinkTarget || defaultNavigateToOptions?.footerCopyrightLinkTarget || '',
            footerPrivacyLinkVisible: !!(opts?.footer?.privacyLinkVisible ?? tenantFooterOptions?.privacyLinkVisible ?? defaultNavigateToOptions?.footerPrivacyLinkVisible),
            footerPrivacyLinkText: opts?.footer?.privacyLinkText || tenantFooterOptions?.privacyLinkText || siteFooterPrivacyFeature?.localizationCode || defaultNavigateToOptions?.footerPrivacyLinkText || '',
            footerPrivacyLinkUrl: opts?.footer?.privacyLinkUrl || tenantFooterOptions?.privacyLinkUrl || siteFooterPrivacyActionLink || defaultNavigateToOptions?.footerPrivacyLinkUrl || '',
            footerPrivacyLinkTarget: opts?.footer?.privacyLinkTarget || tenantFooterOptions?.privacyLinkTarget || siteFooterPrivacyActionLinkTarget || defaultNavigateToOptions?.footerPrivacyLinkTarget || '',
            footerContactLinkVisible: !!(opts?.footer?.contactLinkVisible ?? tenantFooterOptions?.contactLinkVisible ?? defaultNavigateToOptions?.footerContactLinkVisible),
            footerContactLinkText: opts?.footer?.contactLinkText || tenantFooterOptions?.contactLinkText || siteFooterContactFeature?.localizationCode || defaultNavigateToOptions?.footerContactLinkText || '',
            footerContactLinkUrl: opts?.footer?.contactLinkUrl || tenantFooterOptions?.contactLinkUrl || siteFooterContactActionLink || defaultNavigateToOptions?.footerContactLinkUrl || '',
            footerContactLinkTarget: opts?.footer?.contactLinkTarget || tenantFooterOptions?.contactLinkTarget || siteFooterContactActionLinkTarget || defaultNavigateToOptions?.footerContactLinkTarget || '',
            footerAboutLinkVisible: !!(opts?.footer?.aboutLinkVisible ?? tenantFooterOptions?.aboutLinkVisible ?? defaultNavigateToOptions?.footerAboutLinkVisible),
            footerAboutLinkText: opts?.footer?.aboutLinkText || tenantFooterOptions?.aboutLinkText || siteFooterAboutFeature?.localizationCode || defaultNavigateToOptions?.footerAboutLinkText || '',
            footerAboutLinkUrl: opts?.footer?.aboutLinkUrl || tenantFooterOptions?.aboutLinkUrl || siteFooterAboutActionLink || defaultNavigateToOptions?.footerAboutLinkUrl || '',
            footerAboutLinkTarget: opts?.footer?.aboutLinkTarget || tenantFooterOptions?.aboutLinkTarget || siteFooterAboutActionLinkTarget || defaultNavigateToOptions?.footerAboutLinkTarget || '',
            currentLocaleCode: this.localizationService.getCurrentLocaleCode(),
            defaultLocaleCode: this.localizationService.getDefaultLocaleCode(),
            restrictMaxPageWidth: restrictMaxPageWidth,
        }
        if (!this.tenant.getContext()) {
            navigateToOptions = Object.assign({}, defaultNavigateToOptions, navigateToOptions);
        }
        navigateToOptions.localizedResources = await translationsCache.getLocalizedResources();
        return await this.defaultPagesOptionsStore.store(navigateToOptions);
    }

    private patchAction(action?: Action, shouldPatch: boolean = true): Action | undefined {
        if (!shouldPatch) {
            return action;
        }
        if (!action) {
            return undefined;
        }
        switch (action?.type) {
            case undefined:
            case ActionTypeEnum.CALLBACK:
                return undefined
            case ActionTypeEnum.URL:
                return action;
            case ActionTypeEnum.EVENT:
                return this.convertEventActionWithData(action);
        }
        return undefined;
    }

    private convertEventActionWithData(action?: Action): Action | undefined {
        if (!action?.data?.url) {
            return action;
        }
        return {
            code: action.code,
            localizationCode: action.localizationCode,
            type: ActionTypeEnum.URL,
            action: action.data?.url,
            target: action.data?.target ?? ActionTargetEnum.SAME_TAB,
            authorization: action.authorization
        };
    }

    private getLoadingErrorPageConfigurator(): ILoadingErrorPageConfigurator | undefined {
        return this.serviceCollection.resolve<ILoadingErrorPageConfigurator>(ILoadingErrorPageConfiguratorTypeName);
    }

    private isLoadingErrorFullScreenPageEnabledAtRuntime(): boolean | undefined {
        try {
            return this.getLoadingErrorPageConfigurator()?.isLoadingErrorFullScreenPageEnabled();
        } catch (e) {
            return undefined;
        }
    }
}


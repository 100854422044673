/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    PageCustomizationConfig,
    PageCustomizationsOptions
} from "sirius-platform-support-library/models/page-customizations/page-customization.models";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {IPlatformPageCustomizationsProvider} from "./platform-page-customizations-provider.interface";
import {pathToRegexp} from "path-to-regexp";

export const TenantContextPageCustomizationsProviderTypeName = 'TenantContextPageCustomizationsProvider';

export class TenantContextPageCustomizationsProvider implements IPlatformPageCustomizationsProvider {
    private readonly tenant: ITenant

    private readonly routeCustomizations: PageCustomizationConfig[] = [];

    public constructor(
        tenant: ITenant
    ) {
        this.tenant = tenant;
        this.routeCustomizations = this.tenant.getContext()?.behaviour?.pageCustomizations ?? [];
    }

    public async getCustomization(route: string): Promise<PageCustomizationsOptions | undefined> {
        try {
            let matching: PageCustomizationConfig = undefined
            for (const config of this.routeCustomizations) {
                try {
                    const routeTemplate = config.route.replaceAll('*', '(.*)');
                    const expression = pathToRegexp(routeTemplate, undefined, {end: true});
                    if (expression.test(route)) {
                        matching = config;
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            return matching?.customizations;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}

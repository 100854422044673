/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DeviceTraitsProviderLoader, DeviceTraitsProviderLoaderTypeName} from "./device-traits-provider.loader";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {SystemJs, SystemJsTypeName} from "../dynamic-imports/system-js";
import {
    IDeviceTraitsProvider,
    IDeviceTraitsProviderTypeName
} from "sirius-platform-support-library/shared/device-traits/device-traits-provider.interface";
import {
    IDeviceTraitsService,
    IDeviceTraitsServiceTypeName
} from "sirius-platform-support-library/shared/device-traits/device-traits-service.interface";
import {DeviceTraitsService, DeviceTraitsServiceTypeName} from "./device-traits.service";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    RuntimeConfigurationLoader,
    RuntimeConfigurationLoaderTypeName
} from "../runtime-configuration/runtime-configuration.loader";

export class DeviceTraitsDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<DeviceTraitsProviderLoader>(DependencyType.PRIVATE, DeviceTraitsProviderLoaderTypeName, (di) => {
                const systemJs = di.resolve<SystemJs>(SystemJsTypeName);
                const runtimeConfigurationLoader = di.resolve<RuntimeConfigurationLoader>(RuntimeConfigurationLoaderTypeName);
                return new DeviceTraitsProviderLoader(
                    window,
                    systemJs,
                    runtimeConfigurationLoader
                );
            })
            .addUsingFactoryAsSingleton<IDeviceTraitsProvider>(DependencyType.SHARED, IDeviceTraitsProviderTypeName, (di) => {
                const deviceTraitsProviderLoader = di.resolve<DeviceTraitsProviderLoader>(DeviceTraitsProviderLoaderTypeName);
                return deviceTraitsProviderLoader.getDeviceTraitsProvider();
            })
            .addUsingFactoryAsSingleton<IDeviceTraitsService, DeviceTraitsService>(DependencyType.SHARED, IDeviceTraitsServiceTypeName, DeviceTraitsServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                return DeviceTraitsService.build(
                    eventBus,
                    di
                );
            });
    }
}

export const deviceTraitsDependencyModule = new DeviceTraitsDependencyModule();

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IPageCustomizationsHandler
} from "sirius-platform-support-library/shared/page-customizations/page-customizations-handler.interface";
import {
    AllPageCustomizationTypes
} from "sirius-platform-support-library/shared/page-customizations/page-customizations.constants";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";

export abstract class BaseSectionPageCustomizationHandler implements IPageCustomizationsHandler {
    protected readonly window: Window;

    private readonly defaultDisplayStyle?: string;
    private readonly defaultBackgroundColorStyle?: string;

    protected constructor(window: Window) {
        this.window = window;

        const element = this.window.document.querySelector(`.${this.getSectionMarkerCode()}`);
        // @ts-ignore
        this.defaultDisplayStyle = element?.style?.display ?? '';
        // @ts-ignore
        this.defaultBackgroundColorStyle = element?.style?.backgroundColor ? element?.style?.backgroundColor : this.getDefaultBackgroundColor();
    }

    public abstract getSectionMarkerCode(): string;

    public abstract getDefaultBackgroundColor(): string;

    public async apply(sectionCode: string, customizations: AllPageCustomizationTypes): Promise<void> {
        if (sectionCode !== this.getSectionMarkerCode()) {
            return;
        }
        const element = this.window.document.querySelector(`.${this.getSectionMarkerCode()}`);
        if (!element) {
            return;
        }
        // @ts-ignore
        const visible = ObjectUtility.isDefined(customizations?.visible) ? customizations.visible : true;
        // @ts-ignore
        const backgroundColor = ObjectUtility.isDefined(customizations?.backgroundColor) ? customizations.backgroundColor : undefined;

        // @ts-ignore
        element.style.display = visible ? this.defaultDisplayStyle : 'none';
        // @ts-ignore
        element.style.backgroundColor = backgroundColor ? backgroundColor : (this.defaultBackgroundColorStyle ?? this.getDefaultBackgroundColor());

        await this.onVisibilityStateChanged(element, visible);
    }

    public async onVisibilityStateChanged(element: Element, isVisible: boolean): Promise<void> {
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IRequiredClaimsValidator} from "sirius-platform-support-library/shared/authentication/claims/validators/required-claims-validator.interface";
import {RequiredClaimsEnum} from "sirius-platform-support-library/shared/authentication/claims/required-claims.enum";

export const RequiredClaimsValidatorTypeName = 'RequiredClaimsValidator';

export class RequiredClaimsValidator implements IRequiredClaimsValidator {
    public validate(claims: Record<string, string>, requiredClaims: RequiredClaimsEnum[]): boolean {
        return !requiredClaims.some(rc => !claims.hasOwnProperty(rc));
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {SystemJs} from "../../dynamic-imports/system-js";
import {ITenantCustomBootstrapCodeLoader} from "./tenant-custom-bootstrap-code.loader.interface";
import {
    ITenantCustomBootstrapCodeModule
} from "sirius-shell-support-library/tenants/custom-code/tenant-custom-bootstrap-code-module.interface";
import {
    ITenantCustomBootstrapCode
} from "sirius-shell-support-library/tenants/custom-code/tenant-custom-bootstrap-code.interface";
import {TenantLibraryConfig} from "sirius-platform-support-library/tenants/tenant-context";
import {retry} from "sirius-platform-support-library/utilities/promises/retry-promise";
import {TENANT_CUSTOM_CODE_BUNDLE_URL_PLACEHOLDER} from "../../../sirius.config.constants";

export const TenantCustomBootstrapCodeLoaderTypeName = 'TenantCustomBootstrapCodeLoader';

export class TenantCustomBootstrapCodeLoader implements ITenantCustomBootstrapCodeLoader {
    private static preloadingPromises: Record<string, Promise<any>> = {};
    private static preloadedTenantCustomBootstrapCodeModules: Record<string, ITenantCustomBootstrapCodeModule> = {};

    private readonly systemJs: SystemJs;

    public constructor(systemJs: SystemJs) {
        this.systemJs = systemJs;
    }

    public static async preload(systemJs: SystemJs, failFast: boolean = true): Promise<void> {
        const siriusConfig = (window as any)?.sirius?.config;
        const tenantCustomCodeBundleUrl = siriusConfig?.tenantCustomCodeUrl !== TENANT_CUSTOM_CODE_BUNDLE_URL_PLACEHOLDER ? siriusConfig?.tenantCustomCodeUrl : undefined;
        if (!tenantCustomCodeBundleUrl || TenantCustomBootstrapCodeLoader.preloadingPromises[tenantCustomCodeBundleUrl] || TenantCustomBootstrapCodeLoader.preloadedTenantCustomBootstrapCodeModules[tenantCustomCodeBundleUrl]) {
            return;
        }

        const localPreload = async (url: string) => {
            if (!TenantCustomBootstrapCodeLoader.preloadedTenantCustomBootstrapCodeModules[url]) {
                try {
                    TenantCustomBootstrapCodeLoader.preloadedTenantCustomBootstrapCodeModules[url] = await TenantCustomBootstrapCodeLoader.getTenantCustomBootstrapCodeModule(systemJs, url, failFast);
                } catch (e) {
                    console.warn('Failed to preload tenant custom bootstrap code module', e, url);
                    TenantCustomBootstrapCodeLoader.preloadedTenantCustomBootstrapCodeModules[url] = undefined;
                }
            }
            
            return TenantCustomBootstrapCodeLoader.preloadedTenantCustomBootstrapCodeModules[url];
        }

        if (!TenantCustomBootstrapCodeLoader.preloadingPromises[tenantCustomCodeBundleUrl]) {
            TenantCustomBootstrapCodeLoader.preloadingPromises[tenantCustomCodeBundleUrl] = localPreload(tenantCustomCodeBundleUrl);
        }
    }

    public static async getTenantCustomBootstrapCodeModule(systemJs: SystemJs, tenantCustomCodeBundleUrl: string, failFast: boolean = true): Promise<ITenantCustomBootstrapCodeModule | undefined> {
        return failFast ? await systemJs.import<ITenantCustomBootstrapCodeModule>(tenantCustomCodeBundleUrl) : await retry(() => systemJs.import<ITenantCustomBootstrapCodeModule>(tenantCustomCodeBundleUrl), {
            retries: 3,
            backoff: "EXPONENTIAL",
            maxBackOff: 5 * 1000
        });
    }

    public async load(tenantCustomCodeLibrary: TenantLibraryConfig, failFast: boolean = false): Promise<ITenantCustomBootstrapCode> {
        const url = tenantCustomCodeLibrary?.url;
        if (!url) {
            return undefined;
        }

        let tenantCustomBootstrapCodeModule = TenantCustomBootstrapCodeLoader.preloadedTenantCustomBootstrapCodeModules[url];
        if (!tenantCustomBootstrapCodeModule) {
            const promise = TenantCustomBootstrapCodeLoader.preloadingPromises[url];
            if (promise) {
                tenantCustomBootstrapCodeModule = await promise;
            }
            if (!tenantCustomBootstrapCodeModule) {
                tenantCustomBootstrapCodeModule = await TenantCustomBootstrapCodeLoader.getTenantCustomBootstrapCodeModule(this.systemJs, url, failFast);
            }
        }

        if (!tenantCustomBootstrapCodeModule?.getCustomBootstrapCode) {
            tenantCustomBootstrapCodeModule = await TenantCustomBootstrapCodeLoader.getTenantCustomBootstrapCodeModule(this.systemJs, url, failFast);
        }

        const customBootstrapCode = tenantCustomBootstrapCodeModule?.getCustomBootstrapCode();
        if (!customBootstrapCode) {
            console.warn(`Could not get the TenantCustomBootstrapCode from the TenantCustomBootstrapCodeFactory in ${tenantCustomCodeLibrary?.url}.`);
        }

        return customBootstrapCode;
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {InternalDependencyRegistry} from "./internal-dependency-registry";
import {
    ITypedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-container.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {TypedInstanceFactory} from "sirius-platform-support-library/dependency-injection/typed/typed-instance-factory";
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {StringUtils} from "sirius-platform-support-library/utilities/string-utils";

export class RootDependencyContainer implements ITypedDependencyContainer {
    public static readonly GLOBAL_KEY = 'window.sirius.shell.dependencies';
    public static readonly DEFAULT_MISSING_FACTORY_ERROR = 'Please provide a factory method.';
    private readonly registry: InternalDependencyRegistry;

    private constructor() {
        this.registry = new InternalDependencyRegistry();

        SharedDependencyContainer.initialize(this);
    }

    public static getInstance(): ITypedDependencyContainer {
        let instance = ObjectUtility.getFromObjectPath<ITypedDependencyContainer>(RootDependencyContainer.GLOBAL_KEY);
        if (!instance) {
            instance = new RootDependencyContainer();
            ObjectUtility.assignOnObjectPath(RootDependencyContainer.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public addUsingInstanceAsSingleton<TImplementation>(dependencyType: DependencyType, instance: TImplementation): ITypedServiceRegistry
    public addUsingInstanceAsSingleton<TImplementation>(dependencyType: DependencyType, implementationType: string, instance: TImplementation): ITypedServiceRegistry
    public addUsingInstanceAsSingleton<TInterface, TImplementation>(dependencyType: DependencyType, interfaceType: string, implementationType: string, instance: TImplementation): ITypedServiceRegistry;
    public addUsingInstanceAsSingleton<TInterface, TImplementation>(...args: any[]): ITypedServiceRegistry {
        const dependencyType = args[0] as DependencyType;
        let interfaceType: string = undefined;
        let implementationType: string = undefined;
        let instance: TImplementation = undefined;
        if (args.length === 4) {
            interfaceType = args[1] as string;
            implementationType = args[2] as string;
            instance = args[3] as TImplementation;
        } else if (args.length === 3) {
            implementationType = args[1] as string;
            instance = args[2] as TImplementation;
        } else if (args.length === 2) {
            instance = args[1] as TImplementation;
            if (typeof (instance as any)?.getClassName == 'function') {
                implementationType = (instance as any).getClassName();
            }
            if (!implementationType) {
                implementationType = (instance as any)?.constructor?.name;
                if (!implementationType) {
                    implementationType = StringUtils.generateRandomString(8, false);
                }
                implementationType = `proxy-${implementationType}`;
            }
        }
        this.assertValidInstance<TImplementation>(implementationType, instance);
        this.registry.registerInstance(interfaceType, implementationType, instance, dependencyType);
        return this;
    }

    public addUsingFactoryAsSingleton<TImplementation>(dependencyType: DependencyType, implementationType: string, factory: TypedInstanceFactory<TImplementation>): ITypedServiceRegistry;
    public addUsingFactoryAsSingleton<TInterface, TImplementation>(dependencyType: DependencyType, interfaceType: string, implementationType: string, factory: TypedInstanceFactory<TImplementation>): ITypedServiceRegistry;
    public addUsingFactoryAsSingleton<TInterface, TImplementation>(...args: any[]): ITypedServiceRegistry {
        const dependencyType = args[0] as DependencyType;
        let interfaceType: string = undefined;
        let implementationType: string = undefined;
        let factory: TypedInstanceFactory<TImplementation> = undefined;
        if (args.length === 4) {
            interfaceType = args[1] as string;
            implementationType = args[2] as string;
            factory = args[3] as TypedInstanceFactory<TImplementation>;
        } else if (args.length === 3) {
            implementationType = args[1] as string;
            factory = args[2] as TypedInstanceFactory<TImplementation>;
        }
        this.assertValidFactory<TImplementation>(factory);
        this.registry.registerFactory(interfaceType, implementationType, factory, dependencyType);
        return this;
    }

    public addInterfacesOnSingleton(implementationType: string, ...interfacesTypes: string[]): ITypedServiceRegistry {
        this.registry.registerInterfacesOnImplementationType(implementationType, ...interfacesTypes);
        return this;
    }

    public register(bulk: (dependencyContainer: ITypedServiceRegistry) => void) {
        if (bulk) {
            bulk(this);
        }
        return this;
    }

    public registerModule(dependencyModule: TypedDependencyModule): ITypedDependencyContainer {
        if (dependencyModule) {
            dependencyModule.register(this);
        }
        return this;
    }

    public getServiceCollection(): ITypedServiceCollection {
        return this.registry;
    }

    private assertValidFactory<TImplementation>(factory: TypedInstanceFactory<TImplementation>) {
        if (!factory) {
            throw Error(RootDependencyContainer.DEFAULT_MISSING_FACTORY_ERROR);
        }
    }

    private assertValidInstance<TImplementation>(implementationType: string, instance: TImplementation) {
        const ERROR_MESSAGE = `Invalid instance provided for ${implementationType} type.`;
        if (!instance) {
            throw Error(ERROR_MESSAGE);
        }
    }
}

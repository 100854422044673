/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    ISiriusIconsRegistry,
    ISiriusIconsRegistryTypeName
} from "sirius-platform-support-library/shared/icons/registry/sirius-icons-registry.interface";
import {
    SiriusIconsRegistry,
    SiriusIconsRegistryTypeName
} from "sirius-platform-support-library/shared/icons/registry/sirius-icons.registry";
import {IconsImporterTypeName, IconsRegistryConfigurator} from "./registry/icons-registry.configurator";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {
    IIconLoader,
    IIconLoaderTypeName
} from "sirius-platform-support-library/shared/icons/loaders/icon-loader.interface";
import {ClrIconsLoader, ClrIconsLoaderTypeName} from "./loaders/clr/clr-icons.loader";
import {CdsIconsLoader, CdsIconsLoaderTypeName} from "./loaders/cds/cds-icons.loader";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {CdsIconParser, CdsIconParserTypeName} from "./loaders/cds/cds-icon.parser";
import {UxdIconParser, UxdIconParserTypeName} from "./loaders/uxd/uxd-icon.parser";
import {UxdIconsLoader, UxdIconsLoaderTypeName} from "./loaders/uxd/uxd-icons.loader";
import {DdsIconsLoader, DdsIconsLoaderTypeName} from "./loaders/dds/dds-icons.loader";

export class IconsRegistryDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IIconLoader, ClrIconsLoader>(DependencyType.PRIVATE, IIconLoaderTypeName, ClrIconsLoaderTypeName, new ClrIconsLoader())
            .addUsingInstanceAsSingleton<CdsIconParser>(DependencyType.PRIVATE, CdsIconParserTypeName, new CdsIconParser())
            .addUsingFactoryAsSingleton<IIconLoader, CdsIconsLoader>(DependencyType.PRIVATE, IIconLoaderTypeName, CdsIconsLoaderTypeName, (di) => {
                const iconParse = di.resolve<CdsIconParser>(CdsIconParserTypeName);
                return new CdsIconsLoader(
                    iconParse
                );
            })
            .addUsingInstanceAsSingleton<UxdIconParser>(DependencyType.PRIVATE, UxdIconParserTypeName, new UxdIconParser())
            .addUsingFactoryAsSingleton<IIconLoader, UxdIconsLoader>(DependencyType.PRIVATE, IIconLoaderTypeName, UxdIconsLoaderTypeName, (di) => {
                const iconParse = di.resolve<UxdIconParser>(UxdIconParserTypeName);
                return new UxdIconsLoader(
                    iconParse
                );
            })
            .addUsingInstanceAsSingleton<IIconLoader, DdsIconsLoader>(DependencyType.PRIVATE, IIconLoaderTypeName, DdsIconsLoaderTypeName, new DdsIconsLoader())
            .addUsingInstanceAsSingleton<ISiriusIconsRegistry, SiriusIconsRegistry>(DependencyType.SHARED, ISiriusIconsRegistryTypeName, SiriusIconsRegistryTypeName, SiriusIconsRegistry.getInstance())
            .addUsingFactoryAsSingleton<IconsRegistryConfigurator>(DependencyType.PRIVATE, IconsImporterTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const iconsRegistry = di.resolve<ISiriusIconsRegistry>(ISiriusIconsRegistryTypeName);
                return new IconsRegistryConfigurator(
                    tenant,
                    iconsRegistry,
                    di
                );
            })
            .addInterfacesOnSingleton(IconsImporterTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const iconsRegistryDependencyModule = new IconsRegistryDependencyModule();


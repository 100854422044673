import {
    IThemeVariantHandler,
    IThemeVariantHandlerTypeName
} from "sirius-platform-support-library/shared/theming/management/handlers/theme-variant-handler.interface";
import {RuntimeThemeVariant} from "sirius-platform-support-library/shared/theming/models/runtime-theme-variant.model";
import {IBeforePlatformReadyInit} from "../../../initializer/before-platform-ready-init.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {InternalThemeVariant} from "../../models/internal-theme-variant.model";
import {IThemingService} from "sirius-platform-support-library/shared/theming/theming-service.interface";
import {
    IThemeHandler,
    IThemeHandlerTypeName
} from "sirius-platform-support-library/shared/theming/management/handlers/theme-handler.interface";
import {
    IThemingHandlersManager,
    ThemeVariantPropertiesChangedCallback
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {
    IBaseThemingHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/base-theming-handler.interface";
import {ThemeChangedEvent} from "sirius-platform-support-library/shared/theming/events/theming.events";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    ThemingHandlersManagerEvents
} from "sirius-platform-support-library/shared/theming/management/managers/events/theme-variant-properties-changed.events";
import {
    ThemeVariantPropertiesChangedEvent
} from "sirius-platform-support-library/shared/theming/management/managers/events/theme-variant-properties-changed.event";
import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";
import {ThemeVariantProperties} from "sirius-platform-support-library/models/theming/theme-variant-properties.model";

export const ThemingHandlersManagerTypeName = 'ThemingHandlersManager';

export class ThemingHandlersManager implements IThemingHandlersManager, IBeforePlatformReadyInit {
    private static readonly COMPONENT_CODE_PLACEHOLDER = '{{componentCode}}';

    private readonly eventBus: IEventBus;
    private readonly themingService: IThemingService;
    private readonly serviceCollection: IServiceCollection;

    private readonly registry: Record<string, Record<string, IBaseThemingHandler>> = {};

    private themeChangedSubscription?: IEventSubscription;
    private themeVariant?: RuntimeThemeVariant;

    public constructor(
        eventBus: IEventBus,
        themingService: IThemingService,
        serviceCollection: IServiceCollection
    ) {
        this.eventBus = eventBus;
        this.themingService = themingService;
        this.serviceCollection = serviceCollection;
    }

    public async preInit(): Promise<void> {
        await this.initialize();
    }

    public async init(): Promise<void> {
        await this.initialize();
    }

    public async attach(componentCode: ThemedComponent | string, referenceNode: Element | ParentNode, injectionType: DomInjectionType = DomInjectionType.APPEND): Promise<void> {
        componentCode = componentCode.toString().toLowerCase();
        const handlers = Object.values(this.registry)
            .flatMap((themeHandlers: Record<string, IBaseThemingHandler>) => Object.values(themeHandlers))
            .filter((handler: IBaseThemingHandler) => handler.getComponentCode().toLowerCase() === componentCode);
        Array.from(new Set(handlers)).forEach((handler: IBaseThemingHandler) => {
            this.callApplyOnHandler(handler, this.themeVariant);
            this.internalAttach(handler, referenceNode, injectionType);
        });
    }

    public apply(themeVariant: RuntimeThemeVariant): Promise<void> {
        if (!themeVariant?.id) {
            return;
        }
        this.themeVariant = themeVariant;
        const components = this.registry[themeVariant.id];
        if (!components) {
            return;
        }
        Object.values(components).forEach((themeHandler: IThemeVariantHandler) => {
            this.callApplyOnHandler(themeHandler, themeVariant);
            this.triggerThemeVariantPropertiesChangedEvent(themeHandler.getComponentCode());
        });
    }

    public registerThemeHandler(themeHandler: IThemeHandler): void {
        this.validateThemeHandler(themeHandler);
        this.internalRegisterThemeHandler(themeHandler, this.themingService.getCurrentTheme());
    }

    public unregisterThemeHandler(themeHandler: IThemeHandler): void {
        if (!themeHandler) {
            return;
        }
        themeHandler.getVariantsCodes().forEach((variantCode: string) => {
            const componentCode = themeHandler.getComponentCode().toLowerCase();
            const themeVariantId = InternalThemeVariant.generateId(themeHandler.getThemeCode().toLowerCase(), variantCode.toLowerCase());
            this.removeComponentHandler(componentCode, themeVariantId);
        });
    }

    public registerThemeVariantHandler(themeVariantHandler: IThemeVariantHandler): void {
        this.validateThemeVariantHandler(themeVariantHandler);
        this.internalRegisterThemeVariantHandler(themeVariantHandler, this.themingService.getCurrentTheme());
    }

    public unregisterThemeVariantHandler(themeVariantHandler: IThemeVariantHandler): void {
        const componentCode = themeVariantHandler.getComponentCode().toLowerCase();
        const themeVariantId = InternalThemeVariant.generateId(themeVariantHandler.getThemeCode().toLowerCase(), themeVariantHandler.getVariantCode().toLowerCase());
        this.removeComponentHandler(componentCode, themeVariantId);
    }

    public getThemeVariantProperties(componentCode: ThemedComponent | string): ThemeVariantProperties | any | undefined {
        componentCode = componentCode.toString().toLowerCase();
        const handler = this.findHandler(componentCode, this.themeVariant);
        try {
            return handler?.getVariantProperties?.();
        } catch (e) {
            console.error('Failed to get theme variant properties from handler', handler, e);
            return undefined;
        }
    }

    public onThemeVariantPropertiesChanged(context: any, subscriberName: string, componentCode: ThemedComponent | string, callback: ThemeVariantPropertiesChangedCallback): IEventSubscription {
        componentCode = componentCode.toString().toLowerCase();
        const eventName = ThemingHandlersManagerEvents.THEME_VARIANT_PROPERTIES_CHANGED_EVENT_TEMPLATE.replace(ThemingHandlersManager.COMPONENT_CODE_PLACEHOLDER, componentCode);
        return this.eventBus.registerBroadcast<ThemeVariantPropertiesChangedEvent>(this, subscriberName, eventName, (event: IEvent<ThemeVariantPropertiesChangedEvent>) => {
            callback?.call(context, event?.data);
        });
    }

    private async initialize(): Promise<void> {
        this.themeVariant = this.themingService.getCurrentTheme();
        this.addKnownThemeVariantsToRegistry();
        this.addKnownHandlersToRegistry(this.themeVariant);
        this.bind();
    }

    private bind(): void {
        if (this.themeChangedSubscription) {
            return;
        }
        this.themeChangedSubscription = this.themingService.onThemeChanged(this, ThemingHandlersManagerTypeName, this.onThemeChanged.bind(this));
    }

    private validateThemeHandler(themeHandler: IThemeHandler): void {
        if (!themeHandler) {
            throw new Error('Theme handler is not defined');
        }

        if (!themeHandler.getThemeCode()) {
            throw new Error('Theme handler does not provide a valid theme code');
        }

        if ((themeHandler.getVariantsCodes() ?? []).some((variantCode: string) => !variantCode)) {
            throw new Error('Theme handler does not provide any valid theme variant codes');
        }

        if (!themeHandler.getComponentCode()) {
            throw new Error('Theme handler does not provide a valid component code');
        }
    }

    private validateThemeVariantHandler(themeVariantHandler: IThemeVariantHandler): void {
        if (!themeVariantHandler) {
            throw new Error('Theme variant handler is not defined');
        }

        if (!themeVariantHandler.getThemeCode()) {
            throw new Error('Theme variant handler does not provide a valid theme code');
        }

        if (!themeVariantHandler.getVariantCode()) {
            throw new Error('Theme variant handler does not provide a valid variant code');
        }

        if (!themeVariantHandler.getComponentCode()) {
            throw new Error('Theme variant handler does not provide a valid component code');
        }
    }

    private addKnownThemeVariantsToRegistry(): void {
        this.themingService.getAvailableThemes().forEach((themeVariant: RuntimeThemeVariant) => {
            if (!this.registry[themeVariant.id]) {
                this.registry[themeVariant.id] = {};
            }
        });
    }

    private internalRegisterThemeHandler(themeHandler: IThemeHandler, themeVariant: RuntimeThemeVariant): void {
        themeHandler.getVariantsCodes().forEach((variantCode: string) => {
            this.internalRegisterBaseThemeHandler(themeHandler, variantCode, themeVariant);
        });
    }

    private internalRegisterThemeVariantHandler(themeVariantHandler: IThemeVariantHandler, themeVariant: RuntimeThemeVariant): void {
        this.internalRegisterBaseThemeHandler(themeVariantHandler, themeVariantHandler.getVariantCode(), themeVariant);
    }

    private internalRegisterBaseThemeHandler(baseThemeHandler: IBaseThemingHandler, variantCode: string, themeVariant: RuntimeThemeVariant): void {
        const themeVariantId = InternalThemeVariant.generateId(baseThemeHandler.getThemeCode().toLowerCase(), variantCode.toLowerCase());
        let themeComponents = this.registry[themeVariantId];
        if (!themeComponents) {
            themeComponents = {}
            this.registry[themeVariantId] = themeComponents;
        }
        const componentCode = baseThemeHandler.getComponentCode().toLowerCase();
        themeComponents[componentCode] = baseThemeHandler;
        this.callApplyOnHandler(baseThemeHandler, themeVariant);
    }

    private internalAttach(handler: IBaseThemingHandler, referenceNode: Element | ParentNode, injectionType: DomInjectionType = DomInjectionType.APPEND): void {
        try {
            handler?.attach?.(referenceNode, injectionType)
                .catch((error: any) => this.handleAttachError(handler, error));
        } catch (error) {
            this.handleAttachError(handler, error);
        }
    }

    private addKnownHandlersToRegistry(themeVariant: RuntimeThemeVariant): void {
        const themeHandlers = this.serviceCollection.resolveAll<IThemeHandler>(IThemeHandlerTypeName);
        themeHandlers.forEach((themeHandler: IThemeHandler) => {
            try {
                this.validateThemeHandler(themeHandler);
                this.internalRegisterThemeHandler(themeHandler, themeVariant);
            } catch (e) {
                console.error(`Failed to register theme handler ${themeHandler.getComponentCode()} for theme ${themeHandler.getThemeCode()}`, e);
            }
        });
        const themeVariantHandlers = this.serviceCollection.resolveAll<IThemeVariantHandler>(IThemeVariantHandlerTypeName);
        themeVariantHandlers.forEach((themeVariantHandler: IThemeVariantHandler) => {
            try {
                this.validateThemeVariantHandler(themeVariantHandler);
                this.internalRegisterThemeVariantHandler(themeVariantHandler, themeVariant);
            } catch (e) {
                console.error(`Failed to register theme variant handler ${themeVariantHandler.getComponentCode()} for theme ${themeVariantHandler.getThemeCode()} and variant ${themeVariantHandler.getVariantCode()}`, e);
            }
        });
    }

    private async onThemeChanged(event: ThemeChangedEvent): Promise<void> {
        const themeVariant = event?.theme;
        if (!themeVariant) {
            return;
        }
        await this.apply(themeVariant);
    }

    private callApplyOnHandler(handler: IBaseThemingHandler, themeVariant: RuntimeThemeVariant): void {
        try {
            handler?.apply(themeVariant)
                .catch((error: any) => this.handleApplyError(handler, themeVariant, error));
        } catch (error) {
            this.handleApplyError(handler, themeVariant, error);
        }
    }

    private removeComponentHandler(componentCode: string, themeVariantId: string): void {
        const components = this.registry[themeVariantId];
        if (!components) {
            return;
        }
        delete components[componentCode];
    }

    private findHandler(componentCode: string, themeVariant: RuntimeThemeVariant): IBaseThemingHandler | undefined {
        const components = this.registry[themeVariant.id];
        if (!components) {
            return;
        }
        return components[componentCode];
    }

    private triggerThemeVariantPropertiesChangedEvent(componentCode: ThemedComponent | string): void {
        componentCode = componentCode.toString().toLowerCase();
        const properties = this.getThemeVariantProperties(componentCode);
        if (!properties) {
            return;
        }
        const eventName = ThemingHandlersManagerEvents.THEME_VARIANT_PROPERTIES_CHANGED_EVENT_TEMPLATE.replace(ThemingHandlersManager.COMPONENT_CODE_PLACEHOLDER, componentCode);
        this.eventBus.dispatchBroadcast<ThemeVariantPropertiesChangedEvent>(ThemingHandlersManagerTypeName, eventName, {
            componentCode: componentCode,
            properties: properties
        });
    }

    private handleApplyError(handler: IBaseThemingHandler, themeVariant: RuntimeThemeVariant, error: any): void {
        console.error(`Failed to apply theme variant ${themeVariant.name} to component ${handler.getComponentCode()}`, error);
    }

    private handleAttachError(handler: IBaseThemingHandler, error: any): void {
        console.error(`Failed to attach handler ${handler.getComponentCode()} to component ${handler.getComponentCode()}`, error);
    }
}


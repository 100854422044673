/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    RequestedProgressIndicator,
    VisibleProgressIndicator
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicator";
import {
    IProgressIndicatorsService
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicators-service.interface";
import {ProgressIndicatorsDomHandler} from "./dom/progress-indicators-dom-handler";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {
    ProgressIndicatorsConstants
} from "sirius-platform-support-library/shared/progress-indicators/progress-indicators.constants";
import {RendarableProgressIndicator} from "./rendarable-progress-indicator";
import {
    ProgressIndicatorHiddenEvent
} from "sirius-platform-support-library/shared/progress-indicators/events/progress-indicator-hidden.event";
import {
    ProgressIndicatorVisibleEvent
} from "sirius-platform-support-library/shared/progress-indicators/events/progress-indicator-visible.event";

import {
    ProgressIndicatorsEvents
} from "sirius-platform-support-library/shared/progress-indicators/events/progress-indicators-events";
import {
    ILoadingIndicatorService
} from "sirius-platform-support-library/shared/loading-indicators/loading-indicator-service.interface";

export const ProgressIndicatorsServiceTypeName = 'ProgressIndicatorsService';

export class ProgressIndicatorsService implements IProgressIndicatorsService, IAfterPlatformReadyInit {

    private readonly window: Window;
    private readonly localizationService: ILocalizationService;
    private readonly eventBus: IEventBus;
    private readonly loadingIndicatorService: ILoadingIndicatorService;
    private readonly serviceCollection: IServiceCollection;
    private readonly domHandler: ProgressIndicatorsDomHandler;
    private readonly progressIndicators: RendarableProgressIndicator[];
    private currentProgressIndicator: RendarableProgressIndicator;

    constructor(
        window: Window,
        document: Document,
        localizationService: ILocalizationService,
        eventBus: IEventBus,
        loadingIndicatorService: ILoadingIndicatorService,
        serviceCollection: IServiceCollection
    ) {
        this.progressIndicators = [];
        this.window = window;
        this.localizationService = localizationService;
        this.eventBus = eventBus;
        this.loadingIndicatorService = loadingIndicatorService;
        this.serviceCollection = serviceCollection;
        this.domHandler = new ProgressIndicatorsDomHandler(document);
    }

    public static build(
        window: Window,
        document: Document,
        localizationService: ILocalizationService,
        eventBus: IEventBus,
        loadingIndicatorService: ILoadingIndicatorService,
        serviceCollection: IServiceCollection
    ): ProgressIndicatorsService {
        let instance = ObjectUtility.getFromObjectPath<ProgressIndicatorsService>(ProgressIndicatorsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new ProgressIndicatorsService(
                window,
                document,
                localizationService,
                eventBus,
                loadingIndicatorService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(ProgressIndicatorsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IProgressIndicatorsService {
        return ObjectUtility.getFromObjectPath<IProgressIndicatorsService>(ProgressIndicatorsConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        await this.domHandler.init();
        this.bind();
    }

    public async show(context: any, progressIndicator: RequestedProgressIndicator): Promise<VisibleProgressIndicator> {
        if (this.loadingIndicatorService.isShown()) {
            throw new Error('Loading indicator is already shown. You cannot push a progress indicator when there is already an active loading indicator.');
        }
        if (!context) {
            throw new Error('Please provide a valid context');
        }
        if (this.isBlockingProgressIndicatorVisible(progressIndicator)) {
            throw new Error('You cannot push a progress indicator when there is already an active progress indicator.');
        }
        const target = this.domHandler.getTargetElement(progressIndicator);
        const renderingProgressIndicator = new RendarableProgressIndicator(
            document,
            context,
            progressIndicator,
            target,
            this.localizationService,
            this.serviceCollection
        );
        await renderingProgressIndicator.localize();
        this.progressIndicators.push(renderingProgressIndicator);
        this.currentProgressIndicator = renderingProgressIndicator;
        await this.domHandler.render(this.currentProgressIndicator);
        this.applyStyles(renderingProgressIndicator);
        this.triggerProgressIndicatorVisibleEvent(renderingProgressIndicator);
        return renderingProgressIndicator.toVisibleProgressIndicator();
    }

    public async hide(progressIndicator: VisibleProgressIndicator): Promise<void> {
        await this.hideById(progressIndicator?.id);
    }

    public async hideById(id: string): Promise<void> {
        await this.removeProgressIndicatorById(id);
    }

    public async hideAll(): Promise<void> {
        this.domHandler.clear();
        this.progressIndicators.splice(0, this.progressIndicators.length);
        this.removeStyles();
    }

    public async update(context, progressIndicator: VisibleProgressIndicator): Promise<VisibleProgressIndicator> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }
        const renderingProgressIndicator = this.getProgressIndicatorById(progressIndicator.id);
        if (!renderingProgressIndicator) {
            throw `Progress indicator with ID: ${progressIndicator.id}, could not be found.`;
        }
        renderingProgressIndicator.localizationCode = progressIndicator.localizationCode;
        renderingProgressIndicator.progress = progressIndicator.progress;
        await renderingProgressIndicator.localize();
        this.domHandler.update(renderingProgressIndicator);
        return renderingProgressIndicator.toVisibleProgressIndicator();
    }

    public anyProgressIndicatorVisible(): boolean {
        return this.progressIndicators.length > 0;
    }

    public isProgressIndicatorVisible(progressIndicator: VisibleProgressIndicator): boolean {
        return this.isProgressIndicatorWithIdVisible(progressIndicator?.id);
    }

    public isProgressIndicatorWithIdVisible(progressIndicatorId: string): boolean {
        return this.progressIndicators.findIndex(progressIndicator => progressIndicator.id === progressIndicatorId) > -1;
    }

    public isBlockingProgressIndicatorVisible(progressIndicator: RequestedProgressIndicator): boolean {
        const hasActive = this.progressIndicators.findIndex((pi) => {
            return (pi.blocking === progressIndicator.blocking) ||
                (pi.blockContentOnly && this.progressIndicators.length > 0);
        })
        return hasActive >= 0;
    }

    private bind(): void {
        this.localizationService.onLocaleChanged(this, 'ProgressIndicatorsService', this.onLocaleChanged);
    }

    private async onLocaleChanged(): Promise<void> {
        await Promise.allSettled(this.progressIndicators.map(async (progressIndicator: RendarableProgressIndicator) => {
            await progressIndicator.localize();
            this.domHandler.update(progressIndicator);
        }));
    }

    private async removeProgressIndicatorById(progressIndicatorId: string): Promise<void> {
        const currentIndex = this.getIndexOfProgressIndicatorById(progressIndicatorId);
        if (currentIndex < 0) {
            return;
        }
        const progressIndicator = this.progressIndicators.find(d => d.id === progressIndicatorId);
        this.progressIndicators.splice(currentIndex, 1);
        await this.domHandler.removeProgressIndicatorById(progressIndicatorId);
        this.triggerProgressIndicatorHiddenEvent(progressIndicator);
        this.removeStyles(progressIndicator);
    }

    private getIndexOfProgressIndicatorById(id: string): number {
        if (!id) {
            return -1;
        }
        return this.progressIndicators.findIndex(progressIndicator => progressIndicator.id === id);
    }

    private getProgressIndicatorById(id: string): RendarableProgressIndicator {
        if (!id) {
            return undefined;
        }
        return this.progressIndicators.find(progressIndicator => progressIndicator.id === id);
    }

    private triggerProgressIndicatorHiddenEvent(progressIndicator: RendarableProgressIndicator): void {
        this.eventBus.dispatchBroadcast<ProgressIndicatorHiddenEvent>('ProgressIndicatorsService', ProgressIndicatorsEvents.PROGRESS_INDICATOR_HIDDEN, {
            progressIndicator: progressIndicator.toVisibleProgressIndicator()
        });
    }

    private triggerProgressIndicatorVisibleEvent(progressIndicator: RendarableProgressIndicator): void {
        this.eventBus.dispatchBroadcast<ProgressIndicatorVisibleEvent>('ProgressIndicatorsService', ProgressIndicatorsEvents.PROGRESS_INDICATOR_VISIBLE, {
            progressIndicator: progressIndicator.toVisibleProgressIndicator()
        });
    }

    private applyStyles(renderingProgressIndicator: RendarableProgressIndicator): void {
        this.domHandler.addActiveClass(renderingProgressIndicator);
    }

    private removeStyles(renderingProgressIndicator?: RendarableProgressIndicator): void {
        if (renderingProgressIndicator) {
            this.domHandler.removeActiveClass(renderingProgressIndicator);
        } else {
            this.domHandler.removeAnyActiveClass();
        }
    }
}

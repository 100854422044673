/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    IThemesProvider,
    IThemesProviderTypeName
} from "sirius-platform-support-library/shared/theming/providers/themes-provider.interface";
import {DefaultThemesProvider, DefaultThemesProviderTypeName} from "./providers/defaults/default-themes.provider";
import {
    RuntimeOptionsThemesProvider,
    RuntimeOptionsThemesProviderTypeName
} from "./providers/runtime-options/runtime-options-themes.provider";
import {
    RuntimeConfigurationLoader,
    RuntimeConfigurationLoaderTypeName
} from "../runtime-configuration/runtime-configuration.loader";
import {ThemingService, ThemingServiceTypeName} from "./theming.service";
import {
    IThemingService,
    IThemingServiceTypeName
} from "sirius-platform-support-library/shared/theming/theming-service.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {
    IThemeVariantSelector,
    IThemeVariantSelectorTypeName
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selector.interface";
import {
    UserPreferenceThemeVariantSelector,
    UserPreferenceThemeVariantSelectorTypeName
} from "./selectors/user-preference/user-preference-theme-variant.selector";
import {
    IThemeVariantSelectorStoreTypeName
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selector-store.interface";
import {
    IThemeVariantSelectorsPriorityAssigner,
    IThemeVariantSelectorsPriorityAssignerTypeName
} from "sirius-platform-support-library/shared/theming/selectors/theme-variant-selectors-priority-assigner.interface";
import {
    DefaultThemeVariantSelectorsPriorityAssigner,
    DefaultThemeVariantSelectorsPriorityAssignerTypeName
} from "./selectors/default-theme-variant-selectors.priority-assigner";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {ThemingHandlersManager, ThemingHandlersManagerTypeName,} from "./management/managers/theming-handlers.manager";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    TenantContextDellThemeVariantPropertiesCustomizer,
    TenantContextDellThemeVariantPropertiesCustomizerTypeName
} from "./customizers/tenant-context-dell-theme-variant-properties.customizer";
import {
    IThemeVariantPropertiesCustomizer,
    IThemeVariantPropertiesCustomizerTypeName
} from "sirius-platform-support-library/shared/theming/customizers/theme-variant-properties-customizer.interface";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export class ThemingDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IThemesProvider, DefaultThemesProvider>(DependencyType.PRIVATE, IThemesProviderTypeName, DefaultThemesProviderTypeName, new DefaultThemesProvider())
            .addUsingFactoryAsSingleton<IThemesProvider, RuntimeOptionsThemesProvider>(DependencyType.PRIVATE, IThemesProviderTypeName, RuntimeOptionsThemesProviderTypeName, (di) => {
                const runtimeConfigurationLoader = di.resolve<RuntimeConfigurationLoader>(RuntimeConfigurationLoaderTypeName);
                return new RuntimeOptionsThemesProvider(
                    runtimeConfigurationLoader
                );
            })
            .addUsingFactoryAsSingleton<IThemeVariantSelector, UserPreferenceThemeVariantSelector>(DependencyType.PRIVATE, IThemeVariantSelectorTypeName, UserPreferenceThemeVariantSelectorTypeName, (di) => {
                return new UserPreferenceThemeVariantSelector(
                    window
                );
            })
            .addInterfacesOnSingleton(DefaultThemesProviderTypeName, IThemeVariantSelectorStoreTypeName)
            .addUsingInstanceAsSingleton<IThemeVariantSelectorsPriorityAssigner, DefaultThemeVariantSelectorsPriorityAssigner>(DependencyType.PRIVATE, IThemeVariantSelectorsPriorityAssignerTypeName, DefaultThemeVariantSelectorsPriorityAssignerTypeName, new DefaultThemeVariantSelectorsPriorityAssigner())
            .addUsingFactoryAsSingleton<IThemingService, ThemingService>(DependencyType.SHARED, IThemingServiceTypeName, ThemingServiceTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const selectorsPriorityAssigner = di.resolve<IThemeVariantSelectorsPriorityAssigner>(IThemeVariantSelectorsPriorityAssignerTypeName);
                return ThemingService.build(
                    eventBus,
                    selectorsPriorityAssigner,
                    di
                );
            })
            .addInterfacesOnSingleton(ThemingServiceTypeName, IBeforePlatformReadyInitTypeName)
            .addUsingFactoryAsSingleton<IThemeVariantPropertiesCustomizer, TenantContextDellThemeVariantPropertiesCustomizer>(DependencyType.PRIVATE, IThemeVariantPropertiesCustomizerTypeName, TenantContextDellThemeVariantPropertiesCustomizerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                return new TenantContextDellThemeVariantPropertiesCustomizer(
                    tenant
                );
            })
            .addUsingFactoryAsSingleton<IThemingHandlersManager, ThemingHandlersManager>(DependencyType.SHARED, IThemingHandlersManagerTypeName, ThemingHandlersManagerTypeName, (di) => {
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const themingService = di.resolve<IThemingService>(IThemingServiceTypeName);
                return new ThemingHandlersManager(
                    eventBus,
                    themingService,
                    di
                );
            })
            .addInterfacesOnSingleton(ThemingHandlersManagerTypeName, IBeforePlatformReadyInitTypeName);
    }
}

export const themingDependencyModule = new ThemingDependencyModule();

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {AggregatedBrowserNavigationGateHandler} from "./aggregated-browser-navigation-gate.handler";
import {RoutingUtilities} from "sirius-platform-support-library/utilities/routing-utilities";
import {
    IDefaultPagesService
} from "sirius-platform-support-library/shared/site/default-pages/default-pages-service.interface";
import {AggregatedPageUnloadHandler} from "./aggregated-page-unload.service";
import {
    IBrowserNavigationService
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-service.interface";
import {
    PossibleGateChangeCallback
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events-receiver";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    PossibleGateChangedEvents
} from "sirius-platform-support-library/shared/browser-events/possible-gate-changed.events";
import {EventType} from "sirius-platform-support-library/shared/event-bus/event-type";
import {
    AuthorizationBrowserNavigationGateHandlerTypeName
} from "../authorization/routing-gates/authorization-browser-navigation-gate.handler";

export const PlatformBrowserNavigationServiceTypeName = 'PlatformBrowserNavigationService';

export class PlatformBrowserNavigationService implements IBrowserNavigationService {
    private static readonly NAVIGATION_BLOCKED_INTERNAL_EVENT = 'dell.sirius.browser-events.internal.navigation-blocked';
    private static readonly NAVIGATION_ALLOWED_INTERNAL_EVENT = 'dell.sirius.browser-events.internal.navigation-allowed';
    private static readonly BEFORE_NAVIGATION_INTERNAL_EVENT = 'dell.sirius.browser-events.internal.before-navigation';
    private static readonly AFTER_NAVIGATION_INTERNAL_EVENT = 'dell.sirius.browser-events.internal.after-navigation';
    private static readonly POSSIBLE_GATE_CHANGED_EVENT = 'dell.sirius.browser-events.internal.possible-gate-changed';

    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;
    private readonly aggregatedBrowserNavigationGateHandler: AggregatedBrowserNavigationGateHandler;
    private readonly aggregatedPageUnloadHandler: AggregatedPageUnloadHandler;
    private readonly defaultPagesService: IDefaultPagesService;

    private readonly suppressEventsForHandlers: string[] = [];

    private previousNavigationAllowedState: boolean;

    public constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        aggregatedBrowserNavigationHandler: AggregatedBrowserNavigationGateHandler,
        aggregatedPageUnloadHandler: AggregatedPageUnloadHandler,
        defaultPagesService: IDefaultPagesService
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.aggregatedBrowserNavigationGateHandler = aggregatedBrowserNavigationHandler;
        this.aggregatedPageUnloadHandler = aggregatedPageUnloadHandler;
        this.defaultPagesService = defaultPagesService;

        this.suppressEventsForHandlers.push(AuthorizationBrowserNavigationGateHandlerTypeName);

        this.bind();
    }

    public bind(): void {
        this.window.addEventListener(PlatformBrowserNavigationService.NAVIGATION_ALLOWED_INTERNAL_EVENT, async (event: CustomEvent) => {
            const url = event.detail?.url;
            const hardNavigation = event.detail?.hardNavigation;
            await this.onNavigationAllowed(url, hardNavigation);
        });
        this.window.addEventListener(PlatformBrowserNavigationService.NAVIGATION_BLOCKED_INTERNAL_EVENT, async (event: CustomEvent) => {
            const url = event.detail?.url;
            const hardNavigation = event.detail?.hardNavigation;
            await this.onNavigationBlocked(url, hardNavigation);
        });
        this.window.addEventListener(PlatformBrowserNavigationService.BEFORE_NAVIGATION_INTERNAL_EVENT, async (event: CustomEvent) => {
            const url = event.detail?.url;
            await this.onBeforeNavigation(url);
        });
        this.window.addEventListener(PlatformBrowserNavigationService.AFTER_NAVIGATION_INTERNAL_EVENT, async (event: CustomEvent) => {
            const url = event.detail?.url;
            await this.onAfterNavigation(url);
        });
        this.window.addEventListener(PlatformBrowserNavigationService.POSSIBLE_GATE_CHANGED_EVENT, async (event: CustomEvent) => {
            this.onBrowserNavigationGateHandlerRefreshRequested();
        });

        this.aggregatedBrowserNavigationGateHandler.onBrowserNavigationGateHandlerRefreshRequested(this.onBrowserNavigationGateHandlerRefreshRequested.bind(this));
    }

    public dispatchBeforeNavigationEvent(url?: string): void {
        url = RoutingUtilities.getRelativeUrlIfSameOrigin(this.window, url);
        this.window.dispatchEvent(new CustomEvent<any>(PlatformBrowserNavigationService.BEFORE_NAVIGATION_INTERNAL_EVENT, {
            detail: {
                url: url
            }
        }));
    }

    public isNavigationAllowed(url?: string, hardNavigation?: boolean): boolean {
        url = RoutingUtilities.getRelativeUrlIfSameOrigin(this.window, url);
        if (this.getDefaultPagesRoutes().findIndex(defaultRoute => RoutingUtilities.routeStartsWith(url, defaultRoute)) > -1) {
            return true;
        }
        const response = this.aggregatedBrowserNavigationGateHandler.isNavigationAllowed(url, !!hardNavigation);
        const currentNavigationAllowedState = response.isNavigationAllowed;
        const blockingHandlers = Array.from(response.blockingHandlers);
        const includesSuppressed = blockingHandlers.filter(bh => this.suppressEventsForHandlers.includes(bh)).length > 0;
        if (!includesSuppressed) {
            if (!this.previousNavigationAllowedState && currentNavigationAllowedState) {
                this.dispatchNavigationAllowedEvent(url, !!hardNavigation);
            } else if (!currentNavigationAllowedState) {
                this.dispatchNavigationBlockedEvent(url, !!hardNavigation);
            }
        }
        if (this.previousNavigationAllowedState != currentNavigationAllowedState) {
            this.dispatchPossibleGateChangeEvent();
        }
        this.previousNavigationAllowedState = currentNavigationAllowedState;
        return currentNavigationAllowedState;
    }

    public dispatchAfterNavigationEvent(url?: string): void {
        url = RoutingUtilities.getRelativeUrlIfSameOrigin(this.window, url);
        this.window.dispatchEvent(new CustomEvent<any>(PlatformBrowserNavigationService.AFTER_NAVIGATION_INTERNAL_EVENT, {
            detail: {
                url: url
            }
        }));
    }

    public onPageUnload(url?: string): void {
        this.aggregatedPageUnloadHandler.onPageUnload(url);
    }

    public onPossibleGateChange(context: any, subscriberName: string, possibleGateChangeCallback: PossibleGateChangeCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<any>(this, subscriberName, PossibleGateChangedEvents.NAVIGATION_POSSIBLE_GATE_CHANGE, () => {
            possibleGateChangeCallback?.call(context);
        });
    }

    private dispatchNavigationAllowedEvent(url?: string, hardNavigation?: boolean): void {
        url = RoutingUtilities.getRelativeUrlIfSameOrigin(this.window, url);
        this.window.dispatchEvent(new CustomEvent<any>(PlatformBrowserNavigationService.NAVIGATION_ALLOWED_INTERNAL_EVENT, {
            detail: {
                url: url,
                hardNavigation: hardNavigation
            }
        }));
    }

    private dispatchNavigationBlockedEvent(url?: string, hardNavigation?: boolean): void {
        url = RoutingUtilities.getRelativeUrlIfSameOrigin(this.window, url);
        this.window.dispatchEvent(new CustomEvent<any>(PlatformBrowserNavigationService.NAVIGATION_BLOCKED_INTERNAL_EVENT, {
            detail: {
                url: url,
                hardNavigation: hardNavigation
            }
        }));
    }

    private dispatchPossibleGateChangeEvent(): void {
        this.window.dispatchEvent(new CustomEvent<any>(PlatformBrowserNavigationService.POSSIBLE_GATE_CHANGED_EVENT, {
            detail: undefined
        }));
    }

    private async onNavigationAllowed(url?: string, hardNavigation?: boolean): Promise<void> {
        try {
            await this.aggregatedBrowserNavigationGateHandler.onNavigationAllowed(url, hardNavigation);
        } catch (e) {
            console.error(e);
        }
    }

    private async onNavigationBlocked(url?: string, hardNavigation?: boolean): Promise<void> {
        try {
            await this.aggregatedBrowserNavigationGateHandler.onNavigationBlocked(url, hardNavigation);
        } catch (e) {
            console.error(e);
        }
    }

    private async onBeforeNavigation(url?: string): Promise<void> {
        await this.aggregatedBrowserNavigationGateHandler.onBeforeNavigate(url);
    }

    private async onAfterNavigation(url?: string): Promise<void> {
        await this.aggregatedBrowserNavigationGateHandler.onAfterNavigate(url);
    }

    private getDefaultPagesRoutes(): string[] {
        return this.defaultPagesService.getDefaultPagesRoutes();
    }

    private onBrowserNavigationGateHandlerRefreshRequested(): void {
        this.eventBus.dispatch<any>(PlatformBrowserNavigationServiceTypeName, EventType.BROADCAST, PossibleGateChangedEvents.NAVIGATION_POSSIBLE_GATE_CHANGE, {});
    }
}

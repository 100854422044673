import {RuntimeThemeVariant} from "sirius-platform-support-library/shared/theming/models/runtime-theme-variant.model";
import {
    BaseThemeVariantSelectorStore
} from "sirius-platform-support-library/shared/theming/selectors/base-theme-variant-selector.store";
import {DefaultThemeVariantSelectors} from "sirius-platform-support-library/shared/theming/theming.constants";
import {
    UserPreferenceThemeVariantSelectorConstants
} from "sirius-platform-support-library/shared/theming/selectors/user-preferences/user-preference-theme-variant.selector.constants";

export const UserPreferenceThemeVariantSelectorTypeName = 'UserPreferenceThemeVariantSelector';

export class UserPreferenceThemeVariantSelector extends BaseThemeVariantSelectorStore {
    public static readonly UNIQUE_CODE = DefaultThemeVariantSelectors.USER_PREFERENCES_THEME_VARIANT_SELECTOR;

    private readonly window: Window;

    public constructor(window: Window) {
        super();

        this.window = window;
    }

    public getUniqueCode(): string {
        return UserPreferenceThemeVariantSelector.UNIQUE_CODE;
    }

    public async getSelectedThemeVariant(themes: RuntimeThemeVariant[]): Promise<RuntimeThemeVariant | undefined> {
        const themeVariantId = this.readUserPreferenceThemeVariantId();
        if (!themeVariantId) {
            return undefined;
        }
        return themes.find(tv => tv.id === themeVariantId);
    }

    protected async storeSelectedThemeVariant(themeVariant?: RuntimeThemeVariant): Promise<void> {
        this.writeUserPreferenceThemeVariantId(themeVariant?.id);
    }

    private readUserPreferenceThemeVariantId(): string | undefined {
        try {
            return this.window.localStorage.getItem(UserPreferenceThemeVariantSelectorConstants.USER_PREFERENCES_STORAGE_KEY);
        } catch (e) {
            return undefined;
        }
    }

    private writeUserPreferenceThemeVariantId(themeVariantId?: string): void {
        if (!themeVariantId) {
            this.window.localStorage.removeItem(UserPreferenceThemeVariantSelectorConstants.USER_PREFERENCES_STORAGE_KEY);
            return;
        }
        this.window.localStorage.setItem(UserPreferenceThemeVariantSelectorConstants.USER_PREFERENCES_STORAGE_KEY, themeVariantId);
    }
}

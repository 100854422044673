/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    ILocalizationService,
    ILocalizationServiceTypeName
} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {LocalizationService, LocalizationServiceTypeName} from "./localization-service";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    DefaultLocaleProviderPriorityAssigner,
    DefaultLocaleProviderPriorityAssignerTypeName
} from "./default-locale-provider-priority-assigner";
import {
    ILocaleProviderPriorityAssigner,
    ILocaleProviderPriorityAssignerTypeName
} from "sirius-platform-support-library/shared/localization/providers/locale-provider-priority-assigner.interface";
import {
    ILocaleProvider,
    ILocaleProviderTypeName
} from "sirius-platform-support-library/shared/localization/providers/locale-provider.interface";
import {LwpCookieLocaleProvider, LwpCookieLocaleProviderTypeName} from "./providers/lwp-cookie-locale-provider";
import {
    LocalStorageLocaleProvider,
    LocalStorageLocaleProviderTypeName
} from "./providers/local-storage-locale-provider";
import {
    BrowserLanguageLocaleProvider,
    BrowserLanguageLocaleProviderTypeName
} from "./providers/browser-language-locale-provider";
import {
    ILocaleStoreTypeName
} from "sirius-platform-support-library/shared/localization/providers/locale-store.interface";
import {IBeforePlatformReadyInitTypeName} from "../initializer/before-platform-ready-init.interface";
import {
    PlatformTranslationService,
    PlatformTranslationServiceTypeName
} from "./translations/platform-translation.service";
import {
    SupportedTranslationsLoaders
} from "sirius-platform-support-library/shared/localization/translations/loaders/translations-loaders.constants";
import {
    PlatformActionInteractionService,
    PlatformActionInteractionServiceTypeName
} from "../browser-events/platform-action-interaction.service";
import {
    NavigationAndInteractionGateEventsReceiver,
    NavigationAndInteractionGateEventsReceiverTypeName
} from "../browser-events/navigation-and-interaction-gate.events-receiver";

export class LocalizationDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<ILocaleProvider, LwpCookieLocaleProvider>(DependencyType.PRIVATE, ILocaleProviderTypeName, LwpCookieLocaleProviderTypeName, new LwpCookieLocaleProvider(window))
            .addInterfacesOnSingleton(LwpCookieLocaleProviderTypeName, ILocaleStoreTypeName)
            .addUsingInstanceAsSingleton<ILocaleProvider, LocalStorageLocaleProvider>(DependencyType.PRIVATE, ILocaleProviderTypeName, LocalStorageLocaleProviderTypeName, new LocalStorageLocaleProvider(window))
            .addInterfacesOnSingleton(LocalStorageLocaleProviderTypeName, ILocaleStoreTypeName)
            .addUsingInstanceAsSingleton<ILocaleProvider, BrowserLanguageLocaleProvider>(DependencyType.PRIVATE, ILocaleProviderTypeName, BrowserLanguageLocaleProviderTypeName, new BrowserLanguageLocaleProvider(window))
            .addUsingInstanceAsSingleton<ILocaleProviderPriorityAssigner, DefaultLocaleProviderPriorityAssigner>(DependencyType.PRIVATE, ILocaleProviderPriorityAssignerTypeName, DefaultLocaleProviderPriorityAssignerTypeName, new DefaultLocaleProviderPriorityAssigner())
            .addUsingFactoryAsSingleton<ILocalizationService, LocalizationService>(DependencyType.SHARED, ILocalizationServiceTypeName, LocalizationServiceTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                const actionInteractionService = di.resolve<PlatformActionInteractionService>(PlatformActionInteractionServiceTypeName);
                const navigationAndInteractionGateEventsReceiver = di.resolve<NavigationAndInteractionGateEventsReceiver>(NavigationAndInteractionGateEventsReceiverTypeName);
                return LocalizationService.build(
                    window,
                    tenant,
                    eventBus,
                    actionInteractionService,
                    navigationAndInteractionGateEventsReceiver,
                    di
                );
            })
            .addInterfacesOnSingleton(LocalizationServiceTypeName, IBeforePlatformReadyInitTypeName)
            .addUsingFactoryAsSingleton<PlatformTranslationService>(DependencyType.PRIVATE, PlatformTranslationServiceTypeName, (di) => {
                return new PlatformTranslationService({
                    publicPath: {
                        provide: () => {
                            // @ts-ignore
                            return __webpack_public_path__;
                        }
                    },
                    type: SupportedTranslationsLoaders.REMOTE_ASSETS,
                    options: {
                        prependPublicPath: false,
                        baseUrl: '/assets/i18n/',
                        extension: '.json'
                    },
                    localizedResources: {}
                });
            });
    }
}

export const localizationDependencyModule = new LocalizationDependencyModule();

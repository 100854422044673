/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    RequiredClaims,
    RequiredClaimsEnum
} from "sirius-platform-support-library/shared/authentication/claims/required-claims.enum";
import {
    IRequiredClaimsMappingProvider
} from "sirius-platform-support-library/shared/authentication/claims/required-claims-mapping-provider.interface";
import {IClaimsMapper} from "sirius-platform-support-library/shared/authentication/claims/claims-mapper.interface";
import {
    OptionalClaims,
    OptionalClaimsEnum
} from "sirius-platform-support-library/shared/authentication/claims/optional-claims.enum";
import {
    IOptionalClaimsMappingProvider
} from "sirius-platform-support-library/shared/authentication/claims/optional-claims-mapping-provider.interface";

export const ClaimsMapperTypeName = 'ClaimsMapper';

export class ClaimsMapper implements IClaimsMapper {
    public map(
        claims: Record<string, string>,
        requiredClaims: RequiredClaimsEnum[],
        requiredClaimsMappingProvider: IRequiredClaimsMappingProvider,
        optionalClaims: OptionalClaimsEnum[],
        optionalClaimsMappingProvider: IOptionalClaimsMappingProvider,
    ): Record<string, string> {
        const result: Record<string, string> = Object.assign({}, claims);

        const requiredMappedClaims = requiredClaimsMappingProvider.getRequiredClaimsMappings();
        RequiredClaims.REQUIRED_CLAIMS.forEach(requiredClaim => {
            const siriusClaimKey = requiredClaim.toString();
            const externalClaimKey = requiredMappedClaims[siriusClaimKey];
            result[siriusClaimKey] = claims[externalClaimKey];
        });

        const optionalMappedClaims = optionalClaimsMappingProvider.getOptionalClaimsMappings();
        OptionalClaims.OPTIONAL_CLAIMS.forEach(optionalClaim => {
            const siriusClaimKey = optionalClaim.toString();
            const externalClaimKey = optionalMappedClaims[siriusClaimKey];
            result[siriusClaimKey] = claims[externalClaimKey];
        });

        return result;
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";
import {DynamicMenuSection} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-section.enum";
import {
    DynamicMenuCustomizerRefreshRequestedCallback,
    IDynamicMenuCustomizer,
} from "sirius-platform-support-library/shared/dynamic-menu/customizer/dynamic-menu-customizer.interface";

export const PlatformDynamicMenuCustomizerTypeName = 'PlatformDynamicMenuCustomizer';

export class PlatformDynamicMenuCustomizer implements IDynamicMenuCustomizer {
    private readonly dynamicMenuCustomizer: IDynamicMenuCustomizer;

    private refreshRequestedCallback: DynamicMenuCustomizerRefreshRequestedCallback

    public constructor(
        dynamicMenuCustomizer: IDynamicMenuCustomizer
    ) {
        this.dynamicMenuCustomizer = dynamicMenuCustomizer;
        if (this.dynamicMenuCustomizer?.onRefreshRequested) {
            this.dynamicMenuCustomizer.onRefreshRequested(this.onRefreshRequestedInternal.bind(this));
        }
    }

    public async getMenu(sectionCode: DynamicMenuSection, route: string, currentMenu?: DynamicMenuItem[], defaultMenu?: DynamicMenuItem[]): Promise<DynamicMenuItem[] | undefined> {
        try {
            const customizedMenu = await this.dynamicMenuCustomizer?.getMenu(sectionCode, route, currentMenu, defaultMenu);
            if (!customizedMenu) {
                return currentMenu ?? defaultMenu;
            }
            return customizedMenu;
        } catch (e) {
            console.error(e);
            return currentMenu ?? defaultMenu;
        }
    }

    public async refresh?(sectionCode: DynamicMenuSection): Promise<void> {
        try {
            if (this.refreshRequestedCallback) {
                await this.refreshRequestedCallback(sectionCode);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public onRefreshRequested?(callback: DynamicMenuCustomizerRefreshRequestedCallback): void {
        this.refreshRequestedCallback = callback;
    }

    private async onRefreshRequestedInternal(sectionCode: DynamicMenuSection): Promise<void> {
        await this.refresh(sectionCode);
    }
}

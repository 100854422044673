import {LogoFeatureOptions} from "sirius-platform-support-library/models/common";
import {
    ThemeVariantLogoProperties
} from "sirius-platform-support-library/models/theming/theme-variant-properties.model";
import {
    StandardFeatures
} from "sirius-platform-support-library/shared/micro-frontends/features/standard-features.constants";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IThemeVariantPropertiesCustomizer
} from "sirius-platform-support-library/shared/theming/customizers/theme-variant-properties-customizer.interface";
import {RuntimeThemeVariant} from "sirius-platform-support-library/shared/theming/models/runtime-theme-variant.model";
import {DefaultTheme} from "sirius-platform-support-library/shared/theming/theming.constants";
import _ from "lodash";

export const TenantContextDellThemeVariantPropertiesCustomizerTypeName = 'TenantContextDellThemeVariantPropertiesCustomizer';

export class TenantContextDellThemeVariantPropertiesCustomizer implements IThemeVariantPropertiesCustomizer {
    private readonly tenant: ITenant;

    public constructor(tenant: ITenant) {
        this.tenant = tenant;
    }

    public getThemeCode(): string {
        return DefaultTheme.Dell.toString();
    }

    public async customize(themeVariant: RuntimeThemeVariant): Promise<void> {
        if (themeVariant.theme.code !== this.getThemeCode()) {
            return;
        }

        const logoFeatureProperties: LogoFeatureOptions = this.tenant?.getContext()?.site?.top?.header?.features?.find(feature => feature.code === StandardFeatures.HEADER_LOGO)?.options;

        const defaultLogoProperties: ThemeVariantLogoProperties = {
            prependPublicPath: !!(logoFeatureProperties as any)?.prependPublicPath,
            imageSrc: logoFeatureProperties?.imageSrc,
            mobileImageSrc: logoFeatureProperties?.mobileImageSrc,
        };

        const variantLogoProperties: ThemeVariantLogoProperties = logoFeatureProperties?.theming?.[themeVariant.theme.code]?.[themeVariant.variant.code];

        const mergedLogoProperties: ThemeVariantLogoProperties = _.merge({}, defaultLogoProperties, variantLogoProperties ?? {});
        mergedLogoProperties.prependPublicPath = !!mergedLogoProperties.prependPublicPath;

        if (!themeVariant.variant?.properties) {
            themeVariant.variant.properties = {logo: {}};
        }

        themeVariant.variant.properties.logo = {
            ...mergedLogoProperties
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IActionInteractionGateHandler
} from "sirius-platform-support-library/shared/browser-events/action-interaction-gate-handler.interface";

export const IPlatformActionInteractionGateHandlerTypeName = 'IPlatformActionInteractionGateHandler';

export interface IPlatformActionInteractionGateHandler extends IActionInteractionGateHandler {
}

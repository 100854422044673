/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IDialogsInteractionHandler} from "./dialogs-interaction-handler.interface";

export class DialogsInteractionHandler implements IDialogsInteractionHandler {
    private readonly context: any;

    public onDismissCallback: (dialogId: string) => Promise<void>;
    public onActionCallback: (dialogId: string, actionCode: string) => Promise<void>;

    public constructor(context: any) {
        this.context = context;
    }

    public async handleDismiss(dialogId: string): Promise<void> {
        if (this.onDismissCallback) {
            await this.onDismissCallback.call(this.context, dialogId);
        }
    }

    public async handleAction(dialogId: string, actionCode: string) {
        if (this.onActionCallback) {
            await this.onActionCallback.call(this.context, dialogId, actionCode);
        }
    }
}
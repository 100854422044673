/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IDefaultPagesOptionsStore} from "./default-pages-options-store.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {InternalNavigateToOptions} from "../internal-navigate-to.options";

export const DefaultPagesOptionsStoreTypeName = 'DefaultPagesOptionsStore';

export class DefaultPagesOptionsStore implements IDefaultPagesOptionsStore {
    private static instance: DefaultPagesOptionsStore;
    private static readonly DATABASE_NAME = 'sirius.default-pages-options';
    private static readonly STORE_NAME = 'sirius.default-pages-options';
    public static readonly ONE_DAY_INTERVAL = (24 * 60 * 60 * 1000);

    private readonly window: Window;

    public constructor(
        window: Window
    ) {
        this.window = window;

    }

    public static initialize(
        window: Window
    ): DefaultPagesOptionsStore {
        if (!DefaultPagesOptionsStore.instance) {
            DefaultPagesOptionsStore.instance = new DefaultPagesOptionsStore(
                window
            );
        }
        return DefaultPagesOptionsStore.instance;
    }

    public static getInstance(): DefaultPagesOptionsStore {
        return DefaultPagesOptionsStore.instance;
    }

    private static generateRandomCode(): string {
        let result = '';
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXY0123456789';
        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    private static async getDatabase(window: Window, databaseName: string, storeName: string): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const dbRequest = window.indexedDB.open(databaseName, 1);
            dbRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
                DefaultPagesOptionsStore.buildSchema((event.target as any)?.result, storeName);
            }
            dbRequest.onerror = (event) => {
                reject(event)
            };
            dbRequest.onsuccess = (event) => {
                const database = (event.target as any)?.result as IDBDatabase;
                DefaultPagesOptionsStore.purge(database);
                resolve(database);
            }
        });
    }

    private static buildSchema(database: IDBDatabase, storeName: string) {
        const store = database.createObjectStore(storeName, {
            autoIncrement: false,
            keyPath: 'key'
        });
        store.createIndex("key", "key", {unique: true});
        store.createIndex("timestamp", "timestamp", {unique: false});
        store.createIndex("options", "options", {unique: false});
    }

    public async get(code: string): Promise<InternalNavigateToOptions | undefined> {
        if (!code) {
            return undefined;
        }
        const dbItem = await this.dbGetItem(code);
        if (!dbItem) {
            return undefined;
        }
        const base64 = dbItem?.options;
        const json = decodeURIComponent(escape(window.atob(base64)));
        return ObjectUtility.deserialize<InternalNavigateToOptions>(json);
    }

    public async store(options: InternalNavigateToOptions): Promise<string> {
        const code = DefaultPagesOptionsStore.generateRandomCode();
        const json = ObjectUtility.serialize(options);
        const encoded = btoa(unescape(encodeURIComponent(json)));
        await this.dbStoreItem(code, encoded);
        return code;
    }

    public async remove(code: string): Promise<void> {
        if (!code) {
            return;
        }
        await this.dbRemoveItem(code);
    }

    private async dbStoreItem(key: string, value: any): Promise<any | undefined> {
        const database = await DefaultPagesOptionsStore.getDatabase(this.window, DefaultPagesOptionsStore.DATABASE_NAME, DefaultPagesOptionsStore.STORE_NAME);
        const transaction = database.transaction([DefaultPagesOptionsStore.STORE_NAME], 'readwrite');
        const objectStore = transaction.objectStore(DefaultPagesOptionsStore.STORE_NAME);
        return new Promise<any | undefined>((resolve, reject) => {
            const request = objectStore.add({
                key: key,
                options: value,
                timestamp: new Date().toISOString()
            });
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
            transaction.oncomplete = () => database.close();
        });
    }

    private async dbGetItem(key: string): Promise<any | undefined> {
        const database = await DefaultPagesOptionsStore.getDatabase(this.window, DefaultPagesOptionsStore.DATABASE_NAME, DefaultPagesOptionsStore.STORE_NAME);
        const transaction = database.transaction([DefaultPagesOptionsStore.STORE_NAME], 'readwrite');
        const objectStore = transaction.objectStore(DefaultPagesOptionsStore.STORE_NAME);
        return new Promise<any | undefined>((resolve, reject) => {
            const request = objectStore.get(key);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
            transaction.oncomplete = () => database.close();
        });
    }

    private async dbRemoveItem(key: string): Promise<void> {
        const database = await DefaultPagesOptionsStore.getDatabase(this.window, DefaultPagesOptionsStore.DATABASE_NAME, DefaultPagesOptionsStore.STORE_NAME);
        const transaction = database.transaction([DefaultPagesOptionsStore.STORE_NAME], 'readwrite');
        const objectStore = transaction.objectStore(DefaultPagesOptionsStore.STORE_NAME);
        return new Promise<any | undefined>((resolve, reject) => {
            const request = objectStore.delete(key);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
            transaction.oncomplete = () => database.close();
        });
    }

    private static purge(database: IDBDatabase): void {
        const transaction = database.transaction([DefaultPagesOptionsStore.STORE_NAME], 'readwrite');
        const objectStore = transaction.objectStore(DefaultPagesOptionsStore.STORE_NAME);
        const dayAgo = new Date(Date.now() - DefaultPagesOptionsStore.ONE_DAY_INTERVAL).toISOString();
        const range = IDBKeyRange.upperBound(dayAgo);
        const index = objectStore?.index("timestamp");
        if(index) {
            index.openCursor(range).onsuccess = event => {
                const cursor = event.target['result'];
                if (cursor) {
                    objectStore.delete(cursor.value.key);
                    cursor.continue();
                }
            };
        }

    }
}

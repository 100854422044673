/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {
    IMobileViewHandler,
    IMobileViewHandlerTypeName
} from "sirius-platform-support-library/shared/mobile-view/mobile-view-handler.interface";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {IEventBus, IEventBusTypeName} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {MobileViewHandler, MobileViewHandlerTypeName} from "./mobile-view.handler";
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";

export class MobileViewDependencyModule extends TypedDependencyModule {
    register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingFactoryAsSingleton<IMobileViewHandler, MobileViewHandler>(DependencyType.SHARED, IMobileViewHandlerTypeName, MobileViewHandlerTypeName, (di) => {
                const tenant = di.resolve<ITenant>(ITenantTypeName);
                const eventBus = di.resolve<IEventBus>(IEventBusTypeName);
                return new MobileViewHandler(
                    window,
                    tenant,
                    eventBus
                );
            });
    }
}

export const mobileViewDependencyModule = new MobileViewDependencyModule();

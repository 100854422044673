/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    IAuthenticationHandler
} from "sirius-platform-support-library/shared/authentication/handlers/authentication-handler.interface";
import {
    SessionExpiredCallback,
    SessionRenewedCallback
} from "sirius-platform-support-library/shared/authentication/session/session-callbacks";
import {
    ISessionLifecycleManager
} from "sirius-platform-support-library/shared/authentication/session/session-lifecycle-manager.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {ITenantStoreService} from "sirius-platform-support-library/shared/tenant-store/tenant-store-service.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {SessionResponse} from "sirius-platform-support-library/shared/authentication/session/session-response";
import {Session} from "sirius-platform-support-library/shared/authentication/session/session";
import {AuthenticationOptions} from "sirius-platform-support-library/shared/authentication/authentication-options";
import {
    IUserContextUpdater
} from "sirius-platform-support-library/shared/authentication/user-context/user-context-updater.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    SignInProcessOptions
} from "sirius-platform-support-library/shared/authentication/handlers/options/sign-in-process.options";
import {
    SignOutProcessOptions
} from "sirius-platform-support-library/shared/authentication/handlers/options/sign-out-process.options";
import {
    RegistrationProcessOptions
} from "sirius-platform-support-library/shared/authentication/handlers/options/registration-process.options";
import {
    SignInResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/sign-in.response";
import {
    SignOutResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/sign-out-response";
import {
    RegistrationResponse
} from "sirius-platform-support-library/shared/authentication/handlers/responses/registration-response";
import {
    IBrowserNavigationService
} from "sirius-platform-support-library/shared/browser-events/browser-navigation-service.interface";
import {UserInfo} from "sirius-platform-support-library/models/user/user-info";
import {UserContext} from "sirius-platform-support-library/shared/authentication/user-context/user-context";
import {sanitizeUrl} from "@braintree/sanitize-url";
import {
    StandardAuthenticationProviders
} from "sirius-platform-support-library/shared/authentication/handlers/standard-authentication.providers";
import {KeyValuePair} from "sirius-platform-support-library/models/common";
import {ManagedSessionCookieClaimsMapper} from "./services/mapper/managed-session-cookie.claims-mapper";
import {
    IManagedSessionCookieUserInfoService,
    IManagedSessionCookieUserInfoServiceTypeName
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.user-info.service.interface";
import {
    IManagedSessionCookieSessionService, IManagedSessionCookieSessionServiceTypeName
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/services/managed-session-cookie.session.service.interface";
import {
    ManagedSessionCookieAuthenticationConfigurator
} from "sirius-platform-support-library/shared/authentication/handlers/dell-identity/managed-session-cookie/managed-session-cookie.authentication.configurator";
import {
    IRequiredClaimsMappingDelegatedProvider,
    IRequiredClaimsMappingDelegatedProviderTypeName
} from "sirius-platform-support-library/shared/authentication/claims/required-claims-mapping.delegated-provider.interface";
import {
    IOptionalClaimsMappingDelegatedProvider,
    IOptionalClaimsMappingDelegatedProviderTypeName
} from "sirius-platform-support-library/shared/authentication/claims/optional-claims-mapping.delegated-provider.interface";
import _ from "lodash";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {SessionWithClaims} from "sirius-platform-support-library/shared/authentication/session/session-with-claims";

export const ManagedSessionCookieAuthenticationHandlerTypeName = 'ManagedSessionCookieAuthenticationHandler';

export class ManagedSessionCookieAuthenticationHandler implements IAuthenticationHandler, ISessionLifecycleManager {
    public static readonly SECURITY_CONTEXT_STORE_KEY = 'dell.sirius.dell-identity.managed-session-cookie.security-context';
    public static readonly SESSION_EXPIRED_EVENT = 'dell.sirius.dell-identity.managed-session-cookie.session.expired';
    public static readonly SESSION_RENEWED_EVENT = 'dell.sirius.dell-identity.managed-session-cookie.session.renewed';

    public static readonly DISPLAY_NAME_VIRTUAL_CLAIM_KEY = 'http://www.dell.com/identity/claims/profile/displayName';
    public static readonly EMAIL_CLAIM_KEY = 'http://www.dell.com/identity/claims/profile/emailaddress';

    public static readonly DEFAULT_INVALID_SESSION: Session = {
        sessionId: '',
        profileId: '',
        expiresAt: -1,
        issuedAt: -1,
    };

    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly tenantStore: ITenantStoreService;
    private readonly eventBus: IEventBus;
    private readonly browserNavigationService: IBrowserNavigationService;
    private readonly userContextUpdater: IUserContextUpdater;
    private readonly serviceCollection: IServiceCollection;

    private readonly authenticationConfigurator: ManagedSessionCookieAuthenticationConfigurator;
    private readonly claimsMapper: ManagedSessionCookieClaimsMapper;

    public constructor(
        window: Window,
        tenant: ITenant,
        tenantStore: ITenantStoreService,
        eventBus: IEventBus,
        browserNavigationService: IBrowserNavigationService,
        userContextUpdater: IUserContextUpdater,
        serviceCollection: IServiceCollection,
    ) {
        this.window = window;
        this.tenant = tenant;
        this.tenantStore = tenantStore;
        this.eventBus = eventBus;
        this.browserNavigationService = browserNavigationService;
        this.userContextUpdater = userContextUpdater;
        this.serviceCollection = serviceCollection;

        this.authenticationConfigurator = new ManagedSessionCookieAuthenticationConfigurator(this.tenant.getContext()?.authentication?.providerSettings);
        this.claimsMapper = new ManagedSessionCookieClaimsMapper();

        this.bind();
    }

    public async validateSecurityContext(userContext: UserContext): Promise<SessionResponse | undefined | void> {
        if (!this.authenticationConfigurator.getSessionRenewalEnabled()) {
            return;
        }

        const securityContext = this.getSecurityContext();
        if (!securityContext) {
            throw new Error('No security context found.');
        }

        const shouldVerifyMatchingPrincipal = !userContext.claims?.authenticatedAsImpersonator;
        const response = await this.validateSession(userContext.userIdentifier, shouldVerifyMatchingPrincipal);
        if (response?.invalid) {
            throw new Error('The current session is no longer valid');
        }

        return response;
    }

    public bind(): void {
        this.tenantStore.onItemEvicted(this, ManagedSessionCookieAuthenticationHandlerTypeName, async (itemKey, itemValue) => {
            if (itemKey !== ManagedSessionCookieAuthenticationHandler.SECURITY_CONTEXT_STORE_KEY) {
                return;
            }
            await this.expireSession(itemValue as Session);
        });

        if (this.authenticationConfigurator.getSessionRenewalEnabled()) {
            this.window.setInterval(async () => await this.processSessionRenewal(), this.authenticationConfigurator.getSessionRenewalInterval());
        }
    }

    public getRequiredClaimsMappings(): Record<string, string> {
        return this.getRequiredClaimsMappingDelegatedProvider()?.getRequiredClaimsMappings() ?? {};
    }

    public getOptionalClaimsMappings(): Record<string, string> {
        return this.getOptionalClaimsMappingDelegatedProvider()?.getOptionalClaimsMappings() ?? {};
    }

    public getSignInProcessOptions(): SignInProcessOptions {
        const defaultOptions = {
            deferredProcess: true,
            externalProcess: true,
        };

        return _.merge(
            {},
            defaultOptions,
            this.tenant.getContext()?.authentication.providerSettings?.signInProcessOptions ?? {}
        );
    }

    public async silentSignIn(): Promise<SignInResponse> {
        const userInfo = await this.getUserInfoService().getUserInfo(this.authenticationConfigurator);
        if (!userInfo) {
            throw new Error('Could not retrieve user claims');
        }
        const mappedClaims = this.claimsMapper.map(userInfo.claims);
        const displayNameClaim = mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY];
        if (!displayNameClaim) {
            const value = mappedClaims[ManagedSessionCookieAuthenticationHandler.EMAIL_CLAIM_KEY];
            if (value) {
                mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY] = value;
            }
        }
        await this.beginSession(userInfo.session as Session);
        return {
            claims: mappedClaims,
            grantedAuthorities: [],
        };
    }

    public async signIn(options?: AuthenticationOptions): Promise<SignInResponse> {
        const redirectUrl = sanitizeUrl(this.window.location.href);
        const signInUrl = this.authenticationConfigurator.getSignInUrl(redirectUrl);
        if (!signInUrl) {
            throw new Error('Please check your authentication handler configuration, could not configure sign in url.');
        }
        const stoppedNavigation = !this.browserNavigationService.isNavigationAllowed(signInUrl);
        if (!stoppedNavigation) {
            this.window.location.href = sanitizeUrl(signInUrl);
        }
        return {
            options: options,
            stopped: stoppedNavigation
        };
    }

    public getSignOutProcessOptions(): SignOutProcessOptions {
        const defaultOptions = {
            deferredProcess: true,
        };

        return _.merge(
            {},
            defaultOptions,
            this.tenant.getContext()?.authentication.providerSettings?.signOutProcessOptions ?? {}
        );
    }

    public async signOut(options?: AuthenticationOptions): Promise<SignOutResponse> {
        const redirectUrl = sanitizeUrl(this.window.location.href);
        const signOutUrl = this.authenticationConfigurator.getSignOutUrl(redirectUrl);
        if (!signOutUrl) {
            throw new Error('Please check your authentication handler configuration, could not configure sign out url.');
        }
        const stoppedNavigation = !this.browserNavigationService.isNavigationAllowed(signOutUrl);
        if (!stoppedNavigation) {
            this.window.location.href = sanitizeUrl(signOutUrl);
        }
        return {
            options: options,
            stopped: stoppedNavigation
        };
    }

    public getRegistrationProcessOptions(): RegistrationProcessOptions {
        const defaultOptions = {
            enabled: true,
            deferredProcess: true,
            externalProcess: false,
            registerRoute: this.authenticationConfigurator.getRegisterRoute(),
            guardRegistrationRoute: true,
            redirectToOriginatorUrlOnProcessCancel: true,
            redirectToOriginatorUrlOnProcessCompletion: true,
            treatRegisteringStateAsAuthenticatedState: false,
            authenticatedAtCompletionOfProcess: true,
            authenticatedAtCancellationOfProcess: true,
            cancelProcessOnNavigateAway: true,
            disableNavigationWhileProcessInProgress: true,
        };

        return _.merge(
            {},
            defaultOptions,
            this.tenant.getContext()?.authentication?.providerSettings?.registrationProcessOptions ?? {}
        );
    }

    public async register(options?: AuthenticationOptions): Promise<RegistrationResponse> {
        const registerUrl = this.authenticationConfigurator.getRegisterRoute();
        if (!registerUrl) {
            throw new Error('Please check your authentication handler configuration, could not configure register url.');
        }
        const stoppedNavigation = !this.browserNavigationService.isNavigationAllowed(registerUrl);
        if (!stoppedNavigation) {
            this.window.history.pushState(null, null, registerUrl);
        }
        return {
            options: options,
            stopped: stoppedNavigation
        };
    }

    public async processSignInFlow(options?: AuthenticationOptions, data?: any): Promise<SignInResponse> {
        const userInfo = await this.getUserInfoService().getUserInfo(this.authenticationConfigurator);
        if (!userInfo) {
            throw new Error('Could not retrieve user claims');
        }
        const mappedClaims = this.claimsMapper.map(userInfo.claims);
        const displayNameClaim = mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY];
        if (!displayNameClaim) {
            const value = mappedClaims[ManagedSessionCookieAuthenticationHandler.EMAIL_CLAIM_KEY];
            if (value) {
                mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY] = value;
            }
        }
        await this.beginSession(userInfo.session as Session);
        options.redirectUrl = window.location.pathname;
        return {
            registrationRequired: true,
            options: options,
            claims: mappedClaims,
            grantedAuthorities: [],
        };
    }

    public async processSignOutFlow(options?: AuthenticationOptions): Promise<SignOutResponse> {
        await this.clearSession();
        options.redirectUrl = window.location.pathname;
        return {
            options: options
        };
    }

    public async processRegistrationFlow(options?: AuthenticationOptions, data?: any): Promise<RegistrationResponse> {
        const userInfo = data as UserInfo;
        if (!userInfo) {
            throw new Error('Please provide a valid registration user info.');
        }
        const requiredClaimsMappings = this.getRequiredClaimsMappings()
        const optionalClaimsMappings = this.getOptionalClaimsMappings()
        const keyValuePairClaims: KeyValuePair[] = [];
        Object.keys(userInfo.claims).forEach(claimKey => {
            const claimValue = userInfo.claims[claimKey];
            keyValuePairClaims.push({
                key: claimKey,
                value: claimValue
            });
        });
        Object.keys(requiredClaimsMappings).forEach(mappedClaimKey => {
            const claimKey = requiredClaimsMappings[mappedClaimKey];
            const claimValue = userInfo[mappedClaimKey];
            const claim = keyValuePairClaims.find(claim => claim.key === claimKey);
            if (claim) {
                claim.value = claimValue;
            }
        });
        Object.keys(optionalClaimsMappings).forEach(mappedClaimKey => {
            const claimKey = optionalClaimsMappings[mappedClaimKey];
            const claimValue = userInfo[mappedClaimKey];
            const claim = keyValuePairClaims.find(claim => claim.key === claimKey);
            if (claim) {
                claim.value = claimValue;
            }
        });
        const mappedClaims = this.claimsMapper.map(keyValuePairClaims);
        options.redirectUrl = options.originatorUrl;
        return {
            options: options,
            claims: mappedClaims,
            grantedAuthorities: [],
        };
    }

    public getSecurityContext(): any | undefined {
        return this.tenantStore.get(ManagedSessionCookieAuthenticationHandler.SECURITY_CONTEXT_STORE_KEY);
    }

    public async clearSecurityContext(): Promise<void> {
        this.tenantStore.remove(ManagedSessionCookieAuthenticationHandler.SECURITY_CONTEXT_STORE_KEY);
    }

    public async validateSession(userIdentifier: string, shouldVerifyMatchingPrincipal: boolean = true): Promise<SessionResponse> {
        const sessionService = this.getSessionService();
        if (!this.authenticationConfigurator.getSessionRenewalEnabled() || !sessionService) {
            return {
                ...ManagedSessionCookieAuthenticationHandler.DEFAULT_INVALID_SESSION,
                invalid: false
            }
        }
        const session = this.getCurrentSession();
        const localSessionValid = this.isSessionValidInternal(userIdentifier, session, shouldVerifyMatchingPrincipal);
        if (!localSessionValid) {
            await this.clearSession();
        }

        let remoteSessionValid = true;
        let remoteSession: any;
        try {
            remoteSession = await sessionService?.renewSession(this.authenticationConfigurator) ?? ManagedSessionCookieAuthenticationHandler.DEFAULT_INVALID_SESSION;
            remoteSessionValid = !shouldVerifyMatchingPrincipal || remoteSession?.profileId == userIdentifier;
        } catch (e) {
            remoteSessionValid = false;
        }

        const valid = localSessionValid && remoteSessionValid;

        const mappedClaims = this.claimsMapper.map(remoteSession?.claims ?? []) ?? {};
        const displayNameClaim = mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY];
        if (!displayNameClaim) {
            const value = mappedClaims[ManagedSessionCookieAuthenticationHandler.EMAIL_CLAIM_KEY];
            if (value) {
                mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY] = value;
            }
        }

        return {
            ...session,
            sessionId: remoteSession?.sessionId ?? session.sessionId,
            issuedAt: remoteSession?.issuedAt ?? session.issuedAt,
            expiresAt: remoteSession?.expiresAt ?? session.expiresAt,
            claims: Object.keys(mappedClaims).length > 0 ? mappedClaims : undefined,
            invalid: !valid
        };
    }

    public isSessionValid(userIdentifier: string, shouldVerifyMatchingPrincipal: boolean = true): boolean {
        if (!this.authenticationConfigurator.getSessionRenewalEnabled()) {
            return true;
        }
        const session = this.getCurrentSession();
        return this.isSessionValidInternal(userIdentifier, session, shouldVerifyMatchingPrincipal);
    }

    public async beginSession(session: Session): Promise<SessionResponse> {
        if (!this.authenticationConfigurator.getSessionRenewalEnabled()) {
            return {
                ...ManagedSessionCookieAuthenticationHandler.DEFAULT_INVALID_SESSION,
                invalid: false
            };
        }
        this.storeCurrentSession(session);
        return {
            ...session,
            invalid: false
        };
    }

    public async renewSession(): Promise<SessionResponse> {
        const sessionService = this.getSessionService();

        if (!this.authenticationConfigurator.getSessionRenewalEnabled() || !sessionService) {
            return {
                ...ManagedSessionCookieAuthenticationHandler.DEFAULT_INVALID_SESSION,
                invalid: false
            };
        }
        try {
            const previousSession = this.getCurrentSession();
            if (!previousSession) {
                return {
                    invalid: true
                } as unknown as SessionResponse;
            }
            const session: SessionWithClaims = await sessionService?.renewSession(this.authenticationConfigurator) ?? ManagedSessionCookieAuthenticationHandler.DEFAULT_INVALID_SESSION;
            if (!session?.profileId) {
                console.error('Could not renew session');
                return {
                    invalid: true
                } as unknown as SessionResponse;
            }
            const mappedClaims = this.claimsMapper.map(session.claims ?? []) ?? {};
            const displayNameClaim = mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY];
            if (!displayNameClaim) {
                const value = mappedClaims[ManagedSessionCookieAuthenticationHandler.EMAIL_CLAIM_KEY];
                if (value) {
                    mappedClaims[ManagedSessionCookieAuthenticationHandler.DISPLAY_NAME_VIRTUAL_CLAIM_KEY] = value;
                }
            }
            await this.beginSession(session);
            this.eventBus.dispatchBroadcast<Session>(ManagedSessionCookieAuthenticationHandlerTypeName, ManagedSessionCookieAuthenticationHandler.SESSION_RENEWED_EVENT, session, undefined, true);
            return {
                ...session,
                claims: mappedClaims,
                invalid: false
            };
        } catch (e) {
            console.error(e);
            return {
                invalid: true
            } as unknown as SessionResponse;
        }
    }

    public async expireSession(session: Session): Promise<void> {
        if (!this.authenticationConfigurator.getSessionRenewalEnabled()) {
            return;
        }
        await this.clearSession();
        return this.eventBus.dispatchBroadcast<Session>(ManagedSessionCookieAuthenticationHandlerTypeName, ManagedSessionCookieAuthenticationHandler.SESSION_EXPIRED_EVENT, session, undefined, true);
    }

    public onSessionRenewed(context: any, subscriberName: string, callback: SessionRenewedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<Session>(this, subscriberName, ManagedSessionCookieAuthenticationHandler.SESSION_RENEWED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    public onSessionExpired(context: any, subscriberName: string, callback: SessionExpiredCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<Session>(this, subscriberName, ManagedSessionCookieAuthenticationHandler.SESSION_EXPIRED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    public async clearSession(): Promise<void> {
        if (!this.authenticationConfigurator.getSessionRenewalEnabled()) {
            return;
        }
        await this.clearCurrentSession();
    }

    public getUniqueCode(): string {
        return StandardAuthenticationProviders.DELL_IDENTITY_MANAGED_SESSION_COOKIE;
    }

    private async processSessionRenewal(): Promise<void> {
        try {
            const sessionResponse = await this.renewSession();
            if (sessionResponse?.invalid === false) {
                console.debug('Session has been renewed');
            }
        } catch (e) {
            console.error(e);
        }
    }

    private storeCurrentSession(session: Session): void {
        const epochNow = this.getEpochNow();
        const evictIn = session.expiresAt - epochNow;
        this.tenantStore.set(ManagedSessionCookieAuthenticationHandler.SECURITY_CONTEXT_STORE_KEY, session, {evictIn: evictIn});
    }

    private async clearCurrentSession(): Promise<void> {
        await this.clearSecurityContext();
    }

    private getCurrentSession(): Session {
        return this.tenantStore.get<Session>(ManagedSessionCookieAuthenticationHandler.SECURITY_CONTEXT_STORE_KEY) ?? ManagedSessionCookieAuthenticationHandler.DEFAULT_INVALID_SESSION;
    }

    private isSessionValidInternal(userIdentifier: string, session: Session, shouldVerifyMatchingPrincipal: boolean = true): boolean {
        const profileId = session?.profileId;
        return !!profileId && !!userIdentifier && (!shouldVerifyMatchingPrincipal || profileId === userIdentifier);
    }

    private getEpochNow(): number {
        return Math.round(Date.now() / 1000);
    }

    private getUserInfoService(): IManagedSessionCookieUserInfoService {
        return this.serviceCollection.resolve<IManagedSessionCookieUserInfoService>(IManagedSessionCookieUserInfoServiceTypeName);
    }

    private getSessionService(): IManagedSessionCookieSessionService | undefined {
        return this.serviceCollection.resolve<IManagedSessionCookieSessionService>(IManagedSessionCookieSessionServiceTypeName);
    }

    private getRequiredClaimsMappingDelegatedProvider(): IRequiredClaimsMappingDelegatedProvider | undefined {
        return this.serviceCollection.resolve<IRequiredClaimsMappingDelegatedProvider>(IRequiredClaimsMappingDelegatedProviderTypeName);
    }

    private getOptionalClaimsMappingDelegatedProvider(): IOptionalClaimsMappingDelegatedProvider | undefined {
        return this.serviceCollection.resolve<IOptionalClaimsMappingDelegatedProvider>(IOptionalClaimsMappingDelegatedProviderTypeName);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const infoIconTemplate = `
    <sirius-icon aria-hidden="true" shape="info-standard" size="18"></sirius-icon>
`;

export const successIconTemplate = `
    <sirius-icon aria-hidden="true" shape="success-standard" size="18"></sirius-icon>
`;

export const warningIconTemplate = `
    <sirius-icon aria-hidden="true" shape="warning-standard" size="18"></sirius-icon>
`;

export const errorIconTemplate = `
    <sirius-icon aria-hidden="true" shape="error-standard" size="18"></sirius-icon>
`;

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ITenantCustomBootstrapCode
} from "sirius-shell-support-library/tenants/custom-code/tenant-custom-bootstrap-code.interface";
import {TenantLibraryConfig} from "sirius-platform-support-library/tenants/tenant-context";

export const ITenantCustomBootstrapCodeLoaderTypeName = 'ITenantCustomBootstrapCodeLoader';

export interface ITenantCustomBootstrapCodeLoader {
    load(tenantCustomCodeLibrary: TenantLibraryConfig, failFast?: boolean): Promise<ITenantCustomBootstrapCode>;
}

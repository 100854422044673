/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {css, html, LitElement} from "lit";
import {defineCustomElements as initSkeleton} from 'skeleton-webcomponent-loader/loader/index';
import {ITenant, ITenantTypeName} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {Navbar} from "sirius-platform-support-library/tenants/tenant-context";
import {
    SharedDependencyContainer
} from "sirius-platform-support-library/dependency-injection/shared-dependency-container";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {
    IThemingHandlersManager,
    IThemingHandlersManagerTypeName
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {ThemedComponent} from "sirius-platform-support-library/shared/theming/themable-components.constants";
import {DomInjectionType} from "sirius-platform-support-library/shared/theming/management/managers/dom-injection.type";

export class SiriusNavbarSkeleton extends LitElement {
    public static readonly CUSTOM_ELEMENT_NAME = 'sirius-navbar-skeleton';
    public static styles = css`
      :host {
        width: 100%;
      }

      .container {
        height: 50px;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        column-gap: 0;
        box-shadow: 0 -1px var(--uxd-neutral-granite,#d2d2d2) inset;
      }

      .container .button {
        margin: 0;
        padding: 12px 24px;
        box-sizing: border-box;
      }

      .container .selected {
        border-bottom: 2px solid var(--uxd-primary-dellblue,#0672CB);
      }

      .text-skeleton {
        --skeleton-margin-bottom: 6px;
      }
    `;
    private tenant?: ITenant;
    private navbarOptions?: Navbar;

    public async connectedCallback() {
        super.connectedCallback();

        this.tenant = SharedDependencyContainer
            .getInstance()
            .getServiceCollection()
            .resolve<ITenant>(ITenantTypeName);

        this.navbarOptions = this.tenant?.getContext()?.site?.top?.navbar;


        const styleSheetsBasedThemeHandler = new StyleSheetsBasedThemeHandler(document, {
            themeCode: 'dell',
            variantsCodes: ['light', 'dark'],
            defaultVariantCode: 'light',
            component: {
                prefix: 'shell',
                code: 'navbar-skeleton',
                suffix: 'skeleton'
            },
            variantsResources: {
                light: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ],
                dark: [
                    {
                        prependPublicPath: false,
                        baseUrlTemplate: '/assets/styles/themes/standard/{{themeCode}}/{{variantCode}}/',
                        resourceNameTemplate: '{{themeCode}}_{{variantCode}}_color-palette.css'
                    }
                ]
            },
        });

        await styleSheetsBasedThemeHandler.attachAndApplyDefaultVariantLocally(this.renderRoot, DomInjectionType.PREPEND);

        const themingHandlersManager = SharedDependencyContainer.getInstance().getServiceCollection().resolve<IThemingHandlersManager>(IThemingHandlersManagerTypeName);
        if (themingHandlersManager) {
            themingHandlersManager.registerThemeHandler(styleSheetsBasedThemeHandler);
            await themingHandlersManager.attach(ThemedComponent.SHELL_NAVBAR_SKELETON, this.renderRoot, DomInjectionType.PREPEND);
        }

        await initSkeleton();
    }

    public render() {
        if (!this.navbarOptions.visible) {
            return '';
        }

        return html`
            <script src="/libs/skeleton-webcomponent-loader/dist/esm/skeleton-webcomponent.js"></script>

            <div class="container">
                ${this.navbarOptions.items.map(() => html`
                    <div class="button">
                        <nb-skeleton class="text-skeleton" height="20px" width="78px" variant="rect"></nb-skeleton>
                    </div>`)}
            </div>
        `;
    }

}

if (!customElements.get(SiriusNavbarSkeleton.CUSTOM_ELEMENT_NAME)) {
    customElements.define(SiriusNavbarSkeleton.CUSTOM_ELEMENT_NAME, SiriusNavbarSkeleton);
}

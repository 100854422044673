/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    DynamicMenuChangedCallback,
    IDynamicMenuService
} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-service.interface";
import {DynamicMenuSection} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-section.enum";
import {DynamicMenuChangedEvent} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-changed.event";
import {
    IDynamicMenuHandler,
    IDynamicMenuHandlerTypeName
} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-handler.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {InternalMenu} from "./internal-menu";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {DynamicMenuEvents} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-events";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {
    DynamicMenuSectionsMapping
} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-sections.mapping";
import {
    CachedTranslationService
} from "sirius-platform-support-library/shared/localization/translations/cached-translation-service";
import {MenuItem, UnauthorizedHandleType} from "sirius-platform-support-library/models/common";
import {InternalMenuItem} from "./internal-menu-item";
import {
    LocalizableResource
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {
    TranslationsCache
} from "sirius-platform-support-library/shared/localization/translations/cache/translations.cache";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {
    DynamicMenuServiceConstants
} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-service.constants";
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";
import {
    IDynamicMenuCustomizer
} from "sirius-platform-support-library/shared/dynamic-menu/customizer/dynamic-menu-customizer.interface";
import {
    IAuthorizationService
} from "sirius-platform-support-library/shared/authorization/authorization-service.interface";
import {
    IDynamicMenuOperations
} from "sirius-platform-support-library/shared/dynamic-menu/operations/dynamic-menu-operations.interface";


export const DynamicMenuServiceTypeName = 'DynamicMenuService';

export class DynamicMenuService implements IDynamicMenuService, IBeforePlatformReadyInit {
    public static readonly VISIBILITY_OVERRIDDEN_EVENT = 'dell.sirius.dynamic-menu.visibility-overridden';

    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly eventBus: IEventBus;
    private readonly localizationService: ILocalizationService;
    private readonly dynamicMenuOperations: IDynamicMenuOperations;
    private readonly dynamicMenuCustomizer: IDynamicMenuCustomizer;
    private readonly authorizationService: IAuthorizationService;
    private readonly serviceCollection: IServiceCollection;

    private handlers: Record<string, IDynamicMenuHandler> = {};
    private menu: Record<string, InternalMenu> = {};

    public constructor(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        localizationService: ILocalizationService,
        dynamicMenuOperations: IDynamicMenuOperations,
        dynamicMenuCustomizer: IDynamicMenuCustomizer,
        authorizationService: IAuthorizationService,
        serviceCollection: IServiceCollection
    ) {
        this.window = window;
        this.tenant = tenant;
        this.eventBus = eventBus;
        this.localizationService = localizationService;
        this.dynamicMenuOperations = dynamicMenuOperations;
        this.dynamicMenuCustomizer = dynamicMenuCustomizer;
        this.authorizationService = authorizationService;
        this.serviceCollection = serviceCollection;
    }

    public static build(
        window: Window,
        tenant: ITenant,
        eventBus: IEventBus,
        localizationService: ILocalizationService,
        dynamicMenuOperations: IDynamicMenuOperations,
        dynamicMenuCustomizer: IDynamicMenuCustomizer,
        authorizationService: IAuthorizationService,
        serviceCollection: IServiceCollection
    ): DynamicMenuService {
        let instance = ObjectUtility.getFromObjectPath<DynamicMenuService>(DynamicMenuServiceConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new DynamicMenuService(
                window,
                tenant,
                eventBus,
                localizationService,
                dynamicMenuOperations,
                dynamicMenuCustomizer,
                authorizationService,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(DynamicMenuServiceConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IDynamicMenuService {
        return ObjectUtility.getFromObjectPath<IDynamicMenuService>(DynamicMenuServiceConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        await this.populateMenu();

        for (const handler of this.serviceCollection.resolveAll<IDynamicMenuHandler>(IDynamicMenuHandlerTypeName)) {
            await this.registerHandler(handler);
        }

        this.localizationService.onLocaleChanged(this, DynamicMenuServiceTypeName, this.onLocaleChanged.bind(this));
        this.dynamicMenuCustomizer.onRefreshRequested(this.onRefreshRequested.bind(this));
        this.authorizationService.onPermissionsChanged(this, DynamicMenuServiceTypeName, this.onPermissionsChanged.bind(this));
    }

    public async getMenu(sectionCode: DynamicMenuSection): Promise<DynamicMenuItem[]> {
        if (!sectionCode?.toString()) {
            return;
        }
        const menu = this.menu?.[sectionCode];
        return menu?.currentItems ?? [];
    }

    public getDefaultMenu(sectionCode: DynamicMenuSection): MenuItem[] {
        if (!sectionCode?.toString()) {
            return;
        }
        const menu = this.menu?.[sectionCode];
        return menu?.defaultItems ?? menu?.contextItems;
    }

    public async getMenuItemById(sectionCode: DynamicMenuSection, itemId: string): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        return await this.dynamicMenuOperations.getMenuItemById(menu?.currentItems ?? [], itemId);
    }

    public async getMenuItemByPath(sectionCode: DynamicMenuSection, itemPath: string): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        return await this.dynamicMenuOperations.getMenuItemByPath(menu?.currentItems ?? [], itemPath);
    }

    public async getMenuItemByCode(sectionCode: DynamicMenuSection, itemCode: string, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        return await this.dynamicMenuOperations.getMenuItemByCode(menu?.currentItems ?? [], itemCode, parent);
    }

    public async getMenuItemByCondition(sectionCode: DynamicMenuSection, condition: (menuItem: DynamicMenuItem) => boolean, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        return await this.dynamicMenuOperations.getMenuItemByCondition(menu?.currentItems ?? [], condition, parent);
    }

    public async addMenuItem(sectionCode: DynamicMenuSection, context: any, item: DynamicMenuItem, localizableResources?: LocalizableResource, parent?: DynamicMenuItem, notifyChange: boolean = true): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        if (!menu) {
            throw new Error('Could not get menu for given section');
        }
        const rootItems = this.getRootItemsContainer(menu);
        const result = await this.dynamicMenuOperations.addMenuItem(rootItems, context, item, localizableResources, parent);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return result;
    }

    public async addMenuItemBefore(sectionCode: DynamicMenuSection, context: any, item: DynamicMenuItem, referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange: boolean = true): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        if (!menu) {
            throw new Error('Could not get menu for given section');
        }
        const rootItems = this.getRootItemsContainer(menu);
        const result = await this.dynamicMenuOperations.addMenuItemBefore(rootItems, context, item, referenceNode, localizableResources);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return result;
    }

    public async addMenuItemAfter(sectionCode: DynamicMenuSection, context: any, item: DynamicMenuItem, referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange: boolean = true): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        if (!menu) {
            throw new Error('Could not get menu for given section');
        }
        const rootItems = this.getRootItemsContainer(menu);
        const result = await this.dynamicMenuOperations.addMenuItemAfter(rootItems, context, item, referenceNode, localizableResources);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return result;
    }

    public async addMenuItems(sectionCode: DynamicMenuSection, context: any, items: DynamicMenuItem[], localizableResources?: LocalizableResource, parent?: DynamicMenuItem, notifyChange: boolean = true): Promise<DynamicMenuItem[]> {
        const menu = this.getMenuForSection(sectionCode);
        if (!menu) {
            throw new Error('Could not get menu for given section');
        }
        const rootItems = this.getRootItemsContainer(menu);
        const result = await this.dynamicMenuOperations.addMenuItems(rootItems, context, items, localizableResources, parent);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return result;
    }

    public async addMenuItemsBefore(sectionCode: DynamicMenuSection, context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        if (!menu) {
            throw new Error('Could not get menu for given section');
        }
        const rootItems = this.getRootItemsContainer(menu);
        const result = await this.dynamicMenuOperations.addMenuItemsBefore(rootItems, context, items, referenceNode, localizableResources);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return result;
    }

    public async addMenuItemsAfter(sectionCode: DynamicMenuSection, context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        if (!menu) {
            throw new Error('Could not get menu for given section');
        }
        const rootItems = this.getRootItemsContainer(menu);
        const result = await this.dynamicMenuOperations.addMenuItemsAfter(rootItems, context, items, referenceNode, localizableResources);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return result;
    }

    public async updateMenuItem(sectionCode: DynamicMenuSection, context: any, item: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange: boolean = true): Promise<DynamicMenuItem | undefined> {
        const menu = this.getMenuForSection(sectionCode);
        const rootItems = this.getRootItemsContainer(menu);
        const nodeReference = await this.dynamicMenuOperations.updateMenuItem(rootItems, context, item, localizableResources);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
        return nodeReference;
    }

    public async removeMenuItem(sectionCode: DynamicMenuSection, item: DynamicMenuItem, notifyChange: boolean = true): Promise<void> {
        const menu = this.getMenuForSection(sectionCode);
        const rootItems = this.getRootItemsContainer(menu);
        await this.dynamicMenuOperations.removeMenuItem(rootItems, item);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
    }

    public async removeMenuItems(sectionCode: DynamicMenuSection, items: DynamicMenuItem[], notifyChange: boolean = true): Promise<void> {
        const menu = this.getMenuForSection(sectionCode);
        const rootItems = this.getRootItemsContainer(menu);
        await this.dynamicMenuOperations.removeMenuItems(rootItems, items);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
    }

    public async removeMenuItemById(sectionCode: DynamicMenuSection, itemId: string, notifyChange: boolean = true): Promise<void> {
        const menu = this.getMenuForSection(sectionCode);
        const rootItems = this.getRootItemsContainer(menu);
        await this.dynamicMenuOperations.removeMenuItemById(rootItems, itemId);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
    }

    public async removeMenuItemByPath(sectionCode: DynamicMenuSection, itemPath: string, notifyChange: boolean = true): Promise<void> {
        const menu = this.getMenuForSection(sectionCode);
        const rootItems = this.getRootItemsContainer(menu);
        await this.dynamicMenuOperations.removeMenuItemByPath(rootItems, itemPath);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
    }

    public async removeMenuItemByCode(sectionCode: DynamicMenuSection, itemCode: string, parent?: DynamicMenuItem, notifyChange: boolean = true): Promise<void> {
        const menu = this.getMenuForSection(sectionCode);
        const rootItems = this.getRootItemsContainer(menu);
        await this.dynamicMenuOperations.removeMenuItemByCode(rootItems, itemCode, parent);
        this.backup(menu, notifyChange);
        await this.customizeAndNotifyHandler(sectionCode, menu, notifyChange);
    }

    public async clear(sectionCode?: DynamicMenuSection, notifyChange: boolean = true): Promise<void> {
        const sections = sectionCode ? [sectionCode] : Object.keys(DynamicMenuSection);
        for (let index = 0; index < sections.length; index++) {
            const currentSectionCode = sections[index];
            const menu = this.menu[currentSectionCode];
            if (!menu) {
                continue;
            }
            if (!menu.customized) {
                menu.backupItems = [];
            }
            menu.currentItems = [];
            this.backup(menu, notifyChange);
            await this.customizeAndNotifyHandler(currentSectionCode as DynamicMenuSection, menu, notifyChange);
        }
    }

    public async reset(sectionCode?: DynamicMenuSection, force?: boolean, notifyChange: boolean = true): Promise<void> {
        const sections = sectionCode ? [sectionCode] : Object.keys(DynamicMenuSection);
        for (let index = 0; index < sections.length; index++) {
            const currentSectionCode = sections[index];
            const menu = this.menu[currentSectionCode];
            if (!menu) {
                continue;
            }
            if (force) {
                menu.defaultItems = [...menu.contextItems];
                menu.backupItems = [...menu.defaultItems];
            }
            let items = menu.backupItems;
            if (!items?.length) {
                items = menu.defaultItems;
                if (!items?.length) {
                    items = menu.contextItems
                }
            }
            menu.currentItems = [...items];
            menu.customized = false;
            await this.applyLocalization(menu.currentItems ?? []);
            await this.customizeAndNotifyHandler(currentSectionCode as DynamicMenuSection, menu, notifyChange);
        }
    }

    public markAsCustomized(sectionCode?: DynamicMenuSection) {
        const menu = this.menu[sectionCode];
        if (!menu) {
            return;
        }
        menu.customized = true;
    }

    public backup(menu: InternalMenu, notifyChange: boolean) {
        if (!notifyChange || menu.customized) {
            return;
        }
        menu.backupItems = [...menu.currentItems];
    }

    public async customize(sectionCode: DynamicMenuSection, items: DynamicMenuItem[], defaults: DynamicMenuItem[]): Promise<DynamicMenuItem[]> {
        const menu = this.menu[sectionCode];
        if (!menu) {
            return;
        }
        const customizedItems = await this.dynamicMenuCustomizer.getMenu(sectionCode, this.window.location.pathname, [...items], [...defaults]);
        const clonedItems = [];
        for (const item of customizedItems) {
            const clone = await (item as InternalMenuItem)?.clone(undefined);
            if (clone) {
                clonedItems.push(clone);
            }
        }
        menu.customizedItems = clonedItems
        return menu.customizedItems;
    }

    public async bulkUpdate(action: (svc: IDynamicMenuService, notifyChange: boolean) => Promise<DynamicMenuSection[]>): Promise<void> {
        try {
            const affectedSections = await action(this, false);
            for (let index = 0; index < affectedSections?.length ?? 0; index++) {
                const currentSectionCode = affectedSections?.[index];
                const menu = this.menu[currentSectionCode];
                await this.customizeAndNotifyHandler(currentSectionCode, menu, true);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public async populateCustomizedMenuItems(sectionCode: DynamicMenuSection, items: DynamicMenuItem[], localizableResources?: LocalizableResource, context?: any): Promise<void> {
        const menu = this.menu[sectionCode];
        if (!menu) {
            return;
        }
        menu.customized = true;
        await this.clear(sectionCode, false);
        await this.dynamicMenuOperations.addMenuItems(menu.currentItems ?? [], context, items, localizableResources, null);
        await this.customizeAndNotifyHandler(sectionCode, menu, true);
    }

    public onMenuChanged(context: any, subscriberName: string, callback: DynamicMenuChangedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<DynamicMenuChangedEvent>(this, subscriberName, DynamicMenuEvents.DYNAMIC_MENU_CHANGED_EVENT, (event) => {
            callback?.call(context, event.data);
        });
    }

    public async registerHandler(handler: IDynamicMenuHandler): Promise<void> {
        try {
            if (!handler) {
                return;
            }
            const sectionCode = handler.getSectionCode().toString();
            this.handlers[sectionCode] = handler;
            const menu = this.menu[sectionCode];
            await this.customizeAndNotifyHandler(sectionCode as DynamicMenuSection, menu, true);
        } catch (e) {
            console.error(e);
        }
    }

    public async overrideMenuVisibilityState(sectionCode: DynamicMenuSection, visibilityState?: boolean): Promise<void> {
        const menu = this.menu[sectionCode];
        if (!menu) {
            return;
        }
        menu.overriddenVisibility = visibilityState;
        this.window.dispatchEvent(new CustomEvent(DynamicMenuService.VISIBILITY_OVERRIDDEN_EVENT));
    }

    public async clearMenuVisibilityStateOverride(sectionCode: DynamicMenuSection): Promise<void> {
        const menu = this.menu[sectionCode];
        if (!menu) {
            return;
        }
        menu.overriddenVisibility = undefined;
        this.window.dispatchEvent(new CustomEvent(DynamicMenuService.VISIBILITY_OVERRIDDEN_EVENT));
    }

    public getMenuOverriddenVisibilityState(sectionCode: DynamicMenuSection): boolean {
        const menu = this.menu[sectionCode];
        return menu?.overriddenVisibility;
    }

    public isMenuVisibilityStateOverridden(sectionCode: DynamicMenuSection): boolean {
        const menu = this.menu[sectionCode];
        return ObjectUtility.isDefined(menu?.overriddenVisibility);
    }

    public onMenuVisibilityStateOverridden(callback: () => void): void {
        this.window.addEventListener(DynamicMenuService.VISIBILITY_OVERRIDDEN_EVENT, callback);
    }

    private getRootItemsContainer(menu: InternalMenu): DynamicMenuItem[] {
        let rootItems = menu.currentItems ?? [];
        if (menu.customized) {
            console.warn('WARNING! You are using the dynamicMenu service to mutate a menu on a customized page. The operations will be performed on the non-customized menu structure. If you need to dynamically customize the menu on a customized page use the code based page customization provider (IPageCustomizationsProvider) or leverage the dynamic menu customizer infrastructure (IDynamicMenuCustomizer).');
            if (menu.backupItems) {
                rootItems = menu.backupItems;
            } else {
                rootItems = [];
            }
        }
        return rootItems;
    }

    private async populateMenu(): Promise<void> {
        for (const sectionCode of Object.keys(DynamicMenuSection)) {
            const provider = DynamicMenuSectionsMapping.MAP[sectionCode];
            if (!provider) {
                continue;
            }
            const section = provider(this.tenant?.getContext());
            if (!section) {
                continue;
            }
            const context: LocalizableResource = {
                localizedResources: section?.localizedResources
            }
            const contextMenu = section.items ?? [];
            const translationsCache = new TranslationsCache(context, context, true);
            const translationService = new CachedTranslationService(this.localizationService, translationsCache);
            const defaultItems = await this.dynamicMenuOperations.mapMany(contextMenu, translationService, null);
            const currentItems = defaultItems;
            const customizedItems = await this.dynamicMenuCustomizer.getMenu(sectionCode as DynamicMenuSection, this.window.location.pathname, currentItems, defaultItems);
            this.menu[sectionCode] = {
                sectionCode: sectionCode,
                customized: false,
                currentItems: [...currentItems],
                customizedItems: [...customizedItems],
                backupItems: [...currentItems],
                defaultItems: [...defaultItems],
                contextItems: [...defaultItems],
                contextMenu: contextMenu,
                tenantLocalizedResources: context.localizedResources,
                tenantTranslationsService: translationService
            };
        }
    }

    private async onLocaleChanged(): Promise<void> {
        for (const sectionCode of Object.keys(DynamicMenuSection)) {
            const menu = this.menu[sectionCode];
            const items = menu?.customizedItems ?? [];
            await this.applyLocalization(items);
            await this.customizeAndNotifyHandler(sectionCode as DynamicMenuSection, menu, true);
        }
    }

    private async applyLocalization(items: DynamicMenuItem[]): Promise<void> {
        for (let index = 0; index < items.length; index++) {
            const rootNode = items[index];
            await (rootNode as InternalMenuItem)?.localize();
            await this.dynamicMenuOperations.traverseTree(rootNode, async (node: DynamicMenuItem) => {
                await (node as InternalMenuItem)?.localize();
                return false;
            });
        }
    }

    private async onPermissionsChanged(): Promise<void> {
        for (const sectionCode of Object.keys(DynamicMenuSection)) {
            const menu = this.menu[sectionCode];
            if (!menu) {
                return;
            }
            await this.customizeAndNotifyHandler(sectionCode as DynamicMenuSection, menu, true);
        }
    }

    private async onRefreshRequested(sectionCode: DynamicMenuSection): Promise<void> {
        const menu = this.menu[sectionCode];
        if (!menu) {
            return;
        }
        await this.customizeAndNotifyHandler(sectionCode, menu, true);
    }

    private getMenuForSection(sectionCode: DynamicMenuSection): InternalMenu {
        const menu = this.menu[sectionCode];
        if (!menu) {
            throw new Error(`Could not find menu for given section (${sectionCode}).`);
        }
        return menu;
    }

    private async customizeAndNotifyHandler(sectionCode: DynamicMenuSection, menu: InternalMenu, notifyChange: boolean): Promise<void> {
        if (!menu) {
            return;
        }
        const customizedItems = await this.customize(sectionCode, menu.currentItems, menu.defaultItems ?? menu.contextItems);
        if (!notifyChange || !menu) {
            return;
        }
        try {
            const authorizedItems = this.filterUnauthorizedNodes(customizedItems);
            await this.handlers[sectionCode]?.onMenuChanged({
                section: sectionCode as DynamicMenuSection,
                menu: authorizedItems
            });
            this.triggerMenuChanged(sectionCode, authorizedItems);
        } catch (e) {
            console.error(e);
        }
    }

    private triggerMenuChanged(sectionCode: DynamicMenuSection, items: DynamicMenuItem[]): void {
        this.eventBus.dispatchBroadcast<DynamicMenuChangedEvent>(DynamicMenuServiceTypeName, DynamicMenuEvents.DYNAMIC_MENU_CHANGED_EVENT, {
            section: sectionCode,
            menu: items.map((i: any) => {
                return (i.asSerializable ? i.asSerializable() : i) as DynamicMenuItem;
            })
        });
    }

    private filterUnauthorizedNodes(items: DynamicMenuItem[]): DynamicMenuItem[] {
        const collection = [];
        for (let index = 0; index < items.length; index++) {
            let node = items[index];
            if (!node) {
                continue;
            }
            node = this.filterUnauthorizedNode(node);
            if (!node) {
                continue;
            }
            collection.push(node);
            if (node.items?.length) {
                node.items = this.filterUnauthorizedNodes(node.items);
            }
        }
        return collection;
    }

    private filterUnauthorizedNode(item: DynamicMenuItem): DynamicMenuItem | undefined {
        if (item.action?.authorization?.permissions) {
            if (!this.authorizationService.isAuthorized(item.action.authorization.permissions) &&
                (item.action.authorization?.behaviour?.unauthorizedHandleType ?? UnauthorizedHandleType.HIDDEN) === UnauthorizedHandleType.HIDDEN) {
                return undefined;
            }
        }
        return item;
    }
}

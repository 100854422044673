/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */


export const ISiteComponentsTypeName = 'ISiteComponents';

export interface ISiteComponents {
    bootstrapRequiredComponents(isOnErrorPage?: boolean): void;
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    ITypedServiceCollection
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-collection.interface";
import {IAnalyticsService} from "sirius-platform-support-library/shared/analytics/analytics-service.interface";
import {
    IAnalyticsEngine,
    IAnalyticsEngineTypeName
} from "sirius-platform-support-library/shared/analytics/analytics-engine.interface";
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {AnalyticsEngineConfig} from "sirius-platform-support-library/tenants/tenant-context";
import {AnalyticsConstants} from "sirius-platform-support-library/shared/analytics/analytics.constants";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";

export const AnalyticsServiceTypeName = 'AnalyticsService';

export class AnalyticsService implements IAnalyticsService, IBeforePlatformReadyInit {

    private readonly window: Window;
    private readonly tenant: ITenant;
    private readonly analyticsEngineConfig: AnalyticsEngineConfig[];
    private readonly serviceCollection: ITypedServiceCollection;

    public constructor(
        window: Window,
        tenant: ITenant,
        serviceCollection: ITypedServiceCollection,
    ) {
        this.window = window;
        this.tenant = tenant;
        this.serviceCollection = serviceCollection;
        this.analyticsEngineConfig = tenant.getContext()?.analytics || [];
    }

    public static build(
        window: Window,
        tenant: ITenant,
        serviceCollection: ITypedServiceCollection,
    ): AnalyticsService {
        let instance = ObjectUtility.getFromObjectPath<AnalyticsService>(AnalyticsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new AnalyticsService(
                window,
                tenant,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(AnalyticsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IAnalyticsService {
        return ObjectUtility.getFromObjectPath<IAnalyticsService>(AnalyticsConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        const analyticsEngines = this.serviceCollection.resolveAll<IAnalyticsEngine>(IAnalyticsEngineTypeName);
        let refreshRequired = false;
        for (let index = 0; index < analyticsEngines.length; index++) {
            const engine = analyticsEngines[index];
            const engineConfig = this.analyticsEngineConfig.find((engineConfig: AnalyticsEngineConfig) => engineConfig.analyticsEngineName === engine.getUniqueCode());
            if (engineConfig) {
                await engine.init(engineConfig.options);
                if (engineConfig.enabled) {
                    await engine.setEnabled(true);
                    if (engineConfig?.requiresPlatformReinitialization) {
                        refreshRequired = true;
                    }
                }
            }
        }
        if (refreshRequired) {
            // Handle the refresh as a configurable option in tenant preferences - either trigger event or do hard refresh on the spot
            this.window.location.reload();
        }
    }

    public async initializeEngine(engineCode: string, options: any): Promise<void> {
        await this.getAnalyticsEngine(engineCode)?.init(options, true);
    }

    public async enableEngine(engineCode: string): Promise<void> {
        await this.getAnalyticsEngine(engineCode)?.setEnabled(true);
        this.hardRefreshIfRequired(engineCode);
    }

    public async disableEngine(engineCode: string): Promise<void> {
        await this.getAnalyticsEngine(engineCode)?.setEnabled(false);
        this.hardRefreshIfRequired(engineCode);
    }

    public async setOptions(engineCode: string, options: any): Promise<void> {
        await this.getAnalyticsEngine(engineCode)?.setOptions(options);
    }

    public getOptions(engineCode: string): any {
        return this.getAnalyticsEngine(engineCode)?.getOptions();
    }

    public async isEngineEnabled(engineCode: string): Promise<boolean> {
        const analyticsEngine = this.analyticsEngineConfig.find((engineConfig) => engineConfig.analyticsEngineName === engineCode);
        return !!analyticsEngine?.enabled;
    }

    private hardRefreshIfRequired(engineCode: string) {
        const engineConfig = this.analyticsEngineConfig.find((engine) => engine.analyticsEngineName === engineCode);
        if (engineConfig?.requiresPlatformReinitialization) {
            this.window.location.reload();
        }
    }

    private getAnalyticsEngine(engineCode: string): IAnalyticsEngine | undefined {
        const analyticsEngines = this.serviceCollection.resolveAll<IAnalyticsEngine>(IAnalyticsEngineTypeName);
        return analyticsEngines.find((engine) => engine.getUniqueCode() === engineCode);
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {IBeforePlatformReadyInit} from "../initializer/before-platform-ready-init.interface";
import {
    IQuickNavMenuService,
    QuickNavMenuVisibilityOverriddenCallback
} from "sirius-platform-support-library/shared/quicknav-menu/quicknav-menu-service.interface";
import {
    QuickNavMenuServiceConstants
} from "sirius-platform-support-library/shared/quicknav-menu/quick-nav-menu-service.constants";
import {MenuItem} from "sirius-platform-support-library/models/common";
import {DynamicMenuItem} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-item";
import {
    DynamicMenuChangedCallback
} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-service.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {
    LocalizableResource
} from "sirius-platform-support-library/shared/localization/translations/localizable-resource";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {DynamicMenuSection} from "sirius-platform-support-library/shared/dynamic-menu/dynamic-menu-section.enum";
import {DynamicMenuService} from "../dynamic-menu/dynamic-menu.service";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {QuickNavMenuEvents} from "sirius-platform-support-library/shared/quicknav-menu/events/quick-nav-menu.events";
import {
    QuickNavMenuVisibilityOverriddenEvent
} from "sirius-platform-support-library/shared/quicknav-menu/events/quick-nav-menu.visibility-state.events";
import {IEvent} from "sirius-platform-support-library/shared/event-bus/event.interface";


export const QuickNavMenuServiceTypeName = 'QuickNavMenuService';

export class QuickNavMenuService implements IQuickNavMenuService, IBeforePlatformReadyInit {
    private readonly eventBus: IEventBus;
    private readonly dynamicMenuService: DynamicMenuService;

    public constructor(
        eventBus: IEventBus,
        dynamicMenuService: DynamicMenuService
    ) {
        this.eventBus = eventBus;
        this.dynamicMenuService = dynamicMenuService;
    }

    public static build(
        eventBus: IEventBus,
        dynamicMenuService: DynamicMenuService
    ): QuickNavMenuService {
        let instance = ObjectUtility.getFromObjectPath<QuickNavMenuService>(QuickNavMenuServiceConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new QuickNavMenuService(
                eventBus,
                dynamicMenuService
            );
            ObjectUtility.assignOnObjectPath(QuickNavMenuServiceConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IQuickNavMenuService {
        return ObjectUtility.getFromObjectPath<IQuickNavMenuService>(QuickNavMenuServiceConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        this.eventBus.registerBroadcast<QuickNavMenuVisibilityOverriddenEvent>(this, QuickNavMenuServiceTypeName, QuickNavMenuEvents.QUICKNAV_OVERRIDE_VISIBILITY_STATE_EVENT, async (event: IEvent<QuickNavMenuVisibilityOverriddenEvent>) => {
            await this.overrideVisibilityState(event?.data?.overriddenVisibility);
        });
        this.eventBus.registerBroadcast(this, QuickNavMenuServiceTypeName, QuickNavMenuEvents.QUICKNAV_CLEAR_OVERRIDDEN_VISIBILITY_STATE_EVENT, async () => {
            await this.clearVisibilityStateOverride();
        });
    }

    public async getMenu(): Promise<DynamicMenuItem[]> {
        return await this.dynamicMenuService.getMenu(DynamicMenuSection.QUICKNAV);
    }

    public getDefaultMenu(): MenuItem[] {
        return this.dynamicMenuService.getDefaultMenu(DynamicMenuSection.QUICKNAV);
    }

    public async getMenuItemById(itemId: string): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.getMenuItemById(DynamicMenuSection.QUICKNAV, itemId);
    }

    public async getMenuItemByPath(itemPath: string): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.getMenuItemByPath(DynamicMenuSection.QUICKNAV, itemPath);
    }

    public async getMenuItemByCode(itemCode: string, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.getMenuItemByCode(DynamicMenuSection.QUICKNAV, itemCode, parent);
    }

    public async getMenuItemByCondition(condition: (menuItem: DynamicMenuItem) => boolean, parent?: DynamicMenuItem): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.getMenuItemByCondition(DynamicMenuSection.QUICKNAV, condition, parent);
    }

    public async addMenuItem(context: any, item: DynamicMenuItem, localizableResources?: LocalizableResource, parent?: DynamicMenuItem, notifyChange?: boolean): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.addMenuItem(DynamicMenuSection.QUICKNAV, context, item, localizableResources, parent, notifyChange);
    }

    public async addMenuItemBefore(context: any, item: DynamicMenuItem, referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.addMenuItemBefore(DynamicMenuSection.QUICKNAV, context, item, referenceNode, localizableResources, notifyChange);
    }

    public async addMenuItemAfter(context: any, item: DynamicMenuItem, referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.addMenuItemAfter(DynamicMenuSection.QUICKNAV, context, item, referenceNode, localizableResources, notifyChange);
    }

    public async addMenuItems(context: any, items: DynamicMenuItem[], localizableResources?: LocalizableResource, parent?: DynamicMenuItem, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        return await this.dynamicMenuService.addMenuItems(DynamicMenuSection.QUICKNAV, context, items, localizableResources, parent, notifyChange);
    }

    public async addMenuItemsBefore(context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        return await this.dynamicMenuService.addMenuItemsBefore(DynamicMenuSection.QUICKNAV, context, items, referenceNode, localizableResources, notifyChange);
    }

    public async addMenuItemsAfter(context: any, items: DynamicMenuItem[], referenceNode: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem[] | undefined> {
        return await this.dynamicMenuService.addMenuItemsAfter(DynamicMenuSection.QUICKNAV, context, items, referenceNode, localizableResources, notifyChange);
    }

    public async updateMenuItem(context: any, item: DynamicMenuItem, localizableResources?: LocalizableResource, notifyChange?: boolean): Promise<DynamicMenuItem | undefined> {
        return await this.dynamicMenuService.updateMenuItem(DynamicMenuSection.QUICKNAV, context, item, localizableResources, notifyChange);
    }

    public async removeMenuItem(item: DynamicMenuItem, notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.removeMenuItem(DynamicMenuSection.QUICKNAV, item, notifyChange);
    }

    public async removeMenuItems(items: DynamicMenuItem[], notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.removeMenuItems(DynamicMenuSection.QUICKNAV, items, notifyChange);
    }

    public async removeMenuItemById(itemId: string, notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.removeMenuItemById(DynamicMenuSection.QUICKNAV, itemId, notifyChange);
    }

    public async removeMenuItemByPath(itemPath: string, notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.removeMenuItemByPath(DynamicMenuSection.QUICKNAV, itemPath, notifyChange);
    }

    public async removeMenuItemByCode(itemCode: string, parent?: DynamicMenuItem, notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.removeMenuItemByCode(DynamicMenuSection.QUICKNAV, itemCode, parent, notifyChange);
    }

    public async clear(notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.clear(DynamicMenuSection.QUICKNAV, notifyChange);
    }

    public async reset(force?: boolean, notifyChange?: boolean): Promise<void> {
        return await this.dynamicMenuService.reset(DynamicMenuSection.QUICKNAV, force, notifyChange);
    }

    public onMenuChanged(context: any, subscriberName: string, callback: DynamicMenuChangedCallback): IEventSubscription {
        return this.dynamicMenuService.onMenuChanged(context, subscriberName, (event) => {
            if (event.section !== DynamicMenuSection.QUICKNAV) {
                return;
            }
            return callback?.call(context, event);
        });
    }

    public async overrideVisibilityState(visibilityState?: boolean): Promise<void> {
        await this.dynamicMenuService.overrideMenuVisibilityState(DynamicMenuSection.QUICKNAV, visibilityState);
        this.triggerVisibilityStateOverriddenEvent(visibilityState);
    }

    public async clearVisibilityStateOverride(): Promise<void> {
        await this.dynamicMenuService.clearMenuVisibilityStateOverride(DynamicMenuSection.QUICKNAV);
        this.triggerVisibilityStateOverriddenEvent(undefined);
    }

    public getOverriddenVisibilityState(): boolean {
        return this.dynamicMenuService.getMenuOverriddenVisibilityState(DynamicMenuSection.QUICKNAV);
    }

    public isVisibilityStateOverridden(): boolean {
        return this.dynamicMenuService.isMenuVisibilityStateOverridden(DynamicMenuSection.QUICKNAV);
    }

    public onVisibilityStateOverridden(context: any, subscriberName: string, callback: QuickNavMenuVisibilityOverriddenCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<QuickNavMenuVisibilityOverriddenEvent>(this, QuickNavMenuServiceTypeName, QuickNavMenuEvents.QUICKNAV_VISIBILITY_STATE_OVERRIDDEN_EVENT, (event: IEvent<QuickNavMenuVisibilityOverriddenEvent>) => {
            return callback?.call(context, event?.data?.overriddenVisibility);
        });
    }

    private triggerVisibilityStateOverriddenEvent(visibilityState: boolean): void {
        this.eventBus.dispatchBroadcast<QuickNavMenuVisibilityOverriddenEvent>(QuickNavMenuServiceTypeName, QuickNavMenuEvents.QUICKNAV_VISIBILITY_STATE_OVERRIDDEN_EVENT, {
            overriddenVisibility: visibilityState
        });
    }
}

/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {IPlatform} from "./platform.interface";

export const PlatformInitializationAwaiterTypeName = 'PlatformInitializationAwaiter';

export class PlatformInitializationAwaiter {
    public static readonly GLOBAL_VARIABLE_KEY = 'window.sirius.awaiter';

    public static getInstance(): PlatformInitializationAwaiter {
        let instance = ObjectUtility.getFromObjectPath<PlatformInitializationAwaiter>(PlatformInitializationAwaiter.GLOBAL_VARIABLE_KEY);
        if (!instance) {
            instance = new PlatformInitializationAwaiter();
            ObjectUtility.assignOnObjectPath(PlatformInitializationAwaiter.GLOBAL_VARIABLE_KEY, instance);
        }
        return instance;
    }

    private readonly awaiter: Promise<IPlatform>;
    private resolve: (...args: any[]) => void;
    private reject: (...args: any[]) => void;

    public whenInitialized(): Promise<IPlatform> {
        return this.awaiter;
    }

    private constructor() {
        this.awaiter = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public done(platform: IPlatform) {
        this.resolve(platform);
    }
}

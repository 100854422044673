/*
 * Copyright '2022' Dell Inc. or its subsidiaries. All Rights Reserved.
 */

import {SiteConfig} from "sirius-platform-support-library/tenants/tenant-context";

export const ISiteStylerTypeName = 'ISiteStyler';

export interface ISiteStyler {
    style(siteConfig?: SiteConfig): Promise<void>;
}

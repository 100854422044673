/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {OpenedDialog, RequestedDialog} from "sirius-platform-support-library/shared/dialogs/dialog";
import {
    DialogClosedCallback,
    IDialogsService
} from "sirius-platform-support-library/shared/dialogs/dialogs-service.interface";
import {DialogsDomHandler} from "./dom/dialogs-dom-handler";
import {ILocalizationService} from "sirius-platform-support-library/shared/localization/localization-service.interface";
import {IAfterPlatformReadyInit} from "../initializer/after-platform-ready-init.interface";
import {IActionHandler} from "sirius-platform-support-library/shared/actions/action-handler.interface";
import {DialogValidator} from "./validators/dialog.validator";
import {IEventBus} from "sirius-platform-support-library/shared/event-bus/event-bus.interface";
import {
    IServiceCollection
} from "sirius-platform-support-library/dependency-injection/generic/service-collection.interface";
import {ObjectUtility} from "sirius-platform-support-library/utilities/object-utility";
import {DialogsConstants} from "sirius-platform-support-library/shared/dialogs/dialogs.constants";
import {RendarableDialog} from "./rendarable-dialog";
import {IDialogsInteractionHandler} from "./handlers/dialogs-interaction-handler.interface";
import {DialogsInteractionHandler} from "./handlers/dialogs-interaction.handler";
import {
    IBannerAlertsService
} from "sirius-platform-support-library/shared/banner-alerts/banner-alerts-service.interface";
import {IEventSubscription} from "sirius-platform-support-library/shared/event-bus/event-subscription.interface";
import {DialogClosedEvent} from "sirius-platform-support-library/shared/dialogs/events/dialog-closed.event";
import {DialogsEvents} from "sirius-platform-support-library/shared/dialogs/events/dialogs-events";
import {
    IPlatformBrowserNavigationEventsReceiver
} from "../browser-events/platform/platform-browser-navigation-events-receiver.interface";
import {ActionPerformedEvent} from "sirius-platform-support-library/shared/actions/events/action-performed.event";
import {RendarableDialogAction} from "./rendarable-dialog-action";
import {ITenant} from "sirius-platform-support-library/shared/tenants/tenant.interface";
import {
    IThemingHandlersManager
} from "sirius-platform-support-library/shared/theming/management/managers/theming-handlers-manager.interface";
import {
    StyleSheetsBasedThemeHandler
} from "sirius-platform-support-library/shared/theming/management/handlers/stylesheets/stylesheets-based.theme-handler";
import {IButtonStyleProcessor} from "../common-ui/buttons/button-style.processor.interface";

export const DialogsServiceTypeName = 'DialogsService';

export class DialogsService implements IDialogsService, IPlatformBrowserNavigationEventsReceiver, IAfterPlatformReadyInit {
    public static readonly EVENT_SOURCE = 'dialog';

    private readonly window: Window;
    private readonly localizationService: ILocalizationService;
    private readonly bannerAlertService: IBannerAlertsService;
    private readonly actionHandler: IActionHandler;
    private readonly dialogValidator: DialogValidator;
    private readonly eventBus: IEventBus;
    private readonly serviceCollection: IServiceCollection;
    private readonly interactionHandler: IDialogsInteractionHandler;
    private readonly domHandler: DialogsDomHandler;
    private readonly buttonStyleProcessor: IButtonStyleProcessor;
    private readonly dialogs: RendarableDialog[];
    private readonly tenant: ITenant;
    private currentDialog: RendarableDialog;

    public constructor(
        window: Window,
        document: Document,
        localizationService: ILocalizationService,
        bannerAlertService: IBannerAlertsService,
        actionHandler: IActionHandler,
        dialogValidator: DialogValidator,
        eventBus: IEventBus,
        tenant: ITenant,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        buttonStyleProcessor: IButtonStyleProcessor,
        serviceCollection: IServiceCollection
    ) {

        this.dialogs = [];

        this.window = window;
        this.localizationService = localizationService;
        this.actionHandler = actionHandler;
        this.dialogValidator = dialogValidator;
        this.eventBus = eventBus;
        this.tenant = tenant;
        this.serviceCollection = serviceCollection;
        this.bannerAlertService = bannerAlertService;

        this.interactionHandler = new DialogsInteractionHandler(this);
        this.interactionHandler.onDismissCallback = this.onDismissCallback.bind(this);
        this.interactionHandler.onActionCallback = this.onActionCallback.bind(this);
        this.buttonStyleProcessor = buttonStyleProcessor;

        this.domHandler = new DialogsDomHandler(
            document,
            this.interactionHandler,
            styleSheetsBasedThemeHandler,
            themingHandlersManager
        );
    }

    public static build(
        window: Window,
        document: Document,
        localizationService: ILocalizationService,
        bannerAlertService: IBannerAlertsService,
        actionHandler: IActionHandler,
        dialogValidator: DialogValidator,
        eventBus: IEventBus,
        tenant: ITenant,
        styleSheetsBasedThemeHandler: StyleSheetsBasedThemeHandler,
        themingHandlersManager: IThemingHandlersManager,
        buttonStyleProcessor: IButtonStyleProcessor,
        serviceCollection: IServiceCollection
    ): DialogsService {
        let instance = ObjectUtility.getFromObjectPath<DialogsService>(DialogsConstants.GLOBAL_KEY);
        if (instance == undefined) {
            instance = new DialogsService(
                window,
                document,
                localizationService,
                bannerAlertService,
                actionHandler,
                dialogValidator,
                eventBus,
                tenant,
                styleSheetsBasedThemeHandler,
                themingHandlersManager,
                buttonStyleProcessor,
                serviceCollection
            );
            ObjectUtility.assignOnObjectPath(DialogsConstants.GLOBAL_KEY, instance);
        }
        return instance;
    }

    public static getInstance(): IDialogsService {
        return ObjectUtility.getFromObjectPath<IDialogsService>(DialogsConstants.GLOBAL_KEY);
    }

    public async init(): Promise<void> {
        await this.domHandler.init();
        this.bind();
    }

    public async open(context: any, dialog: RequestedDialog): Promise<OpenedDialog> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }

        if (this.topDialogIsModal()) {
            throw new Error('You cannot push a dialog on top of an active Modal dialog.');
        }

        this.dialogValidator.validate(dialog);

        const renderingDialog = new RendarableDialog(
            context,
            dialog,
            this.interactionHandler,
            this.localizationService,
            this.actionHandler,
            this.buttonStyleProcessor,
            this.serviceCollection
        );

        await renderingDialog.localize();
        this.dialogs.push(renderingDialog);
        this.currentDialog = renderingDialog;
        await this.domHandler.render(this.currentDialog);
        return renderingDialog.toOpenedDialog();
    }

    public async close(dialog: OpenedDialog): Promise<void> {
        await this.closeById(dialog?.id);
    }

    public async closeById(id: string): Promise<void> {
        await this.removeDialogById(id);
    }

    public getById(id: string): OpenedDialog | undefined {
        return this.getDialogById(id)?.toOpenedDialog();
    }

    public async update(context, dialog: OpenedDialog): Promise<OpenedDialog> {
        if (!context) {
            throw new Error('Please provide a valid context');
        }

        const renderingDialog = this.getDialogById(dialog.id);
        renderingDialog.title.localizationCode = dialog.title.localizationCode;
        renderingDialog.bodyHtml.localizationCode = dialog.bodyHtml.localizationCode;
        renderingDialog.updateActions(dialog.actions);

        await renderingDialog.localize();

        this.getIndexOfDialogById(renderingDialog.id);
        this.domHandler.update(renderingDialog);
        return renderingDialog.toOpenedDialog();
    }

    public anyDialogOpened(): boolean {
        return this.dialogs.length > 0;
    }

    public isDialogOpened(dialog: OpenedDialog): boolean {
        return this.isDialogWithIdOpened(dialog?.id);
    }

    public isDialogWithIdOpened(dialogId: string): boolean {
        return this.dialogs.findIndex(dialog => dialog.id === dialogId) > -1;
    }

    public onDialogClosed(context: any, subscriberName: string, dialogClosedCallback: DialogClosedCallback): IEventSubscription {
        return this.eventBus.registerBroadcast<DialogClosedEvent>(this, subscriberName, DialogsEvents.DIALOG_CLOSED, (busEvent) => {
            dialogClosedCallback?.call(context, busEvent.data);
        });
    }

    public onBeforeNavigate(url: string): void {
    }

    public onAfterNavigate(url: string): void {
        if (!this.currentDialog) {
            return;
        }
        const closeDialogOnRouteChange = this.tenant.getContext()?.behaviour?.dialogs?.closeDialogOnRouteChange;
        if (closeDialogOnRouteChange) {
            this.onRouteChanged();
        }
    }

    private topDialogIsModal(): boolean {
        const lastIndex = this.dialogs.length - 1;
        return lastIndex >= 0 ? this.dialogs[lastIndex].modal : false;
    }

    private bind(): void {
        this.localizationService.onLocaleChanged(this, 'DialogsService', this.onLocaleChanged);
        this.actionHandler.onActionPerformed(this, DialogsService.EVENT_SOURCE, this.onActionPerformed.bind(this));
    }

    private async onLocaleChanged(): Promise<void> {
        await Promise.allSettled(this.dialogs.map(async (dialog: RendarableDialog) => {
            await dialog.localize();
            this.domHandler.update(dialog);
        }));
    }

    private onRouteChanged(): void {
        this.domHandler.clear();
        this.dialogs.splice(0, this.dialogs.length);
    }

    private async onDismissCallback(dialogId: string): Promise<void> {
        const dialog = this.getDialogById(dialogId);
        await this.removeDialogById(dialog?.id, true);
    }

    private async removeDialogById(dialogId: string, closedByDedicatedButton: boolean = false): Promise<void> {
        const currentIndex = this.getIndexOfDialogById(dialogId);
        if (currentIndex < 0) {
            return;
        }
        const dialog = this.dialogs.find(d => d.id === dialogId);
        this.dialogs.splice(currentIndex, 1);
        await this.domHandler.removeDialogById(dialogId);
        this.triggerDialogClosedEvent(dialog, closedByDedicatedButton);
        this.setFocusToTopDialog();
    }

    private async onActionCallback(dialogId: string, actionCode: string): Promise<void> {
        const dialog = this.getDialogById(dialogId);
        if (!dialog) {
            return;
        }
        const action = dialog.getActionByCode(actionCode);
        if (!action) {
            return;
        }
        let success = true;
        if (ObjectUtility.isDefined(action.action)) {
            success = await this.actionHandler.handle(dialog.context, DialogsServiceTypeName, action, {
                source: DialogsService.EVENT_SOURCE,
                sourceId: dialogId
            }, false);
        }
        if (!success) {
            return;
        }
        if (action.closeAfterAction) {
            await this.removeDialogById(dialog.id);
        }
    }

    private getIndexOfDialogById(id: string): number {
        if (!id) {
            return -1;
        }
        return this.dialogs.findIndex(dialog => dialog.id === id);
    }

    private getDialogById(id: string): RendarableDialog {
        if (!id) {
            return undefined;
        }
        return this.dialogs.find(dialog => dialog.id === id);
    }

    private triggerDialogClosedEvent(dialog: RendarableDialog, closedByDedicatedButton: boolean = false): void {
        this.eventBus.dispatchBroadcast<DialogClosedEvent>('DialogsService', DialogsEvents.DIALOG_CLOSED, {
            dialog: dialog.toOpenedDialog(),
            closedByDedicatedButton: closedByDedicatedButton
        });
    }

    private setFocusToTopDialog() {
        this.domHandler.setFocusToTopDialog();
    }

    private async onActionPerformed(event: ActionPerformedEvent): Promise<void> {
        if (!event.sourceId) {
            return;
        }
        const action: RendarableDialogAction = event?.action as RendarableDialogAction;
        if (event.success && action?.closeAfterAction) {
            await this.removeDialogById(event.sourceId);
        }
    }
}

/*
 * Copyright '2023' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    TypedDependencyModule
} from "sirius-platform-support-library/dependency-injection/typed/typed-dependency-module";
import {
    ITypedServiceRegistry
} from "sirius-platform-support-library/dependency-injection/typed/typed-service-registry.interface";
import {HeaderLoaderSkeleton, HeaderLoaderSkeletonTypeName} from "./header-loader.skeleton";
import {DependencyType} from "sirius-platform-support-library/dependency-injection/typed/dependency-type.enum";
import {NavbarLoaderSkeleton, NavbarLoaderSkeletonTypeName} from "./navbar-loader.skeleton";
import {FooterLoaderSkeleton, FooterLoaderSkeletonTypeName} from "./footer-loader.skeleton";
import {ContentLoaderSkeleton, ContentLoaderSkeletonTypeName} from "./content-loader.skeleton";
import {
    IMicroFrontendLoadingSkeleton,
    IMicroFrontendLoadingSkeletonTypeName
} from "sirius-platform-support-library/shared/site/loaders/micro-frontend-loading-skeleton.interface";

export class MicroFrontendsSkeletonsDependencyModule extends TypedDependencyModule {
    public register(dependencyContainer: ITypedServiceRegistry): void {
        dependencyContainer
            .addUsingInstanceAsSingleton<IMicroFrontendLoadingSkeleton, HeaderLoaderSkeleton>(DependencyType.PRIVATE, IMicroFrontendLoadingSkeletonTypeName, HeaderLoaderSkeletonTypeName, new HeaderLoaderSkeleton())
            .addUsingInstanceAsSingleton<IMicroFrontendLoadingSkeleton, NavbarLoaderSkeleton>(DependencyType.PRIVATE, IMicroFrontendLoadingSkeletonTypeName, NavbarLoaderSkeletonTypeName, new NavbarLoaderSkeleton())
            .addUsingInstanceAsSingleton<IMicroFrontendLoadingSkeleton, ContentLoaderSkeleton>(DependencyType.PRIVATE, IMicroFrontendLoadingSkeletonTypeName, ContentLoaderSkeletonTypeName, new ContentLoaderSkeleton())
            .addUsingInstanceAsSingleton<IMicroFrontendLoadingSkeleton, FooterLoaderSkeleton>(DependencyType.PRIVATE, IMicroFrontendLoadingSkeletonTypeName, FooterLoaderSkeletonTypeName, new FooterLoaderSkeleton());
    }
}

export const microFrontendsSkeletonsDependencyModule = new MicroFrontendsSkeletonsDependencyModule();
